import React, { useEffect, useState } from 'react';
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import * as Constants from "../../../../config/Constants";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import * as actions from "../../../../actions";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import { BwmInput } from "../../../shared/form/BwmInput";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustom";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import { BtnFill } from "../../../shared/BtnFill";
import { DateField } from "../../../shared/form/DateField";
import { ProvinceField } from "../../application-cora/relationships/ProvinceField";
import CodiceFiscale from "codice-fiscale-js";
import { PersonTypeField } from "../../application-cora/relationships/PersonTypeField";
import { isEmpty } from '../../../shared/Utility';

registerLocale("it", it);


export const ModaleAggiungiDelegati = ({ relationship
    , relationshipLocations
    , roles
    , handlerSubmitDelegate
    , onHide
    , delegatePerson
    , index }) => {
    const [formDataDelegate, updateFormDataDelegate] = useState({
        personType: Constants.PF,
        coraRelationship: {
            id: relationship?.id ? relationship?.id : null
        }
    });

    let startDate = delegatePerson?.startDate ? new Date(delegatePerson.startDate) : (relationship?.startDate ? new Date(relationship.startDate) : null);
    const [startDateDelegate, setStartDateDelegate] = useState(startDate);
    const [errorStartDateDelegate, setErrorStartDateDelegate] = useState({ isValid: true, msg: "" });
    const [endDateDelegate, setEndDateDelegate] = useState(delegatePerson?.endDate ? new Date(delegatePerson.endDate) : null);
    const [errorEndDateDelegate, setErrorEndDateDelegate] = useState({ isValid: true, msg: "" });
    const [roleDelegate, setRoleDelegate] = useState(delegatePerson?.role?.id ? delegatePerson.role.id : 5);
    const [errorRoleDelegate, setErrorRoleDelegate] = useState({ isValid: true, msg: "" });
    const [fiscalCodeDelegate, setFiscalCodeDelegate] = useState(delegatePerson?.fiscalCode ? delegatePerson.fiscalCode : "");
    const [errorFiscalCodeDelegate, setErrorFiscalCodeDelegate] = useState({ isValid: true, msg: "" });
    const [lastNameDelegate, setLastNameDelegate] = useState(delegatePerson?.lastName ? delegatePerson.lastName : "");
    const [errorLastNameDelegate, setErrorLastNameDelegate] = useState({ isValid: true, msg: "" });
    const [firstNameDelegate, setFirstNameDelegate] = useState(delegatePerson?.firstName ? delegatePerson.firstName : "");
    const [errorFirstNameDelegate, setErrorFirstNameDelegate] = useState({ isValid: true, msg: "" });
    const [genderDelegate, setGenderDelegate] = useState(delegatePerson?.gender || Constants.genderList[0].id)
    const [errorGenderDelegate, setErrorGenderDelegate] = useState({ isValid: true, msg: "" });

    const [birthDateDelegate, setBirthDateDelegate] = useState(delegatePerson?.birthDate ? new Date(delegatePerson.birthDate) : null);
    const [errorBirthDateDelegate, setErrorBirthDateDelegate] = useState({ isValid: true, msg: "" });
    const [birthLocationDelegate, setBirthLocationDelegate] = useState(delegatePerson?.location ? delegatePerson.location : "");
    const [errorBirthLocationDelegate, setErrorBirthLocationDelegate] = useState({ isValid: true, msg: "" });
    const [birthProvDelegate, setBirthProvDelegate] = useState(delegatePerson?.province ? delegatePerson.province : "");
    const [errorBirthProvDelegate, setErrorBirthProvDelegate] = useState({ isValid: true, msg: "" });
    const [personType, setPersonType] = useState(delegatePerson?.personType || Constants.PF);
    const [companyName, setCompanyName] = useState(delegatePerson?.companyName || '');
    const [errorCompanyName, setErrorCompanyName] = useState({ isValid: true, msg: "" });
    const [errorPersonType, setErrorPersonType] = useState({ isValid: true, msg: "" });
    const [displayPf, setDisplayPf] = useState(true);
    const [displayPnf, setDisplayPnf] = useState(false);



    useEffect(() => {
        setRoleDelegate(delegatePerson?.role?.id ? delegatePerson.role.id : 5);
        if (personType == Constants.PF) {
            setDisplayPf(true);
            setDisplayPnf(false);
        } else if (personType == Constants.PNF) {
            setDisplayPf(false);
            setDisplayPnf(true);
        }
        updateFormDataDelegate({
            ...formDataDelegate,
            coraRelationship: {
                id: relationship?.id ? relationship?.id : null
            },
            personType: personType,
            startDate: startDateDelegate,
            companyName: companyName,
            fiscalCode: fiscalCodeDelegate,
            endDate: endDateDelegate,
            role: { id: roleDelegate },
            firstName: firstNameDelegate,
            lastName: lastNameDelegate,
            gender: genderDelegate,
            birthDate: birthDateDelegate,
            location: birthLocationDelegate,
            province: birthProvDelegate,
            id: delegatePerson?.id || index+1
        });
    }, []);

    const handleInputBirthLocationDelegateAutocompile = (newLocation) => {

        if (birthProvDelegate?.toUpperCase() === 'EE') {
            setBirthLocationDelegate(newLocation?.toUpperCase() || '');
            updateFormDataDelegate({
                ...formDataDelegate,
                location: newLocation.toUpperCase(),
                province: birthProvDelegate?.toUpperCase() || ''
            });
            return;
        }
        actions.getLocation(newLocation?.toUpperCase()).then(
            (loc) => {
                if (loc) {
                    setBirthProvDelegate(loc.province);
                    updateFormDataDelegate({
                        ...formDataDelegate,
                        coraRelationship: {
                            id: relationship?.id ? relationship?.id : null
                        },
                        location: newLocation.toUpperCase(),
                        province: loc.province
                    });
                }
            },
            (errors) => {
                console.log(errors);
            })
        setBirthLocationDelegate(newLocation?.toUpperCase());
        updateFormDataDelegate({
            ...formDataDelegate,
            location: newLocation.toUpperCase()
        })
    };

    const handlerStartDate = date => {
        setStartDateDelegate(date);
        updateFormDataDelegate({
            ...formDataDelegate,
            coraRelationship: {
                id: relationship?.id ? relationship?.id : null
            },
            startDate: date,
        });
    };

    const handlerEndDate = date => {
        setEndDateDelegate(date);
        updateFormDataDelegate({
            ...formDataDelegate,
            coraRelationship: {
                id: relationship?.id ? relationship?.id : null
            },
            endDate: date,
        });
    };

    const handlerBirthDate = date => {
        setBirthDateDelegate(date);
        updateFormDataDelegate({
            ...formDataDelegate,
            coraRelationship: {
                id: relationship?.id ? relationship?.id : null
            },
            birthDate: date,
        });
    };

    const handlerRole = (e) => {


        setRoleDelegate(e.target.value.trim());
        updateFormDataDelegate({
            ...formDataDelegate,
            coraRelationship: {
                id: relationship?.id ? relationship?.id : null
            },
            role: { id: e.target.value.trim(), code: e.target.getAttribute("data-cod"), description: e.target[e.target.value].text }
        });

    };

    const handlerChangeFiscalCode = (e) => {
        setFiscalCodeDelegate(e.target.value?.trim().toUpperCase());
        updateFormDataDelegate({
            ...formDataDelegate,
            coraRelationship: {
                id: relationship?.id ? relationship?.id : null
            },
            fiscalCode: e.target.value?.trim().toUpperCase(),
        });
    };

    const handlerLastName = (e) => {
        setLastNameDelegate(e.target.value.toUpperCase());
        updateFormDataDelegate({
            ...formDataDelegate,
            coraRelationship: {
                id: relationship?.id ? relationship?.id : null
            },
            lastName: e.target.value.toUpperCase(),
        });
    };

    const handlerFirstName = (e) => {
        setFirstNameDelegate(e.target.value.toUpperCase());
        updateFormDataDelegate({
            ...formDataDelegate,
            coraRelationship: {
                id: relationship?.id ? relationship?.id : null
            },
            firstName: e.target.value.toUpperCase(),
        });
    }

    const handlerGender = (e) => {
        setGenderDelegate(e.target.value);
        updateFormDataDelegate({
            ...formDataDelegate,
            coraRelationship: {
                id: relationship?.id ? relationship?.id : null
            },
            gender: e.target.value,
        });
    };

    const handleKeyPress = val => {
            if (personType === Constants.PF) {
                try {
                    const cf = new CodiceFiscale(val).toJSON();
                    if (cf) {
                        getFiscalCodeData(cf);
                    }
                } catch (e) {
                    console.log("codice fiscale errato");
                }
            }
    };


    const getFiscalCodeData = (cf) => {
        const formatLoc = cf?.birthplace.toUpperCase();
        birthDateDelegate || setBirthDateDelegate(new Date(cf?.birthday) || null);
        setGenderDelegate(cf?.gender === "M" ? 'MALE' : 'FEMALE');
        if (cf.birthplaceProvincia !== 'EE') {
            birthLocationDelegate || setBirthLocationDelegate(formatLoc || '');
        }
        birthProvDelegate || setBirthProvDelegate(cf?.birthplaceProvincia || '');
        updateFormDataDelegate({
            ...formDataDelegate,
            coraRelationship: {
                id: relationship?.id ? relationship?.id : null
            },
            location: birthLocationDelegate || cf.birthplaceProvincia !== 'EE' ? formatLoc : '',
            province: birthProvDelegate || cf?.birthplaceProvincia || '',
            birthDate: birthDateDelegate || new Date(new Date(cf?.birthday) || null),
            gender: cf?.gender === "M" ? 'MALE' : 'FEMALE'
        });
    }

    const handleBirthProvChange = (e) => {
        setBirthProvDelegate(e.target.value.toUpperCase());
        updateFormDataDelegate({
            ...formDataDelegate,
            province: e.target.value.toUpperCase(),
            location: birthLocationDelegate
        });
    };


    const handleValidation = () => {
        resetError();

        let isValid = true;
        if (startDateDelegate === undefined || isEmpty(startDateDelegate)) {
            setErrorStartDateDelegate({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        } else if (new Date(startDateDelegate) < new Date(relationship.startDate)) {
            setErrorStartDateDelegate({ isValid: false, msg: "Data di inizio deve essere uguale o successiva a data inizio rapporto" });
            isValid = false;
        }
        if (roleDelegate === undefined || isEmpty(roleDelegate)) {
            setErrorRoleDelegate({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }


        if (personType === Constants.PF) {
            if (lastNameDelegate === undefined || isEmpty(lastNameDelegate)) {
                setErrorLastNameDelegate({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            } else {
                formDataDelegate.lastName = lastNameDelegate?.trim();
            }
            if (firstNameDelegate === undefined || isEmpty(firstNameDelegate)) {
                setErrorFirstNameDelegate({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            } else {
                formDataDelegate.firstName = firstNameDelegate?.trim();
            }
            if (genderDelegate === undefined || isEmpty(genderDelegate)) {
                setErrorGenderDelegate({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }
            if (birthDateDelegate === undefined || isEmpty(birthDateDelegate)) {
                setErrorBirthDateDelegate({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }
        } else {
            if (companyName === undefined || isEmpty(companyName)) {
                setErrorCompanyName({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            } else {
                formDataDelegate.companyName = companyName?.trim();
            }
        }

        if (birthLocationDelegate === undefined || isEmpty(birthLocationDelegate)) {
            setErrorBirthLocationDelegate({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        } else {
            formDataDelegate.location = birthLocationDelegate?.trim();
        }
        if (birthProvDelegate === undefined || isEmpty(birthProvDelegate)) {
            setErrorBirthProvDelegate({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        } else {
            formDataDelegate.province = birthProvDelegate?.trim();
        }
        return isValid;
    };

    const resetError = () => {
        setErrorStartDateDelegate({ isValid: true, msg: "" });
        setErrorEndDateDelegate({ isValid: true, msg: "" });
        setErrorRoleDelegate({ isValid: true, msg: "" });
        setErrorFiscalCodeDelegate({ isValid: true, msg: "" });
        setErrorLastNameDelegate({ isValid: true, msg: "" });
        setErrorFirstNameDelegate({ isValid: true, msg: "" });
        setErrorBirthLocationDelegate({ isValid: true, msg: "" });
        setErrorGenderDelegate({ isValid: true, msg: "" });
        setErrorBirthDateDelegate({ isValid: true, msg: "" });
        setErrorBirthProvDelegate({ isValid: true, msg: "" });
        setErrorCompanyName({ isValid: true, msg: "" });
    };


    const handlerSubmit = () => {
        if (handleValidation()) {
            handlerSubmitDelegate(formDataDelegate);
            handlerClose();
        }
    };

    const handlerClose = () => {
        resetError();
        onHide();
    };

    const handlePersonTypeClick = event => {
        setPersonType(event.target.value);
        updateFormDataDelegate({
            ...formDataDelegate,
            personType: event.target.value
        });
        if (event.target.value == Constants.PF) {
            updateFormDataDelegate({
                ...formDataDelegate,
                personType: event.target.value,
                companyName: '',
                gender: null
            });
            setDisplayPf(true);
            setDisplayPnf(false);

        } else if (event.target.value == Constants.PNF) {
            updateFormDataDelegate({
                ...formDataDelegate,
                personType: event.target.value,
                birthDate: null,
                firstName: '',
                lastName: '',
                gender: null
            });
            setDisplayPf(false);
            setDisplayPnf(true);
        }
    };

    const handlerChangeCompanyName = (e) => {
        setCompanyName(e.target.value.toUpperCase());
        updateFormDataDelegate({
            ...formDataDelegate,
            companyName: e.target.value.toUpperCase()
        });
    };


    return (

        <Modal
            show={true}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static" size="xl"
            centered
            onHide={handlerClose}
            bsPrefix="modal-create modal">
            <div className="bar"></div>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    FAMIGLIA (Anagrafiche collegate al rapporto)
                    <h4>
                        {relationship?.companyName
                            ? relationship.companyName
                            : (relationship.lastName + " " + relationship.firstName)}
                    </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className="show-grid">
                        <Col md={2} lg={2} className="me-4">
                            <DateField label={"Data Inizio"} date={startDateDelegate}
                                onChange={date => handlerStartDate(date)} error={errorStartDateDelegate} />
                        </Col>
                        <Col md={2} lg={2} className="me-4">
                            <DateField label={"Data Fine"} date={endDateDelegate}
                                onChange={date => handlerEndDate(date)} error={errorEndDateDelegate} />
                        </Col>
                        <Col md={6} lg={6} className="me-6">
                            <BwmSelect
                                options={roles}
                                name='role'
                                label='Ruolo'
                                className='form-control'
                                onChange={(e) => handlerRole(e)}
                                error={errorRoleDelegate}
                                value={roleDelegate}
                                showCode={true}
                            />
                        </Col>
                    </Row>
                    <div className="row">

                        <div className="col-md-2 col-lg-2">
                            <PersonTypeField personType={personType} onClick={handlePersonTypeClick}
                                error={errorPersonType} />
                        </div>
                        <div id="infoPnf" className={"col-md-10 col-lg-10" + (!displayPnf ? " d-none" : "")}>
                            <div className="form-row col-md-12 col-lg-12">
                                <div className="col-md-3 col-lg-3 me-5 offset-1">
                                    <BwmInput name='fiscalCode'
                                        label='Codice Fiscale'
                                        className='form-control'
                                        value={fiscalCodeDelegate}
                                        error={errorFiscalCodeDelegate}
                                        maxLength="16"
                                        onChange={e => handlerChangeFiscalCode(e)}
                                        onBlur={e => handleKeyPress(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-5 col-lg-5">
                                    <BwmInput name='companyName'
                                        label='Denominazione'
                                        className='form-control'
                                        value={companyName}
                                        onChange={e => handlerChangeCompanyName(e)}
                                        error={errorCompanyName}
                                        maxLength="60"
                                    />
                                </div>
                                <div className="col-md-5 col-lg-5 me-5 offset-1">
                                    <AutoCompileCustom
                                        label='Sede Legale (Comune) '
                                        id="location"
                                        handleInputAutocompile={handleInputBirthLocationDelegateAutocompile}
                                        filtro={relationshipLocations}
                                        value={birthLocationDelegate}
                                        error={errorBirthLocationDelegate}
                                    />
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <ProvinceField province={birthProvDelegate}
                                        onChange={e => handleBirthProvChange(e)}
                                        error={errorBirthProvDelegate} />
                                </div>
                            </div>
                        </div>
                        <div id="infoPf" className={"col-md-10 col-lg-10" + (!displayPf ? " d-none" : "")}>
                            <div className="form-row col-md-12 col-lg-12">
                                <div className="col-md-3 col-lg-3">
                                    <BwmInput name='fiscalCode'
                                        label='Codice Fiscale'
                                        className='form-control'
                                        value={fiscalCodeDelegate}
                                        error={errorFiscalCodeDelegate}
                                        maxLength="16"
                                        onChange={e => handlerChangeFiscalCode(e)}
                                        onBlur={e => handleKeyPress(e.target.value)}
                                    />

                                </div>

                                <div className="col-md-3 col-lg-3 offset-1">
                                    <BwmInput name='lastName'
                                        label='Cognome'
                                        className='form-control'
                                        value={lastNameDelegate}
                                        onChange={e => handlerLastName(e)}
                                        error={errorLastNameDelegate}
                                        maxLength="26"
                                    />
                                </div>
                                <div className="col-md-3 col-lg-3 offset-1">
                                    <BwmInput name='firstName'
                                        label='Nome'
                                        className='form-control'
                                        value={firstNameDelegate}
                                        onChange={e => handlerFirstName(e)}
                                        error={errorFirstNameDelegate}
                                        maxLength="25"
                                    />
                                </div>
                            </div>
                            <div className="form-row col-md-12 col-lg-12">
                                <div className="col-md-1 col-lg-1">
                                    <BwmSelect
                                        options={Constants.genderList}
                                        name='gender'
                                        label='Sesso'
                                        className='form-control'
                                        onChange={handlerGender}
                                        error={errorGenderDelegate}
                                        value={genderDelegate}
                                    />
                                </div>
                                <div className="col-md-3 col-lg-3 offset-1">
                                    <DateField label={"Data di Nascita"} date={birthDateDelegate}
                                        onChange={date => handlerBirthDate(date)}
                                        error={errorBirthDateDelegate} name={"birthDate"} />
                                </div>
                                <div className={"col-md-3 col-lg-3 offset-1"}>
                                    <AutoCompileCustom
                                        label='Comune di Nascita'
                                        id="birthLocation"
                                        handleInputAutocompile={handleInputBirthLocationDelegateAutocompile}
                                        filtro={relationshipLocations}
                                        value={birthLocationDelegate}
                                        error={errorBirthLocationDelegate}
                                    />
                                </div>
                                <div className="col-md-2 col-lg-2 offset-1">
                                    <ProvinceField province={birthProvDelegate}
                                        onChange={e => handleBirthProvChange(e)}
                                        error={errorBirthProvDelegate} />
                                </div>

                            </div>
                        </div>
                    </div>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={handlerClose} />
                <BtnFill text="CONFERMA" classCustom="float-end text-end" handlerOnClick={handlerSubmit} />
            </Modal.Footer>
        </Modal>);
};

export default (ModaleAggiungiDelegati)
