// Adattare FormDelegati, gestendo le varie insert/update/delete. No chiamate a backend, se non alla fine

import React, { useState } from 'react';
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import * as styles from "../../../../config/styles";
import "react-datepicker/dist/react-datepicker.css";

import { ModaleAggiungiDelegati } from "./ModaleAggiungiDelegati";
import { BtnDelete } from "../../../shared/BtnDelete";
import { PopupDelete } from "../../../shared/PopupDelete";

import { formatDateForDisplay, isNotEmpty } from '../../../shared/Utility';


registerLocale("it", it);

export const FormDelegati = ({ relationship
    , relationshipLocations
    , roles
    , onSave
}) => {


    const [delegates, setDelegates] = useState(relationship?.coraRelationDelegates || []);
    const [delegateCheckedItems, setDelegateCheckedItems] = useState(new Map());
    const [allCheckDelegate, setAllCheckDelegate] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalDelegate, setModalDelegate] = useState(undefined);


    const handleDelegateAllCheckChange = event => {
        setAllCheckDelegate(event.target.checked);
        if (event.target.checked && delegates.length > 0) {
            if (delegateCheckedItems.size > 0) {
                delegateCheckedItems.clear();
            } else {
                delegates.map((delegate, index) => (
                    delegateCheckedItems.set(delegate.id, true)
                ));
            }
            setDelegateCheckedItems(new Map(delegateCheckedItems));
            setIsDisabled(false);
        } else {
            delegateCheckedItems.clear();
            setIsDisabled(true);
        }

    };


    const handleDelegateCheckChange = event => {
        delegateCheckedItems.set(event.target.value, event.target.checked)
        setDelegateCheckedItems(new Map(delegateCheckedItems));
        let count = 0;
        for (const checkedItem of delegateCheckedItems) {
            if (checkedItem[1]) {
                setIsDisabled(false);
                return;
            }
            if (!checkedItem[1]) {
                count++;
            }
        }
        if (count === delegateCheckedItems.size) {
            setIsDisabled(true);
        }
    };

    const handlerSubmitDelegate = (formDataDelegate) => {
        // Pass formDataDelegate to FormRapporto in order to save it in rapporti,
        const index = delegates.findIndex((d) => formDataDelegate.id === d.id);
        let updatedDelegates = delegates;
        if (index !== -1) {
            updatedDelegates[index] = formDataDelegate;
        } else {
            updatedDelegates = [...delegates, formDataDelegate];
        }
        setDelegates(updatedDelegates);
        onSave(updatedDelegates);
        setShowModal(false)
        setModalDelegate(undefined);

    };


    const openPopupDelete = () => {
        let array = Array.from(delegateCheckedItems.keys())
        let data = [];
        delegates.forEach(function (entry) {
            if (array.some((el) => el == entry.id)) {
                data.push(entry);
            }

        });
        let finalArray = [];
        data.forEach(function (entry) {
            finalArray.push("- " + entry.fiscalCode);
        });

        PopupDelete({
            list: finalArray,
            operazione: "Titolare Effettivo e Delegato",
            handleClickConfirm: handlerDeleteDelegate
        });
    };


    const handlerDeleteDelegate = () => {

        let data = delegates;
        for (const checkedItem of delegateCheckedItems) {
            //rimuovere semplicemente dall'array
            const id = checkedItem[0];
            const isChecked = checkedItem[1];
            if (isChecked) {
                data = data.filter((d) => id != d.id)
            }
        }
        setDelegates(data);
        onSave(data);
        setAllCheckDelegate(false);
        setIsDisabled(true);
    };



    const handlerShowDelegateForm = (delegate) => {
        setShowModal(true)
        if (isNotEmpty(delegate)) {
            setModalDelegate(delegate);
        }
    };

    const addNewDelegateForm = () => {
        setShowModal(true)
    };



    const closeModal = () => {
        setShowModal(false)
    };

    const getRoleDesc = (selectedItem) => {

        let roleSelected = "";

        if (selectedItem) {
            roleSelected = roles.filter(role => role.id == selectedItem);
            return roleSelected[0].code + " - " + roleSelected[0].description;
        } else {
            return roleSelected;
        }

    };

    const getLatestDelegateId = ()=>{
        const del = delegates[delegates.length-1];
        return del?.id || 0;
    }

    const renderDelegateRows = (delegates, delegateCheckedItems, handleDelegateCheckChange, handlerShowDelegateForm, getRoleDesc) => {

        return delegates.length > 0 && delegates.map((delegate) => (
            <div className='text-start row-table' id={"row-" + delegate?.id} key={"key-delegate-" + delegate?.id}>
                <div className="col w-5 text-center div-td">
                    <input type="checkbox" value={delegate?.id} id={"delegate-" + delegate?.id} name={"delegateCheck"}
                        checked={delegateCheckedItems.get(delegate?.id)}
                        onChange={handleDelegateCheckChange} />
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {getRoleDesc(delegate?.role?.id)}
                </div>
                <div className="col w-30 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate.companyName
                        ? delegate.companyName
                        : (delegate.lastName + " " + delegate.firstName)}
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.fiscalCode ? delegate?.fiscalCode : ""}
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.birthDate ? formatDateForDisplay(delegate?.birthDate) : ""}
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.location ? delegate?.location : ""}
                </div>
                <div className="col w-10 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.province ? delegate?.province : ""}
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.startDate ? formatDateForDisplay(delegate?.startDate) : ""}
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.endDate ? formatDateForDisplay(delegate?.endDate) : ""}
                </div>
            </div>
        ));
    }

    return (
        <div className="row">
            <div className="col-md-12 col-lg-12"><h5>Elenco anagrafiche collegate al rapporto</h5></div>
            <div className="col-md-12 col-lg-12 form-row header-btn small align-items-center">
                <BtnDelete handlerOnClick={() => openPopupDelete()} isDisabled={isDisabled} />
                <div className="ms-auto text-end me-2">
                    <button className={styles.btnFillPillSm} onClick={() => addNewDelegateForm()} >
                        + AGGIUNGI
                    </button>
                </div>
            </div>
            <div id={"listDelegates_"} className="col-md-12 col-lg-12 table-delegate">
                <div className="col-md-12 col-lg-12 header-table p-0">
                    <div className="col w-5 text-center div-td">
                        <input type="checkbox" checked={allCheckDelegate} value={allCheckDelegate} onChange={handleDelegateAllCheckChange} />
                    </div>
                    <div className="col w-20 div-td">
                        Ruolo
                    </div>
                    <div className="col w-30 div-td">
                        Denominazione
                    </div>
                    <div className="col w-20 div-td">
                        Codice Fiscale
                    </div>
                    <div className="col w-20 div-td">
                        Data di nascita
                    </div>
                    <div className="col w-20 div-td">
                        Comune
                    </div>
                    <div className="col w-10 div-td">
                        Provincia
                    </div>
                    <div className="col w-20 div-td">
                        Data Inizio
                    </div>
                    <div className="col w-20 div-td">
                        Data Fine
                    </div>
                </div>

                {renderDelegateRows(delegates, delegateCheckedItems, handleDelegateCheckChange, handlerShowDelegateForm, getRoleDesc)
                }

            </div>
            {showModal &&
                <ModaleAggiungiDelegati
                    key={modalDelegate?.id || new Date().toString()}
                    onHide={() => closeModal()}
                    handlerSubmitDelegate={handlerSubmitDelegate}
                    relationship={relationship}
                    relationshipLocations={relationshipLocations}
                    roles={roles}
                    index={getLatestDelegateId()}
                    delegatePerson={modalDelegate}
                />
            }
        </div>
    )
};

export default (FormDelegati)
