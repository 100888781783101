import React from 'react';
import { RelationshipRowDetail } from "./RelationshipRowDetail";
import show from "../../../../styles/images/show_blu.png";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { cleanErrorString } from './Relationships';
import { formatDateForDisplay } from '../../../shared/Utility';
import { StarTooltip } from '../../../shared/tooltips/Tooltip';

export class RelationshipRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
        this.handleRowChange = this.handleRowChange.bind(this);
    }

    componentDidMount() {
        if (this.props.autoOpen) {
            this.setState({
                show: !this.state.show
            })
        }
    }

    handleRowChange(e) {
        this.props.handleCheckChange(e);
    }

    showDetail = (id) => {

        this.setState({
            show: !this.state.show
        })

    };



    render() {
        const { subject, subjectId, relationship, relationshipTypes, relationshipLocations, relationshipProvinces,
            index, currencies, roles, communicationTypes, getRelationships, isCloned } = this.props;
        let toCommunicate = "";
        if (relationship.lastCommunicationError) {
            toCommunicate = relationship.lastCommunicationError;
        } else if (relationship.includedInNextCommunication === true) {
            toCommunicate = relationship.coraCommunicationType.code + " - " + relationship.coraCommunicationType.description;
        }

        const backgroundColor = () => {
            if (isCloned) {
                return "bg-green-row"
            } else if (relationship.lastCommunicationError) {
                return "bg-yellow-row";
            } else if (relationship.includedInNextCommunication) {
                return "bg-pink";
            }
        }

        return (
            <React.Fragment>
                <div className={`text-start row-table ${backgroundColor()}`} id={`row-${relationship.id}`}>
                    <div className='col w-5 text-center div-td'>
                        <input type="checkbox" aria-label="Checkbox for following text input" id={`input-row-${relationship.id}`}
                            name="relationshipCheck"
                            value={relationship.id} checked={this.props.isChecked}
                            onChange={(e) => this.handleRowChange(e)}
                        />
                    </div>
                    <div onClick={() => { this.showDetail() }} 
                        className='col w-20 div-td text-truncate'>
                        {relationship.completeName}
                    </div>
                    <div onClick={() => { this.showDetail() }} 
                        className='col w-10 div-td text-truncate'>{relationship.fiscalCode}</div>
                    <div onClick={() => { this.showDetail() }}
                        className='col w-7 div-td text-truncate'>{formatDateForDisplay(relationship.startDate)}</div>
                    <div onClick={() => { this.showDetail() }}
                        className='col w-7 div-td text-truncate'>{relationship.endDate && formatDateForDisplay(relationship.endDate)}</div>
                    <div onClick={() => { this.showDetail() }} 
                        className='col w-12 div-td text-truncate'>{relationship.relationshipType.code} - {relationship.relationshipType.description}</div>
                    <StarTooltip text={this.props.showIdentifiers ? relationship.identifier : relationship.comment}>
                        <div onClick={() => { this.showDetail() }}
                            className='col w-33 div-td text-truncate'>{this.props.showIdentifiers ?

                                <>{relationship.identifier}</>
                                :
                                <>{relationship.comment}</>

                            }</div>
                    </StarTooltip>
                    <div onClick={() => { this.showDetail() }}
                        className='col w-10 div-td text-truncate'>
                        <span data-tip={toCommunicate}>{cleanErrorString(toCommunicate)}</span>
                    </div>
                    <div id={"detail-" + relationship.id} className='hidden' onClick={() => { this.showDetail(relationship.id) }}><img src={show} alt={"Mostra dettagli"} /></div>
                </div>
                <TransitionGroup component={null}>
                    {this.state.show &&
                        <CSSTransition
                            in={this.state.show}
                            timeout={300}
                            classNames="visible"
                            unmountOnExit>
                            <RelationshipRowDetail
                                key={'relationShipRowDetail_' + relationship.id}
                                onClone={this.props.onClone}
                                subject={subject}
                                subjectId={subjectId}
                                relationship={relationship}
                                relationshipTypes={relationshipTypes}
                                relationshipLocations={relationshipLocations}
                                relationshipProvinces={relationshipProvinces}
                                currencies={currencies}
                                roles={roles}
                                communicationTypes={communicationTypes}
                                index={index}
                                getRelationships={getRelationships}
                                handlerShowDetail={this.showDetail}
                            />
                        </CSSTransition >

                    }
                </TransitionGroup>
            </React.Fragment>
        )
    }
}
