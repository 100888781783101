import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

export function NumberOfResultsSelector(props) {

    const defaultOptions = [20, 50, 100];
    const options = props.options || defaultOptions;
    const [selected, setSelected] = useState(props.selected || options[1]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    const handleChange = (e) => {
        props.onChange(e.target.text);
    }

    function renderItems() {
        return options.map((opt) => {
            return (
                <Dropdown.Item key={'results-per-page-' + opt} onClick={e => handleChange(e)}>{opt}</Dropdown.Item>
            )
        })
    }


    return (
        <React.Fragment>

            <DropdownButton title={'N. Elementi: ' + selected}
                drop={"right"}
                bsPrefix={'btn-empty'}>
                {renderItems()}
            </DropdownButton>

        </React.Fragment>
    )



}