import React from "react";
import { IQuestion } from "../../../styles/icons";
import { assistanceRequest, getCurrentApplicationName } from "../Utility";

export function ButtonAssistance({buttonTitle}) {
    const onClick = () => {
        const link = document.createElement('a');
        const applicationName = getCurrentApplicationName();
        link.href = `mailto:assistenza@starinfostudio.it?
        subject=${applicationName.toUpperCase()} - Richiesta assistenza`;
        document.body.appendChild(link);
        link.click();
        assistanceRequest(applicationName);
        
    };

    return (
    <button className="btn btn-primary rounded-pill btn-new-rel mb-3" onClick={onClick}>
        <IQuestion className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; {buttonTitle || "RICHIEDI ASSISTENZA"}
    </button>)

}