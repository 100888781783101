import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import UploadReceiptEmailForm from "./UploadReceiptEmailiForm";
import { Link } from "react-router-dom";
import { ReactComponent as ITextFile } from "../../../../styles/images/svg/file-alt.svg";
import axiosService from "../../../../services/axios-service";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { CommunicationStatus } from "../../../../config/CommunicationStatus";
/**
 * Questa funzione serve per visualizzare il popup, con i parametri passati in input
 * @param {*} props {props: tutte le props del padre, fileName: nome del file analizzato, communication: dati di ritorno dal bakend}
 * @returns popup creato
 */
export default function ModalProcessingOutComeReceipt(props) {
  const [showReceiptModal, setshowReceiptModal] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");

  const companyName = props.nameSubject;
  useEffect(() => {
    let communication = props.communication;
    if (!communication?.id) {
      setErrorModal(true);
      setErrorModalMessage(communication?.communicationError);
    }
  }, []);

  const closeReceiptModal = () => {
    setshowReceiptModal(false);
    setErrorModal(false);
    setErrorModalMessage("");
  };

  const downloadReceiptTxt = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadReceiptTxt/${communication.subject.id}/${communication.id}`;
    callDownloadService(
      url,
      "ATPECRQ." + communication.sidFilename + ".run.txt"
    );
  };

  const callDownloadService = (url, file) => {
    const axiosInstance = axiosService.getInstance();
    axiosInstance({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
        PopupSuccess({ text: "File scaricato" });
      }
    });
  };
  let status = "";
  let communicationStatus = props.communication.communicationStatus;
  if (communicationStatus === CommunicationStatus.CREATED) {
    status = "Creata - In attesa di ricevuta";
  } else if (communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) {
    status = "Creata con errori";
  } else if (communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
    status = "Comunicazione ACCOLTA TOTALMENTE";
  } else if (communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR) {
    status = "Comunicazione ACCOLTA PARZIALMENTE (al netto degli errori indicati)";
  } else if (communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
    status = "Comunicazione SCARTATA TOTALMENTE";
  } else if (communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_AND_COMMUNICATION_NOT_FIND) {
    status = "Ricevuta ACCOLTA TOTALMENTE, comunicazione non trovata su CRS WEB";
  } else if (communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR_AND_COMMUNICATION_NOT_FIND) {
    status = "Ricevuta ACCOLTA CON ERRORI FORMALI, comunicazione non trovata su CRS WEB";
  } else if (communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED_AND_COMMUNICATION_NOT_FIND) {
    status = "Ricevuta SCARTATA TOTALMENTE, comunicazione non trovata su CRS WEB";
  }

  return (
    <Modal
      backdrop="static"
      show={showReceiptModal}
      onHide={closeReceiptModal}
      dialogClassName="modal-60w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>ESITO ELABORAZIONE RICEVUTA SID</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>{'file elaborato: "' + props.fileName + '"'}</h6>
        <h6>Nome soggetto: {companyName}</h6>
        {errorModal ? (
          <div className="popup communication">
            <Row>
              <React.Fragment>
                Stato elaborazione:&nbsp;
                <strong>{errorModalMessage}</strong>
                <div className={"text-center"}></div>
              </React.Fragment>
            </Row>
            <div className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
              <button
                className="btn btn-outline-primary rounded-pill btn-empty px-4 btn-sm"
                onClick={(e) => closeReceiptModal()}
              >
                ANNULLA
              </button>
              <button
                onClick={(e) => closeReceiptModal()}
                className="btn btn-primary rounded-pill btn-new-rel px-5"
              >
                OK
              </button>
            </div>
          </div>
        ) : (
          <div className="popup communication">
            <Row>
              <React.Fragment>
                Stato elaborazione:&nbsp;<strong>{status}</strong>
                <div className="col w-10 div-td text-truncate d-flex justify-content-around pe-4 ps-4 containerPopOver">
                  <button onClick={() => downloadReceiptTxt(props.communication)}>
                    <div className="txt">
                      <ITextFile width="20" fill="#128186" />
                    </div>
                  </button>
                </div>
              </React.Fragment>
            </Row>
            <UploadReceiptEmailForm
              closeModal={closeReceiptModal}
              subjectId={props.communication?.subject?.id}
              communication={props.communication}
              emailAddress={props.communication.subject?.coraSubjectSid?.email}
              flagPec={props.communication.subject?.coraSubjectSid?.flagPec}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
