import React, { useEffect, useState } from 'react';
import { reduxForm } from "redux-form";
import * as actions from "../../../../actions";
import '../../../../styles/comurei-form.css';
import { BwmInput } from "../../../shared/form/BwmInput";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { PopupError, closePopupError } from "../../../shared/PopupError";
import { BwmCheckbox } from "../../../shared/form/BwmCheckbox";
import { PageSpinner } from '../../../shared/spinner/PageSpinner';
import { BtnFill } from '../../../shared/BtnFill';
import { PopupAlert } from '../../../shared/PopupAlert';
import { ParametriPecModal } from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/ParametriPecModal';

const EmailForm = props => {
    const [formData, updateFormData] = useState();
    const coraSubjectSid = props.subject?.coraSubjectSid;
    const subjectId=props.subjectId;
    const [flagPec, setFlagPec] = useState(props.flagPec || false);
    const [emailAddress, setEmailAddress] = useState(props.emailAddress || props.auth?.user?.login);
    const sentCommunications =props.sentCommunications;
    const [errorEmailAddress, setErrorEmailAddress] = useState({ isValid: true, msg: "" });
    const [isLoading, setLoading] = useState(false);

    const [isPecModalOpen, setPecModalOpen] = useState(false);

    useEffect(() => {

        updateFormData({
            ...formData,
            id: subjectId,
            emailAddress: emailAddress,
            flagPec: flagPec
        })
    }, []);

    const confirmSendCommunicationsEmail = (subjectId, sentCommunications, emailAddress, flagPec) => {
        setLoading(true);
        sentCommunications.map((communication) => {
            actions.sendCommunicationMail(subjectId, communication.id, emailAddress, flagPec).then(
                (res) => {
                    if (res) {
                        PopupSuccess({ text: "Email inviata" })
                    } else {
                        PopupError({ text: "Errore nell'invio della email" })
                    }
                },
                (errors) => {
                    PopupError({  text: "Errore nell'invio della email" })
                }).then(
                    () => setLoading(false)
                );
        });
        props.closeModal();
        const history = document.getElementById("history");
        if(history)  history.click();
       
    };

    const handleClose = () => {
        props.closeModal();
    };

    const handlerChangeEmailAddress = (e) => {
        setEmailAddress(e.target.value.toUpperCase());
        updateFormData({
            ...formData,
            emailAddress: e.target.value.toUpperCase()
        });
        setFlagPec(false);
    };

    const handlerChangeFlagPec = (e) => {
        let checked = e.target.checked;
        setFlagPec(checked);
        updateFormData({
            ...formData,
            flagPec: checked
        });
    };

    const resetError = () => {
        setErrorEmailAddress({ isValid: true, msg: "" });
    };

    const handleValidation = () => {
        resetError();
        let isValid = true;
        if (!emailAddress) {
            setErrorEmailAddress({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        } else if (emailAddress.toUpperCase().indexOf("AGENZIAENTRATE") !== -1) {
            setErrorEmailAddress({ isValid: false, msg: "Inserire un destinatario diverso dall'agenzia delle entrate" });
            isValid = false;
        }

        return isValid;
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (handleValidation()) {
            confirmSendCommunicationsEmail(subjectId, sentCommunications, emailAddress, flagPec);
        }
    };

    const openPecConfirmPopup=()=>{
        if(coraSubjectSid?.couldSendPec){
            PopupAlert({handleClickConfirm: ()=>sendFromOwnPec(), title: `Invia Mail PEC`, confirm: "OK, INVIA",
                 innerHtml: `<p>Mittente: ${coraSubjectSid?.pecEmail}</p><p> Destinatario: ${coraSubjectSid?.recipientEmail}</p>`})
        } else {
            PopupError({text: "PEC non configurata correttamente"
                        ,buttonClose: (() => <><BtnFill text={"Configurazione parametri PEC"}
                            handlerOnClick={() => {
                                closePopupError()
                                setPecModalOpen(true)
                            }}></BtnFill></>)})
        }
    }

    const sendFromOwnPec = () => {
        setLoading(true);
        let communicationIds = [];
        sentCommunications.forEach(element => {
            communicationIds.push(element.id);
        });
        actions.sendCoraCommunicationFromOwnPec(subjectId, communicationIds).then(
            (res) => {
                PopupSuccess({ text: "Invio email avviato." })

            },
            (err) => {
                PopupError({
                    text: err?.response?.data,
                    buttonClose: (() => <><BtnFill text={"Configurazione parametri PEC"}
                        handlerOnClick={() => {
                            closePopupError()
                            setPecModalOpen(true)
                        }}></BtnFill></>)
                })
            }).then(
                () => setLoading(false)
            );

    }


    const closePecModal = () => {
        setPecModalOpen(false);
    }


    const renderPecModal = () => {
        return <ParametriPecModal
            coraSubjectSid= {{coraSubjectSid}}
            show={isPecModalOpen}
            onClose={closePecModal}
        />
  
    }

    return (
        <div >
            {renderPecModal()}
            {isLoading && <PageSpinner />}
            <div className="row-detail comurei bg-blue pt-4 pe-3 pb-4 ps-3 mt-5 d-flex radius">
                <div className="tab-content col-md-12 col-lg-12">
                    <div className="form-row">
                        <div className="col-md-12 col-lg-12 subDetailComurei">
                            <div className="row">
                                <div className="col-md-11 col-lg-11">
                                    <BwmInput
                                        id="emailAddress"
                                        type="email"
                                        className="form-control"
                                        label="Indirizzo email a cui spedire le comunicazioni completate"
                                        error={errorEmailAddress}
                                        value={emailAddress}
                                        maxLength="100"
                                        onChange={handlerChangeEmailAddress}
                                    />
                                </div>


                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12 pt-1 pb-1">
                                    <BwmCheckbox
                                        name="flagPec"
                                        label='Selezionare in caso di email PEC'
                                        className='form-control'
                                        value={flagPec}
                                        onClick={handlerChangeFlagPec}
                                    ></BwmCheckbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                <button
                    className="btn btn-empty px-4 btn-sm"
                    onClick={handleClose}>ANNULLA
                </button>
                {props.children}
                <button
                    onClick={() => openPecConfirmPopup()}
                    className="btn btn-new-rel px-5">
                    Invia direttamente da PEC
                </button>
                <button
                    onClick={e => handleFormSubmit(e)}
                    className="btn btn-new-rel px-5">CONFERMA
                </button>
            </div>
        </div>
    )

};

export default reduxForm({
    form: "EmailForm"
})(EmailForm)
