import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { ReactComponent as IPdfFile } from "../../../styles/images/svg/file-pdf.svg";
import { ReactComponent as ICheck } from "../../../styles/images/svgs/regular/check.svg";
import getText from './labels';
import { blueDownloadAntiTerrorism } from '../../../actions';
import { base64ToArrayBuffer, downloadFile } from '../../shared/Utility';
import { PopupSuccess } from '../../shared/PopupSuccess';
import { PopupError } from '../../shared/PopupError';
import moment from 'moment';

export default function BlueSearchResultPopup(props) {

    const data = props.data;
    const subject = props.subject;
    const [size, setSize] = useState(0);
    //const [searchTerm, setSearchTerm] = useState('');
    const lang = useSelector(state => state.blueLanguage.language)
    const labels = getText(lang);


    useEffect(() => {
        if (data?.result && data.result[0].entity) {
            setSize(data.result[0].entity.length);
        } else {
            setSize(0);
        }
    }, [data])

    const downloadDocument = () => {
        blueDownloadAntiTerrorism(subject.id, data.savedEventId, null, lang).then((response) => {
            if (response) {
                let ext = "pdf";
                downloadFile(base64ToArrayBuffer(response), `${subject.denomination}-${moment(data.searchDate).format('YYYY-MM-DD')}.${ext}`)
                PopupSuccess({
                    title: labels.SUCCESSTITLE,
                    text: labels.DOWLOADOK,
                    ok: labels.SUCCESSOK
                });
            } else {
                PopupError({
                    text: labels.FILEERROR,
                    title: labels.WARNING,
                    request: labels.CONTACTSUPPORT,
                    close: labels.CLOSE
                });
            }

        }, (err) => {
            PopupError({
                text: labels.FILEERROR,
                title: labels.WARNING,
                request: labels.CONTACTSUPPORT,
                close: labels.CLOSE
            });
        });
    }

    return (
        <React.Fragment>

            <p>
                <b>
                    {`${labels.SEARCHRESULT1} ${size} ${labels.SEARCHRESULT2}`}
                </b>
            </p>
            <div className="row mt-6 w-100 align-items-center justify-content-around">
                <div className="col-lg-4 ">
                    <button onClick={() => props.onClose()}
                        className="btn btn-primary  btn-new-rel px-3 "><ICheck className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} /> {labels.CLOSE}
                    </button>
                </div>
                <div className="col-lg-6">
                    <button onClick={() => downloadDocument()} className="btn btn-primary w-100 btn-new-rel px-3" >
                        <IPdfFile className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} /> {labels.VIEWPDF}
                    </button>
                </div>
            </div>

        </React.Fragment>
    )
}