import React, { useEffect, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import { BtnFill } from "../../../shared/BtnFill";
import { isEmpty, isNotEmpty } from "../../../shared/Utility";
import { FloatLabelTextBox } from "../../../shared/form/input-boxes/FloatLabelTextBox";
import { isBefore } from "date-fns";

export function DelayedCommunicationModal({ show = false, date = undefined, licenseExpiration, onHide = () => { }, onConfirm = (date) => { } }) {

    const [dateLocal, setDateLocal] = useState(undefined);
    const [error, setError] = useState("");

    useEffect(() => {
        setDateLocal(date);
        setError(getError(date));
    }, []);

    useEffect(() => {
        setError(getError(dateLocal));
    }, [dateLocal]);

    const isDateAfterToday = (date) => {
        if (isNotEmpty(date)) {
            return new Date(date) > new Date();
        }
        return true;
    }

    const getError = (date) => {
        const isAfterToday = isDateAfterToday(date);
        const isBeforeLicenseExpiration = isEmpty(date) ? true : isBefore(new Date(date), new Date(licenseExpiration))

        if (!isAfterToday) {
            return "Selezionare data successiva a quella odierna";
        }
        if (!isBeforeLicenseExpiration) {
            return "La licenza acquistata scade precedentemente alla data selezionata"
        }
    }

    return (
        <Modal
            centered
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            show={show}
            onHide={onHide}
        >
            <div className="bar"></div>
            <Modal.Header closeButton>
                <Modal.Title>
                    IMPOSTA INVIO DIFFERITO
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='ms-3 me-1 mt-3 h-100 '>
                    <div className='col-12'>
                        Imposta la data di creazione della comunicazione.
                        <br></br>Questa verrà spedita tramite PEC alla data indicata
                    </div>

                    <div className='col-8 m-auto'>
                        <div className="mt-3">
                            <FloatLabelTextBox
                                type={"date"}
                                label={"Data di INVIO"}
                                value={dateLocal}
                                onChange={(e) => setDateLocal(e.target.value)}
                                error={error}
                            />

                        </div>
                    </div>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Row className='row align-items-center justify-content-between'>

                    <BtnEmpty
                        classCustom="col"
                        text={"ANNULLA"}
                        handlerOnClick={onHide}
                    />
                    <BtnFill
                        classCustom="col"
                        text={"Conferma"}
                        isDisabled={isEmpty(dateLocal) || isNotEmpty(error)}
                        handlerOnClick={() => onConfirm(dateLocal)}
                    />
                </Row>
            </Modal.Footer>
        </Modal>
    )
}