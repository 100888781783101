import { v4 as uuidv4 } from 'uuid';
import { format, isValid } from "date-fns";
import axiosService from "../../services/axios-service";
import html2canvas from "html2canvas";
import { sendAssistanceRequest } from "../../actions";

/**
 * controlla se un valore val è string vuota, null o undefined
 * potrebbe essere utile includere un controllo su array vuoto e oggetti vuoti
 * @param {*} val 
 * @returns 
 */
export function isEmpty(val) {
    if (val === "" || val === null || val === undefined) {
        return true;
    } else {
        return false;
    }
}

/**
 * vedi {@link isEmpty} 
 * @param {*} val
 * @returns 
 */
export function isNotEmpty(val) {
    if (!isEmpty(val)) {
        return true;
    } else {
        return false;
    }
}

/**
 * converte stringhe base64 in array di bytes, da usare come argomento per downloadFile
 * @param {*} base64 
 * @returns 
 */
export function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

/**
 * funzione per scaricare bytes come file da browser
 * @param {array} blob 
 * @param {string} fileName
 */
export function downloadFile(blob, fileName) {
    const url = window.URL.createObjectURL(new Blob([blob]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
}

/**
 * Permette di settare un valore in un oggetto innestato. Il percorso è definito con dot notation. accetta anche path di primo livello
 * @param {object} obj oggetto da aggiornare
 * @param {object} value il valore da settare
 * @param {string} propPath la path da seguire, ad esempio "oggetto.figlio1.figlio2"
 */
export const updateObjProp = (obj, value, propPath) => {
    const [head, ...rest] = propPath.split('.');

    !rest.length
        ? obj[head] = value
        : updateObjProp(obj[head], value, rest.join());
}

export const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

/**
 * verifica che l'email sia congurente con {@link emailRegexp}
 * @param {*} email 
 * @returns 
 */
export const testEmail = (email) => {
    if (isEmpty(email)) {
        return false;
    }
    return emailRegexp.test(email);
}

/**
 * rimuove le chiavi del secondo oggetto dal primo oggetto
 * @param {*} object 
 * @param {*} objectKeys 
 * @returns nuovo oggetto privo di chiavi
 */
export const removeKeysFromObject=(object={}, objectKeys=[])=>{
    objectKeys.forEach(key=> object = removeKeyFromObject(object, key));
    return object;
}

/**
 * rimuove una data chiave da un oggetto
 * @param {*} object 
 * @param {*} key 
 * @returns nuovo oggetto privo di chiave
 */
export const removeKeyFromObject=(object={}, key="")=>{
    const {[key]: deletedKey, ...otherKeys} = object;
  return otherKeys;
}

/**
 * genera stringa univoca. Da utilizzare come key per componenti di react
 * ATTENZIONE: si consiglia di usare questa funzione nelle seguenti modalità:
 * -componente senza figli e senza un chiaro modo di generare una chiave univoca
 * -per i componenti con figli(specialmente campi di input), conservare e riutilizzare la key dopo averla generata: react la utilizza per mantenere il focus sul componente
 * @param {*} prefix 
 * @param {*} suffix 
 * @returns 
 */
export function generateUniqueKey(prefix="", suffix=""){
    return `${prefix ? prefix+"-":""}${uuidv4()}${suffix?"-"+suffix:""}`
}

/**
 * restituisce una stringa della data in formato "dd/MM/yyyy"
 * @param {*} date
 * @deprecated utilizzare formatDateForDisplay
 * @returns 
 */
export const formatDate=(date)=>{
   return  date ? format(new Date(date), "dd/MM/yyyy") : ""
}


/**
 * restituisce una stringa in formato compatibile con <input type="date">
 * @param {*} date 
 * @returns 
 */
export const formatDateForInput=(date)=>{
    if(isNotEmpty(date)){
        return format(new Date(date), 'yyyy-MM-dd');
    }
    return "";
}

/**
 * formatta hore e minuti da una data. Compatibile con <input type="time">
 * @param {Date} date 
 * @returns 
 */
export const formatTimeFromDate=(date)=>{
    if(isNotEmpty(date)){
        return format(new Date(date), 'HH:mm');
    }
    return "";
}

/**
 * unisce data e ora, l'ora è data da un input di tipo "time", formattato in "HH:mm"
 * @param {*} date 
 * @param {*} time 
 * @returns 
 */
export const joinDateAndTime=(date, time="")=>{
    if(isNotEmpty(date) && isNotEmpty(time) && isValid(new Date(date))){

        const hoursAndMinutes = time.split(":");
        const hours = hoursAndMinutes[0];
        const minutes=hoursAndMinutes[1];

        date.setHours(hours,minutes,0);
        return date;
    }
    return undefined;
}



/**
 * formatta data e ora per la scrittura dei nomi dei file
 * @param {*} date 
 * @returns data formattata, o stringa vuota
 */
export const formatDateForFile = (date) => {
    if (isNotEmpty(date)) {
        return format(new Date(date), 'dd-MM-yyyy HH_mm_ss');
    }
    return "";
}

/**
 * formatta la data per la visualizzazione su schermo
 * @param {*} date 
 * @returns data formattata, o stringa vuota
 */
export const formatDateForDisplay = (date) => {
    if (isNotEmpty(date)) {
        return format(new Date(date), 'dd/MM/yyyy');
    }
    return "";
}

/**
 * formatta la data e ora per la visualizzazione su schermo
 * @param {*} date 
 * @returns data formattata, o stringa vuota
 */
export const formatDateTimeForDisplay = (date) => {
    if (isNotEmpty(date)) {
        return format(new Date(date), 'dd/MM/yyyy, HH:mm:ss');
    }
    return "";
}

/**
 * utilizzata da componente dateField. Testa la data da input di tipo "date"
 * @param {*} s 
 * @returns 
 */
export const isValidDate = (s) => {

    if (isEmpty(s)) {
        return false;
    } else if (!isValid(s)) {
        return false;
    }

    s = format(s, "yyyy-MM-dd");

    // Assumes s is "yyyy-MM-dd"
    if (!/^\d{4}-\d{2}-\d{2}$/.test(s)) {
        return false;
    }
    const parts = s.split('-').map((p) => parseInt(p, 10));
    let year = parts[0];
    let month = parts[1];
    let day = parts[2];

    month -= 1;
    const d = new Date(year, month, day);
    return d.getMonth() === month && d.getDate() === day && d.getFullYear() === year;
}

/**
 * recupera il nome dell'applicazione corrente dal path nel browser
 * @returns 
 */
export const getCurrentApplicationName = () => {
    const splitPath = window.location.pathname.split("/");
    if (splitPath[1] === "app") {
        return splitPath[2];
    }
    return "";
}

/**
 * scatta una "fotografia" del DOM, recupera le ultime richieste http, ed invia i dati via email all'assistenza
 */
export const assistanceRequest = (applicationName="")=> {
    const logs = axiosService.getInstance().logs;
    const screenshotTarget = document.body;
    html2canvas(screenshotTarget, {
        x: window.scrollX,
        y: window.scrollY,
        width: window.innerWidth,
        height: window.innerHeight
    }).then((canvas) => {
        canvas.toBlob((blob) => { 
            sendAssistanceRequest(new Blob([blob]), JSON.stringify(logs),applicationName)
        });
    });
}

export const parseQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  };