import React, { useEffect, useState } from 'react';
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import * as styles from "../../../../config/styles";
import "react-datepicker/dist/react-datepicker.css";
import * as actions from "../../../../actions";
import { ModalCreateDelegate } from "./ModalCreateDelegate";
import { BtnDelete } from "../../../shared/BtnDelete";
import { PopupDelete } from "../../../shared/PopupDelete";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { PopupError } from "../../../shared/PopupError";
import RowSpinner from '../../../shared/spinner/Spinner';
import { formatDateForDisplay, isNotEmpty } from '../../../shared/Utility';


registerLocale("it", it);

export const DelegateForm = props => {


    const { relationship, relationshipLocations, roles, subjectId } = props;
    const [modalShow, setModalShow] = useState(false);
    const [delegates, setDelegates] = useState([]);
    const [delegateCheckedItems, setDelegateCheckedItems] = useState(new Map());
    const [allCheckDelegate, setAllCheckDelegate] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    let [delegatePerson, setDelegatePerson] = useState(null);
    let [newDelegate, setNewDelegate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleDelegateAllCheckChange = event => {
        setAllCheckDelegate(event.target.checked);
        if (event.target.checked && delegates.length > 0) {
            if (delegateCheckedItems.size > 0) {
                delegateCheckedItems.clear();
            } else {
                delegates.map((delegate, index) => (
                    delegateCheckedItems.set(delegate.id, true)
                ));
            }
            setDelegateCheckedItems(new Map(delegateCheckedItems));
            setIsDisabled(false);
        } else {
            delegateCheckedItems.clear();
            setIsDisabled(true);
        }

    };

    const handlerRefreshDelegate = () => {
        if (relationship?.id) {
            setIsLoading(true)
            actions.getDelegatesByRelationshipId(subjectId, relationship.id).then(
                (res) => {
                    setDelegates(res);
                },
                (errors) => {
                    console.log(errors);
                })
                .then(() => setIsLoading(false));
        }

    };
    useEffect(() => { handlerRefreshDelegate() }, []);

    const handleDelegateCheckChange = event => {
        delegateCheckedItems.set(event.target.value, event.target.checked)
        setDelegateCheckedItems(new Map(delegateCheckedItems));
        let count = 0;
        for (const checkedItem of delegateCheckedItems) {
            if (checkedItem[1]) {
                setIsDisabled(false);
                return;
            }
            if (!checkedItem[1]) {
                count++;
            }
        }
        if (count === delegateCheckedItems.size) {
            setIsDisabled(true);
        }
    };

    const handlerSubmitDelegate = (formDataDelegate) => {
        setIsLoading(true);
        if (formDataDelegate?.id || delegatePerson?.id) {
            actions.updateCoraDelegate(formDataDelegate).then(
                (coraDelegate) => {
                    let elementsIndex = delegates.findIndex(element => element.id == formDataDelegate?.id);
                    delegates[elementsIndex] = coraDelegate;
                    setDelegates(delegates);
                    props.updateDelegates(delegates);

                    closeModal();
                },
                (errors) => {
                    setModalShow(false);
                    PopupError({ ...props, text: "Errore nell'aggiornamento dei delegati " + errors })
                })
                .then(() => setIsLoading(false));
        } else {
            actions.createCoraDelegate(formDataDelegate).then(
                (coraDelegate) => {
                    delegates.push(coraDelegate);
                    setDelegates(delegates);
                    props.updateDelegates(delegates);

                    closeModal();
                },
                (errors) => {
                    setModalShow(false);
                    PopupError({ ...props, text: "Errore nell'inserimento dei delegati " + errors })
                })
                .then(() => setIsLoading(false));
        }
        setDelegatePerson(null);
    };


    const openPopupDelete = () => {
        let array = Array.from(delegateCheckedItems.keys())
        let data = [];
        delegates.forEach(function (entry) {
            if (array.some((el) => el == entry.id)) {
                data.push(entry);
            }

        });
        let finalArray = [];
        data.forEach(function (entry) {
            finalArray.push("- " + (entry.completeName.trim() ? entry.completeName : entry.fiscalCode));
        });

        PopupDelete({
            list: finalArray,
            operazione: "Titolare Effettivo e Delegato",
            handleClickConfirm: handlerDeleteDelegate
        });
    };


    const handlerDeleteDelegate = () => {
        setAllCheckDelegate(false);
        setIsDisabled(true);
        let data = delegates;
        for (const checkedItem of delegateCheckedItems) {//FIXME: utilizzare una unica chiamata
            if (checkedItem[1]) {
                actions.deleteCoraDelegate(checkedItem[0]).then(
                    () => {
                        data = data.filter((entry) => {
                            return entry.id != checkedItem[0];
                        });
                        setDelegates(data);
                        setDelegateCheckedItems(new Map());
                        PopupSuccess();
                    },
                    (errors) => {
                        PopupError({ text: "Si è verificato un errore durante l'eliminazione dei dati anagrafici" })
                        setAllCheckDelegate(true);
                        setIsDisabled(false);
                    }
                )
            }
        }
    };



    const handlerShowDelegateForm = (delegate) => {
        setNewDelegate(false);
        if (isNotEmpty(delegate)) {
            setDelegatePerson(delegate);
        }
        setModalShow(true);
    };

    const addNewDelegateForm = () => {
        setNewDelegate(true);
        setDelegatePerson({});
        setModalShow(true);
    };

    const setTab = () => {
        handlerRefreshDelegate();
    };

    const closeModal = () => {
        setModalShow(false);
        setTab();

    };

    const getRoleDesc = (selectedItem) => {

        let roleSelected = "";

        if (selectedItem) {
            roleSelected = roles.filter(role => role.id == selectedItem);
            return roleSelected[0].code + " - " + roleSelected[0].description;
        } else {
            return roleSelected;
        }

    };

    const renderDelegateRows = (delegates, delegateCheckedItems, handleDelegateCheckChange, handlerShowDelegateForm, getRoleDesc) => {
        if (isLoading) {
            return (<RowSpinner />)
        }
        return delegates?.length > 0 && delegates.map((delegate) => (
            <div className='text-start row-table' id={"row-" + delegate?.id} key={"key-delegate-" + delegate?.id}>
                <div className="col w-5 text-center div-td">
                    <input type="checkbox" value={delegate?.id} id={"delegate-" + delegate?.id} name={"delegateCheck"}
                        checked={delegateCheckedItems.get(delegate?.id)}
                        onChange={handleDelegateCheckChange} />
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {getRoleDesc(delegate?.role?.id)}
                </div>
                <div className="col w-30 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate.completeName}
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.fiscalCode ? delegate?.fiscalCode : ""}
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.birthDate ? formatDateForDisplay(delegate?.birthDate) : ""}
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.location ? delegate?.location : ""}
                </div>
                <div className="col w-10 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.province ? delegate?.province : ""}
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.startDate ? formatDateForDisplay(delegate?.startDate) : ""}
                </div>
                <div className="col w-20 div-td text-truncate" onClick={() => handlerShowDelegateForm(delegate)}>
                    {delegate?.endDate ? formatDateForDisplay(delegate?.endDate) : ""}
                </div>
            </div>
        ));
    }

    return (
        <div className="row">
            <div className="col-md-12 col-lg-12"><h5>Elenco anagrafiche collegate al rapporto</h5></div>
            <div className="col-md-12 col-lg-12 form-row header-btn small align-items-center">
                <BtnDelete handlerOnClick={() => openPopupDelete()} isDisabled={isDisabled} />
                <div className="ms-auto text-end me-2" data-tip={!relationship.id ? "Completa la compilazione del rapporto per registrare un elemento" : null}>
                    <button className={styles.btnFillPillSm} onClick={() => addNewDelegateForm()} disabled={!relationship.id} >
                        + AGGIUNGI
                    </button>
                </div>
            </div>
            <div id={"listDelegates_" + relationship.id} className="col-md-12 col-lg-12 table-delegate">
                <div className="col-md-12 col-lg-12 header-table p-0">
                    <div className="col w-5 text-center div-td">
                        <input type="checkbox" checked={allCheckDelegate} value={allCheckDelegate} onChange={handleDelegateAllCheckChange} />
                    </div>
                    <div className="col w-20 div-td">
                        Ruolo
                    </div>
                    <div className="col w-30 div-td">
                        Denominazione
                    </div>
                    <div className="col w-20 div-td">
                        Codice Fiscale
                    </div>
                    <div className="col w-20 div-td">
                        Data di nascita
                    </div>
                    <div className="col w-20 div-td">
                        Comune
                    </div>
                    <div className="col w-10 div-td">
                        Provincia
                    </div>
                    <div className="col w-20 div-td">
                        Data Inizio
                    </div>
                    <div className="col w-20 div-td">
                        Data Fine
                    </div>
                </div>

                {renderDelegateRows(delegates, delegateCheckedItems, handleDelegateCheckChange, handlerShowDelegateForm, getRoleDesc)
                }

            </div>
            {(delegatePerson || newDelegate) &&
                <ModalCreateDelegate
                    key={delegatePerson?.id || new Date().toString()}
                    show={modalShow}
                    onHide={() => closeModal()}
                    handlerSubmitDelegate={handlerSubmitDelegate}
                    relationship={relationship}
                    relationshipLocations={relationshipLocations}
                    roles={roles}
                    delegatePerson={newDelegate ? null : delegatePerson}
                />
            }
        </div>
    )
};

export default (DelegateForm)


