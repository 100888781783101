import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from "react-bootstrap/Row";
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg';
import '../../../../styles/alertComm.css';
import Modal from "react-bootstrap/Modal";
import axiosService from "../../../../services/axios-service";
import moment from "moment";
import * as Constants from "../../../../config/Constants";
import ComureiEmailForm from "./ComureiEmailiForm";
import { ReactComponent as IPdfFile } from "../../../../styles/images/svg/file-pdf.svg";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { downloadFile } from '../../../shared/Utility';

class CreateCommunicationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true
        }
    }

    downloadTxt = (communication) => {
        let url = `/api/comurei/custom/comurei-communications/downloadTxt/${communication.subjectId}/${communication.id}`;
        this.callDownloadService(url, communication.filename);
    };

    downloadPdf = (communication) => {
        let url = `/api/comurei/custom/comurei-communications/downloadPdf/${communication.subjectId}/${communication.id}`;
        this.callDownloadService(url, communication.filename.replace(".txt", ".pdf"));
    };

    callDownloadService = (url, file) => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {

            if (response) {
                downloadFile(response.data, file)
                PopupSuccess({ text: "File scaricato" });
            }
        });
    };

    renderRows(communications) {

        return communications.map((communication, index) => {
            let section = "";
            if (communication.registryReason) {
                section = section + "Indagini Bancarie+";
            }

            if (communication.monitoringReason) {
                section = section + "Monitoraggio Fiscale+";
            }

            if (communication.factaReason) {
                section = section + "FATCA/CRS";
            }

            if (section.endsWith("+")) {
                section = section.substring(0, section.length - 1);
            }
            return (
                <Row className="alert-body">
                    <React.Fragment>
                        <div className='col w-20 div-td'>{moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)}</div>
                        <div
                            className='col w-40 div-td'>{communication.communicationType.code} - {communication.communicationType.description}</div>
                        <div className='col w-40 div-td'>{section}</div>
                        <div className='col w-10 div-td text-center containerPopOver'>
                            <div className="txt">
                                <ITextFile width="20" fill="#128186" onClick={() => this.downloadTxt(communication)} />
                            </div>
                            <div className="pdf">
                                <IPdfFile width="20" fill="#128186" onClick={() => this.downloadPdf(communication)} />
                            </div>
                        </div>
                    </React.Fragment>
                </Row>
            )
        })
    }

    close = () => {
        this.setState({ showModal: false });
        this.props.closeModalComm()
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    show={this.state.showModal}
                    onHide={this.close}
                    dialogClassName="modal-60w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>COMUNICAZIONE R.E.I. PER <strong>{this.props.subject.personType === Constants.PNF ? this.props.subject.companyName : this.props.subject.lastName + " " + this.props.subject.firstName}</strong></h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-start header-table">
                            <div className='col w-20 div-td'>Data creazione</div>
                            <div className='col w-40 div-td'>Tipo Comunicazione</div>
                            <div className='col w-40 div-td'>Sezione</div>
                            <div className='col w-10 div-td'>Download</div>
                        </div>
                        {this.renderRows(this.props.communications)}
                        <ComureiEmailForm {...this.props}
                            closeModal={this.close}
                            sentCommunications={this.props.communications}
                            emailAddress={this.props.user.login}
                            subjectId={this.props.communication?.subjectId}
                        />
                    </Modal.Body>
                </Modal>
            </React.Fragment>

        )
    }
}
export default CreateCommunicationModal;

