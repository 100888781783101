import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BtnEmpty } from "./BtnEmpty";
import { ButtonAssistance } from './form/ButtonAssistance';

export function PopupError(props) {
    
    
    return (
        confirmAlert({
            customUI: ({ onClose }) => {
                const ButtonClose = props?.buttonClose || (() => <><BtnEmpty text={props?.close || "CHIUDI"} classCustom="m-auto" handlerOnClick={onClose} /></>)
                return (
                    <React.Fragment>
                        <div className="bar"></div>
                        <div className='popup'>
                            <button hidden type="button" className="close" onClick={onClose}><span aria-hidden="true">×</span><span
                                className="visually-hidden">Close</span>
                            </button>
                            <Row className={"show-grid popup-title-delete text-center"}>
                                <Col md={12} lg={12}>
                                    <img alt={""} src="" />
                                </Col>
                                <Col md={12} lg={12} className="py-3">
                                    <h4>{props?.title || "Attenzione"}</h4>
                                </Col>
                            </Row>
                            <Row className={"show-grid text-center"}>
                                <Col md={12} lg={12} dangerouslySetInnerHTML={{ __html: props?.text }}>
                                </Col>
                            </Row>
                            {
                                props?.second_title !== undefined && (
                                    <Row className={"show-grid text-center"}>
                                        <Col md={12} lg={12} dangerouslySetInnerHTML={{ __html: props?.second_title }}>
                                        </Col>
                                    </Row>
                                )
                            }

                            <div className="my-2 py-5 text-center">

                                <ButtonAssistance
                                buttonTitle={props?.request }
                                />


                                <ButtonClose />
                            </div>
                        </div>
                    </React.Fragment>
                )
            },
            closeOnClickOutside: false
        })
    );
}

export function closePopupError() {
    const closeButton = document.querySelector(".react-confirm-alert .popup .close");
    if (closeButton) closeButton.click();
}