import React, { useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { CommunicationStatus } from "../../../../config/CommunicationStatus";
import { formatDateForDisplay, isEmpty, isNotEmpty } from "../../../shared/Utility";
import { downloadErrorReportBulk } from "../CommunicationDownloadUtils";
import { CommunicationModalRow, computeIsError, computeStatusText, isCreatedWithError } from "./CommunicationRow";
import EmailiForm from "./EmailiForm";
import { sendCommunicationAssistanceEmail } from "../../../../actions";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { PopupError } from "../../../shared/PopupError";
import { te } from "date-fns/locale";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";

export function CommunicationModal(props) {
    const coraSubject = props.coraSubject;
    const { downloadTxt, downloadPecTz, downloadDgn, downloadPdf, downloadErrorReport, delayedCommunicationDate } = props;
    const communications = props.communications || [];
    const createdCommunications = communications.filter(c => c.communicationStatus === CommunicationStatus.CREATED);
    const createdWithError = communications.filter(c => isCreatedWithError(c));
    const [isLoading, setIsLoading] = useState(false);


    const sendEnabled = () => {
        return createdCommunications.length > 0 && isEmpty(delayedCommunicationDate);
    }

    const renderRows = (communications) => {
        return communications.map((communication, index) => {
            return <CommunicationModalRow
                key={"communication-" + index}
                communication={communication}
                status={computeStatusText(communication)}
                isError={computeIsError(communication.communicationStatus)}
                downloadTxt={downloadTxt}
                downloadPecTz={downloadPecTz}
                downloadDgn={downloadDgn}
                downloadPdf={downloadPdf}
                downloadErrorReport={downloadErrorReport} />
        })
    }



    const sendAssistanceRequest = (ids = []) => {
        setIsLoading(true);
        sendCommunicationAssistanceEmail(ids).then((res) => {
            PopupSuccess({text: "Richiesta di assistenza inoltrata. A breve verrete contattati dai nostri uffici."})
        },
            (err) => {
                PopupError({text:"Si è verificato un errore durante la generazione della richiesta."});
            }
        )
        .then(()=> setIsLoading(false));
    }

    const renderDownloadEsitErrorsButton = () => {


        if (createdWithError.length > 0) {
            const ids = createdWithError.map(comm => comm.id);
            return (<BulkErrorDownloadButton ids={ids} onAssistanceButtonClick={()=>sendAssistanceRequest(ids)}/>)
        } else {
            return <></>
        }

    }

    return (
        <Modal
            backdrop="static"
            show={props.showModal}
            onHide={props.closeModal}
            dialogClassName="modal-60w"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <div className="bar"></div>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h2>ESITO ELABORAZIONE SID</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <PageSpinner/>}
                <div className='popup communication'>
                    <Row className="alert-header">
                        <React.Fragment>
                            <div className='col w-10 div-td'>Periodo</div>
                            <div className='col w-30 div-td'>Tipo Comunicazione</div>
                            <div className='col w-20 div-td'>Tipologia Invio</div>
                            <div className='col w-30 div-td'>Stato</div>
                            <div className='col w-15 text-center div-td'>Scarica</div>
                        </React.Fragment>
                    </Row>
                    {renderRows(communications)}

                    {sendEnabled() ?
                        <EmailiForm
                            closeModal={props.closeModal}
                            emailAddress={coraSubject.coraSubjectSid?.email}
                            flagPec={coraSubject.coraSubjectSid?.flagPec}
                            sentCommunications={createdCommunications}
                            subject={coraSubject}
                            subjectId={coraSubject.id}
                        >
                            {renderDownloadEsitErrorsButton()}
                        </EmailiForm>
                        :
                        <div>
                            {isNotEmpty(delayedCommunicationDate) && coraSubject.coraSubjectSid?.couldSendPec &&
                                <>
                                    <div className="form-row mt-4 mb-2 justify-content-center align-item-center">
                                        <span className="text-danger" style={{ fontSize: "1.5rem" }}>
                                            <strong>Le comunicazioni CREATE SENZA ERRORI saranno spedite tramite PEC in data: {formatDateForDisplay(delayedCommunicationDate)}</strong>
                                        </span>
                                    </div>

                                    <div className="form-row mt-4 mb-2 justify-content-center align-item-center">
                                        <span style={{ fontSize: "1rem" }}>
                                            <strong>MITTENTE: {coraSubject.coraSubjectSid?.pecEmail}</strong>
                                        </span>
                                    </div>

                                    <div className="form-row mt-4 mb-2 justify-content-center align-item-center">
                                        <span style={{ fontSize: "1rem" }}>
                                            <strong>DESTINATARIO: {coraSubject.coraSubjectSid?.recipientEmail}</strong>
                                        </span>
                                    </div>

                                </>
                            }
                            <div className="form-row mt-2 form-relationship-button justify-content-between align-item-center">

                                <div className="ms-auto me-auto">
                                    {renderDownloadEsitErrorsButton()}
                                </div>
                                <button
                                    className="btn btn-empty px-4 btn-sm"
                                    onClick={props.closeModal}>CHIUDI
                                </button>
                            </div>
                        </div>}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export function BulkErrorDownloadButton({ids = [], onAssistanceButtonClick}) {
    return (
        <div className="d-flex align-items-center">
            <button className="btn btn-new-rel px-5 bg-yellow"
                onClick={() => downloadErrorReportBulk(ids)}>
                SCARICA errori
            </button>
            <button className="ms-3 btn btn-new-rel px-5"
                onClick={onAssistanceButtonClick}>
                RICHIEDI ASSISTENZA
            </button>
        </div>
    )
}