import React from "react";
import ModalProcessingOutComeReceipt from "./ModalProcessingOutComeReceipt";
/**
 * Questa funzione serve a visualizzare piu popup insieme quando è ritornata la risposta
 * @param {*} props l'oggetto communication, con fileName e tutte le props, del padre
 * @returns piu popup insieme
 */
export default function ShowManyMofalTogheter(props) {
  let communications = props.communication;

  if (communications?.length===0){
    return null;
  }
  
  return (
    <div>
      {communications.map((communication, index) => {
        return (
          <ModalProcessingOutComeReceipt
            communication={communication.crsCommunication}
            props={props.props}
            key={communication.filename+" "+index}
            fileName={communication.filename}
            nameSubject = {communication.crsCommunication?.subject?.companyName}
          />
        );
      })}
    </div>
  );
}
