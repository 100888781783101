import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BtnEmpty } from "./BtnEmpty";


export function PopupSuccess(props) {

    return (
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <React.Fragment>
                        <div className="bar"></div>
                        <div className='popup'>
                            <button type="button" className="close" onClick={onClose}><span aria-hidden="true">×</span><span
                                className="visually-hidden">Close</span>
                            </button>
                            <Row className={"show-grid popup-title-success text-center"}>
                                <Col md={12} lg={12}>
                                    <img alt={""} src="" />
                                </Col>
                                <Col md={12} lg={12} className="py-3">
                                    <h4>{props?.title || "Operazione completata con successo"}</h4>
                                </Col>
                            </Row>
                            <Row className={"show-grid text-center"}>
                                <Col md={12} lg={12} dangerouslySetInnerHTML={{ __html: props?.text }}>
                                </Col>
                                {(props?.dataList && props?.dataList?.length > 0) ?
                                    <Col md={12} lg={12}>
                                        <div className={"h300Scroll"}>
                                            {props.dataList.map((data) => {
                                                return <p>{data}</p>;
                                            })}
                                        </div>
                                    </Col>
                                    : ""
                                }
                            </Row>
                            <div className="my-2 py-5 text-center">
                                <BtnEmpty text={props?.ok || "OK, CHIUDI"} classCustom="m-auto" handlerOnClick={() => {
                                    if (props?.handleClickConfirm) {props.handleClickConfirm()}
                                    onClose()
                                }} />
                            </div>
                        </div>
                    </React.Fragment>
                )
            },
            closeOnClickOutside: false
        })
    );
}
