import React from "react";
import Row from "react-bootstrap/Row";
import UploadReceiptEmailForm from "../UploadReceiptEmailiForm";
import { ReactComponent as ITextFile } from "../../../../../styles/images/svgs/regular/file-alt.svg";
import { Link } from "react-router-dom";
import GenerateStatus from "./GenerateStatus";
import { CommunicationStatus } from '../../../../../config/CommunicationStatus';

import { downloadConsistencyReceiptTxt, downloadReceiptTxt } from "../../CommunicationDownloadUtils";
/**
 * Corpo da visualizzare su Cora se il messaggio è corretto 
 * @param {*} props 
 * @returns corpo del messaggio del popup
 */
export default function MessageCorrectFile({
  communication
  ,receiptType
  ,closeReceiptModal
  ,fileName
}) {
  const noCommunicationFoundMsg =
    "(ATTENZIONE: la ricevuta NON  si riferisce ad una comunicazione nello storico)";

  const dispatchType = communication.dispatchType;
  const isConsistencyReceipt = dispatchType === "CONSISTENCY_RECEIPT";


  return (
    <div className="popup communication">
      <Row>
        <div className="col-9 ">
          <GenerateStatus
            dispatchType={dispatchType}
            communicationStatus={communication.communicationStatus}
            communicationError={communication.communicationError}
          />
        </div>
        {communication.communicationType || isConsistencyReceipt ? (
          ""
        ) : (
          <div className="col-7 ">
            <strong>{noCommunicationFoundMsg}</strong>
          </div>
        )}
        <div className="col-3 div-td text-truncate justify-content-around align-items-center pe-4 ps-4 containerPopOver">
          {receiptType === "CONSISTENCY" ? (
            <button onClick={() => downloadConsistencyReceiptTxt(communication)}>
              <div className="photo">
                <ITextFile width="20" fill="#128186" />
              </div>
            </button>
          ) : communication.communicationStatus !==
            CommunicationStatus.ERROR_SID ? (
            <button onClick={() => downloadReceiptTxt(communication, fileName)}>
              <div className="recepit">
                <ITextFile width="20" fill="#128186" />
              </div>
            </button>
          ) : (
            ""
          )}
        </div>
      </Row>
      <UploadReceiptEmailForm
        closeModal={closeReceiptModal}
        subjectId={communication?.subject?.id}
        communication={communication}
        emailAddress={communication?.subject?.coraSubjectSid?.email}
        flagPec={communication.subject?.coraSubjectSid?.flagPec}
        fileName={fileName}
      />
    </div>
  );
}
