import React, { useState } from 'react';
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import * as actions from "../../../../actions"
import Modal from "react-bootstrap/Modal";
import { ReactComponent as ICheck } from "../../../../styles/images/svg/check.svg";
import { PopupError } from "../../../shared/PopupError";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { UploadFileLabel } from '../../../shared/form/UploadFileLabel';

registerLocale("it", it);

export function ArcoSubjectImportDocumentModal(props) {
    const [dataFile, setDataFile] = useState([]);
    const [errorDataFile, setErrorDataFile] = useState({ isValid: true, msg: "" });
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const closeModal = () => {
        props.onHide();
    };



    const checkFileName = (name) => {
        const nameSplit = name.split('.').pop().toLowerCase();
        switch (nameSplit) {
            case 'ariw':
            case 'ari':
            case 'arc':
            case 'arcw':
                return true
            default:
                return false
        }

    };

    const handleUploadFile = (val) => {
        if (isEmpty(val)) {
            setErrorDataFile({ isValid: false, msg: "Il file è obbligatorio" });
        }
        let files = [];
        for (let i = 0; i < val.target.files.length; i++) {
            if (!checkFileName(val.target.files[i].name)) {
                setErrorDataFile({ isValid: false, msg: "Estensione o nome file errati" });
            }
            else {
                setErrorDataFile({ isValid: true, msg: "" });
                files.push(val.target.files[i]);
            }
        }
        if (files.length > 0)
            setDataFile(files);
    };

    const handleSendDataFile = () => {
        let formData = new FormData();
        if (dataFile?.length > 0) {
            for (let i = 0; i < dataFile.length; i++) {
                formData.append("files", dataFile[i]);
            }
        } else {
            setErrorDataFile({ isValid: false, msg: "Si prega di selezionare un file ARI" });
            return;
        }


        const getTextError = (url, fileName) => {
            return (
                `   <div>Cliccare sulla riga del soggetto e verificare le operazioni importate</div>
                    <div>
                        <a href="${url}" download="${fileName}">
                            <button class="${'btn btn-primary btn-new-rel mt-3'}">
                                Controlla il file LOG
                            </button>
                        </a>
                    </div>
                `
            )
        }

        setLoadingSpinner(true);
        actions.importARIWFile(formData).then(
            (res) => {
                if (res) {
                    const responseArray = res.split(/\r?\n/g);
                    if (responseArray.length > 1) {
                        const url = window.URL.createObjectURL(new Blob([responseArray.join("\r\n")]));
                        const link = document.createElement('a');
                        link.href = url;
                        const fileName = `${responseArray[0]}.txt`;

                        PopupSuccess({
                            title: "IMPORT DATI  TERMINATO CORRETTAMENTE",
                            text: getTextError(url, fileName)
                        });
                    } else {
                        PopupSuccess();
                    }
                }
            }, (err) => {
                if(err.errorKey === "FileSizeTooBig"){
                    PopupSuccess({
                        title: "FILE CARICATO CON SUCCESSO",
                        text: "Il completamento dell'operazione sarà notificato via mail con eventuali log"})
                }else if(err?.errorKey==="MaxSubject"){
                    let arco_link = '<a title="Acquista su Arco"href="https://www.starsoluzioni.it/product/arco-antiriciclaggio/" >ARCO</a>';
                    let html = "<p>Acquistare nuovi soggetti nella pagina, cliccare qui  '"+arco_link+"'";
                    
                    PopupError({ text: err?.title,second_title:html  });
                }
                
                else if(err?.title !== null){
                    PopupError({ text: err?.title });
                } else {
                    PopupError({ text: "Errore durante l'import del soggetto" });
                }
            })
            .then(() => {
                setLoadingSpinner(false);
                props.getSubjects();
                closeModal();
            });
    };

    const isEmpty = (val) => {
        return (val === "" || val == null);
    };



    return (
        <React.Fragment>
            <Modal
                backdrop="static"
                show={props.show}
                onHide={closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="bar"></div>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2>IMPORTA FILE</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadingSpinner ? <PageSpinner /> : ''}
                    <div className="image-upload text-start ms-4">
                        <UploadFileLabel
                            forLabel={"dataFile"}
                            label={"CARICA FILE"}
                        />

                        <input
                            id={`dataFile`}
                            type='file'
                            name={`dataFile`}
                            multiple
                            onChange={(e) => handleUploadFile(e)}
                        />
                        <div className="certificatiLoaded ps-4 ms-2 text-start">
                            {
                                !errorDataFile.isValid ?
                                    <div className="certificatiLoaded-error">
                                        {errorDataFile.msg}
                                    </div> :
                                    <div>
                                        {dataFile.length > 0 && dataFile.map((file, index) => {
                                            return <div><ICheck width="12" fill="#8e8e8e" />{file.name}</div>
                                        })}
                                    </div>
                            }
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between align-content-end mt-0">
                        <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
                        <button
                            disabled={!errorDataFile.isValid || !dataFile}
                            onClick={handleSendDataFile}
                            type="button"
                            className="btn btn-primary btn-new-rel px-5"
                        >
                            CONFERMA
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}
