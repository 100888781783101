import React from "react";
import { ReactComponent as ICheck } from "../../../../styles/images/svg/check.svg";
export default function GenerateNameFileWhenIsend(props) {
  let files = props.files;

//this.state.receiptFile && this.state.receiptFile.map
  const isAnArray = Array.isArray(files);
  if (files?.length > 0 && files !== null && isAnArray===true) {
    return (
      <div>
        {files.map((file, index) => (
          <div key={index}>
            <ICheck width="12" fill="#8e8e8e" />
            <div>{file.name}</div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>---</div>
  )
}
