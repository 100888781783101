/**
 * Form rapporto CORA
 * Simile al form nella app omonima, tranne per il fatto che non supporta il salvataggio a DB, l'autocompletamento dei dati o la clonazione.
 */

import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { PF, PNF, codRelTypeCab, exludedCommTypes, genderList } from "../../../../../config/Constants";
import FormDelegati from "../FormDelegati";
import { ProvinceField } from "../../../application-cora/relationships/ProvinceField";
import AutoCompileCustom from "../../../../shared/form/AutoCompileCustom";
import { DateField } from "../../../../shared/form/DateField";
import { BwmSelect } from "../../../../shared/form/BwmSelect";
import { BwmInput } from "../../../../shared/form/BwmInput";
import { PersonTypeField } from "../../../application-cora/relationships/PersonTypeField";

import { isNotEmpty } from "../../../../shared/Utility";
import { BwmCheckbox } from "../../../../shared/form/BwmCheckbox";
import { RankedDropdownList } from "../../../../shared/form/RankedDropdownList";
import { endOfDay } from "date-fns";
import CodiceFiscale from 'codice-fiscale-js';
import { getLocation } from "../../../../../actions";
import Tabs from "../../../application-cora/relationships/Tabs";

import "react-datepicker/dist/react-datepicker.css";
import "../../../../../styles/autocomplete.css"


export function FormRapporto({
    relationshipLocations, currencies, roles,
    relationship, saveRelationship, index
}) {

    const [id, setId] = useState(undefined);
    const relationshipTypes = useSelector(state => state.relationshipTypes.data);
    const [identifier, setIdentifier] = useState("");
    const [relType, setRelType] = useState(null);
    const [commType, setCommType] = useState('1');
    const commTypeList = useSelector(state => state.communicationTypes.data.filter(el => exludedCommTypes.indexOf(el.code) === -1))
    const [showCab, setShowCab] = useState(false);
    const [cab, setCab] = useState('');
    const [errorCab, setErrorCab] = useState({ isValid: true, msg: "" });
    const [showDescription, setShowDescription] = useState(false);
    const [description, setDescription] = useState('');
    const [errorDescription, setErrorDescription] = useState({ isValid: true, msg: "" });
    const [errorRelType, setErrorRelType] = useState({ isValid: true, msg: "" });
    const [startDate, setStartDate] = useState(null);
    const [errorStartDate, setErrorStartDate] = useState({ isValid: true, msg: "" });
    const [endDate, setEndDate] = useState(null);
    const [errorEndDate, setErrorEndDate] = useState(false);
    const [endDateDisabled, setEndDateDisabled] = useState(false);
    const [errorCommType, setErrorCommType] = useState({ isValid: true, msg: "" });
    const [personType, setPersonType] = useState(PNF);
    const [errorPersonType, setErrorPersonType] = useState({ isValid: true, msg: "" });
    const [displayPf, setDisplayPf] = useState(false);
    const [displayPnf, setDisplayPnf] = useState(true);
    const [fiscalCode, setFiscalCode] = useState('')
    const [errorFiscalCode, setErrorFiscalCode] = useState({ isValid: true, msg: "" });
    const [companyName, setCompanyName] = useState('');
    const [errorCompanyName, setErrorCompanyName] = useState({ isValid: true, msg: "" });
    const [location, setLocation] = useState('');
    const [errorLocation, setErrorLocation] = useState({ isValid: true, msg: "" });
    const [province, setProvince] = useState("");
    const [errorProvince, setErrorProvince] = useState({ isValid: true, msg: "" });
    const [lastName, setLastName] = useState('');
    const [errorLastName, setErrorLastName] = useState({ isValid: true, msg: "" });
    const [firstName, setFirstName] = useState('');
    const [errorFirstName, setErrorFirstName] = useState({ isValid: true, msg: "" });
    const [gender, setGender] = useState('FEMALE');
    const [birthDate, setBirthDate] = useState(null);
    const [errorBirthDate, setErrorBirthDate] = useState({ isValid: true, msg: "" });
    const [birthLocation, setBirthLocation] = useState('');
    const [errorBirthLocation, setErrorBirthLocation] = useState({ isValid: true, msg: "" });
    const [birthProv, setBirthProv] = useState('');
    const [errorBirthProv, setErrorBirthProv] = useState({ isValid: true, msg: "" });
    const [comment, setComment] = useState("");

    const [coraRelationDelegates, setDelegates] = useState([]);

    const [idResult, setIdResult] = useState("");

    const [localRelationship, setLocalRelationship] = useState(undefined)

    const resetTypeDependentFields = () => {
        setErrorCab({ isValid: true, msg: "" });
        setShowCab(false);
        setCab(null);
        setErrorDescription({ isValid: true, msg: "" });
        setShowDescription(false);
        setErrorCab({ isValid: true, msg: "" })
        setDescription(null);
        setEndDateDisabled(false);
    }

    //setto i valori iniziali, solo quando i valori di dominio sono stati reperiti da DB
    useEffect(() => {
        if (relationshipTypes?.length > 0) {
            setId(relationship?.id || index + 1)
            setIdentifier(relationship?.identifier);
            setStartDate(relationship?.startDate ? new Date(relationship.startDate) : null)
            setEndDate(relationship?.endDate ? new Date(relationship.endDate) : null)
            setRelType(relationship?.relationshipType || relationshipTypes[0]);
            setCommType(relationship?.coraCommunicationType?.id || "1")
            setCab(codRelTypeCab.indexOf(relationship?.relationshipType?.code) !== -1 ? relationship?.cab : '')
            setDescription(relationship?.relationshipType?.code === '99' ? relationship?.description : '')
            setPersonType(relationship?.personType || PNF)
            setFiscalCode(relationship?.fiscalCode || "")
            setCompanyName(relationship?.companyName || "")
            setLocation(relationship?.location || "")
            setProvince(relationship?.province || "")
            setLastName(relationship?.lastName || "")
            setFirstName(relationship?.firstName || "")
            setGender(relationship?.gender || "FEMALE")
            setBirthDate(relationship?.birthDate ? new Date(relationship?.birthDate) : null)
            setBirthLocation(relationship?.personType === PF ? relationship?.location || '' : '')
            setBirthProv(relationship?.personType === PF ? relationship?.province || '' : '')
            setComment(relationship?.comment || "")
            setIdResult(relationship?.idResult || "")
            setShowCab(codRelTypeCab.indexOf(relationship?.relationshipType?.code) !== -1 ? true : false)
            setShowDescription(relationship?.relationshipType?.code === '99' ? true : false)
            setEndDateDisabled(relationship?.relationshipType?.code === '98' ? true : false)
            setDelegates(relationship?.coraRelationDelegates || []);

            setLocalRelationship(relationship);
        }

    }, [relationshipTypes, relationship])


    useEffect(() => {
        if (isNotEmpty(id)) {
            const rel = {
                id,
                identifier,
                coraRole: {
                    id: "1",
                    code: "0",
                    description: "Titolare o Contitolare"
                },
                coraCommunicationType: { id: commType },
                relationshipType: relType,
                startDate,
                endDate,
                personType,
                fiscalCode,
                companyName,
                location,
                province,
                lastName,
                firstName,
                gender,
                birthDate,
                birthLocation,
                birthProv,
                comment,
                idResult,
                coraRelationDelegates
            }
            saveRelationship(rel)
            setLocalRelationship(rel);
        }


    }, [
        startDate,
        endDate,
        personType,
        fiscalCode,
        companyName,
        location,
        province,
        lastName,
        firstName,
        gender,
        birthDate,
        birthLocation,
        birthProv,
        comment,
        commType,
        identifier,
        idResult,
        relType,
        JSON.stringify(coraRelationDelegates)
    ]);

    const handlerChangeRelationshipType = (e) => {
        const relationshipTypeId = parseInt(e.target.value);
        const relationshipType = relationshipTypes.find(el => el.id === relationshipTypeId);
        const cod = relationshipType?.code;
        setRelType(relationshipType);
        resetTypeDependentFields();

        if (codRelTypeCab.indexOf(cod) !== -1) {
            setShowCab(true);
        } else if (cod === "98") {
            setEndDate(null);
            setEndDateDisabled(true);
        } else if (cod === "99") {
            setShowDescription(true);
        }

    }

    const handleEndDateLogic = (endDate) => {
        setEndDate(endDate);
        let today = new Date();
        //datemin = inizio mese precedente; datemax:fine mese precedente
        let endDateEndOfDay = endOfDay(endDate); //utilizzo endOfDay di date-fns per prevenire casistiche in cui l'ora di endDate sia successiva all'ra di dateMax
        let dateMin = endOfDay(new Date(today.getFullYear(), (today.getMonth() - 1), 1));
        let dateMax = endOfDay(new Date(today.getFullYear(), (today.getMonth()), 0));
        // Se la data selezionata è tra datemin e datemax-> tipo comunicazione 5-Chiusura nei termini

        if (endDateEndOfDay >= dateMin && endDateEndOfDay <= dateMax) {
            setCommType("2");

        } else if (endDateEndOfDay < dateMin) {
            //se la data selezionata è precedente al mese precedente -> tipo comunicazione 2=chiusura tardiva
            setCommType("3");
        }

    }


    const handlePersonTypeClick = event => {
        setPersonType(event.target.value);

        if (event.target.value === PF) {
            setDisplayPf(true);
            setDisplayPnf(false);
        } else if (event.target.value === PNF) {
            setDisplayPf(false);
            setDisplayPnf(true);
        }

    }

    const handleFiscalCode = val => {

        if (displayPf) {
            try {
                const cf = new CodiceFiscale(val).toJSON();
                if (cf) {
                    setBirthDate(new Date(cf?.birthday) || null);
                    setGender(cf?.gender === "M" ? "MALE" : gender);
                    setBirthLocation(cf?.birthplace.toUpperCase() || birthLocation);
                    setBirthProv(cf?.birthplaceProvincia || birthProv);
                }
            } catch (e) {
                console.log("codice fiscale errato");
                setErrorFiscalCode({ isValid: false, msg: 'Codice fiscale non valido' })
            }
        }



    };


    const handleInputAutocompile = (newLocation) => {
        setLocation(newLocation?.toUpperCase());
        if (province?.toUpperCase() === 'EE') {
            return;
        }
        getLocation(newLocation?.toUpperCase()).then(
            (loc) => {
                if (loc) {
                    setProvince(loc.province || "EE");
                }
            },
            (errors) => {
                console.log(errors);
            })

    };

    const handleInputBirthLocationAutocompile = (newLocation) => {
        setBirthLocation(newLocation?.toUpperCase());
        if (birthProv?.toUpperCase() === 'EE') {
            return;
        }
        getLocation(newLocation.toUpperCase()).then(
            (loc) => {
                if (isNotEmpty(loc)) {
                    setBirthProv(loc.province.toUpperCase());

                }
            },
            (errors) => {
                console.log(errors);
            })

    };

    return (
        <React.Fragment>
            <div className="bg-blue row-detail m-auto">

                <div className="form-row">
                    <div className="panel mt-3 w-100">
                        <div className="primary">
                            <div className="inputSelect">
                                <RankedDropdownList
                                    list={relationshipTypes}
                                    rankedElements={relationshipTypes}
                                    label={'Tipo di Rapporto'}
                                    onChange={handlerChangeRelationshipType}
                                    error={errorRelType}
                                    name='relationshipType'
                                    selectedValue={relType?.id}
                                ></RankedDropdownList>

                            </div>
                        </div>
                        <div className="secondary">
                            <div className="inputDate">
                                <DateField label={"Data Inizio"} date={startDate}
                                    onChange={date => { setStartDate(date); }}
                                    error={errorStartDate} />
                            </div>
                            <div className="inputDate">
                                <DateField label={"Data Fine"} date={endDate}
                                    onChange={date => {

                                        handleEndDateLogic(date);
                                    }}
                                    error={errorEndDate} isDisabled={endDateDisabled} />
                            </div>
                            {
                                showCab &&
                                <div className="inputText small">
                                    <BwmInput name='cab'
                                        label='CAB'
                                        className='form-control'
                                        value={cab}
                                        onChange={e => { setCab(e.target.value); }}
                                        error={errorCab}
                                        maxLength="5"
                                    />
                                </div>
                            }
                            {
                                showDescription &&
                                <div className="inputText">
                                    <BwmInput name='description'
                                        label='Descrizione'
                                        className='form-control'
                                        value={description}
                                        onChange={e => { setDescription(e.target.value.toUpperCase()); }}
                                        error={errorDescription}
                                        maxLength="24"
                                    />
                                </div>
                            }
                        </div>

                    </div>
                    <div className={`panel me-4 ms-4 mt-3`}>
                        <div className="primary">
                            <div className="inputSelect">
                                <BwmSelect
                                    options={commTypeList}
                                    name='communicationType'
                                    label='Tipo Comunicazione'
                                    className='form-control'
                                    onChange={(e) => { setCommType(e.target.value.trim()); }}
                                    error={errorCommType}
                                    value={commType}
                                    showCode={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="panel-info">
                        <h5>ID ESITO:</h5>
                        <h6>
                            <input className={"input-identifier"} value={idResult} type={"text"} onChange={e => setIdResult(e.target.value)} />
                        </h6>
                        <h5>Codice UNIVOCO:</h5>
                        <h6><input className={"input-identifier"} maxLength={50} value={identifier} type={"text"} onChange={e => setIdentifier(e.target.value)} />
                        </h6>

                    </div>
                </div>
                <div className="form-row">
                    <div className="tab-content relationship col-md-12 col-lg-12">
                        <Tabs>
                            <div id="tab-rel" label="Rapporto">
                                <div className="form-row">
                                    <div className="col-md-2 col-lg-2">
                                        <PersonTypeField key={"PersonTypeField-" + 0} id={"PersonTypeField-" + 0}
                                            personType={personType} onClick={handlePersonTypeClick}
                                            error={errorPersonType} />
                                    </div>
                                    <div id="infoPnf" className={"col-md-7 col-lg-7" + (!displayPnf ? " d-none" : "")}>
                                        <div className="row col-md-12 col-lg-12">
                                            <div className="col-md-3 col-lg-3 me-5 offset-1">
                                                <BwmInput name='fiscalCode'
                                                    label='Codice Fiscale'
                                                    className='form-control'
                                                    value={fiscalCode}
                                                    onChange={e => { setFiscalCode(e.target.value.toUpperCase().trim()); }}
                                                    error={errorFiscalCode}
                                                    maxLength="16"
                                                    onBlur={e => handleFiscalCode(e.target.value)} />
                                            </div>
                                            <div className="col-md-5 col-lg-5">
                                                <BwmInput name='companyName'
                                                    label='Denominazione'
                                                    className='form-control'
                                                    value={companyName}
                                                    onChange={e => { setCompanyName(e.target.value.toUpperCase()); }}
                                                    error={errorCompanyName}
                                                    maxLength="60"
                                                />
                                            </div>
                                            <div className="col-md-5 col-lg-5 me-5 offset-1">
                                                <AutoCompileCustom
                                                    label='Sede Legale (Comune) '
                                                    id="location"
                                                    handleInputAutocompile={handleInputAutocompile}
                                                    filtro={relationshipLocations}
                                                    value={location}
                                                    error={errorLocation}
                                                />
                                            </div>
                                            <div className="col-md-3 col-lg-3">
                                                <ProvinceField province={province}
                                                    onChange={e => { setProvince(e.target.value.toUpperCase()); }}
                                                    error={errorProvince} />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="infoPf" className={"col-md-7 col-lg-7" + (!displayPf ? " d-none" : "")}>
                                        <div className="row col-md-12 col-lg-12">
                                            <div className="col-md-3 col-lg-3">
                                                <BwmInput name='fiscalCode'
                                                    label='Codice Fiscale'
                                                    className='form-control'
                                                    value={fiscalCode}
                                                    onChange={e => { setFiscalCode(e.target.value.toUpperCase().trim()) }}
                                                    error={errorFiscalCode}
                                                    maxLength="16"
                                                    onBlur={e => handleFiscalCode(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-3 col-lg-3 offset-1">
                                                <BwmInput name='lastName'
                                                    label='Cognome'
                                                    className='form-control'
                                                    value={lastName}
                                                    onChange={e => setLastName(e.target.value.toUpperCase())}
                                                    error={errorLastName}
                                                    maxLength="26"
                                                />
                                            </div>
                                            <div className="col-md-3 col-lg-3 offset-1">
                                                <BwmInput name='firstName'
                                                    label='Nome'
                                                    className='form-control'
                                                    value={firstName}
                                                    onChange={e => setFirstName(e.target.value.toUpperCase())}
                                                    error={errorFirstName}
                                                    maxLength="25"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row col-md-12 col-lg-12">
                                            <div className="col-md-1 col-lg-1">
                                                <BwmSelect
                                                    options={genderList}
                                                    name='gender'
                                                    label='Sesso'
                                                    className='form-control'
                                                    onChange={(e) => setGender(e.target.value)}
                                                    value={gender}
                                                />
                                            </div>
                                            <div className="col-md-3 col-lg-3 offset-1">
                                                <DateField label={"Data di Nascita"} date={birthDate}
                                                    onChange={date => setBirthDate(date)}
                                                    error={errorBirthDate} name={"birthDate"} />
                                            </div>
                                            <div className={"col-md-3 col-lg-3 offset-1"}>
                                                <AutoCompileCustom
                                                    label='Comune di Nascita'
                                                    id="birthLocation"
                                                    handleInputAutocompile={handleInputBirthLocationAutocompile}
                                                    filtro={relationshipLocations}
                                                    value={birthLocation}
                                                    error={errorBirthLocation}
                                                />
                                            </div>
                                            <div className="col-md-2 col-lg-2 offset-1">
                                                <ProvinceField province={birthProv}
                                                    onChange={e => setBirthProv(e.target.value.toUpperCase())}
                                                    error={errorBirthProv} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-3 col-lg-3 panel-note ">
                                        <label>Note</label>
                                        <textarea className={'form-control'}
                                            rows={3}
                                            cols={12}
                                            onChange={e => setComment(e.target.value)}
                                            value={comment}
                                            name="comment"
                                            maxLength={255}>
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div handleCreateRelationship={undefined} id="tab-tit" className="d-none" label="Titolare Eff. e Delegato" >
                                <FormDelegati
                                    relationship={localRelationship}
                                    saveRelationship={saveRelationship}
                                    onSave={(coraRelationDelegates) => { setDelegates(coraRelationDelegates) }}
                                    startDate={startDate}
                                    endDate={endDate}
                                    relationshipLocations={relationshipLocations}
                                    roles={roles}

                                />
                            </div>

                        </Tabs>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}