import React from "react";
import CoraSubjectRow from "./CoraSubjectRow";
import {ReactComponent as ISort} from "../../../../styles/images/svgs/regular/sort.svg";


export class CoraSubjectList extends React.Component{

    constructor(props) {
        super(props);
        this.state = ({
            subjects: undefined,
            sortCompanyName: 1,
            sortFiscalCode: 1,
            sortLocation: 1
        })
    }

    sortBy = (val) => {
        if(val){
            let subjects = (this.state.subjects && this.state.subjects.length > 0) ? this.state.subjects : this.props.subjects;
            let subjectsOrdered = subjects;
            switch(val) {
                case "companyName":
                    // Sort by companyName
                    subjectsOrdered =  subjects.sort((a,b) => (a.companyName > b.companyName) ? this.state.sortCompanyName : ((b.companyName > a.companyName) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortCompanyName : -this.state.sortCompanyName});
                    break;
                case "fiscalCode":
                    // Sort by fiscalCode
                    subjectsOrdered =  subjects.sort((a,b) => (a.fiscalCode > b.fiscalCode) ? this.state.sortFiscalCode : ((b.fiscalCode > a.fiscalCode) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortFiscalCode : -this.state.sortFiscalCode});
                    break;
                case "location":
                    // Sort by location
                    subjectsOrdered =  subjects.sort((a,b) => (a.location > b.location) ? this.state.sortLocation : ((b.location > a.location) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortLocation : -this.state.sortLocation});
                    break;
                default:
                    return;
            }
        }
    }

    subjectsFields(){
        return (
            <React.Fragment>
                    <div className='col w-3 text-center div-td'>
                        <input type="checkbox"
                               id='checkboxAllId'
                                onClick={this.onCheckClick}/>
                    </div>
                    <div className='col w-10 div-td' ><strong>Codice Fiscale</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("fiscalCode")}/></div>
                    <div className='col w-20 div-td' ><strong>Ragione Sociale/Nominativo</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("companyName")}/></div>
                    <div className='col w-15 div-td' ><strong>Sede Legale</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("location")}/></div>
                    <div className='col w-3 div-td text-truncate' ><strong>Prov.</strong></div>
                    <div className='col w-8 div-td' ><strong>Num. SID</strong></div>
                    <div className='col w-8 div-td text-center'  ><strong>Scad. Certificati</strong></div>
                    <div className='col w-75 div-td' ><strong>Email da StarSoluzioni</strong></div>
                    <div className='col w-5 div-td text-truncate' style={{textAlign:'center'}}><strong>Dettaglio</strong></div>
            </React.Fragment>
        )
    }

    onCheckClick = (event) =>{
        if(event.target.checked)
            this.checkAll();
        else
            this.uncheckAll()
    };

    checkAll = () => {
        this.props.checkAllSubjects();

    };

    uncheckAll = () =>{
        this.props.uncheckAllSubjects();
    };

    showRows = (subjects) => {
        return subjects.map((subject, index) => {
            return (
                <CoraSubjectRow
                    key={index}
                    subject={subject}
                    allSubjects={subjects}
                    updateCoraSubject={this.props.updateCoraSubject}
                    customLocations={this.props.customLocations}
                    checkSubject={this.props.checkSubject}
                    uncheckSubject={this.props.uncheckSubject}
                    reloadSubjects={this.props.reloadSubjects}
                    licenseExpired={this.props.licenseExpired}
                    onRowClick={this.props.onRowClick}
                />
            )
        });
    };


    render() {
        let subjectsData = (this.state.subjects) ? this.state.subjects : this.props.subjects;
        return(
            <React.Fragment>
                <div className="text-start header-table">
                    {this.subjectsFields()}
                </div>
                {this.showRows(subjectsData)}
            </React.Fragment>
        )
    }
}

