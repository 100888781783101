import React, { useState } from "react";
import imgShowBlu from "../../../../styles/images/show_blu.png";
import { ReactComponent as ICheck } from "../../../../styles/images/svg/check.svg";
import { ReactComponent as ISignature } from "../../../../styles/images/svg/file-signature.svg";
import { ReactComponent as IUpload } from "../../../../styles/images/svg/file-upload.svg";
import { ReactComponent as IExport } from "../../../../styles/images/svgs/regular/file-export.svg";

import { BwmInput } from "../BwmInput";
import * as Constants from "../../../../config/Constants";
import {
  postSendFileToVerifySidCertificate,
  postSendFileToVerifySidCertificateById,
  exportCertificateFiles
} from "../../../../actions/index";
import { PopupError } from "../../PopupError";
import ModalPasswordBits from "./ModalPasswordBits";
import { handleChangeCerts, saveSid } from "../../SidCertsHandler";
import * as Utility from "../../Utility";
import { PopupSuccess } from "../../PopupSuccess";
import { PageSpinner } from "../../spinner/PageSpinner";
import { LicenseExpiredPopup } from "../../../application/application-cora/LicenseExpiredPopup";
import { Col, Modal } from "react-bootstrap";
import { StarTooltip } from "../../tooltips/Tooltip";

function SidForm(self) {
  const [openModalPasswordBits, setOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bits, setBits] = useState("");
  const [certCn, setCertCn] = useState("");
  const subjectFiscalCode = self.state.fiscalCode || self.state.subject?.fiscalCode //i modali di CRS e CORA gestiscono lo stato in maniera differente. In CRS, il CF si trova sotto subject.
  const [isLoading, setIsLoading] = useState("");
  const coraSubjectSid = self.state.coraSubjectSid;
  const sidPassword = coraSubjectSid?.password;
  const sidNumber = coraSubjectSid?.sidNumber;
  const [certificateDto, setCertificateDto] = useState();
  const [signatureCertificateDto, setSignatureCertificateDto] = useState();


  const nameApplication = self.nameApplication;


  /**
   * Questa funzione serve per capire se attivare il pulsante VERIFICA.
   */
  //condizioni per abilitare il pulsante:
  //- i certificati devono essere stati caricati
  //- il codice fiscale, numero sid e password devono essere valorizzati
  //- la licenza deve essere valida
  const shouldEnableVerifyButton =
    Utility.isNotEmpty(sidNumber) &&
    Utility.isNotEmpty(sidPassword) &&
    Utility.isNotEmpty(subjectFiscalCode) &&
    !self.props.licenseExpired &&
    (coraSubjectSid?.certificate1 && coraSubjectSid?.certificate1.includes("UTEC.P12")) &&
    (coraSubjectSid?.certificate2 && coraSubjectSid?.certificate2.includes("UTEF.P12"));


  const shouldEnableLoadButton =
    Utility.isNotEmpty(sidNumber) &&
    Utility.isNotEmpty(sidPassword) &&
    Utility.isNotEmpty(subjectFiscalCode);



  const onClickIconPsw = () => {
    let x = document.getElementById(`password${self.props.subject?.id || 0}`);
    if (x !== null) {
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    }
  };

  /**
   * verifica, i certificati caricati contro la password fornita nel form
   * se il SID non è ancora stato salvato, viene passato anche il file  UTEC.P12 per la verifica
   * se il SID è già stato salvato, lo aggiorna con i nuovi dati(bits, date di scadenza/emissione)
   */
  const verifySidCertificate = () => {
    const id = coraSubjectSid?.id;
    const password = coraSubjectSid?.password;
    const files = self.state.certificateFiles.files;
    if (Utility.isNotEmpty(id)) {
      postSendFileToVerifySidCertificateById(
        nameApplication,
        id,
        password,
        subjectFiscalCode
      ).then(
        (answer) => onCorrectRequest(answer),
        (err) => errRequest(err)
      );
    } else if (files.length > 0 && files.length < 4) {
      postSendFileToVerifySidCertificate(nameApplication, files, password,subjectFiscalCode).then(
        (answer) => {
          onCorrectRequest(answer);
        },
        (err) => errRequest(err)
      );
    }
  };


/**
 * al caricamento, viene effettuata automaticamente una verifica.
 * Il savataggio dei file sarà subordinata alla verifica con esito positivo
 * @param {*} e evento
 */
  const onChangeCertificateFiles = (e)=> {
    const password = coraSubjectSid?.password;
    const files = self.state.certificateFiles.files;
    postSendFileToVerifySidCertificate(nameApplication, files, password,subjectFiscalCode).then(
      (answer) => {
        onCorrectRequest(answer);
        handleChangeCerts(
          e,
          (value) => self.setStateFunction(value),
          self.state,
          self.props.subject?.coraSubjectSid
        )
      },
      (err) => errRequest(err)
    );
  }

  const onClickExportCertificateFiles = () => {
    const id = coraSubjectSid?.id;
    if (!id) {
      return;
    }
    exportCertificateFiles(id)
      .then((res) => {
        if (res) {
          Utility.downloadFile(res.data, "certificati.zip");
          PopupSuccess({ text: "Il file è stato scaricato correttamente" })
        }
      }, (err) => {
        PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
      }).then(() => setIsLoading(false))
  }

  /**
   * Questa funzione aggiorna il sid con i dati appena ottenuti dalla verifica dei certificati
   * @param {array} answer la risposta data dalla chiamata rest
   */
  const onCorrectRequest = (answer) => {
    answer.forEach(element => {
      if (element.certificateType === "CERTIFICATO") {
        setCertificateDto(element);
        setStartDate(element.startDate);
        setEndDate(element.endDate);
        setBits(element.econdingBits);
        setCertCn(element.cn)
        self.handleChangeEncryptionBits(element.econdingBits);
        self.handleChangeExpirationDate(new Date(element.endDate));
        self.handleChangeStartDate(new Date(element.startDate));
      } else if (element.certificateType === "FIRMA") {
        setSignatureCertificateDto(element);
      }
    });
    setOpen(true);
  };

  /**
   * Questa funzione deve ritornare un popup e nel farlo deve capire che errore dare
   * Essendo che non c'è la certezza che title abbia un valore si fa questo controllo e
   * e si mette un valore piu generico casomai non c'è niente di piu descrittivo dal
   * backend
   * @param {*} err L'errore dato nel fare la chiamata REST
   */
  const errRequest = (err) => {
    if (err?.title) {
      if (err.errorKey === "InvalidLicense") {
        LicenseExpiredPopup();
      } else {
        PopupError({ text: err.title });
      }
    } else {
      PopupError({ text: Constants.APPLICATION_GENERIC_ERROR });
    }
  };




  const datesText = coraSubjectSid?.startDate && coraSubjectSid?.expirationDate &&
    `${new Date(coraSubjectSid?.startDate).toLocaleDateString()} - ${new Date(coraSubjectSid?.expirationDate).toLocaleDateString()}`


  const hideModal = () => {
    if (coraSubjectSid.id) {
      saveSid(undefined, coraSubjectSid, coraSubjectSid.fiscalCode, coraSubjectSid.application
        , undefined
        , () => PopupError()
        , () => setOpen(false))
    } else {
      setOpen(false);
    }
  };

  return (

    <div className="mt-4">
      {isLoading && <PageSpinner />}
      <div className="row">
        <div className="col-md-3 align-self-center">
          <h5>
            <ISignature fill="#000" width="18" height={17} />
            Certificati
          </h5>
        </div>
        <div className="col-md-9 d-flex justify-content-end align-self-center">
          <span className="image-upload">
            <label htmlFor={`certificateFiles${self.props.subject?.id || 0}`}>
              <StarTooltip text={!shouldEnableLoadButton && "Prima di caricare i certificati, inserire Codice fiscale/P.IVA, numero SID e password certificati"}>
                <div className={`btn btn-outline-primary btn-empty px-3 btn-sm text-small ${!shouldEnableLoadButton ? "disabled" : ""}`}>
                  <IUpload
                    width="18"
                    height="17"
                    fill="#128186"
                    className="me-2"
                  />
                  CARICA
                </div>

              </StarTooltip>
            </label>
            <input 
              id={`certificateFiles${self.props.subject?.id || 0}`}
              type="file"
              name={`certificateFiles${self.props.subject?.id || 0}`}
              multiple
              accept=".p12,.ks"
              ref={(ref) => self.setCertificateFilesCustom(ref)}
              disabled={Utility.isEmpty(sidPassword)}
              onChange={(e) =>
                onChangeCertificateFiles(e)
              }
            />
          </span>

          <StarTooltip text={!shouldEnableVerifyButton && "Inserire Codice fiscale/P.IVA, numero SID, password certificati e caricare file"}>
            <button
              disabled={!shouldEnableVerifyButton}
              className="btn btn-outline-primary btn-empty px-3 btn-sm  me-2 ms-2  text-small"
              onClick={() => verifySidCertificate()}
              title="Verifica certificati"
            >
              <ICheck width="18" height="17" fill="#128186" className="me-2" />
              VERIFICA
            </button>

          </StarTooltip>
          <button
            className="btn btn-outline-primary btn-empty px-3 btn-sm  text-small"
            onClick={() => onClickExportCertificateFiles()}
            title="Scarica certificati"
            disabled={!(coraSubjectSid?.id && shouldEnableVerifyButton)}
          >
            <IExport width="18" height="17" fill="#128186" className="me-2" />
            ESPORTA
          </button>
        </div>

      </div>
      {self.state.errorCertificates && !self.state.errorCertificates.isValid &&
        <div className="row align-items-center justify-content-end text-danger me-2"><strong>{self.state.errorCertificates.msg}</strong></div>
      }
      <div className="row mt-2 align-items-center">
        <div className="col-md-4 d-flex ">
          <BwmInput
            className={"form-control"}
            label={"Numero accreditamento SID"}
            name="sidNumber"
            error={self.state.errorSidNumber}
            value={sidNumber}
            onChange={(e) =>
              self.handleChangeSid(
                e,
                self.state.errorSidNumber,
                "Campo obbligatorio"
              )
            }
          />
        </div>
        <div className="col-md-4 ">
          <div className="d-flex">
            <BwmInput
              id={`password${self.props.subject?.id || 0}`}
              type={"password"}
              className={"form-control"}
              label={"Password di protezione (Max 20 caratteri)"}
              maxLength={20}
              name="password"
              error={self.state.errorPassword}
              value={sidPassword}
              onChange={(e) =>
                self.handleChangeSid(
                  e,
                  self.state.errorPassword,
                  "Campo obbligatorio"
                )
              }
              autoComplete={"new-password"}
            />
            <div className="align-self-center mt-2">
              <img
                alt={"eye-blu"}
                className="img-info ms-2"
                src={imgShowBlu}
                onClick={onClickIconPsw}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <BwmInput
            disabled
            label={"Validità certificati"}
            className={"form-control"}
            name="expirationDate"
            value={datesText}
            dataTip={datesText}

          />
        </div>
        {openModalPasswordBits &&
          <Modal
            show={openModalPasswordBits}
            centered
            aria-labelledby="contained-modal-title-vcenter"
            onHide={hideModal}
            size="lg"
          >
            <div className="bar"></div>
            <Modal.Header closeButton>
              <Modal.Title>ESITO VERIFICA CERTIFICATI</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ paddingLeft: "27px", paddingTop: "70px" }}>
              <div className="d-flex">
                <Col>
                  <ModalPasswordBits
                    certificateDto={certificateDto}
                    fiscalCode={subjectFiscalCode}
                  />
                </Col>

                <Col>
                  <ModalPasswordBits
                    certificateDto={signatureCertificateDto}
                    fiscalCode={subjectFiscalCode}
                  />
                </Col>
              </div>

            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
              <button className="btn btn-primary btn-new-rel " onClick={hideModal}>
                CHIUDI E SALVA
              </button>
            </Modal.Footer>
          </Modal>
        }

      </div>
    </div>
  );
}

export default SidForm;
