import React from 'react';
import * as actions from "../../../../actions";
import { connect } from "react-redux";
import { ReactComponent as ITextFileSolid } from '../../../../styles/images/svg/file-alt-solid.svg';
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg';
import { Link } from "react-router-dom";
import { downloadConsistencyFile, downloadConsistencyReceiptTxt, downloadOriginalReceipt, downloadReceiptTxt } from '../CommunicationDownloadUtils';
import { CommunicationStatus } from '../../../../config/CommunicationStatus';
import { formatDateForDisplay } from '../../../shared/Utility';

class ConsistencyHistoryList extends React.Component {

    constructor() {
        super();
    }

    componentWillMount() {
        const { subjectId } = this.props;
        this.getHistory(subjectId);
        this.getSubject(subjectId);
    }

    getHistory = (subjectId) => {
        this.props.dispatch(actions.getConsistencyReceiptHistoryBySubjectId(subjectId));
    };

    getSubject(subjectId) {
        this.props.dispatch(actions.getCoraSubject(subjectId));
    }


    renderRowHeader() {
        return (
            <React.Fragment>
                <div className='col w-10 div-td'>Data creazione</div>
                <div className='col w-25 div-td'>Nome File</div>
                <div className='col w-75 div-td'>Stato</div>
                <div className='col w-10 div-td'>Scarica</div>
            </React.Fragment>
        )
    }


    isATPEC03or04(communication){
        return communication?.txtFilename.startsWith("ATPEC03") || communication?.txtFilename.startsWith("ATPEC04");
    }
    computeStatusText(communication){
        let status = "";
        if (communication?.communicationStatus === CommunicationStatus.ERROR_SID) {
            status = "Attenzione!! " + communication?.communicationError + ".";
        } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
            if(this.isATPEC03or04(communication)){
                status = "Esito Elaborato: comunicazione ACCOLTA";
            } else {
                status = "Fotografia Elaborata: nessuna discordanza tra gli archivi"
            }
        } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR) {
            if(this.isATPEC03or04(communication)){
                status = "Esito Elaborato: comunicazione ACCOLTA CON ERRORI";
            } else {
                status = "Fotografia Elaborata: presenti discordanze tra gli archivi"
            }
        } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
            if (communication.communicationError){
                status = communication.communicationError;
            } else {
                if(this.isATPEC03or04(communication)){
                    status = "Esito Elaborato: comunicazione SCARTATA";
                } else {
                    status = "Fotografia non processata";
                }
            }
        }
        return status;
    }

    renderCommunicationRow(communication) {
        let isReceipt = this.isATPEC03or04(communication);
        let status = this.computeStatusText(communication);
        return (
            <React.Fragment>
                <div className="text-start row-table">
                    <div className='col w-10 div-td text-truncate'>{formatDateForDisplay(communication.creationDate)}</div>
                    <div className='col w-25 div-td text-truncate'>{communication?.txtFilename}</div>
                    <div className='col w-75 div-td text-truncate'>{status}</div>
                    <div className='col w-10 div-td text-truncate d-flex justify-content-around align-items-center pe-4 ps-4 containerPopOver'>
                        {isReceipt ?
                            <>
                                <button data-tip={"Scarica file esito in chiaro"} onClick={() => downloadReceiptTxt(communication)}><ITextFile width="20" /></button>
                                <button data-tip={"Scarica file esito originale"} onClick={() => downloadOriginalReceipt(communication)}><ITextFileSolid width="20" fill="#128186" /></button>
                            </>
                            :
                            <>
                                <button data-tip={"Scarica fotografia in chiaro"} onClick={() => downloadConsistencyReceiptTxt(communication)}><ITextFile width="20" /></button>
                                <button data-tip={"Scarica file ricevuto se presente (P7M)"} onClick={() => downloadConsistencyFile(communication)}><ITextFileSolid width="20" fill="#128186" /></button>
                                <button data-tip={"Scarica file elaborato da SID (RUN)"} onClick={() => downloadOriginalReceipt(communication)}><ITextFileSolid width="20" fill="#128186" /></button>
                            </>
                        }

                    </div>
                </div>
            </React.Fragment>

        )
    }


    renderRows(communications) {
        return communications.map((communication) => {
            return this.renderCommunicationRow(communication)
        });
    }

    renderErrorRows(communication) {
        return this.renderCommunicationRow(communication);

    }

    render() {
        const { communicationsHistory } = this.props;
        return (
            <React.Fragment>
                {communicationsHistory?.length > 0 &&
                    <React.Fragment>
                        <div className="row header-btn align-items-left">
                            STORICO FOTOGRAFIE DI CONSISTENZA/ESITI
                        </div>
                        <div className="text-start header-table">
                            {this.renderRowHeader()}
                        </div>
                    </React.Fragment>
                }
                {this.renderRows(communicationsHistory)}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        subject: state.subject.data,
        communicationsHistory: state.communicationsHistory.data,
    }
}

export default connect(mapStateToProps)(ConsistencyHistoryList);


