import { useEffect } from 'react';

export function Dac7Refresh() {
    useEffect(() => {
        if (window.location.href.indexOf('localhost:') === -1) {
            window.location.reload(true);
        }
    }, []);

    return null;
}