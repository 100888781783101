import React, { useEffect, useState } from "react";
import { isEmpty, isNotEmpty } from "../../Utility";
import "./floatLabelTextBox.css"
export function FloatLabelTextBox({ value,type, error,disabled, label, onChange, onBlur, placeholder, maxLength }) {

    const [empty, setEmpty] =useState(true);//necessario al funzionamento della label mobile

    useEffect(()=>{
        setEmpty(isEmpty(value));
    },[value]);
    
    const onBlurFunc =(e)=>{
            setEmpty(isEmpty(value));
            if(onBlur){
                onBlur(e);
            }
    }


    return (
        <div className="inputs">
            <label className="input">
                <input className={`input__field ${!empty && 'notEmpty'}`}
                    type={type || "text"} 
                    placeholder={placeholder} 
                    value={value} 
                    onChange={onChange}
                    onBlur={(e)=>onBlurFunc(e)}
                    disabled={disabled}
                    maxLength={isNotEmpty(maxLength) ? maxLength : 255}
                    />
                <span className="input__label">{label}</span>
            </label>
                <span className="input__error">{error}</span>
        </div>
    )

}