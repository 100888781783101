import React from "react";
import { ReactComponent as IDelete } from "../../../../../styles/images/svg/trash-solid.svg";
export function DeleteButton(props) {

    return (
        <button type="button" disabled={props.disabled}
            className={`btn btn-outline-primary  btn-empty px-4 btn-sm margin-left-5px ${props.disabled && 'disabled'}`}
            onClick={props.onClick} >
            <IDelete className={"padding-bottom-4px"} width="12" fill={` ${props.disabled ? '#FFFFFF' : '#128186'}`} />
            &nbsp; Elimina
        </button>
    )
}