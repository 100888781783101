import React from 'react';
import '../../../../styles/comurei-form.css';
import '../../../../styles/arcoTablesModal.css';
import * as actions from "../../../../actions";
import { PopupError } from "../../../shared/PopupError";
import { BwmInput } from "../../../shared/form/BwmInput";
import { BwmCheckbox } from "../../../shared/form/BwmCheckbox";
import { DateField } from "../../../shared/form/DateField";
import { PopupDelete } from "../../../shared/PopupDelete";
import { CloseButton } from './table-components/CloseButton';
import { Col } from 'react-bootstrap';
import { CreateNewButton } from './table-components/CreateNewButton';
import { DeleteButton } from './table-components/DeleteButton';
import { ConfirmButton } from './table-components/ConfirmButton';
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants';


class ArcoTableCollaboratorsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            update: false,
            show: false,
            indexShow: 0,
            checkedCollaborators: [],
            collaborator: {
                name: "",
                fiscalCode: "",
                code: "",
                disableDate: null,
                disabled: false,
                createDate: new Date(),
                updateDate: new Date(),
                arcoSubject: {
                    id: this.props.subject.id
                },
                customer: {
                    id: this.props.subject.customerId
                }
            },
            errorrCode: { isValid: true, msg: "" },
            errorFiscalCode: { isValid: true, msg: "" },
            errorName: { isValid: true, msg: "" },
            errorDisableDate: { isValid: true, msg: "" },
            errorDisabled: { isValid: true, msg: "" }
        }
    }

    onCheckClick = (event) => {
        if (event.target.checked)
            this.checkAll();
        else
            this.uncheckAll();
    };

    checkAll = () => {
        document.getElementById('checkboxAllCollaboratorId').checked = true;
        this.props.collaborators.map(collaborator => {
            const checkboxDetailsId = `checkboxCollaboratorDetails${collaborator.id}`;
            const idCheckbox = `collaborator-${collaborator.id}`;
            if (document.getElementById(checkboxDetailsId)) {
                document.getElementById(checkboxDetailsId).checked = true;
            }
            if (document.getElementById(idCheckbox)) {
                document.getElementById(idCheckbox).checked = true;
            }
        });
        let rowIds = document.querySelectorAll('[id^="collaborator-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.add("bg-cobalt", "bold");
            }
        }
        this.checkAllCollaborators();

    };

    uncheckAll = () => {
        document.getElementById('checkboxAllCollaboratorId').checked = false;
        this.props.collaborators.map(collaborator => {
            const checkboxDetailsId = `checkboxCollaboratorDetails${collaborator.id}`;
            const idCheckbox = `collaborator-${collaborator.id}`;
            if (document.getElementById(checkboxDetailsId)) {
                document.getElementById(checkboxDetailsId).checked = false;
            }
            if (document.getElementById(idCheckbox)) {
                document.getElementById(idCheckbox).checked = false;
            }
        });
        this.uncheckAllCollaborators();
        let rowIds = document.querySelectorAll('[id^="collaborator-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
    };

    onCheckBoxClick = (event, collaborator) => {
        if (event.target.checked) {
            this.checkCollaborator(collaborator);
            let element = document.getElementById("collaborator-" + collaborator.id);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        } else {
            this.uncheckCollaborator(collaborator);
            document.getElementById('checkboxAllCollaboratorId').checked = false;
            let element = document.getElementById("collaborator-" + collaborator.id);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }

    };
    showDetail = (index) => {
        this.setState({
            show: !this.state.show,
            indexShow: index
        })
    };

    updateAll = () => {
        if (!this.handleValidation()) return;

        this.addArcoCollaborator(this.state.collaborator);
        this.openUpdate();
    };
    handleValidation = () => {
        let isValid = true;
        if (!this.state.collaborator.name) {
            this.setState({
                errorName: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.collaborator.fiscalCode) {
            this.setState({
                errorFiscalCode: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.collaborator.code) {
            this.setState({
                errorCode: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (this.state.collaborator.disabledDate && this.state.collaborator.disabled) {
            this.setState({
                errorDisabled: { isValid: false, msg: "Campo errato" },
                errorDisableDate: { isValid: false, msg: "Campo errato" }
            });
            isValid = false;
        }

        if (!this.state.collaborator.disabledDate && !this.state.collaborator.disabled) {
            this.setState({
                errorDisabled: { isValid: false, msg: "Campo obbligatorio" },
                errorDisableDate: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        return isValid;
    };

    changeName = (e) => {
        this.setState({
            collaborator: {
                ...this.state.collaborator,
                name: e.target.value.toUpperCase()
            },
            errorName: { isValid: true, msg: "" },
        });
    };
    changeFiscalCode = (e) => {
        this.setState({
            collaborator: {
                ...this.state.collaborator,
                fiscalCode: e.target.value.toUpperCase()
            },
            errorFiscalCode: { isValid: true, msg: "" },
        });
    };
    changeCode = (e) => {
        this.setState({
            collaborator: {
                ...this.state.collaborator,
                code: e.target.value.toUpperCase()
            },
            errorCode: { isValid: true, msg: "" },
        });
    };
    changeDisabledDate = (date) => {
        this.setState({
            collaborator: {
                ...this.state.collaborator,
                disabledDate: date !== null ? new Date(date) : null
            },
            errorDisableDate: { isValid: true, msg: "" },
        });

    };

    changeDisable = (e) => {
        this.setState({
            collaborator: {
                ...this.state.collaborator,
                disabled: e.target.checked
            },
            errorDisabled: { isValid: true, msg: "" },
        });
    };


    addArcoCollaborator = (collaborator) => {
        actions.saveArcoCollaborator(collaborator).then(
            (response) => {
                this.props.getAllCollaboratorBySubjectId();

            },
            (errors) => {

                PopupError(
                    { text: errors?.title || APPLICATION_GENERIC_ERROR }
                );
            }
        );

    };

    openUpdate = () => {
        this.setState({
            update: !this.state.update,
            errorrCode: { isValid: true, msg: "" },
            errorFiscalCode: { isValid: true, msg: "" },
            errorName: { isValid: true, msg: "" },
            errorDisableDate: { isValid: true, msg: "" },
            errorDisabled: { isValid: true, msg: "" },
            collaborator: {
                id: "",
                name: "",
                fiscalCode: "",
                code: "",
                disableDate: null,
                disabled: false,
                createDate: new Date(),
                updateDate: new Date(),
                arcoSubject: {
                    id: this.props.subject.id
                },
                customer: {
                    id: this.props.subject.customerId
                }
            }
        })
    };

    openPopupDelete = () => {
        PopupDelete({
            operazione: "Collaboratori Selezionati",
            handleClickConfirm: this.deleteArcoCollaboratorSubject
        });
    };

    deleteArcoCollaboratorSubject = () => {
        this.state.checkedCollaborators.map(collaborator => {
            actions.deleteArcoCollaborator(collaborator.id).then(
                () => {
                    this.setState({ checkedCollaborators: [] })
                    this.props.getAllCollaboratorBySubjectId();
                    //chiudo form se contiene l'elemento cancellato
                    if (this.state.collaborator.id) {
                        this.openUpdate();
                    }
                },
                (error) => {
                    PopupError({ text: error.title })
                })
            document.getElementById(`checkboxCollaboratorDetails${collaborator.id}`).checked = false;
        });
        let rowIds = document.querySelectorAll('[id^="collaborator-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
    };

    checkCollaborator = (collaborator) => {
        let newList = this.state.checkedCollaborators;
        newList.push(collaborator);
        this.setState({ checkedCollaborators: newList });
        if (newList.length === this.props.collaborators.length) {
            this.checkAll();
        }
    };

    uncheckCollaborator = (collaborator) => {
        let newList = [];
        this.state.checkedCollaborators.map(current => {
            if (current.id !== collaborator.id)
                newList.push(current);
        });
        this.setState({ checkedCollaborators: newList })
    };

    checkAllCollaborators = () => {
        let allCollaborators = this.props.collaborators;
        this.setState({
            checkedCollaborators: allCollaborators
        });
    };

    uncheckAllCollaborators = () => {
        this.setState({
            checkedCollaborators: []
        });
    };

    onClickCompileUpdate = (e, collaboratorSelected) => {
        this.setState({
            errorrCode: { isValid: true, msg: "" },
            errorFiscalCode: { isValid: true, msg: "" },
            errorName: { isValid: true, msg: "" },
            errorDisableDate: { isValid: true, msg: "" },
            errorDisabled: { isValid: true, msg: "" },
            update: true,
            collaborator: {
                ...collaboratorSelected,
                disabledDate: collaboratorSelected.disabledDate ? new Date(collaboratorSelected.disabledDate) : null
            }
        });
    };

    renderRows(collaborators) {
        return collaborators.map((collaborator) => {
            const idCheckbox = `checkboxCollaboratorDetails${collaborator.id}`;
            return (
                <React.Fragment >
                    <div
                        key={`collaborator-${collaborator.id}`}
                        className={'row text-start row-table'}
                        id={`collaborator-${collaborator.id}`}>
                        <div className='col-lg-1 text-center div-td'>
                            <input type="checkbox"
                                id={idCheckbox}
                                onClick={e => this.onCheckBoxClick(e, collaborator)} />
                        </div>
                        <div className='col-lg-2 text-center div-td text-truncate' onClick={e => this.onClickCompileUpdate(e, collaborator)}>
                            {collaborator.code}</div>
                        <div className='col-lg-4 text-center div-td text-truncate' onClick={e => this.onClickCompileUpdate(e, collaborator)}>
                            {collaborator.name}</div>
                        <div className='col-lg-4 text-center div-td text-truncate' onClick={e => this.onClickCompileUpdate(e, collaborator)}>
                            {collaborator.fiscalCode}
                        </div>
                        <div className='col-lg-1 text-center div-td text-truncate text-center' onClick={e => this.onClickCompileUpdate(e, collaborator)}>
                            {collaborator.disabled ? "SI" : "NO"}
                        </div>
                    </div>
                </React.Fragment>
            )
        });
    };



    render() {
        let collaboratorsData = this.props.collaborators;
        const hasNoCkeckedItems = (this.state.checkedCollaborators).length === 0;
        return (
            <React.Fragment>
                <div className="form-row mt-2 mb-2 align-item-center">
                    <div className="col-lg-9">
                        <DeleteButton
                            disabled={hasNoCkeckedItems}
                            onClick={this.deleteArcoCollaboratorSubject}
                        />
                    </div>
                </div>

                <div className='overflow-auto ms-3 me-3' style={{ height: '470px' }}>
                    <div className={"px-2 mx-2"}>
                        <div className="row text-center header-table">
                            <div className='col-sm-1 text-center div-td'>
                                <input type="checkbox"
                                    id='checkboxAllCollaboratorId'
                                    onClick={this.onCheckClick} />
                            </div>
                            <div className='col-lg-2 text-center div-td' ><strong>Codice</strong> </div>
                            <div className='col-lg-4 text-center div-td' ><strong>Nome&nbsp; e &nbsp;Cognome</strong></div>
                            <div className='col-lg-4 text-center div-td' ><strong>Codice Fiscale</strong> </div>
                            <div className='col-lg-1 text-center div-td' ><strong>Abilitato</strong> </div>
                        </div>
                        {this.renderRows(collaboratorsData)}
                    </div>
                </div>
                <div>
                    <div className={` row me-1 ms-1 mt-4 mb-4 ${!this.state.update && 'invisible'}`} >
                        <div className='col-1'>
                            <BwmInput name='code'
                                label='Codice'
                                className='form-control'
                                onChange={e => this.changeCode(e)}
                                error={this.state.errorrCode}
                                value={this.state.collaborator.code}
                                maxLength="5"
                            />
                        </div>
                        <div className='col-3'>
                            <BwmInput name='name'
                                label='Nome e Cognome'
                                onChange={e => this.changeName(e)}
                                value={this.state.collaborator.name}
                                className='form-control'
                                error={this.state.errorName}
                            />
                        </div>
                        <div className="col-2">
                            <DateField 
                                label="Data Fine"
                                onChange={date => this.changeDisabledDate(date)}
                                error={this.state.errorDisableDate}
                                date={this.state.collaborator.disabledDate}
                                value={this.state.collaborator.disabledDate}
                                name={"disabilityDate"} />
                        </div>
                        <div className='col-2'>
                            <BwmInput name='fiscalCode'
                                label='Codice Fiscale'
                                onChange={e => this.changeFiscalCode(e)}
                                value={this.state.collaborator.fiscalCode}
                                className='form-control'
                                error={this.state.errorFiscalCode}
                            />
                        </div>
                        <div className="form-check col-1">
                            <label>Abilitato</label>
                            <div className="input-group mt-2 d-flex justify-content-between">
                                <BwmCheckbox
                                    name="ability"
                                    value={this.state.collaborator.disabled}
                                    onClick={e => this.changeDisable(e)}
                                    className='form-control'
                                    error={this.state.errorDisabled}

                                />
                            </div>
                        </div>
                        <div className='col-3 d-flex justify-content-end align-items-center'>
                            <ConfirmButton
                                onClick={this.updateAll}
                            />
                        </div>

                    </div>
                    <div className="form-row mt-2 align-item-center">
                        <Col lg={2}>
                            <CloseButton
                                onClick={this.props.onClose}
                            />
                        </Col>
                        <Col lg={8} className={"text-center"}>
                            <CreateNewButton
                                disabled={this.props.disableInsert}
                                onClick={this.openUpdate}
                                active={this.state.update} />

                        </Col>
                    </div>

                </div>


            </React.Fragment>
        )
    }
}

export default ArcoTableCollaboratorsList
