import {DELAYED_COMMUNICATION_DATE} from '../../actions/types';

const INITIAL_STATE = null;

export const delayedCommunicationDateReducer = (state = INITIAL_STATE, action) => {
     switch(action.type) {
        case DELAYED_COMMUNICATION_DATE:
            return action.delayedCommunicationDate;

        default:
            return state;
    }
}