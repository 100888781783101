import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as ICheck } from "../../../styles/images/svg/check.svg";
import * as actions from "../../../actions";


export default function BlueLanguageSelector(props) {


    const dispatch = useDispatch();
    const lang = useSelector(state => state.blueLanguage.language)

    const [selectedLanguage, setSelectedLanguage] = useState(lang);


    const handleLanguageClick = (e) => {
        setSelectedLanguage(e.target.value);
        dispatch(actions.changeBlueLanguage(e.target.value));
    }

    return (
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="btn-group-toggle col-lg-12 text-end " data-toggle="buttons" >
                        <input hidden={true}
                            type="radio"
                            name={`italianGeneral`}
                            defaultChecked={selectedLanguage === "it"}
                            id={`italianGeneral`} value={"it"}
                            onClick={handleLanguageClick} />
                        <label className="btn rounded-0 btn-primary btn-new-rel   me-2"
                            htmlFor={`italianGeneral`}>
                            it&nbsp;
                            {selectedLanguage === "it" && <ICheck className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} />}
                        </label>
                        <input hidden={true}
                            type="radio"
                            name={`englishGeneral`}
                            defaultChecked={selectedLanguage === "en"}
                            id={`englishGeneral`} value={"en"}
                            onClick={handleLanguageClick} />
                        <label className="btn rounded-0 btn-primary btn-new-rel"
                            htmlFor={`englishGeneral`}>
                            en&nbsp;
                            {selectedLanguage === "en" && <ICheck className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} />}
                        </label>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}