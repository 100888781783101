import React, { useEffect, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import EmailiForm from "./EmailiForm";
import { ReactComponent as IPdfFile } from "../../../../styles/images/svg/file-pdf.svg";
import { ReactComponent as ITextFile } from "../../../../styles/images/svg/file-alt.svg";
import { ReactComponent as IZipFile } from "../../../../styles/images/svg/file-archive.svg";
import { ReactComponent as IListFile } from "../../../../styles/images/svg/list.svg";
import { ReactComponent as IQuestion } from "../../../../styles/images/svgs/regular/question-circle.svg";
import { base64ToArrayBuffer, downloadFile } from "../../../shared/Utility";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { PopupError } from "../../../shared/PopupError";
import axiosService from "../../../../services/axios-service";


export function CrsCommunicationModal({
    sentCommunications = []
    , showModal = false
    , closeModal = () => { }
    , subject = {}
    , emailAddress = ""
}) {

    const [show, setShow]=useState(false)

    useEffect(()=>{
        setShow(showModal)
    },[])

    const onClose=()=>{
        setShow(false);
        closeModal();
    }

    const subjectId = subject?.id;


    const buttonAssistance = () => {
        const link = document.createElement("a");
        let subjectName = subject
          ? " - Soggetto: " + subject.companyName
          : "";
        let customerName = subject.customer
          ? "Utente: " + subject.customer?.companyName
          : "";
        link.href = `mailto:assistenza@starinfostudio.it?subject=CRSWEB - Richiesta assistenza ${customerName} ${subjectName}`;
        document.body.appendChild(link);
        link.click();
      };

    const downloadXml = (communication) => {
        let url = `/api/crs/custom/crs-communications/downloadXml/${subjectId}/${communication.id}`;
        callDownloadService(url, communication.xmlFilename);
    };

    const downloadDgn = (communication) => {
        let url = `/api/crs/custom/crs-communications/downloadDgn/${subjectId}/${communication.id}`;
        callDownloadService(
            url,
            communication.xmlFilename.replace(".xml", ".dgn")
        );
    };

    const downloadPecTz = (communication) => {
        let url = `/api/crs/custom/crs-communications/downloadPecTz/${subjectId}/${communication.id}`;
        callDownloadService(
            url,
            "PECAT." + communication.sidFilename + ".zip"
        );
    };

    const downloadPdf = (communication) => {
        downloadFile(new Blob([base64ToArrayBuffer(communication.pdf)], { type: "application/pdf" }),communication.xmlFilename.replace(".xml", ".pdf"))
        PopupSuccess({ text: "File scaricato" });
    };

    const callDownloadService = (url, file) => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance({
            url: url,
            method: "GET",
            responseType: "blob",
        }).then((response) => {
            if (response) {
                if (response?.data?.size > 0) {
                    downloadFile(response.data, file);

                    PopupSuccess({ text: "File scaricato" });
                } else {
                    PopupError({ text: "File non trovato" });
                }
            }
        });
    };

    const sendEnabled = sentCommunications?.length > 0 &&
        sentCommunications[0]?.communicationStatus !== "CREATED_WITH_ERROR";


    const renderRows = (communications) => {
        return communications.map((communication) => {
            let status = "";
            let isError =
                communication.communicationStatus === "RECEIPT_NOT_ACCEPTED" ||
                    communication.communicationStatus === "ERROR_SID" ||
                    communication.communicationStatus === "CREATED_WITH_ERROR"
                    ? true
                    : false;

            if (communication.communicationStatus === "CREATED") {
                status = "Creata - In attesa di ricevuta";
            } else if (communication.communicationStatus === "CREATED_WITH_ERROR") {
                status = "Creata con errori";
            } else if (communication.communicationStatus === "RECEIPT_ACCEPTED") {
                status = "Comunicazione accolta";
            } else if (communication.communicationStatus === "RECEIPT_NOT_ACCEPTED") {
                status = "Comunicazione NON accolta";
            }

            return (
                <Row key={"communication-row-" + communication.id} className="text-start row-table subrow">
                    <React.Fragment>
                        <div className="col w-10 div-td">{communication.referenceYear}</div>
                        <div className="col w-30 div-td">CRS</div>
                        <div className="col w-20 div-td">XML</div>
                        <div
                            className={
                                isError ? "col w-30 div-td  color-red" : "col w-30 div-td"
                            }
                        >
                            {status}
                        </div>
                        <div className="col w-15 div-td files text-center containerPopOver">
                            <div className={"xml"}>
                                <ITextFile
                                    width="20"
                                    fill="#128186"
                                    onClick={() => downloadXml(communication)}
                                />
                            </div>
                            {communication.communicationStatus === "CREATED" ? (
                                <div className={"zip"}>
                                    <IZipFile
                                        width="20"
                                        fill="#128186"
                                        onClick={() => downloadPecTz(communication)}
                                    />
                                </div>
                            ) : (
                                <div className={"dgn"}>
                                    <IListFile
                                        width="20"
                                        fill="#128186"
                                        onClick={() => downloadDgn(communication)}
                                    />
                                </div>
                            )}
                            {communication?.pdf &&
                                <div className={"pdf"}>
                                    <IPdfFile
                                        width="20"
                                        fill="#128186"
                                        onClick={() => downloadPdf(communication)}
                                    />
                                </div>
                            }

                        </div>
                    </React.Fragment>
                </Row>
            );
        });
    }



    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onClose}
            dialogClassName="modal-60w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="bar"></div>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h2>ESITO ELABORAZIONE SID: {subject?.companyName}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="popup communication">
                    <Row className="alert-header">
                        <React.Fragment>
                            <div className="col w-10 div-td">Periodo</div>
                            <div className="col w-30 div-td">Tipo Comunicazione</div>
                            <div className="col w-20 div-td">Tipologia Invio</div>
                            <div className="col w-30 div-td">Stato</div>
                            <div className="col w-15 text-center div-td">Scarica</div>
                        </React.Fragment>
                    </Row>
                    {renderRows(sentCommunications)}
                    {sendEnabled ? (
                        <EmailiForm
                            closeModal={onClose}
                            sentCommunications={sentCommunications}
                            emailAddress={emailAddress}
                            flagPec={subject?.coraSubjectSid?.flagPec}
                            subject={subject}
                        />
                    ) : (
                        <div className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                            <button
                                className="btn btn-outline-primary btn-empty px-4 btn-sm"
                                onClick={onClose}
                            >
                                ANNULLA
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary btn-new-rel px-5 btn-sm"
                                onClick={buttonAssistance}
                            >
                                <IQuestion
                                    className={"padding-bottom-4px svg"}
                                    width="16"
                                    fill={"#FFFFFF"}
                                />
                                &nbsp; RICHIEDI ASSISTENZA
                            </button>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}