import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { BwmInput } from "../../shared/form/BwmInput";
import { BtnFill } from "../../shared/BtnFill";
import { Col, Row } from "react-bootstrap";
import { restoreCoraSubject } from "../../../actions";
import { PopupError } from "../../shared/PopupError";
import { PopupSuccess } from "../../shared/PopupSuccess";

export function CoraAdmin() {

    const [userLogin, setUserLogin] = useState("");
    const [fiscalCode, setFiscalCode] = useState("");
    const location = useLocation()

    useEffect(()=>{
        setUserLogin(location?.state?.loginParam || "")
    },[])
    const onRestore = () => {
        let dto = {
            userLogin,
            fiscalCode
        }

        restoreCoraSubject(dto).then((res) => {
            PopupSuccess({
                text: ` Soggetto ${res.companyName}${res.firstName && res.firstName + " " + res.lastName} con id ${res.id},  ripristinato.
             Potrebbe essere necessario reinserire rapporti e certificati.` })
        },
            (err) => {
                PopupError({ text: err.title })
            });
    }

    return (
        <React.Fragment>
            <Row className="justify-content-center">
                <Col lg={6} >
                    <h4>
                        RIPRISTINA SOGGETTO CORA
                    </h4>
                    <div className="form-row">
                        <Col lg={7} >
                            <BwmInput
                                maxLength={255}
                                className='form-control'
                                name={"login"}
                                value={userLogin}
                                label={"Login utente: "}
                                onChange={e => setUserLogin(e.target.value.trim())}
                            />
                        </Col>

                    </div>
                    <div className="form-row">
                        <Col lg={7} >
                            <BwmInput
                                name={"fiscalCode"}
                                maxLength={16}
                                className='form-control'
                                value={fiscalCode}
                                label={"Codice Fiscale soggetto: "}
                                onChange={e => setFiscalCode(e.target.value.trim())}
                            />
                        </Col>
                    </div>
                    <div className="form-row">
                        <BtnFill
                            text={"RIPRISTINA"}
                            handlerOnClick={() => { onRestore() }}
                        />
                    </div>

                </Col>
            </Row>
        </React.Fragment>
    )



}