import React from "react";
import imgShowBlu from '../../../../styles/images/show_blu.png';
import { CoraSubjectRowDetails } from "./CoraSubjectRowDetails";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Link } from "react-router-dom";
import * as styles from "../../../../config/styles";
import * as Constants from "../../../../config/Constants";
import { connect } from "react-redux";
import { isNotEmpty } from "../../../shared/Utility";
import { PopupError } from "../../../shared/PopupError";

class CoraSubjectRow extends React.Component {

    constructor() {
        super();
        this.state = {
            checked: false,
            showDetail: false
        }
    }

    openDetails = () => {
        this.setState({
            showDetail: !this.state.showDetail
        })
    };


    onCheckBoxClick = (event) => {
        const checkboxDetailsId = `checkboxDetails${this.props.subject.id}`;
        let id = event.target.id.match(/\d+/);
        if (event.target.checked) {
            this.props.checkSubject(this.props.subject);
            document.getElementById(checkboxDetailsId).checked = true;

            let element = document.getElementById("subjectRow" + id);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        }
        else {
            this.props.uncheckSubject(this.props.subject);
            document.getElementById(checkboxDetailsId).checked = false;
            document.getElementById('checkboxAllId').checked = false;
            let element = document.getElementById("subjectRow" + id);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }

    };

    render() {
        const subject=this.props.subject;
        const id=`subjectRow${subject.id}`;
        const idCheckbox= `checkboxDetails${subject.id}`;
        const couldSendPec = this.props.subject?.coraSubjectSid?.couldSendPec;
        const isDelayedCommunicationDateSet = isNotEmpty(this.props.delayedCommunicationDate);
        const cannotSendPec = couldSendPec !== true && isDelayedCommunicationDateSet === true;
        const disableLink =cannotSendPec || this.props.licenseExpired;
        const path = disableLink ? "#" : {pathname:`/app/cora/relationship/${subject.id}`, state:{licenseExpired:this.props.licenseExpired}};
        
        const name = subject.personType === Constants.PNF ? subject.companyName : subject.lastName + " " + subject.firstName;
        const openPopUpPec=()=>{
            if(cannotSendPec){
                PopupError({text: `Parametri pec non configurati per il soggetto</br>${name}</br> Completare la configurazione o eliminare data di invio differito per continuare.`})
            } else if(this.props.onRowClick){
                this.props.onRowClick()
            }
        }

        let expirationDate = subject.coraSubjectSid?.expirationDate ? new Date(subject.coraSubjectSid?.expirationDate).toLocaleDateString("en-GB"): "";
        return (
            <React.Fragment>
                <div className='text-start row-table' id={id}>
                    <div className='col w-3 text-center div-td'>
                        <input type="checkbox" id={idCheckbox} onClick={this.onCheckBoxClick} />
                    </div>
                    <Link onClick={openPopUpPec} className={`col w-10 ${styles.rowLink}`} to={path} >{subject.fiscalCode}</Link>
                    <Link onClick={openPopUpPec} className={`col w-20 ${styles.rowLink}`} to={path} ><span className="notranslate">{name}</span></Link>
                    <Link onClick={openPopUpPec} className={`col w-15 ${styles.rowLink}`} to={path} >{subject.location}</Link>
                    <Link onClick={openPopUpPec} className={`col w-3 ${styles.rowLink}`} to={path} >{subject.province}</Link>
                    <Link onClick={openPopUpPec} className={`col w-8 ${styles.rowLink}`} to={path} >{subject.coraSubjectSid?.sidNumber}</Link>
                    <Link onClick={openPopUpPec} className={`col w-8 text-center ${styles.rowLink}`} to={path} >{expirationDate}</Link>
                    <Link onClick={openPopUpPec} className={`col w-75 ${styles.rowLink}`} to={path}>{subject.coraSubjectSid?.email}</Link>
                    <div className='col w-5 div-td text-truncate text-center' onClick={this.openDetails}>
                        <img src={imgShowBlu}  alt={"Mostra dettagli"}/>
                    </div>
                </div>
                <TransitionGroup component={null}>
                    {this.state.showDetail &&
                        <CSSTransition
                            in={this.state.show}
                            timeout={300}
                            classNames="visibleSubject"
                            unmountOnExit>
                            <CoraSubjectRowDetails
                                subject={subject}
                                allSubjects={this.props.allSubjects}
                                openDetails={this.openDetails}
                                customLocations={this.props.customLocations}
                                updateCoraSubject={this.props.updateCoraSubject}
                                checkSubject={this.props.checkSubject}
                                uncheckSubject={this.props.uncheckSubject}
                                coraSubjectSid={subject.coraSubjectSid}
                                licenseExpired={this.props.licenseExpired}
                            />
                        </CSSTransition >
                    }



                </TransitionGroup>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {

    return {
        delayedCommunicationDate: state.delayedCommunicationDate
    }
}
export default connect(mapStateToProps)(CoraSubjectRow)