import React from 'react';
import {connect} from 'react-redux';
import * as actions from "../../../../actions";
import elenco from "../../../../styles/images/elenco.png";
import spedire from "../../../../styles/images/spedire.png";
import arrow_l from "../../../../styles/images/arrow_l.png"
import {Link} from "react-router-dom";
import Tabs from "../../application-cora/relationships/Tabs";
import RelationshipList from "../../application-crs/relationship-crs/RelationshipList"
import CommunicationsHistoryList from "../../application-crs/communications/CommunicationsHistoryList";
import { CheckLicenseAndRedirect } from '../../../shared/auth/license/LicenseChecker';
import { APPLICATION_CRS } from '../../../../config/Constants';

class CrsRelationships extends React.Component {
    constructor() {
        super();
        this.state = {
            subject: {},
            subjectId: '',
            errors: []
        }
    }

    componentWillMount() {
        this.setState({subjectId: this.props.match.params.id});
        this.getSubject(this.props.match.params.id);
    }

    componentDidUpdate(prev) {
        if (prev.auth?.user?.customer?.customerApplications !== this.props?.auth?.user?.customer?.customerApplications) {
              CheckLicenseAndRedirect(
                this.props.history,
                '/app/crs/dashboard',
                this.props?.auth?.user?.customer?.customerApplications,
                APPLICATION_CRS)
                
        }
      }

    getSubject(subjectId) {
        this.props.dispatch(actions.getCrsSubject(subjectId));
    }

    render() {
        return (
            <React.Fragment>
                <div className="row title-page">
                    <Link to="/app/crs/dashboard">
                        <img src={arrow_l} className="px-4" alt="Torna a elenco soggetti"/>
                        {this.props.subject.companyName}
                    </Link>
                </div>
                <div className="table-custom">
                    <Tabs>
                        <div label="ELENCO RAPPORTI" icon={elenco}>
                            {this.props.subject.id &&
                            <RelationshipList subject={this.props.subject} {...this.props} />
                            }
                        </div>
                        <div label="STORICO COMUNICAZIONI" icon={spedire}>
                            <CommunicationsHistoryList subjectId={this.state.subjectId} />
                        </div>
                    </Tabs>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        relationships: state.relationships.data,
        subject: state.crsSubject.data
    }
}

export default connect(mapStateToProps)(CrsRelationships);
