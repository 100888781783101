import React from 'react';

import Modal from "react-bootstrap/Modal";
import RelationshipForm from "../RelationshipForm";
import * as Constants from "../../../../../config/Constants";

export function ModalCreateRelationship(props) {

    const handleClose = () => {
        props.onHide();
    };

    return (
        <React.Fragment>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                bsPrefix="modal-create modal"
                centered
                onHide={handleClose}
                dialogClassName="width-80 p-2"
                backdrop="static">
                <div className="bar"></div>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Anagrafica Rapporto
                        {props.subject.personType === Constants.PF
                            ? <h4>{props.subject.lastName + " " + props.subject.firstName}</h4>
                            : <h4>{props.subject.companyName}</h4>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RelationshipForm
                        subject={props.subject}
                        subjectId={props.subjectId}
                        relationship={props.relationship ? props.relationship : new Object()}
                        relationshipLocations={props.relationshipLocations}
                        relationshipProvinces={props.relationshipProvinces}
                        currencies={props.currencies}
                        roles={props.roles}
                        communicationTypes={props.communicationTypes}
                        getRelationships={props.getRelationships}
                        modal={true}
                        handleCloseModal={handleClose}
                        handleShowDetail={handleClose} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}



