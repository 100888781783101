
import React from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Tabs from "../../application-cora/relationships/Tabs";
import '../../../../styles/comurei-form.css';
import '../../../../styles/arcoTablesModal.css';
import { PopupDelete } from "../../../shared/PopupDelete";
import * as actions from "../../../../actions";
import ArcoTableCollaboratorsList from "./ArcoTableCollaboratorsList"
import ArcoRiskClasses from "./ArcoRiskClasses"
import ArcoTableProfessionistsList from "./ArcoTableProfessionistsList"
import ArcoTableRiskValues from "./ArcoTableRiskValues"


class ArcoTablesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arcoCollaborators: [],
            checkedCollaborators: [],
            arcoProfessionists: [],
            checkedProfessionists: [],
            arcoRiskValues: [],
            checkedRiskValues: [],
            arcoTableDomains:[]
        }
    }


    componentWillMount() {
        if (this.props.subject.id) {
            this.getAllCollaboratorBySubjectId();
            this.getAllProfessionistBySubjectId();
            this.getAllArcoDomainsBySubjectId();
            this.dropBoxTable();
        }
    }

    getAllCollaboratorBySubjectId = () => {
        this.props.dispatch(actions.getAllCollaboratorBySubjectId(this.props.subject.id));
    };

    getAllProfessionistBySubjectId = () => {
        this.props.dispatch(actions.getAllProfessionistBySubjectId(this.props.subject.id));
    };

    getAllArcoDomainsBySubjectId = () => {
        this.props.dispatch(actions.getAllArcoDomains(this.props.subject.id));
    };

    dropBoxTable = () => {
        this.props.dispatch(actions.getArcoTableDomains());
    };

    checkCollaborator = (collaborator) => {
        let newList = this.state.checkedCollaborators;
        newList.push(collaborator);
        this.setState({ checkedCollaborators: newList })
    };

    uncheckCollaborator = (collaborator) => {
        let newList = [];
        this.state.checkedCollaborators.map(current => {
            if (current.id !== collaborator.id)
                newList.push(current);
        });
        this.setState({ checkedCollaborators: newList })
    };

    checkProfessionist = (professionist) => {
        let newList = this.state.checkedProfessionists;
        newList.push(professionist);
        this.setState({ checkedProfessionists: newList })
    };

    uncheckProfessionist = (professionist) => {
        let newList = [];
        this.state.checkedProfessionists.map(current => {
            if (current.id !== professionist.id)
                newList.push(current);
        });
        this.setState({ checkedProfessionists: newList })
    };

    checkRiskValue = (riskValue) => {
        let newList = this.state.checkedRiskValues;
        newList.push(riskValue);
        this.setState({ checkedRiskValues: newList })
    };

    uncheckRiskValue = (riskValue) => {
        let newList = [];
        this.state.checkedRiskValues.map(current => {
            if (current.id !== riskValue.id)
                newList.push(current);
        });
        this.setState({ checkedRiskValues: newList })
    };

    openPopupDelete = () => {
        PopupDelete({
            operazione: "Soggetti Selezionati",
            handleClickConfirm: this.deleteCrsSubject
        });
    };

    checkAllCollaborators = () => {
        let allCollaborators = this.props.arcoCollaborators.data;
        this.setState({
            checkedCollaborators: allCollaborators
        })
    };

    uncheckAllCollaborators = () => {
        this.setState({
            checkedCollaborators: []
        })
    };
    checkAllProfessionists = () => {
        let allProfessionists = this.props.arcoProfessionists.data;
        this.setState({
            checkedProfessionists: allProfessionists
        })
    };

    uncheckAllProfessionists = () => {
        this.setState({
            checkedProfessionists: []
        })
    };

    checkAllRiskValues = () => {
        let allRiskValues = this.props.arcoRiskValues.data;
        this.setState({
            checkedRiskValues: allRiskValues
        })
    };

    uncheckAllRiskValues = () => {
        this.setState({
            checkedRiskValues: []
        })
    };

    pbPresent = () => {
      
         let filtered = this.props.subjectTypes.filter(subjectTypes => {
             if(subjectTypes.code === "PB"){
                return subjectTypes.code === this.props.subject.arcoSubjectType.code;
             }
             
        })
        return filtered.length > 0;
      };

    render() {
        return (

            <React.Fragment>
                <Modal
                    backdrop="static"
                    show={this.props.showTableModal}
                    onHide={this.props.closeTableModal}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className={"arco-modal-max overflow-hidden"}
                    >
                    <div className="bar"></div>
                    <Modal.Header closeButton className='pb-0'>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>{this.props.subject.companyName} - GESTIONE TABELLE</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pt-0'>
                        <div className="row-detail comurei bg-blue  pe-3 pb-4 ps-3 d-flex">
                            <div className="tab-content col-md-12 col-lg-12">
                                <Tabs>
                                    <div id="tab-sub" label="Valori di rischio">
                                    <ArcoTableRiskValues
                                            {...this.props}
                                            arcoTableDomains={this.props.arcoTableDomains.data}
                                            riskValues={this.props.arcoDomains.domains}
                                            checkAllRiskValues={this.checkAllRiskValues}
                                            uncheckAllRiskValues={this.uncheckAllRiskValues}
                                            checkRiskValue={this.checkRiskValue}
                                            uncheckRiskValue={this.uncheckRiskValue}
                                            getAllRiskValueBySubjectId={this.getAllArcoDomainsBySubjectId}
                                            subject={this.props.subject}
                                            onConfirm={undefined}
                                            onClose ={this.props.closeTableModal}
                                            disableInsert={this.props.disableInsert}
                                        />
                                    </div>

                                    <div id="tab-sub" label="Classi di rischio">
                                        <ArcoRiskClasses {...this.props}
                                            crDomains={this.props.arcoDomains.crDomains}></ArcoRiskClasses>
                                    </div>

                                    <div id="tab-sub" label="Collaboratori">
                                        <ArcoTableCollaboratorsList
                                            {...this.props}
                                            collaborators={this.props.arcoCollaborators.data}
                                            checkAllCollaborators={this.checkAllCollaborators}
                                            uncheckAllCollaborators={this.uncheckAllCollaborators}
                                            checkCollaborator={this.checkCollaborator}
                                            uncheckCollaborator={this.uncheckCollaborator}
                                            getAllCollaboratorBySubjectId={this.getAllCollaboratorBySubjectId}
                                            subject={this.props.subject}
                                            onClose ={this.props.closeTableModal}
                                            disableInsert={this.props.disableInsert}
                                        />

                                    </div>

                                    {this.pbPresent()  ? 
                                    <div id="tab-resp" label="Professionisti" >
                                        <ArcoTableProfessionistsList
                                            professionists={this.props.arcoProfessionists.data}
                                            checkAllProfessionists={this.checkAllProfessionist}
                                            uncheckAllProfessionists={this.uncheckAllProfessionist}
                                            checkProfessionist={this.checkProfessionist}
                                            uncheckProfessionist={this.uncheckProfessionist}
                                            getAllProfessionistBySubjectId={this.getAllProfessionistBySubjectId}
                                            subject={this.props.subject}
                                            showDisabled = {true}
                                            onClose ={this.props.closeTableModal}
                                            disableInsert={this.props.disableInsert}
                                        />


                                    </div>
                                    : <div label="Professionisti" disabled='disabled'></div>}
                                </Tabs>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment >
        )
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        arcoCollaborators: state.arcoCollaborators,
        arcoProfessionists: state.arcoProfessionists,
        arcoRiskValues: state.arcoRiskValues,
        arcoDomains: state.arcoDomains,
        arcoTableDomains: state.arcoTableDomains
    }
}
export default connect(mapStateToProps)(ArcoTablesModal);