import { isEmpty } from "./Utility";
/**
 * Questa funzione è usata da crs e cora per caricare i file delle ricevute e successivamente analizzarle per capire se sono corrette
 * @param {*} val file caricati
 * @param {*} setState funziona che deve dare la possibilita di cambiare lo state del padre
 * @param {*} checkFileName funzione che controlla i nomi dei file, cambia da cora e crs
 * @deprecated usato solo su CRS
 */
export function handleUploadReceiptCrsCora (val,setState,checkFileName) {
    if (isEmpty(val)) {
      setState({
        errorReceipt: { isValid: false, msg: "La ricevuta è obbligatoria" },
      });
    } else {
      let files = val.target.files ;
      const list_element = [];
      for (let x = 0; x<files.length;x++){
        list_element.push(files[x])
      }
      setState({ receiptFile: list_element });
      if (files.length > 50) {
        setState({
          errorReceipt: {
            isValid: false,
            msg: "Si possono caricare massimo 50 file alla volta",
          },
        });
      } else if (!checkFileName(val.target.files)) {
        setState({
          errorReceipt: {
            isValid: false,
            msg: "Estensione o nome file errati",
          },
        });
      } else if (!checkDimensionFile(val.target.files)) {
        setState({
          errorReceipt: {
            isValid: false,
            msg: "Consentito caricamento di file MAX 3MB ",
          },
        });
      } else {
        setState({ errorReceipt: { isValid: true, msg: "" } });
      }
    }
  };
/**
 * controlla dimensione del file se supera un tot da error
 * @param {*} files tutti i file passati dall'utente
 * @returns 
 */
  const checkDimensionFile = (files) => {
    for (let x = 0; x < files.length; x++) {
      let file = files[x];
      let size = file.size / 1024 / 1024;
      if (size > 3) {
        return false;
      }
    }
    return true;
  };

  export const checkReceiptSize = (file)=>{
    let size = file.size / 1024 / 1024;
    return size < 5;
  }