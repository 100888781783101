import { baseSearchObject } from "./TableUtils";

function storageAvailable() {
    let storage;
    try {
        storage = window["localStorage"];
        const x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === "QuotaExceededError" ||
                // Firefox
                e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
}


export function getTableStorage(tableName) {
    if (storageAvailable()) {
        return TableStorage(tableName);
    }
    return null;

}

const TableStorage = (tableName) => {
    let store = localStorage.getItem(tableName) ? JSON.parse(localStorage.getItem(tableName)) : {};

    const setItem = () => {
        localStorage.setItem(tableName, JSON.stringify(store));
    }

    const cleanStore = ()=>{
        localStorage.setItem(tableName, "");
        store ={};
    }

    //controlla che tutte le chiavi del dato searchObject siano presenti tra le chiavi del searchObject conservato, e che le versioni combacino.
    //in caso negativo, il localStorage viene resettato per evitare conflitti
    const verifySearchObject = (searchObject) => {
        let isValid = true;

        const inboundSearchObjectKeys = Object.keys(searchObject);
        const storedSearchObjectKeys = store.searchObject ? Object.keys(store.searchObject) : [];
        if(storedSearchObjectKeys.length > 0){
            if (inboundSearchObjectKeys.length === storedSearchObjectKeys.length) {
                inboundSearchObjectKeys.forEach(key => {
                    if (isValid) {
                        isValid = storedSearchObjectKeys.includes(key);
                        if(!isValid){
                            console.log(`Valore non trovato: ${key}, resetto la cache...`);
                            cleanStore();
                        } else if("version" === key && store.searchObject?.version !== searchObject.version){
                            isValid = false;
                            console.log(`Versione in localstorage obsoleta: ${store.searchObject.version}, nuova versione: ${searchObject.version} resetto la cache...`);
                            cleanStore();
                        }
                    }
                });
            } else {
                isValid = false;
                console.log(`Rilevata discordanza, resetto la cache...`);
                cleanStore();
            }
        } else {
            isValid = false;
        }
        return isValid;


    }

    return {

        setHiddenColumns: (columns = []) => {
            store.hiddenColumns = columns;
            setItem();
        },
        getHiddenColumns: () => {
            return store.hiddenColumns || [];
        },

        setColumnOrder: (columnOrderMap = []) => {
            store.columnOrder = columnOrderMap;
            setItem();
        },
        getColumnOrder: () => {
            return store.columnOrder;
        },


        setSearchObject: (searchObject = baseSearchObject) => {
            store.searchObject = searchObject;
            setItem();
        },
        getSearchObject: () => {
            return store.searchObject
        },

        setSearchObjectIfNotPresent: (searchObject) => {
            if (!verifySearchObject(searchObject)) {
                store.searchObject = searchObject;
                setItem();
            } 
        },

        cleanSearchObject: ()=>{
            cleanStore();
        },


        setColumnSizes: (columnSizes = "") => {
            store.columnSizes = columnSizes;
            setItem();
        },
        getColumnSizes: () => {return store.columnSizes } 
    }



}