import moment from "moment";
import React from 'react';
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../../actions";
import * as Constants from "../../../../config/Constants";
import list from "../../../../styles/images/elenco.png";
import {ReactComponent as IZipFile} from '../../../../styles/images/svg/file-archive.svg';
import { BtnEmpty } from "../../../shared/BtnEmpty";

class CommunicationsHistoryModal extends React.Component {

    constructor() {
        super();
        this.state = {
            showModal: false
        };
    }

    getHistory = (subjectId) => {
        this.props.dispatch(actions.getCorproCommunicationsHistoryBySubjectId(subjectId));
    };

    showModal = () => {
        const { subjectId } = this.props;
        this.getHistory(subjectId);
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    renderTableHeader() {
        return (
            <React.Fragment>
                <div className="text-start header-table">
                    <div className='col w-20 div-td'>Data creazione</div>
                    <div className='col w-20 div-td'>Soggetto</div>
                    <div className='col w-40 div-td'>Tipo Comunicazione</div>
                    <div className='col w-40 div-td'>Anno</div>
                    <div className='col w-40 div-td'>Numero Totale di Iscritti</div>
                    <div className='col w-10 div-td'>Download</div>
                </div>
            </React.Fragment>
        )
    }

    renderRows(communications) {
        return communications.map((communication, index) => {
            let section = "";
            if (communication.registryReason) {
                section = section + "Indagini Bancarie/";
            }

            if (communication.monitoringReason) {
                section = section + "Monitoraggio Fiscale/";
            }

            if (communication.factaReason) {
                section = section + "FACTA/CRS";
            }

            if (section.endsWith("/")) {
                section = section.substring(0, section.length - 1);
            }

            return (
                <React.Fragment>
                    <div className={"text-start row-table corpro"}>
                        <div className='col w-20 div-td text-truncate'>{moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)}</div>
                        <div className='col w-20 div-td text-truncate'>{communication.corproSubject.subjectName}</div>
                        <div className='col w-40 div-td text-truncate'>{communication.corproCommunicationType.code} - {communication.corproCommunicationType.description}</div>
                        <div className='col w-40 div-td text-truncate'>{communication.referenceYear}</div>
                        <div className='col w-40 div-td text-truncate'>{communication.subscribersNumber}</div>
                        <div className='col w-10 div-td text-truncate text-center containerPopOver'>
                            <div className="zip">
                                <IZipFile width="20" fill="#128186" onClick={() => this.props.downloadTxt(communication)} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        });
    }

    render() {
        const { communicationsHistory } = this.props;
        return (
            <React.Fragment>
                <button onClick={() => this.showModal()}><img src={list} alt={"Mostra dettaglio"}/></button>
                <Modal
                    backdrop="static"
                    show={this.state.showModal}
                    onHide={this.closeModal}
                    dialogClassName="modal-60w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>STORICO - <strong>{this.props.subjectName}</strong></h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.renderTableHeader()}
                        {this.renderRows(communicationsHistory)}
                    </Modal.Body>
                    <Modal.Footer>
                        <BtnEmpty text="ANNULLA" handlerOnClick={this.closeModal} />
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        communicationsHistory: state.corproCommunications.data
    }
}

export default connect(mapStateToProps)(CommunicationsHistoryModal);


