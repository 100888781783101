import React from 'react';
import RelationshipForm from "./RelationshipForm";

export class RelationshipRowDetail extends React.Component {

    render() {
        return (
            <div className="row-detail bg-blue">
                <div className="col-md-12 col-lg-12 text-start p-0">
                    <RelationshipForm
                        subject={this.props.subject}
                        relationship={this.props.relationship}
                        index={this.props.index}
                        getRelationships={this.props.getRelationships}
                        handlerShowDetail={this.props.handlerShowDetail}
                        modal={false}/>
                </div>
            </div>
        )
    }
}

