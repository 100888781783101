import React from 'react';
import { useSelector} from 'react-redux';
import getText from './labels';
export default function BlueHelpModal(props) {
    const lang = useSelector(state => state.blueLanguage.language)
    const labels = getText(lang);

    return (
        <React.Fragment>
            <div className="container">
                {
                    labels.HELP.map(h => (
                        <div key={`HELP_${h.name}`}>
                          <strong>{h.name}</strong><br/>
                          <ul>
                            {h.text.map((t, i) => <li key={`HELP_${h.name}_${i}`}>{t}</li>)}
                          </ul><br/>
                        </div>
                      ))
                }
                
            </div>
        </React.Fragment>
    )
}