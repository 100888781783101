import { PopupAlert } from "../../../shared/PopupAlert";

export function PopupAlertCommunicationYear(referenceYear, lastYear,onClickCancel=()=>{}, handleClickConfirm=()=>{}){

    PopupAlert({
        cancel: "LASCIA " + referenceYear,
        confirm: "IMPOSTA " + lastYear,
        innerHtml: `L'anno di riferimento impostato è </br><span class='text-danger fw-bold' style='font-size:2rem'>${referenceYear}</span></br> 
        La comunicazione in scadenza il 30 giugno è per l'anno ${lastYear}</br>
        Si desidera impostare l'anno ${lastYear} per la comunicazione?`,
        onClickCancel: onClickCancel,
        handleClickConfirm: handleClickConfirm
    })
}