import React from 'react';
import RelationshipForm from "./RelationshipForm";
import { cleanErrorString } from './Relationships';

export class RelationshipRowDetail extends React.Component {

    render() {
        return (
            <div className="row-detail bg-blue">

                <div className="col-md-12 col-lg-12 text-start p-0">
                    <div className="row align-items-center">
                        <div className='col-10 text-danger text-break mt-4 ms-4'>
                            <h6><strong>{cleanErrorString(this.props.relationship?.lastCommunicationError)}</strong></h6>
                        </div>
                        <div className="col-1 pe-4 ms-auto">
                            <button onClick={() => this.props.handlerShowDetail()} type="button" className="close"><span aria-hidden="true">×</span><span
                                className="visually-hidden">Close</span></button>
                        </div>
                    </div>
                    <RelationshipForm
                        onClone={this.props.onClone}
                        subject={this.props.subject}
                        subjectId={this.props.subjectId}
                        relationship={this.props.relationship}
                        relationshipTypes={this.props.relationshipTypes}
                        relationshipLocations={this.props.relationshipLocations}
                        relationshipProvinces={this.props.relationshipProvinces}
                        currencies={this.props.currencies}
                        roles={this.props.roles}
                        communicationTypes={this.props.communicationTypes}
                        index={this.props.index}
                        getRelationships={this.props.getRelationships}
                        handlerShowDetail={this.props.handlerShowDetail}
                        modal={false}/>
                </div>
            </div>
        )
    }
}

