import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BtnFill } from "../../shared/BtnFill";
import { getFacciamoNoiSubjectTypes, inviaAccreditamentoSid, ottieniRiepilogoAccreditamento } from "../../../actions";
import { PageSpinner } from "../../shared/spinner/PageSpinner";
import { PopupSuccess } from "../../shared/PopupSuccess";
import { PopupError } from "../../shared/PopupError";
import { FloatLabelTextBox } from "../../shared/form/input-boxes/FloatLabelTextBox";
import { base64ToArrayBuffer, downloadFile } from "../../shared/Utility";
import { ConfirmModal } from "./ConfirmModal";
import { Tips } from "./Tips";
import { PasswordSection } from "./PasswordSection";
import { SubjectCredentialsSection } from "./SubjectCredentialsSection";
import { AdministratorSection } from "./AdministratorSection";
import { BwmCheckbox } from "../../shared/form/BwmCheckbox";
import { BwmSelect } from "../../shared/form/BwmSelect";

export function FormAccreditamento(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [nomeCliente, setNomeCliente] = useState("");

    const [comunicanteRagioneSociale, setComunicanteRagioneSociale] = useState("");
    const [comunicanteCodiceFiscale, setComunicanteCodiceFiscale] = useState("");

    const [rappresentanteNomeCognome, setRappresentanteNomeCognome] = useState("");
    const [rappresentanteCodiceFiscale, setRappresentanteCodiceFiscale] = useState("");
    const [rappresentanteDataNascita, setRappresentanteDataNascita] = useState(undefined);
    const [rappresentanteLuogoNascita, setRappresentanteLuogoNascita] = useState("");
    const [rappresentanteNumeroTelefono, setRappresentanteNumeroTelefono] = useState("");
    const [rappresentanteEmail, setRappresentanteEmail] = useState("");

    const [tipoSoggetto, setTipoSoggetto] = useState("");
    const [pecAddress, setPecAddress] = useState("");
    const [pecProvider, setPecProvider] = useState("");
    const [pecDataDecorrenza, setPecDataDecorrenza] = useState(undefined);
    const [motivoAnagrafeRapporti, setMotivoAnagrafeRapporti] = useState(false);
    const [motivoMonitoraggioFiscale, setMotivoMonitoraggioFiscale] = useState(false);
    const [motivoFactaCrs, setMotivoFactaCrs] = useState(false);

    const [pinFiscoOnline, setPinFiscoOnline] = useState("");
    const [pinRevoca, setPinRevoca] = useState("");
    const [progressivoSede, setProgressivoSede] = useState("");
    const [numeroRichiestaRegistrazione, setNumeroRichiestaRegistrazione] = useState("")

    const [amministratoreNomeCognome, setAmministratoreNomeCognome] = useState("");
    const [amministratoreCodiceFiscale, setAmministratoreCodiceFiscale] = useState("");
    const [amministratorePassword, setAmministratorePassword] = useState("");
    const [amministratorePin, setAmministratorePin] = useState("");
    const [amministratoreProgressivoSede, setAmministratoreProgressivoSede] = useState("")


    const [password, setPassword] = useState("");
    const [passwordRevoca, setPasswordRevoca] = useState("");

    const [showSendModal, setShowSendModal] = useState(false);
    const [dto, setDto] = useState();

    const [subjectTypes, setSubjectTypes] = useState([]);

    const sectionTitleStyle = "fw-bold mt-5";


    useEffect(() => {
        getFacciamoNoiSubjectTypes()
            .then((res) => setSubjectTypes(res)
        , (err) => console.log(JSON.stringify(err)))
           
    }, [])

    const submitForm = () => {
        const accreditamentoSidDto = {
            nomeCliente: nomeCliente,
            comunicanteRagioneSociale: comunicanteRagioneSociale,
            comunicanteCodiceFiscale: comunicanteCodiceFiscale,
            rappresentanteNomeCognome: rappresentanteNomeCognome,
            rappresentanteCodiceFiscale: rappresentanteCodiceFiscale,
            rappresentanteDataNascita: new Date(rappresentanteDataNascita),
            rappresentanteLuogoNascita: rappresentanteLuogoNascita,
            rappresentanteNumeroTelefono: rappresentanteNumeroTelefono,
            rappresentanteEmail: rappresentanteEmail,
            tipoSoggetto: tipoSoggetto,
            pecAddress: pecAddress,
            pecProvider,
            pecDataDecorrenza: new Date(pecDataDecorrenza),
            motivoAnagrafeRapporti,
            motivoMonitoraggioFiscale,
            motivoFactaCrs,
            pinFiscoOnline: pinFiscoOnline,
            pinRevoca: pinRevoca,
            progressivoSede: progressivoSede,
            numeroRichiestaRegistrazione: numeroRichiestaRegistrazione,
            amministratoreNomeCognome: amministratoreNomeCognome,
            amministratoreCodiceFiscale: amministratoreCodiceFiscale,
            amministratorePassword: amministratorePassword,
            amministratorePin: amministratorePin,
            amministratoreProgressivoSede: amministratoreProgressivoSede,
            password: password,
            passwordRevoca: passwordRevoca
        };
        setDto(accreditamentoSidDto);
        setShowSendModal(true)

    }

    const riepilogo = () => {
        setIsLoading(true);
        ottieniRiepilogoAccreditamento(dto).then((res) => {
            if (res) {
                downloadFile(base64ToArrayBuffer(res), `FNSID_${comunicanteRagioneSociale}.pdf`)
                PopupSuccess({ text: "File scaricato" })
            }

        },
            (err) => {
                PopupError({ text: "Si è verificato un errore durante l'invio dei dati" })
            })
            .then(() => setIsLoading(false));
    }

    const invia = () => {
        setIsLoading(true);
        inviaAccreditamentoSid(dto).then((res) => {
            PopupSuccess({ text: "Dati inviati correttamente" })
        },
            (err) => {
                PopupError({ text: "Si è verificato un errore durante l'invio dei dati" })
            })
            .then(() => setIsLoading(false));
    }


    const renderConfirmModal = () => {
        return (
            <ConfirmModal
                showSendModal={showSendModal}
                setShowSendModal={setShowSendModal}
                riepilogo={riepilogo}
                invia={invia}
            />
        )
    }

    return (<>
        {isLoading && <PageSpinner />}
        <div className="text-center">
            <h1>Servizio FacciamoNoi</h1>
            <h1>ACCREDITAMENTO AL SID</h1>
        </div>
        <div className="w-75 m-auto bg-blue px-2">
            <div>
                Il servizio comprende:
                <ol>
                    <li>
                        Richiesta di accreditamento della holding al SID
                    </li>
                    <li>
                        Trasmissione della PEC al REI;
                    </li>
                    <li>
                        Generazione dell'ambiente di sicurezza.
                    </li>
                </ol>
                Compilare i dati richiesti:
            </div>
            <section className="ms-3">
                <Row>
                    <Col lg={8}>
                        <FloatLabelTextBox
                            value={nomeCliente}
                            label={"Nome Cliente"}
                            onChange={(e) => setNomeCliente(e.target.value)}
                        />
                    </Col>
                </Row>
                <div className={sectionTitleStyle}>DATI RIFERITI AL SOGGETTO CHE COMUNICA (Holding o altro soggetto) da abilitare al SID</div>
                <Row>
                    <Col lg={6}>
                        <FloatLabelTextBox
                            value={comunicanteRagioneSociale}
                            label={"Ragione Sociale"}
                            onChange={(e) => setComunicanteRagioneSociale(e.target.value)}
                        />

                    </Col>
                    <Col lg={3}>
                        <FloatLabelTextBox
                            value={comunicanteCodiceFiscale}
                            label={"Codice Fiscale"}
                            onChange={(e) => setComunicanteCodiceFiscale(e.target.value)}
                        />
                    </Col>

                </Row>
                <div className={sectionTitleStyle}>DATI ANAGRAFICI DEL RAPPRESENTANTE LEGALE come comunicato in Agenzia delle Entrate</div>
                <Row>
                    <Col lg={6}>
                        <FloatLabelTextBox
                            value={rappresentanteNomeCognome}
                            label={"Cognome Nome"}
                            onChange={(e) => setRappresentanteNomeCognome(e.target.value)}
                        />
                    </Col>
                    <Col lg={3}>
                        <FloatLabelTextBox
                            value={rappresentanteCodiceFiscale}
                            label={"Codice Fiscale"}
                            onChange={(e) => setRappresentanteCodiceFiscale(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <FloatLabelTextBox
                            value={rappresentanteLuogoNascita}
                            label={"Luogo di nascita"}
                            onChange={(e) => setRappresentanteLuogoNascita(e.target.value)}
                        />

                    </Col>
                    <Col lg={3}>
                        <FloatLabelTextBox
                            value={rappresentanteDataNascita}
                            label={"Data di Nascita"}
                            type={"date"}
                            onChange={(e) => setRappresentanteDataNascita(e.target.value)}
                        />
                    </Col>

                </Row>
                <Row>
                    <Col lg={6}>
                        <FloatLabelTextBox
                            value={rappresentanteEmail}
                            label={"Email ordinaria (NON pec)"}
                            type={"email"}
                            onChange={(e) => setRappresentanteEmail(e.target.value)}
                        />
                    </Col>
                    <Col lg={3}>
                        <FloatLabelTextBox
                            value={rappresentanteNumeroTelefono}
                            label={"Numero di telefono"}
                            onChange={(e) => setRappresentanteNumeroTelefono(e.target.value)}
                        />
                    </Col>

                </Row>
                <div className={sectionTitleStyle}>
                    COMUNICAZIONE AL REI (Registro Elettronico Indirizzi)
                </div>
                <Row>
                    <Col lg={9} >
                        <BwmSelect
                            options={subjectTypes}
                            name='subjectType'
                            label='Tipo Soggetto'
                            className='form-control '
                            onChange={(e) => setTipoSoggetto(e.target.value)}
                            value={tipoSoggetto}
                            showCode={true}
                        />

                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <FloatLabelTextBox
                            value={pecAddress}
                            label={"Indirizzo PEC del soggetto"}
                            onChange={(e) => setPecAddress(e.target.value)}
                        />
                    </Col>
                    <Col lg={3}>
                        <FloatLabelTextBox
                            value={pecDataDecorrenza}
                            label={"Data decorrenza"}
                            type={"date"}
                            onChange={(e) => setPecDataDecorrenza(e.target.value)}
                        />
                    </Col>

                </Row>
                <Row>
                    <Col lg={3}>
                        <FloatLabelTextBox
                            value={pecProvider}
                            label={"Gestore Pec"}
                            onChange={(e) => setPecProvider(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>
                        <BwmCheckbox
                            label={"Anagrafe Rapporti"}
                            value={motivoAnagrafeRapporti}
                            name={"Anagrafe-Rapporti"}
                            onClick={(e) => {
                                setMotivoAnagrafeRapporti(e.target.checked)
                            }}
                        />
                    </Col>
                    <Col lg={3}>
                        <BwmCheckbox
                            label={"Monitoraggio fiscale"}
                            value={motivoMonitoraggioFiscale}
                            name={"Monitoraggio-fiscale"}
                            onClick={(e) => {
                                setMotivoMonitoraggioFiscale(e.target.checked)
                            }}
                        />
                    </Col>
                    <Col lg={3}>
                        <BwmCheckbox
                            label={"FATCA/CRS"}
                            value={motivoFactaCrs}
                            name={"FATCA-CRS"}
                            onClick={(e) => {
                                setMotivoFactaCrs(e.target.checked)
                            }}
                        />
                    </Col>
                </Row>
                <SubjectCredentialsSection
                    sectionTitleStyle={sectionTitleStyle}
                    pinFiscoOnline={pinFiscoOnline}
                    pinRevoca={pinRevoca}
                    progressivoSede={progressivoSede}
                    numeroRichiestaRegistrazione={numeroRichiestaRegistrazione}
                    setPinFiscoOnline={setPinFiscoOnline}
                    setPinRevoca={setPinRevoca}
                    setProgressivoSede={setProgressivoSede}
                    setNumeroRichiestaRegistrazione={setNumeroRichiestaRegistrazione}
                />
                <AdministratorSection
                    sectionTitleStyle={sectionTitleStyle}
                    amministratoreNomeCognome={amministratoreNomeCognome}
                    setAmministratoreNomeCognome={setAmministratoreNomeCognome}
                    amministratoreCodiceFiscale={amministratoreCodiceFiscale}
                    setAmministratoreCodiceFiscale={setAmministratoreCodiceFiscale}
                    amministratorePassword={amministratorePassword}
                    setAmministratorePassword={setAmministratorePassword}
                    amministratorePin={amministratorePin}
                    setAmministratorePin={setAmministratorePin}
                    amministratoreProgressivoSede={amministratoreProgressivoSede}
                    setAmministratoreProgressivoSede={setAmministratoreProgressivoSede}
                />

                <PasswordSection
                    sectionTitleStyle={sectionTitleStyle}
                    password={password}
                    passwordRevoca={passwordRevoca}
                    setPassword={setPassword}
                    setPasswordRevoca={setPasswordRevoca}
                />

            </section>
            <Row className="justify-content-end mt-3 pb-3 pe-3">
                <BtnFill classCustom="col text-end" handlerOnClick={submitForm} text={"CONFERMA"}></BtnFill>
            </Row>
            <Tips />
        </div>
        {renderConfirmModal()}
    </>
    )
}