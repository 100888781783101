import { CART_LINK, getEcommerceSubjectProductId } from '../../config/Constants';
import { PopupError } from './PopupError';
import { isNotEmpty } from './Utility';

export function PopupSubjectLicense(props) {
    const { total, used, name } = props;
    return PopupError({
        text: `Il numero di soggetti nella lista (${used})
                </br>deve essere uguale o inferiore
                </br>al numero di soggetti acquistati (${total})
                </br></br>Potete:
                </br>-Acquistare nuovi soggetti 
                </br>-Eliminare i soggetti in eccesso 
 
                </br><button class="btn btn-danger btn-sm mt-5" 
                onclick="location.href='${CART_LINK(0, getEcommerceSubjectProductId(name), 1)}}'" type="button">Acquista soggetti aggiuntivi</button>`
    })
}