import React from "react";
import SidForm from './SidForm';
import ContactForm from './ContactForm';
function SidContactFormContainer(self) {
  return (
    <React.Fragment
      key={`certificati-${self.props.subject?.id || 0}`}
    >
      <SidForm {...self} />
      <ContactForm {...self} />
    </React.Fragment>
  );
}

export default SidContactFormContainer;
