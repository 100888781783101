import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateField } from "../../shared/form/DateField";
import { BwmInput } from "../../shared/form/BwmInput";
import { formatTimeFromDate, joinDateAndTime } from "../../shared/Utility";
import { IPhoneAlt } from "../../../styles/icons";
import { CallIcon } from "./ContactInfoModal";

export function ContactHistoryForm({ historyObject, onChange }) {

    return (
        <React.Fragment>
            <Row>
                <Col lg={1} className="d-flex align-items-center justify-content-between me-4">
                    <DateField
                        date={historyObject.date}
                        onChange={date => { onChange({ ...historyObject, date: date }) }}
                    />
                    <BwmInput
                        className='form-control'
                        type={"time"}
                        value={formatTimeFromDate(historyObject.date)}
                        placeholder={"Inserisci contatto"}
                        onChange={e => { onChange({ ...historyObject, date: joinDateAndTime(historyObject.date, e.target.value) }) }}
                    />
                </Col>
    
                <Col lg={2}>
                    <BwmInput
                        className='form-control'
                        value={historyObject.contact}
                        placeholder={"Inserisci contatto"}
                        onChange={e => { onChange({ ...historyObject, contact: e.target.value }) }}
                    />
                </Col>
                <Col lg={1}>
                    <BwmInput
                        className='form-control'
                        value={historyObject.phone}
                        placeholder={"Inserisci Numero"}
                        onChange={e => { onChange({ ...historyObject, phone: e.target.value }) }}
                        Icon={()=><CallIcon phone={historyObject.phone}/>}
                        
                    />
                </Col>
                <Col lg={1}>
                    <BwmInput
                        className='form-control'
                        value={historyObject.caller}
                        placeholder={"Inserisci chiamante"}
                        onChange={e => { onChange({ ...historyObject, caller: e.target.value }) }}
                    />
                </Col>
                <Col>
                    <BwmInput
                        autofocus={true}
                        className='form-control'
                        placeholder={"Inserisci note"}
                        value={historyObject.notes}
                        onChange={e => onChange({ ...historyObject, notes: e.target.value })}

                    />
                </Col>

            </Row>
        </React.Fragment>
    )
}