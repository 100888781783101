import React from 'react';
import deleteIcon from "../../styles/images/cancella.png";
import { IDelete } from '../../styles/icons';

export class BtnDelete extends  React.Component {
    constructor(props) {
        super(props);
    }

    render(){
       const {handlerOnClick, isDisabled} = this.props;
       return (
               <button type="button" className="btn btn-empty px-4  btn-sm "  onClick={handlerOnClick} disabled={isDisabled}>
                   <IDelete  className={"padding-bottom-4px"} width="12" fill={` ${(isDisabled) ? '#FFFFFF' : '#128186'}`} alt={"Elimina"}/> Elimina
               </button>
       )
   }
}



