import React from 'react';
import * as actions from "../../../../actions"
import Modal from "react-bootstrap/Modal";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { PopupError } from "../../../shared/PopupError";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import * as Constants from "../../../../config/Constants"
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { UploadFileLabel } from '../../../shared/form/UploadFileLabel';
import { isEmpty } from '../../../shared/Utility';
import { CheckRemainingSubjects } from '../../../shared/auth/license/LicenseChecker';
import { ICheck } from '../../../../styles/icons';
import { Row } from 'react-bootstrap';
import { BwmCheckbox } from '../../../shared/form/BwmCheckbox';


export class ImportDataModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            dataFile: [],
            errorDataFile: { isValid: true, msg: "" },
            loading: false,
            showResultModal: false,
            importType: "RELATIONSHIP",
            updateExisting: false
        }
    }

    closeModal = () => {
        this.props.onClose()
    };

    closeResultModal = () => {
        this.setState({ showResultModal: false });
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    setDataFile = () => {
    };

    checkFileName = (name) => {
        if (name.toLowerCase().includes("note")) {
            this.setState({ importType: "NOTES" });
        }
        const ext = name.split('.').pop().toLowerCase();
        return (ext === 'txt' || ext === "dat")
    };

    handleUploadFile = (val) => {
        if (isEmpty(val)) {
            this.setState({ errorDataFile: { isValid: false, msg: "Il file è obbligatorio" } });
        }
        let files = [];
        for (let i = 0; i < val.target.files.length; i++) {
            if (!this.checkFileName(val.target.files[i].name)) {
                this.setState({ errorDataFile: { isValid: false, msg: "Estensione o nome file errati: " + val.target.files[i].name } });
            } else {
                files.push(val.target.files[i]);
            }
        }
        if (files.length > 0)
            this.setState({ dataFile: files });

    };

    handleErrorMessages = (error) => {

        if (error) {

            switch (error.errorKey) {
                case Constants.MAX_SUBJECTS_EXCEEDED:
                    CheckRemainingSubjects(Constants.APPLICATION_CORA, true);
                    break;
                case Constants.SUBJECT_NOT_FOUND:
                    PopupError({ text: `${error.title}` });
                    break;
                case Constants.DUPLICATE_ENTRY:
                    PopupError({ text: `${error.title}` });
                    break;

                default:
                    PopupError({ text: `Errore durante l'importazione dei dati di CORA. <br>Controllare il file e riprovare.` });
                    break;
            }
        }
    }

    handleSendDataFile = () => {
        let formData = new FormData();
        if (this.state.dataFile?.length > 0) {
            for (let i = 0; i < this.state.dataFile.length; i++) {
                formData.append("dataFile", this.state.dataFile[i]);
            }
            formData.append("updateExisting", this.state.updateExisting);
            this.props.subjectId && formData.append("subjectId", this.props.subjectId)
        } else {
            return;
        }
        this.setState({ loading: true });
        actions.importCoraDataFile(formData).then(
            (res) => {
                let results = [];
                if (res) {
                    for (let i = 0; i < res.length; i++) {
                        if (res[i].type === "RELATIONSHIP")
                            results.push(" - Importati " + res[i].totalRelationships + " RAPPORTI per il soggetto " + res[i].subjectName);
                        if (res[i].type === "NOTE")
                            results.push(" - Importate " + res[i].totalRelationships + " NOTE per il soggetto " + res[i].subjectName);
                    }
                    PopupSuccess({
                        dataList: results
                    });
                    this.props.onSuccess();
                } else {
                    this.setState({ loading: false, dataFile: [] });
                    PopupError({ text: "Errore durante l'importazione dei dati di CORA. <br>Controllare il file e riprovare." })
                }

            }, (errors) => {
                this.handleErrorMessages(errors);
            }).then(() => {
                this.setState({ loading: false });
            });
    };



    render() {

        return (
            <React.Fragment>
                {this.state.loading && <PageSpinner />}

                <Modal
                    backdrop="static"
                    show={this.props.show}
                    onHide={this.props.onClose}
                    dialogClassName="modal-40w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {
                                this.props.subjectId 
                                ? <h2>IMPORTA RAPPORTI DA COMUNICAZIONE TXT</h2>
                                : <h2>IMPORTA SOGGETTO DA COMUNICAZIONE TXT</h2>
                            }
                            
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <div className="image-upload text-start">
                                <UploadFileLabel
                                    forLabel={"dataFile"}
                                    label={"CARICA FILE DATI"}
                                />
                                <input
                                    id={`dataFile`}
                                    type='file'
                                    name={`dataFile`}
                                    multiple
                                    ref={(ref) => this.setDataFile(ref)}
                                    onChange={(e) => this.handleUploadFile(e)}
                                    accept='.txt,.dat'
                                />
                                <div className="certificatiLoaded text-start">
                                    {
                                        !this.state.errorDataFile.isValid ?
                                            <div className="certificatiLoaded-error">
                                                {this.state.errorDataFile.msg}
                                            </div> :
                                            <div>
                                                {this.state.dataFile && this.state.dataFile.map((file) => {
                                                    return <div><ICheck width="12" fill="#8e8e8e" />{file.name}</div>
                                                })}
                                            </div>
                                    }
                                </div>
                            </div>
                        </Row>
                        <Row className="pb-3">
                            <BwmCheckbox
                                name={`update-existing`}
                                label='Integra delegati e saldi mancanti nei rapporti esistenti'
                                className='form-control'
                                value={this.state.updateExisting}
                                onClick={(e) => this.setState({ updateExisting: e.target.checked })}
                            />
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-between align-content-end mt-5">
                            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={this.closeModal} />
                            <button
                                disabled={!this.state.errorDataFile.isValid || this.state.dataFile?.length === 0}
                                onClick={this.handleSendDataFile}
                                type="button"
                                className="btn btn-new-rel px-5"
                            >
                                CONFERMA
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}
