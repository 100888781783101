import React from "react";
import { useSelector} from 'react-redux';
import getText from './labels';

export default function BlueResultDetailsPopup(props) {
    const item = props.item;
    const lang = useSelector(state => state.blueLanguage.language)
    const labels = getText(lang);

    const renderAliases = (aliasList=[]) => {
        return aliasList.map((el, index )=> {
            return (
                    <li key = {"alias-"+index}>
                        {el.wholeName || el.aliasJoin}
                    </li>

            )
        })
    }


    return (
        <React.Fragment>
            <div className="container">
                <p>
                    <b>{labels.NAMELIST}:</b> {item?.nameJoin}
                </p>
                <div>
                    <b>{labels.ALIASLIST}:</b>

                    <ul>
                        {renderAliases(item?.aliasList)}
                    </ul>
                </div>
                <p>
                    <b>{labels.TITLELIST}:</b> {item?.titleList && item?.titleList.join('; ')}
                </p>
                <p>
                    <b>{labels.ADDRESSLIST}:</b> {item?.addressList && item?.addressList.map(a => `${a.address || ''} ${a.city || ''} ${a.zipCode || ''} ${a.country || ''}`).join('; ')}
                </p>
                <p>
                    <b>{labels.BIRTHLIST}:</b> {item?.birthList && item?.birthList.map(b => `${b.date || ''} ${b.country || ''}`).join('; ')}
                </p>
                <p>
                    <b>{labels.CITIZENLIST}:</b> {item?.citizenList &&item?.citizenList.map(n => n.country).join('; ')}
                </p>
                <p>
                    <b>{labels.DOCUMENTLIST}:</b> {item?.documentList && item?.documentList.map(d => `${d.document || ''} ${d.number || ''} ${d.country || ''}`).join('; ')}
                </p>
                <p>
                    <b>{labels.NOTE}:</b> {item?.note}
                </p>
                <p>
                    <b>{labels.ORIGINDB}:</b> {item?.origin?.name} - id: {item?.origin?.id}
                </p>
                <p>
                    <b>{labels.PUBLICATIONURL}:</b> {item?.origin?.url && <a href={item?.origin?.url} target="_blank" rel="noopener noreferrer">{item?.origin?.url}</a>}
                </p>
            </div>
        </React.Fragment>
    )


}