import {RANKED_RELATIONSHIP_TYPE_SUCCESS, RELATIONSHIP_SUCCESS, RELATIONSHIP_TYPE_SUCCESS} from '../../actions/types';

const INITIAL_STATE = {
  relationships: {
    data: [],
    total: 0,
    errors: []
  },
  relationshipTypes:{
    data:[],
    errors:[]
  },
  rankedRelationshipTypes:{
    data:[],
    errors:[]
  }
};

export const relationshipsReducer = (state = INITIAL_STATE.relationships, action) => {
    switch (action.type) {
        case RELATIONSHIP_SUCCESS:
            return {...state, data: action.relationships, total: action.total};
        default:
            return state;
    }
};

export const selectedRelationshipReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {

    default:
      return state;
  }
};

export const relationshipTypesReducer = (state = INITIAL_STATE.relationshipTypes, action) => {
  switch(action.type) {
    case RELATIONSHIP_TYPE_SUCCESS:
      return {...state, data: action.relationshipTypes};
    default:
      return state;
  }
}

export const rankedRelationshipTypesReducer = (state = INITIAL_STATE.rankedRelationshipTypes, action) => {
  switch(action.type) {
    case RANKED_RELATIONSHIP_TYPE_SUCCESS:
      return {...state, data: action.rankedRelationshipTypes};
    default:
      return state;
  }
}