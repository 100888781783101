import React from 'react';
import * as actions from "../../../../actions";
import axiosService from "../../../../services/axios-service";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../styles/autocomplete.css";
import elenco from "../../../../styles/images/elenco.png";
import arrow_l from "../../../../styles/images/arrow_l.png"
import { Link } from "react-router-dom";
import { ArcoRelationshipNewOperation } from "./ArcoRelationshipNewOperation"
import { PopupError } from "../../../shared/PopupError";
import ArcoRegistrationsList from './ArcoRegistrationsList';
import { ArcoRegistryClipModal } from './ArcoRegistryClipModal';
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { SearchBar } from './SearchBar';
import { PopupSuccess } from '../../../shared/PopupSuccess';
import { ArcoRegistriesList } from './ArcoRegistriesList';
import { base64ToArrayBuffer, downloadFile } from '../../../shared/Utility';


import { IsLicenseValid } from '../../../shared/auth/license/LicenseChecker';
import { APPLICATION_ARCO } from '../../../../config/Constants';
import { IDelete, IExport, IList, IPlus } from '../../../../styles/icons';


class ArcoRegistrations extends React.Component {
    constructor() {
        super();
        this.state = {
            loadingSpinner: true,
            searchOptions: [{
                id: 1,
                description: "Numero Operazione"
            },
            {
                id: 2,
                description: "Data",
                type: "date"
            },
            {
                id: 3,
                description: "Descrizione"
            },
            {
                id: 4,
                description: "Clienti e Controparti"
            },
            {
                id: 5,
                description: "AUI",
                type: "date"
            }],

            searchType: "4",
            searchTerm: "",
            searchPlaceholder: "es. Mario Rossi",
            arcoRegistriesOperations: this?.props?.arcoRegistriesOperations,
            arcoRegistriesOperationsFromStore: null,
            checkedArcoRelationship: [],
            a1List: [],
            a2List: [],
            a3List: [],
            a4List: [],
            a5List: [],
            showModal: false,
            show: false,
            showRegistryList: false,
            showContractsList: false,
            isLicenseValid: false
        }
    }


    checkExpire() {
        if (this.props?.auth?.user?.customer?.customerApplications) {
            let valid = IsLicenseValid(this.props?.auth?.user?.customer?.customerApplications, APPLICATION_ARCO);
            this.setState({ isLicenseValid: valid });
        }
    }

    updateCheckedArcoRelationship = (checked) => {
        this.setState({ checkedArcoRelationship: checked });
    }

    closeModal = () => {
        this.setState({ showModal: false });
    };

    getAllArcoDomainsBySubjectId = () => {
        this.props.dispatch(actions.getAllArcoDomains(this.props.match.params.id));
    };

    openModalOperation = () => {
        this.setState({
            showOperationModal: !this.state.showOperationModal,
            show: false
        });
    };

    showDetail = (element, fromRowClick) => {
        this.forceUpdate();
        if (this.state.currentOperation?.id != element?.id) {
            this.setState({
                show: true,
                showOperationModal: false,
                currentOperation: element,
            })
        } else {
            this.setState({
                show: !this.state.show,
                showOperationModal: false,
                currentOperation: element,
            })
        }

    };

    componentDidMount() {
        this.setState({ subjectId: this.props.match.params.id, loadingSpinner: true });
        this.getSubject(this.props.match.params.id);
        this.props.dispatch(actions.getAllArcoOperations(this.props.match.params.id));
        this.getProfessionals(this.props.match.params.id);
        this.getArcoDomains(this.props.match.params.id);
        this.getAllCollaborator();
        this.getDocumentType();
        this.getLocations();
        this.getCountries();
        this.getAllCurrency();
        this.checkExpire();

    }

    componentDidUpdate(prevprops) {
        if (prevprops !== this.props) {
            if ((
                (this.props.arcoRegistriesOperations?.length != 0 && this.props.arcoRegistriesOperations[0].arcoSubject.id == this.props.match.params.id)
                || this.props.operationSearchData?.totalPages == 1 || this.props.operationSearchData?.totalPages == 0)
                && this.state.loadingSpinner) {
                this.setState({ loadingSpinner: false });
            }
            if (prevprops.arcoSubject !== this.props.arcoSubject) {
                const hasProfessionals = this.props.arcoSubject?.arcoSubjectType?.code === 'PB'
                if (hasProfessionals) {
                    let options = this.state.searchOptions.map(e => {
                        if (e.id === 4) {
                            e.description = "Clienti e Professionisti"
                        }
                        return e
                    });
                    this.setState({
                        searchOptions: options,
                    })
                }
            }

            if (prevprops.auth?.user?.customer?.customerApplications !== this.props.auth?.user?.customer?.customerApplications) {
                this.checkExpire();
            }
        }
    }

    getAllCollaborator = () => {
        this.props.dispatch(actions.getAllCollaboratorBySubjectId(this.props.match.params.id));
    }

    getArcoOperation = (data, searchObject) => {
        this.setState({ loadingSpinner: true })
        this.props.dispatch(actions.getAllArcoOperations(this.props.match.params.id, searchObject || this.props.operationSearchData));
        this.uncheckAll();
        if (data) {
            this.showClipModal(data);
        }
    }

    showClipModal = (arcoOperation) => {
        this.setState({ selectedArcoOperation: arcoOperation });
        this.handleOpenClipModal();
    }

    getSubject(subjectId) {
        this.props.dispatch(actions.getArcoSubject(subjectId));
    }

    getProfessionals(subjectId) {
        this.props.dispatch(actions.getAllProfessionistBySubjectId(subjectId));
    }

    getCountries = () => {
        if (!this.props.countries || this.props.countries.length == 0) {
            this.props.dispatch(actions.getAllCountries());
        }
    };

    getArcoDomains = (subjectId) => {
        this.props.dispatch(actions.getAllArcoDomains(subjectId));
    }


    getLocations = () => {
        if (!this.props.locations || this.props.locations.length == 0) {
            this.props.dispatch(actions.getAllLocations());
        }
    };

    getDocumentType = () => {
        this.props.dispatch(actions.getAllDocumentType())
    };

    getAllCurrency() {
        if (!this.props.currencies || this.props.currencies.length == 0) {
            this.props.dispatch(actions.getAllCurrency());
        }
    }

    naturaGiuridicaList = () => {
        let naturaGiuridicaList = [];
        this.state.arcoDomains.domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A1") {
                naturaGiuridicaList.push(arcoDomain);
            }
        });
        this.setState({
            a1List: naturaGiuridicaList
        });
    };

    naturaGiuridicaDef = () => {
        let naturaGiuridica;
        this.state.arcoDomains.domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A1" && arcoDomain.def) {
                naturaGiuridica = arcoDomain;
            }
        });
        if (naturaGiuridica === undefined || naturaGiuridica === null || naturaGiuridica === "") {
            naturaGiuridica = this.state.a1List[0];
        }
        this.setState({
            a1: naturaGiuridica
        });
    };

    attivitaPrevalenteList = () => {
        let attivitaPrevalenteList = [];
        this.state.arcoDomains.domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A2") {
                attivitaPrevalenteList.push(arcoDomain)
            }
        });
        this.setState({
            a2List: attivitaPrevalenteList
        });
    };

    attivitaPrevalenteDef = () => {
        let attivitaPrevalente;
        this.props.arcoDomains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A2" && arcoDomain.def) {
                attivitaPrevalente = arcoDomain;
            }
            if (attivitaPrevalente === undefined || attivitaPrevalente === null || attivitaPrevalente === "") {
                attivitaPrevalente = this.state.a2List[0];
            }
        });
        this.setState({
            a2: attivitaPrevalente
        });
    };

    comportamentoList = () => {
        let comportamentoList = [];
        this.state.arcoDomains.domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A3") {
                comportamentoList.push(arcoDomain)
            }

        });
        this.setState({
            a3List: comportamentoList
        });
    };

    comportamentoDef = () => {
        let comportamento;
        this.state.arcoDomains.domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A3" && arcoDomain.def) {
                comportamento = arcoDomain;
            }
            if (comportamento === undefined || comportamento === null || comportamento === "") {
                comportamento = this.state.a3List[0];
            }
        });
        this.setState({
            a3: comportamento
        });
    };

    areaGeograficaList = () => {
        let areaGeograficaList = [];
        this.state.arcoDomains.domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A4") {
                areaGeograficaList.push(arcoDomain)
            }

        });
        this.setState({
            a4List: areaGeograficaList
        });
    };

    areaGeograficaDef = () => {
        let areaGeografica;
        this.props.arcoDomains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A4" && arcoDomain.def) {
                areaGeografica = arcoDomain
            }
            if (areaGeografica === undefined || areaGeografica === null || areaGeografica === "") {
                areaGeografica = this.state.a4List[0];
            }
        });
        this.setState({
            a4: areaGeografica
        });
    };

    personalizzataList = () => {
        let personalizzataList = [];
        this.state.arcoDomains.domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A5") {
                personalizzataList.push(arcoDomain)
            }
        });
        return personalizzataList;
    };

    personalizzataDef = () => {
        let personalizzata;
        this.state.arcoDomains.domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A5" && arcoDomain.def) {
                personalizzata = arcoDomain
            }
        });
        if (personalizzata === undefined || personalizzata === null || personalizzata === "") {
            personalizzata = this.state.a5List[0];
        }
        return personalizzata;
    };

    closeShowDetail = () => {
        this.setState({
            showOperationModal: false,
        })
    };

    openSearchBox = (deleteSearch) => {

        if (!deleteSearch) {
            this.setState({
                searchType: "4",
                searchTerm: "",
                searchPlaceholder: "es. Mario Rossi",
                arcoRegistriesOperations: this.state.arcoRegistriesOperationsFromStore
            });

        }
    };

    handlerChangeSearch = (e) => {
        let option = e.target.value;
        if (undefined !== option && "" !== option) {
            this.setState({
                searchType: option
            });
            switch (parseInt(option)) {
                case 1:
                    // Search by number
                    this.setState({
                        searchPlaceholder: "es. 12345.."
                    });
                    break;
                case 2:// Search by date
                case 6:// Search by AUI
                    this.setState({
                        searchPlaceholder: "es. 15/02/2001"
                    });
                    break;
                case 3:
                    // Search by description
                    this.setState({
                        searchPlaceholder: "es. STAR INFOSTUDIO"
                    });
                    break;
                case 4:
                    // Search by registry name
                    this.setState({
                        searchPlaceholder: "es. Mario Rossi"
                    });
                    break;
                case 5:
                    // Search by Counterparts
                    this.setState({
                        searchPlaceholder: "es. 15/02/2001"
                    });
                    break;
                default:
                    this.setState({
                        searchPlaceholder: ""
                    });
                    return;
            }
        }
    };


    closeAllRowDetails = () => {
        let details = document.getElementsByClassName("bg-blue bold");
        if (details) {
            for (let i = 0; i < details.length; i++) {
                let detail = "detail-" + details[i].id.replace('row-', '');
                document.getElementById(detail).click();
            }
        }
    };

    searchOperations = () => {
        let searchTypeInt = parseInt(this.state.searchType);
        this.closeAllRowDetails();
        let searchObject = {
            pageNumber: 0,
            pageSize: this.state.resultsPerPage || 20,
            searchTerm: this.state.searchTerm
        };
        switch (searchTypeInt) {
            case 1:
                searchObject.searchField = "number";
                break;
            case 2:
                searchObject.searchField = "profilingDate";
                break;
            case 3:
                searchObject.searchField = "description";
                break;
            case 4:
                searchObject.searchField = "nominative";//ricerca per nominativo su clienti, controparti e professionisti
                break;
            case 5:
                searchObject.searchField = "AUI";
                break;
            default:
                break;
        }
        this.setState({ searchTermError: { isValid: true }, loadingSpinner: true })
        this.props.dispatch(actions.getAllArcoOperations(this.props.match.params.id, searchObject));
        this.uncheckAll();
    }


    onClearSearch = () => {
        this.props.dispatch(actions.getAllArcoOperations(this.props.match.params.id));
        this.uncheckAll();
        this.setState({
            searchTerm: "",
            searchTermError: { isValid: true },
            loadingSpinner: true
        });
    }

    dynamicSearch = (value) => {
        this.setState({
            searchTerm: value,
            searchTermError: { isValid: true }
        });

    };

    onCheckBoxClick = (event, arcoOperation) => {
        if (event.target.checked) {
            this.checkArcoOperation(arcoOperation);
            let element = document.getElementById("registration-" + arcoOperation.id);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        } else {
            this.uncheckArcoOperation(arcoOperation);
            document.getElementById('checkboxAllRelationshipId').checked = false;
            let element = document.getElementById("registration-" + arcoOperation.id);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }
    };

    checkArcoOperation = (arcoOperation) => {
        let newArray = this.state.checkedArcoRelationship
        newArray.push(arcoOperation);
        this.setState({
            checkedArcoRelationship: newArray
        });
        if (newArray.length === this.props.arcoRegistriesOperations.length) {
            this.checkAll();
        }
    }

    uncheckArcoOperation = (arcoOperation) => {
        let newList = [];
        this.state.checkedArcoRelationship.map(current => {
            if (current.id !== arcoOperation.id)
                newList.push(current);
        });
        this.setState({
            checkedArcoRelationship: newList
        });
    };

    onCheckClick = (event) => {
        if (event.target.checked)
            this.checkAll();
        else
            this.uncheckAll();
    };

    checkAll = () => {
        document.getElementById('checkboxAllRelationshipId').checked = true;
        this.props.arcoRegistriesOperations.map(arcoOperation => {
            const checkboxRegistrationDetailsId = `checkboxRegistrationDetails${arcoOperation.id}`;
            const idCheckbox = `registration-${arcoOperation.id}`;
            document.getElementById(checkboxRegistrationDetailsId).checked = true;
            document.getElementById(idCheckbox).checked = true;
        });
        let rowIds = document.querySelectorAll('[id^="registration-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.add("bg-cobalt", "bold");
            }
        }
        this.checkAllArcoRegistriesOperationsOperations();

    };
    checkAllArcoRegistriesOperationsOperations = () => {
        let allArcoOperations = this.props.arcoRegistriesOperations;
        this.setState({
            checkedArcoRelationship: allArcoOperations
        });
    };

    uncheckAll = () => {
        if (document.getElementById('checkboxAllRelationshipId')) {
            document.getElementById('checkboxAllRelationshipId').checked = false;
        }
        this.props.arcoRegistriesOperations.map(arcoOperation => {
            const checkboxRegistrationDetailsId = `checkboxRegistrationDetails${arcoOperation.id}`;
            const idCheckbox = `registration-${arcoOperation.id}`;
            if (document.getElementById(checkboxRegistrationDetailsId)) {
                document.getElementById(checkboxRegistrationDetailsId).checked = false;
            }
            if (document.getElementById(idCheckbox)) {
                document.getElementById(idCheckbox).checked = false;
            }
        });
        this.uncheckallarcoRegistriesOperations();
        let rowIds = document.querySelectorAll('[id^="registration-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
    };
    uncheckallarcoRegistriesOperations = () => {
        this.setState({
            checkedArcoRelationship: []
        });
    };

    deleteArcoOperationSubject = () => {//FIXME implementare delete in batch
        this.state.checkedArcoRelationship.map(arcoOperation => {
            actions.deleteArcoOperation(arcoOperation.id).then(
                () => {
                    this.getArcoOperation();
                },
                (errors) => {
                    console.error("Error while deleting arco subject: ", errors);
                    PopupError({ text: "Errore durante l'eliminazione del soggetto" })
                })
            document.getElementById(`checkboxAllRelationshipId`).checked = false;

        });
        let rowIds = document.querySelectorAll('[id^="registration-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
            this.setState({
                checkedArcoRelationship: []
            });
        }
    };


    printPersonalData = () => {
        this.setState({
            loadingSpinner: true
        });
        let idArray = [];
        if (this.state.checkedArcoRelationship.length === 0) {
            idArray.push(0);
        } else {
            this.state.checkedArcoRelationship.map(arcoOperation => {
                idArray.push(arcoOperation.id);
                document.getElementById(`checkboxRegistrationDetails${arcoOperation.id}`).checked = false;
            });
        }
        const axiosInstance = axiosService.getInstance();
        axiosInstance({
            url: `/api/arco/custom/arco-registries-list-download/${this.props.arcoSubject.id}/${idArray}`,
            method: 'GET',
        }).then((response) => {
            if (response) {
                downloadFile(base64ToArrayBuffer(response.data), "Anagrafica.pdf")

            }
            PopupSuccess({ text: "file scaricato" })
        }, (err) => {
            if (err.errorKey === "arcoOperationCheck") {
                PopupError({ ...this.props, text: err.title });
            } else {
                PopupError({ text: "Errore download documento" });
            }

        }).then(() => {
            this.setState({
                loadingSpinner: false
            });
        });


        document.getElementById(`checkboxAllRelationshipId`).checked = false;
        let rowIds = document.querySelectorAll('[id^="registration-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
            this.setState({
                checkedArcoRelationship: []
            });
        }
    };


    printSAR = () => {
        this.setState({
            loadingSpinner: true
        });

        actions.downloadSAR(this.state.subjectId)
            .then((data) => {
                downloadFile(base64ToArrayBuffer(data), "Estrazione.zip");
                PopupSuccess({ text: "File scaricato" });
            },
                (err) => {
                    PopupError({ text: err?.title || "Si è verificato un errore durante la creazione del file" });
                })
            .then(() => {
                this.setState({
                    loadingSpinner: false
                });
            })

    }

    printContract = () => {
        this.setState({
            loadingSpinner: true
        });
        let idArray = [];
        if (this.state.checkedArcoRelationship.length === 0) {
            idArray.push(0);
        } else {
            this.state.checkedArcoRelationship.map(arcoOperation => {
                idArray.push(arcoOperation.id);
                document.getElementById(`checkboxRegistrationDetails${arcoOperation.id}`).checked = false;
            });
        }
        const axiosInstance = axiosService.getInstance();
        axiosInstance({
            url: `/api/arco/custom/arco-contracts-list-download/${this.props.arcoSubject.id}/${idArray}`,
            method: 'GET'
        }).then((response) => {
            if (response) {
                downloadFile(base64ToArrayBuffer(response.data), "Contratti.pdf")
            }
            PopupSuccess({ text: "file scaricato" })
            this.setState({
                loadingSpinner: false
            });
        }, (err) => {
            if (err.errorKey === "arcoOperationCheck") {
                PopupError({ ...this.props, text: err.title });
            } else {
                PopupError({ text: "Errore download documento" });
            }
            this.setState({
                loadingSpinner: false
            });
        });
        let checkboxAllRelationshipId = document.getElementById(`checkboxAllRelationshipId`);
        if (checkboxAllRelationshipId) {
            checkboxAllRelationshipId.checked = false;
        }
        let rowIds = document.querySelectorAll('[id^="registration-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
            this.setState({
                checkedArcoRelationship: []
            });
        }
    };

    handleOpenClipModal = () => {
        this.setState({ showClipModal: !this.state.showClipModal });
    }

    onChangeResultsPerPage = (value) => {
        if (value) {
            if (value > 99) {
                this.setState({ resultsPerPage: 99 });
            } else {
                this.setState({ resultsPerPage: value });
            }
        } else {
            this.setState({ resultsPerPage: value });
        }
    }

    renderSearchBar = () => {
        return (
            <SearchBar
                openSearchBox={this.openSearchBox}
                searchOptions={this.state.searchOptions}
                handlerChangeSearch={this.handlerChangeSearch}
                searchType={this.state.searchType}
                searchPlaceholder={this.state.searchPlaceholder}
                dynamicSearch={this.dynamicSearch}
                searchTerm={this.state.searchTerm}
                searchTermError={this.state.searchTermError}
                onChangeResultsPerPage={this.onChangeResultsPerPage}
                resultsPerPage={this.state.resultsPerPage}
                doSearch={this.searchOperations}
                placeholder={20}
                customClass={'searchBarArcoRegList'}
            />

        )
    };

    render() {
        const disabled = !this.state.isLicenseValid;
        return (
            <React.Fragment>
                {this.state.loadingSpinner && <PageSpinner />}
                <div className="row title-page">
                    <Link to="/app/arco/dashboard" >
                        <img src={arrow_l} className="px-4" alt="Torna a elenco soggetti" />
                        {this.props.arcoSubject.companyName}
                    </Link>
                </div>
                <div className="table-custom">
                    <div className="tabs single">
                        <ul className="tab-list nav nav-tabs">
                            <li className="tab-list-item nav-link tab-list-active active">
                                <div className="col-md-12 col-lg-12 text-center "><img src={elenco} />&nbsp;&nbsp;ELENCO OPERAZIONI</div>
                            </li>
                        </ul>
                        <div className="table-custom mt-1 mb-1">
                            <div className="tab-content tab-rel" >
                                <div className="row pt-2 justify-content-between align-items-center">
                                    <div className='col-9'>
                                        {this.renderSearchBar()}
                                    </div>
                                    <div className="col-3 text-end" role="group" aria-label="action buttons">
                                        <button type="button" className="btn btn-secondary  w-50 px-4 btn-sm" onClick={() => this.onClearSearch()} hidden={!this.props.operationSearchData?.searchTerm}>
                                            Pulisci ricerca
                                        </button>
                                    </div>
                                </div>

                                <div className="row justify-content-between align-items-center pb-1">
                                    <div className='col-9'>
                                        <div className='row'>
                                            <div className="btn-group col-2" role="group" aria-label="action buttons">
                                                <button type="button" disabled={this.state.checkedArcoRelationship.length === 0 || disabled}
                                                    className={`btn btn-outline-primary btn-empty px-4 btn-sm  ${((this.state.checkedArcoRelationship).length === 0) ? 'disabled' : ''}`} onClick={() => this.deleteArcoOperationSubject()} >
                                                    <IDelete className={"padding-bottom-4px"} width="10" fill={` ${((this.state.checkedArcoRelationship).length === 0) ? '#FFFFFF' : '#128186'}`} />&nbsp; Elimina</button>
                                            </div>
                                            <div className="btn-group col-2 ps-2" role="group" aria-label="action buttons">
                                                <button type="button" className={`btn btn-outline-primary btn-empty px-4 btn-sm margin-left-5px`}
                                                    data-tip={"Stampa Anagrafiche di operazioni selezionate"}
                                                    onClick={() => this.printPersonalData()}>
                                                    <IExport className={"padding-bottom-4px"} width="16" fill={`#128186`} />&nbsp; Stampa Anagrafiche
                                                </button>
                                            </div>
                                            <div className="btn-group col-2 ps-2" role="group" aria-label="action buttons">
                                                <button type="button" className="btn btn-outline-primary btn-empty px-4 btn-sm margin-left-5px"
                                                    data-tip={"Stampa Contratti di operazioni selezionate"}
                                                    onClick={() => this.printContract()} >
                                                    <IExport className={"padding-bottom-4px"} width="16" fill={`#128186`} />&nbsp; Stampa Contratti
                                                </button>
                                            </div>
                                            <div className="btn-group col-2 ps-2" role="group" aria-label="action buttons">
                                                <button type="button" className="btn btn-outline-primary btn-empty px-4 btn-sm margin-left-5px"
                                                    data-tip={"Lista Anagrafiche con Operazioni e Analisi del rischio"}
                                                    onClick={this.printSAR} >
                                                    <IExport className={"padding-bottom-4px"} width="16" fill={`#128186`} />&nbsp;
                                                    Stampa completa
                                                </button>
                                            </div>
                                            <div className="btn-group col-2 ps-2 ms-5 d-flex justify-content-end" role="group" aria-label="action buttons">
                                                <button type="button" className="btn btn-outline-primary btn-empty px-4 btn-sm margin-left-5px"
                                                    data-tip={"Visualizza lista anagrafiche"}
                                                    onClick={() => this.setState({ showRegistryList: true })} >
                                                    <IList className={"padding-bottom-4px"} width="16" fill={`#128186`} />&nbsp;
                                                    Lista anagrafiche
                                                </button>
                                            </div>
                                        </div>


                                        {/* <div className="btn-group col-2 ps-2" role="group" aria-label="action buttons">
                                            <button type="button" className="btn btn-outline-primary btn-empty px-4 btn-sm margin-left-5px"
                                                data-tip={"Visualizza lista anagrafiche"}
                                                onClick={() => this.setState({ showContractsList: true })} >
                                                <IList className={"padding-bottom-4px"} width="16" fill={`#128186`} />&nbsp;
                                                Lista contratti
                                            </button>
                                        </div> */}
                                    </div>
                                    <div className="btn-group col-2 ps-3 pe-3 text-center">
                                        <button type="button" disabled={disabled} className="btn  btn-primary btn-new-rel" onClick={this.openModalOperation}>
                                            <IPlus className={"padding-bottom-4px"} width="14" />&nbsp; NUOVA OPERAZIONE
                                        </button>
                                    </div>

                                </div>
                                <div className='row'>
                                    {!this.state.showOperationModal &&
                                        <ArcoRegistrationsList
                                            {...this.props}
                                            getArcoOperation={this.getArcoOperation}
                                            arcoRegistriesOperations={this.props.arcoRegistriesOperations}
                                            showDetail={this.showDetail}
                                            page={this.state.page}
                                            show={this.state.show}
                                            currentOperation={this.state.currentOperation}
                                            checkedArcoRelationship={this.checkedArcoRelationship}
                                            onCheckBoxClick={this.onCheckBoxClick}
                                            onCheckClick={this.onCheckClick}
                                            disableInsert={disabled}
                                        />}
                                </div>
                            </div>


                        </div>
                        <div >

                            {this.state.showOperationModal && <div>
                                <ArcoRelationshipNewOperation
                                    {...this.props}
                                    onHide={this.closeShowDetail}
                                    closeModalOperation={this.closeModalOperation}
                                    show={this.state.show}
                                    currencies={this.props.currencies}
                                    arcoDomains={this.props.arcoDomains}
                                    getArcoOperation={this.getArcoOperation}
                                    disableInsert={disabled}
                                />
                            </div>}


                            {this.state.showClipModal && <div>
                                <ArcoRegistryClipModal
                                    {...this.props}
                                    show={this.state.showClipModal}
                                    arcoRegistriesOperation={this.state.selectedArcoOperation}
                                    onHide={this.handleOpenClipModal}
                                    disableInsert={disabled}
                                />
                            </div>}

                            {
                                this.state.showRegistryList &&
                                <ArcoRegistriesList
                                    showModalRegistriesList={this.state.showRegistryList}
                                    onHide={() => this.setState({ showRegistryList: false })}
                                    disableInsert={disabled}
                                />
                            }
                            {/*   {
                                <Modal
                                    backdrop="static"
                                    show={this.state.showContractsList}
                                    onHide={() => this.setState({ showContractsList: false })}
                                    size="xl"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    className="arco-modal-max mt-0 mb-1"
                                    centered
                                    scrollable
                                >
                                    <div className="bar"></div>
                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <h2>CONTRATTI</h2>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="px-2">

                                        <ArcoContractsList
                                            arcoSubjectId={this.state.subjectId}
                                        />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button
                                            className="btn btn-outline-primary btn-empty px-4 btn-sm"
                                            onClick={() => this.setState({ showContractsList: false })}><IUndo className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />&nbsp; CHIUDI
                                        </button>
                                    </Modal.Footer>
                                </Modal>

                            } */}
                        </div>

                    </div>


                </div>




            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    let customLocations = state.locations?.data.map((location, index) => {
        return { name: location.location, key: index }
    });
    let customCountries = state.countries?.data.map((country, index) => {
        return { name: country.code, key: country.description }
    })
    return {
        auth: state.auth,
        documents: state.arcoRelationshipDocuments.data,
        arcoSubject: state.arcoSubject.data,
        subjects: state.arcoSubjects.data,
        locations: customLocations,
        countries: customCountries,
        arcoDomains: state.arcoDomains.domains,
        arcoRegistries: state.arcoRegistries.data,
        arcoRegistriesOperations: state.arcoRegistriesOperations.data,
        operationSearchData: state.operationSearchData,
        currencies: state.currencies.data,
    }
}

export default connect(mapStateToProps)(ArcoRegistrations)
