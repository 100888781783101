import React from 'react';
import { connect } from "react-redux";
import * as actions from "../../../../actions";
import { ComureiSubjectModal } from "./ComureiSubjectModal";
import ComureiSubjectList from "./ComureiSubjectList";
import CreateCommunicationModal from "../communications/CreateCommunicationModal";
import { APPLICATION_COMUREI, APPLICATIONS, COMUREI_BUY_LINK } from '../../../../config/Constants';
import { CheckRemainingSubjects } from '../../../shared/auth/license/LicenseChecker';
import { SubjectTotalAndCallToAction } from '../../../shared/SubjectTotalAndCallToAction';


class ComureiSubject extends React.Component {
    constructor() {
        super();
        this.state = {
            checkedSubjects: [],
            communication: {
                show: false,
                user: null,
                subject: null,
                communication: null,
            },
            subjectSummary: undefined
        }
    }

    componentWillMount() {
        this.getSubjects();
        this.getLocations();
        this.getSubjectTypes();


    }

    componentDidUpdate(prevProps){
        if(prevProps !== this.props && this.props?.auth){

            const application = APPLICATIONS.find(a => a.description === APPLICATION_COMUREI);
            const customerApplication = this.props?.auth?.user?.customer?.customerApplications?.find(ca => ca?.application?.id === application.id);
            this.setState({ customerApplication: customerApplication });
        }
    }

    getSubjectSummary = () => {
        CheckRemainingSubjects(APPLICATION_COMUREI, false, (subjectSummary) => {
            this.setState({ subjectSummary: subjectSummary });
        })
    }

    getSubjects = () => {
        this.props.dispatch(actions.getAllComureiSubjects());
        this.getSubjectSummary();
    };

    getLocations = () => {
        this.props.dispatch(actions.getAllLocations());
    };

    getSubjectTypes = () => {
        this.props.dispatch(actions.getAllComureiSubjectTypes());
    };



    closeCreateCommunicationModal = () => {
        this.setState({
            communication: {
                show: false,
                user: null,
                subject: null,
                communication: null
            }
        })
        this.getSubjects();
    };

    openCreateCommunicationModal = (subject, communications) => {
        this.setState({
            communication: {
                show: true,
                user: this.props.auth.user,
                subject: subject,
                communication: communications
            }
        })
    }

    render() {
        let historicSubjects = 0;
        if (this.props.subjects) {
            for (const subject of this.props.subjects) {
                if (subject.historic) {
                    historicSubjects++;
                }
            }
        }
        return (
            <React.Fragment>
                <div className="table-custom mt-1">
                    <div className="tab-content tab-rel">
                        <div className="row header-btn-relationship">
                            <div className="col-9 ps-0">
                                <SubjectTotalAndCallToAction
                                    total={this.state.subjectSummary?.total}
                                    remaining={this.state.subjectSummary?.available}
                                    used={this.state.subjectSummary?.used}
                                    licenseType={this.state.customerApplication?.licenseType}
                                    customerApplication={this.state.customerApplication}
                                />
                                <span className='ms-2 font-italic fw-light text-secondary' style={{ fontSize: "0.8rem" }} >Soggetti storici (non conteggiati): {historicSubjects}</span>
                            </div>

                            <div className="col-3 text-end pe-0">
                                <ComureiSubjectModal
                                    locations={this.props.locations}
                                    subjectTypes={this.props.subjectTypes}
                                    getSubjects={() => { this.getSubjects(); this.getSubjectSummary(); }}
                                    openCreateCommunicationModal={this.openCreateCommunicationModal}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <ComureiSubjectList
                                    subjects={this.props.subjects}
                                    subjectTypes={this.props.subjectTypes}
                                    locations={this.props.locations}
                                    getSubjects={this.getSubjects}
                                    checkSubject={this.checkSubject}
                                    uncheckSubject={this.uncheckSubject}
                                    checkAllSubjects={this.checkAllSubjects}
                                    uncheckAllSubjects={this.uncheckAllSubjects}
                                    user={this.props.auth.user}
                                    openCreateCommunicationModal={this.openCreateCommunicationModal}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                {this.state.communication.show &&
                    <CreateCommunicationModal user={this.state.communication.user} subject={this.state.communication.subject} communications={this.state.communication.communication} closeModalComm={this.closeCreateCommunicationModal} />
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    let customLocations = state.locations?.data.map((location, index) => {
        return { name: location.location, key: index }
    });

    return {
        auth: state.auth,
        locations: customLocations,
        subjects: state.comureiSubjects.data,
        subjectTypes: state.comureiSubjectTypes.data
    }
}

export default connect(mapStateToProps)(ComureiSubject);
