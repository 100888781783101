import {GET_BALANCES_BY_RELATIONSHIP_SUCCESS} from '../../actions/types';

const INITIAL_STATE = {
    balances: {
        data: [],
        errors: []
    }

};

export const balanceReducer = (state = INITIAL_STATE.balances, action) => {
    switch (action.type) {
        case GET_BALANCES_BY_RELATIONSHIP_SUCCESS:
            return {...state, data: action.balances};
        default:
            return state;
    }
};