/**
 * Componente che definisce una lista "virtuale" 
 * Da utilizzare pe rmigliorare le performance di grosse liste, dove il render iniziale è molto pesante
 */

import React from "react";
import { FixedSizeList as List } from "react-window";



//lista virtuale. prende come argomento una lista di componenti JSX
export const VirtualList = ({ rows = [] }) => (
    <List itemData={rows}
        height={1000}
        itemCount={rows.length}
        itemKey={index => `virtual-list-element-${index}`}
        itemSize={35}
        width={'100%'}
        overscanCount={10}>
        {BalanceRow}
    </List>
);



//Componente utilizzato da react-window per renderizzare le righe
//Dichiarato al di fuori della classe per ovviare ad una perdita di focus all'aggiornamento di un input (https://stackoverflow.com/questions/71474302/react-window-loses-focus)
//data= array di componenti react da inserire in lista
//style= stile in linea inserito da react-window
export const BalanceRow = ({ index, data, style }) => {
    const item = data[index];

    return (
        <div style={style} key={item.id}>
            {item}</div>
    )
};