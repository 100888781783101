import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BtnEmpty} from "./BtnEmpty";
import {BtnFill} from "./BtnFill";

export function PopupDelete(props) {

    return (
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <React.Fragment>
                        <div className="bar"></div>
                        <div className='popup'>
                            <button type="button" className="close" onClick={onClose}><span aria-hidden="true">×</span><span
                                className="visually-hidden">Close</span>
                            </button>
                            <Row className={"show-grid popup-title-delete text-center"}>
                                <Col md={12} lg={12}>
                                    <img alt={""} src="" />
                                </Col>
                                <Col md={12} lg={12} className="py-3">
                                    {props.title ?
                                    <h4>{props.title}</h4>
                                    :
                                    <h4>Elimina {props.operazione}</h4>}
                                </Col>
                            </Row>
                            <Row className={"show-grid text-center"}>
                                {(props.text) ?
                                    <Col md={12} lg={12}>
                                        <div className='text-center'>{props.text}</div>
                                    </Col>
                                :
                                ""}

                                <Col md={12} lg={12}>
                                    {
                                        props.list?.map((item) => (
                                            <div className='text-center'>{item}</div>
                                        ))
                                    }
                                </Col>
                            </Row>
                            <Row className="my-2 py-5 d-flex justify-content-around">
                                <BtnEmpty classCustom={"col"} text={props.cancelText || "ANNULLA"} handlerOnClick={onClose}/>
                                <BtnFill classCustom={"col text-end"} text={props.confirmText || "CONFERMA"} handlerOnClick={() => {
                                    props.handleClickConfirm()
                                    onClose()
                                }}/>
                            </Row>
                        </div>
                    </React.Fragment>
                )
            },
            closeOnClickOutside : false
        })
    );
}
