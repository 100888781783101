import React from "react";
import { StarTable } from "./shared/tables/StarTable";

export function TestPage(){

    const headerColums= [
        "col1",
        "col2",
        "col3",
        "col4"
    ];



    return <StarTable headerColums={headerColums} />
}