import {GET_CURRENCY_SUCCESS, GET_CURRENCY_TYPES_SUCCESS} from '../../actions/types';

const INITIAL_STATE = {
    currencies: {
        data: [],
        errors: []
    },
    currencyTypes: {
        data: [],
        errors: []
    }
}

export const currencyReducer = (state = INITIAL_STATE.currencies, action) => {
    switch(action.type) {
        case GET_CURRENCY_SUCCESS:
            return {...state, data: action.currencies};
        default:
            return state;
    }
}

export const currencyTypesReducer = (state = INITIAL_STATE.currencyTypes, action) => {
    switch(action.type) {
        case GET_CURRENCY_TYPES_SUCCESS:
            return {...state, data: action.currencyTypes};
        default:
            return state;
    }
}

