import it from "date-fns/locale/it";
import React from 'react';
import Modal from "react-bootstrap/Modal";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
import "../../../../styles/autocomplete.css";
import { ArcoRegistryClipForm } from "./ArcoRegistryClipForm";
import { formatDateForDisplay } from "../../../shared/Utility";

registerLocale("it", it);

export function ArcoRegistryClipModal(props) {
    const arcoSubject = useSelector(state => state.arcoSubject.data);    

    const getSubjectName = () => {
        return arcoSubject?.companyName;
    };

return (
    <React.Fragment>
        <Modal
            backdrop="static"
            show={props.show}
            onHide={props.onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <div className="bar"></div>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                <h2>{'< ' + getSubjectName()}</h2>
                <h3>{'OPERAZIONE: ' + props?.arcoRegistriesOperation?.description} &nbsp; {'DEL ' + formatDateForDisplay(props?.arcoRegistriesOperation?.createDate)}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <ArcoRegistryClipForm
                        {...props}
                        disableInsert={props.disableInsert}
                    />
                </div>
            </Modal.Body>
        </Modal>
    </React.Fragment>
)
}