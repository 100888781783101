import React from "react"
import { Col, Row } from "react-bootstrap"

 const pinTooltip = `* Se la Società è iscritta al servizio Fisconline, indicare il Pincode composto da 10 caratteri numerici e ottenuto in fase   di registrazione.
Se la Società è iscritta al servizio Entratel, indicare il Pincode composto da 16 caratteri alfanumerici e presente nella sezione 3 della busta virtuale ottenuta in fase di registrazione al servizio Entratel;
indicare anche il progressivo sede rilevabile dall'attestazione fornita dall'ufficio: da impostare a "000" se si intende generare l'ambiente di sicurezza per la sede principale.
Vale, invece, "001", "002", etc. nel caso in cui l'ambiente si riferisce ad una sede secondaria;
`
 const credenzialiToolTip = `**Se l'amministratore/incaricato accede con Spid e quindi non possiede le credenziali di Fisconline, è necessario concordare un appuntamento per una sessione di assistenza remota scrivendo a:  Assistenza@starsoluzioni.it`

 const nuoveCredenzialiToolTip = `***Password a vostra scelta, minimo 8 massimo 20 caratteri senza lettere accentate, almeno una lettera e almeno un numero`
 const PinToolTip = `***Pin di revoca a scelta, inserire un codice scelto a piacere dall’utente costituito da un minimo di 15 e da un massimo di 20 caratteri (di cui solo numeri e/o lettere non accentate).`

export function Tips(){
    return (
        <section>
                <Row>
                    <Col >
                        <small>
                            {pinTooltip}
                        </small>
                    </Col>
                </Row>
                <Row className="mt-2 mb-2">
                    <Col>
                        <small>
                            {credenzialiToolTip}
                        </small>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <small>
                            {nuoveCredenzialiToolTip}
                        </small>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <small>
                            {PinToolTip}
                        </small>
                    </Col>
                </Row>

            </section>
    )
}
