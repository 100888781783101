import React from 'react';

export class BtnFill extends  React.Component {
    constructor(props) {
        super();
    }

    render(){
       const {text, handlerOnClick, classCustom, isDisabled, title} = this.props;
       return (
           <div  className={(classCustom ? classCustom : '') }>
               <button id={"btn-fill"} type="button" className="btn btn-primary btn-new-rel px-5 btn-sm"
                       onClick={handlerOnClick} disabled={isDisabled === true ? isDisabled : false} title={title}>{text}</button>
           </div>
       )
   }
}



