import React from 'react';
import NumberFormat from "react-number-format";
import {BwmCheckbox} from "../../../shared/form/BwmCheckbox";
import PaymentList from "./PaymentList";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustom";
import * as actions from "../../../../actions";
import {PopupError} from "../../../shared/PopupError";
import { isEmpty, isNotEmpty } from '../../../shared/Utility';

class PaymentForm extends React.Component {
    constructor(props) {
        super(props);
        this.paymentListRefData = React.createRef();
        this.state = {
            yearRel: this.props.subject?.referenceYear || new Date().getFullYear() - 1,
            amountRel: this.props.relationship?.balance || 0.00,
            currencyRel: this.props.relationship?.currency || {
                id: '58',
                isoCode: 'EUR',
                description: ''
            },
            closed:  this.props.relationship?.closed || false,
            sleepy: this.props.relationship?.sleepy || false,
            notDocumented: this.props.relationship?.notDocumented || false,
            errorAmount : {isValid: true, msg: ""}
        }

        console.log("amountRel: " + this.state.amountRel);
    }

    handlerAmountRel = (val) => {
        if(isEmpty(val))
            val = 0;
        this.setState({
            amountRel: val.floatValue,
            errorAmount : {isValid: true, msg: ""}
        })
    };



    isNotEmpty = (val) => {
        if (isNotEmpty(val)) {
            return true;
        } else {
            return false;
        }
    };

    handleInputAutocompileCurrency = (newCurrency, id, suggestionIndex) => {
        this.setState({
            currencyRel: {
                id: '',
                isoCode: newCurrency,
                description: ''
            }
        });

        let currencyFilter = this.props.currencies.filter(currency => {
            return currency.name === newCurrency
        })
        if (currencyFilter.length === 1) {
            this.setState({
                currencyRel: {
                    id: currencyFilter[0].id,
                    isoCode: currencyFilter[0].name.split(' - ')[0],
                    description: currencyFilter[0].name.split(' - ')[1]
                }
            });
        }
        if (currencyFilter.length === 0) {
            this.setState({
                currencyRel: {
                    id: '',
                    isoCode: newCurrency,
                    description: ''
                }
            });
        }
    };

    handlerChangeClosed = (e) => {
        this.setState({closed: e.target.checked})
    };

    handlerChangeSleepy = (e) => {
        this.setState({sleepy: e.target.checked})
    };

    handlerChangeNotDocumented = (e) => {
        this.setState({notDocumented: e.target.checked})
    };

    savePayments = () => {
        this.paymentListRefData.current.addCrsPayment();
        this.paymentListRefData.current.getCrsPaymentList();
    };

    refreshPayments = () => {
        this.paymentListRefData.current.getCrsPaymentList();
    };


    getData(){
        if(this.handleValidation()){
            return {
                yearRel: this.state.yearRel,
                amountRel: this.state.amountRel,
                currencyRel: this.state.currencyRel,
                closed: this.state.closed,
                sleepy: this.state.sleepy,
                notDocumented: this.state.notDocumented
            }
        }
        return {}

    }

    handleValidation = () => {
        let isValid = true;
        if (isEmpty(this.state.amountRel)) {
            this.setState({
                errorAmount: {isValid: false, msg: "Il saldo deve essere valorizzato"}
            });
            isValid = false;
        }
        return isValid;
    };

    updateCrsRelationship = () => {
        if(this.handleValidation()) {
            let relationship = this.props.relationship;
            let amountData = this.getData();
            relationship.balance = amountData.amountRel?.floatValue;
            relationship.currency = amountData.currencyRel;
            relationship.closed = amountData.closed;
            relationship.sleepy = amountData.sleepy;
            relationship.notDocumented = amountData.notDocumented;
            //console.log("relationship: " + JSON.stringify(relationship));
            actions.addCrsRelationship(relationship).then(
                (response) => {

                },
                (errors) => {
                    PopupError({...this.props, text:errors});
                }
            );
        }
    };



    render() {
        return (
            <div className="row">
                <div className="col-3">
                    <div className="payment_relationship">
                        <div className='form-group mb-3'>
                            <label>Anno di riferimento (dettaglio soggetto)</label>
                            <div className='input-group'>
                                <input name='yearRel'
                                       className='form-control text-start paymentYear'
                                       value={this.state.yearRel}
                                       maxLength="4"
                                       disabled="disabled"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between row">
                            <div className='form-group col-8'>
                                <label>SALDO RAPPORTO</label>
                                <div className='input-group'>
                                    <NumberFormat
                                        name={`amountRel_`}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        className="form-control"
                                        inputmode="numeric"
                                        value={this.state.amountRel}
                                        onValueChange={val => this.handlerAmountRel(val)}/>
                                </div>
                                <div className="text-danger">{this.state.errorAmount.msg || ""}</div>
                            </div>
                            <div className='form-group col-4'>
                                <div className='input-group'>
                                    <AutoCompileCustom
                                        id="currencyRel_"
                                        handleInputAutocompile={e => this.handleInputAutocompileCurrency(e)}
                                        filtro={this.props.currencies}
                                        value={this.state.currencyRel.isoCode || ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column form-check">
                            <div className="mb-1">
                                <BwmCheckbox
                                    name={`closed_`}
                                    label='Rapporto CHIUSO'
                                    className='form-control'
                                    value={this.state.closed}
                                    onClick={(e) => this.handlerChangeClosed(e)}
                                />
                            </div>
                            <div className="mb-1">
                                <BwmCheckbox
                                    name={`sleepy_`}
                                    label='Rapporto DORMIENTE'
                                    className='form-control mb-1'
                                    value={this.state.sleepy}
                                    onClick={(e) => this.handlerChangeSleepy(e)}
                                />
                            </div>
                            <div className="mb-1">
                                <BwmCheckbox
                                    name={`notDocumented_`}
                                    label='Rapporto NON DOCUMENTATO'
                                    className='form-control'
                                    value={this.state.notDocumented}
                                    onClick={(e) => this.handlerChangeNotDocumented(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-9">
                    <PaymentList ref={this.paymentListRefData} {...this.props} relationship={this.props.relationship} />
                </div>
            </div>
        );
    }
}

export default PaymentForm;
