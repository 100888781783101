import React from 'react';

export const BwmSelect = ({
        input,
        label,
        options,
        className,
        onChange,
        name,
        error,
        value,
        showCode,
        disabled,
        nameKey
        }) => {

  function renderOptions(nameKey) {

    return options.map((option, index) => {
        if(nameKey) {
            if(nameKey === "currencies"){
                let code = option.code !== undefined ? option.code : option.isoCode !== undefined ? option.isoCode : "";
                return <option key={index} value={option.id}  data-cod={code}>{`${showCode ? code : option.description}`}</option>
            }
            return <option key={index} value={option.name} data-cod={option.name}>{option.name + " - " + option.key}</option>
        }else {
            let code = option.code !== undefined ? option.code : option.isoCode !== undefined ? option.isoCode : "";
            return <option key={index} value={option.id}  data-cod={code}>{`${showCode ? code + ' - ' + option.description : option.description}`}</option>
        }
    })
  }

  return (
    <div className={`form-group ${disabled&& 'disabledSelect'}`}>
        {label &&
      <label className='form-label'>{label}</label> }
      <div className='input-group'>
        <select {...input}className={`${className} custom-select-arrow`} onChange={onChange} name={name} value={value} disabled={disabled}>
            <option ></option>
          {renderOptions(nameKey)}
          
        </select>
      </div>
        { error !== undefined && !error.isValid
            ? <div className="text-danger">{error.msg}</div>
            : ''}

    </div>
  )
}
