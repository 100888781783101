import { ReactComponent as IPlus } from "../../../../../styles/images/svgs/regular/plus.svg";
import { ReactComponent as IMinus } from "../../../../../styles/images/svgs/regular/minus.svg";
import React from 'react';

export function CreateNewButton(props) {

    return (
        <button type="button" className="btn btn-primary btn-new-rel px-5 m-auto btn-sm"
            disabled={props.disabled}
            onClick={props.onClick}>
            {props.active ?
                <><IMinus className={"svg"} width="17" fill={"#FFFFFF"} />&nbsp; ANNULLA</>
                :
                <><IPlus className={"svg"} width="17" fill={"#FFFFFF"} />&nbsp; CREA NUOVO</>}
        </button>
    )
}