import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import * as Constants from "../../../../config/Constants";
import "react-datepicker/dist/react-datepicker.css";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustom";
import Tabs from "./Tabs";
import BalanceForm from "./BalanceForm";
import * as actions from "../../../../actions";
import DelegateForm from "./DelegateForm";
import { DateField } from "../../../shared/form/DateField";
import { PersonTypeField } from "./PersonTypeField";
import { ProvinceField } from "./ProvinceField";
import "../../../../styles/autocomplete.css";
import { BwmCheckbox } from "../../../shared/form/BwmCheckbox";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import { BwmInput } from "../../../shared/form/BwmInput";
import CodiceFiscale from 'codice-fiscale-js';
import { PopupError } from '../../../shared/PopupError';
import { PopupSuccess } from '../../../shared/PopupSuccess';
import { RankedDropdownList } from '../../../shared/form/RankedDropdownList';
import { formatDateForDisplay, isEmpty, isNotEmpty } from '../../../shared/Utility';
import { endOfDay, getMonth, getYear, isBefore } from 'date-fns';
import { Col, Row } from 'react-bootstrap';
import { ICopy, ISave, IUndo, IUnlock } from '../../../../styles/icons';
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm';

registerLocale("it", it);

export default function RelationshipForm(props) {

    const subjectid = props.subjectId;
    const [relationship, setRelationship] = useState(props.relationship);
    const [delegates, setDelegates] = useState(relationship.coraRelationDelegates || []);
    const { relationshipLocations, relationshipProvinces/**TODO: eliminare */, currencies, roles, modal, index } = props;
    const relationshipTypes = useSelector(state => state.relationshipTypes.data);
    const rankedrelationshipTypes = useSelector(state => state.rankedRelationshipTypes.data);
    const [relType, setRelType] = useState(relationship?.relationshipType);
    const [commType, setCommType] = useState(relationship?.coraCommunicationType?.id || '1');
    const [commTypeList, setCommTypeList] = useState(props?.communicationTypes || []);
    const [showCab, setShowCab] = useState(Constants.codRelTypeCab.indexOf(relationship?.relationshipType?.code) !== -1 ? true : false);
    const [cab, setCab] = useState(Constants.codRelTypeCab.indexOf(relationship?.relationshipType?.code) !== -1 ? relationship?.cab : '');
    const [errorCab, setErrorCab] = useState({ isValid: true, msg: "" });
    const [showDescription, setShowDescription] = useState(relationship?.relationshipType?.code === '99' ? true : false);
    const [description, setDescription] = useState(relationship?.relationshipType?.code === '99' ? relationship?.description : '');
    const [errorDescription, setErrorDescription] = useState({ isValid: true, msg: "" });
    const [errorRelType, setErrorRelType] = useState({ isValid: true, msg: "" });
    const [startDate, setStartDate] = useState(relationship?.startDate ? new Date(relationship.startDate) : null);
    const [errorStartDate, setErrorStartDate] = useState({ isValid: true, msg: "" });
    const [endDate, setEndDate] = useState(relationship?.endDate ? new Date(relationship.endDate) : null);
    const [errorEndDate, setErrorEndDate] = useState(false);
    const [endDateDisabled, setEndDateDisabled] = useState(relationship?.relationshipType?.code === '98' ? true : false);
    const [errorCommType, setErrorCommType] = useState({ isValid: true, msg: "" });
    const [personType, setPersonType] = useState(relationship?.personType || Constants.PNF);
    const [errorPersonType, setErrorPersonType] = useState({ isValid: true, msg: "" });
    const [displayPf, setDisplayPf] = useState(false);
    const [displayPnf, setDisplayPnf] = useState(true);
    const [identifier, setIdentifier] = useState(relationship?.identifier || '')
    const [errorIdentifier, setErrorIdentifier] = useState({ isValid: true, msg: "" });
    const [fiscalCode, setFiscalCode] = useState(relationship?.fiscalCode || '')
    const [errorFiscalCode, setErrorFiscalCode] = useState({ isValid: true, msg: "" });
    const [companyName, setCompanyName] = useState(relationship?.companyName || '');
    const [errorCompanyName, setErrorCompanyName] = useState({ isValid: true, msg: "" });
    const [location, setLocation] = useState(relationship?.location || '');
    const [errorLocation, setErrorLocation] = useState({ isValid: true, msg: "" });
    const [province, setProvince] = useState(relationship?.province);
    const [errorProvince, setErrorProvince] = useState({ isValid: true, msg: "" });
    const [lastName, setLastName] = useState(relationship?.lastName || '');
    const [errorLastName, setErrorLastName] = useState({ isValid: true, msg: "" });
    const [firstName, setFirstName] = useState(relationship?.firstName || '');
    const [errorFirstName, setErrorFirstName] = useState({ isValid: true, msg: "" });
    const [gender, setGender] = useState(relationship?.gender || 'FEMALE');
    const [birthDate, setBirthDate] = useState(relationship?.birthDate ? new Date(relationship?.birthDate) : null);
    const [errorBirthDate, setErrorBirthDate] = useState({ isValid: true, msg: "" });
    const [birthLocation, setBirthLocation] = useState(relationship?.personType === Constants.PF ? relationship?.location || '' : '');
    const [errorBirthLocation, setErrorBirthLocation] = useState({ isValid: true, msg: "" });
    const [birthProv, setBirthProv] = useState(relationship?.personType === Constants.PF ? relationship?.province || '' : '');
    const [errorBirthProv, setErrorBirthProv] = useState({ isValid: true, msg: "" });
    const [comment, setComment] = useState(relationship?.comment || "");
    const [formData, updateFormData] = useState({ coraRole: { id: 1 }, coraSubject: { id: subjectid } });
    const [included, setIncluded] = useState(relationship?.includedInNextCommunication !== false);
    const [errorIncluded, setErrorIncluded] = useState();
    const [idResult, setIdResult] = useState(relationship?.idResult || null);
    const canceledAndCommunicated = relationship?.canceledAndCommunicated;

    const delayedCommunicationDate = useSelector(state => state.delayedCommunicationDate)

    const [disableIdentifierInput, setDisableIdentifierInput] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!relationshipTypes?.length) {
            dispatch(actions.getRelationshipTypes());
        }
        dispatch(actions.getRankedRelationshipTypes((props.subject.id)));

    }, [])

    /**
     * effetto per gestire il valore di default per il tipo rapporto
     */
    useEffect(() => {
        if (personType === Constants.PF) {
            setDisplayPf(true);
            setDisplayPnf(false);
        } else if (personType === Constants.PNF) {
            setDisplayPf(false);
            setDisplayPnf(true);
        }
        if (endDateDisabled) setEndDate(null);
        const relType = relationship?.relationshipType || rankedrelationshipTypes[0] || relationshipTypes[0];
        setRelType(relType);
        if (relType?.code === "99") {
            setShowDescription(true);
        }
        if (Constants.codRelTypeCab.indexOf(relType?.code) !== -1) {
            setShowCab(true);
        } else {
            setShowCab(false);
        }

        updateFormData({
            ...formData,
            id: relationship?.id || '',
            startDate: startDate,
            endDate: endDate,
            relationshipType: relType,
            coraSubject: {
                id: subjectid
            },
            coraRole: {
                id: "1"
            },
            cab: cab,
            description: description,
            personType: personType,
            fiscalCode: fiscalCode,
            companyName: companyName,
            location: location,
            province: province,
            firstName: firstName,
            lastName: lastName,
            gender: gender,
            birthDate: birthDate,
            comment: comment,
            identifier: identifier,
            includedInNextCommunication: included,
            coraCommunicationType: { id: commType },
            codError: relationship?.codError,
            idResult: idResult,
            lastCommunicationError: relationship?.lastCommunicationError,
            canceledAndCommunicated: relationship?.canceledAndCommunicated
        });
    }, [relationshipTypes, rankedrelationshipTypes])


    // i rapporti cancellati e comunicati in precedenza devono tornare visibili sulla lista principale in caso di modifica
    useEffect(() => {
        if (formData.id) {
            updateFormData({ ...formData, canceledAndCommunicated: false })
        }
    }, [startDate, endDate, cab, description, personType, fiscalCode, companyName, location, lastName, firstName, gender, birthDate, comment, included, identifier, relType, commType])


    const handlePersonTypeClick = event => {
        setPersonType(event.target.value);
        updateFormData({
            ...formData,
            personType: event.target.value
        });
        if (event.target.value === Constants.PF) {
            updateFormData({
                ...formData,
                personType: event.target.value,
                companyName: ''
            });
            setDisplayPf(true);
            setDisplayPnf(false);

        } else if (event.target.value === Constants.PNF) {
            updateFormData({
                ...formData,
                personType: event.target.value,
                birthDate: null,
                firstName: '',
                lastName: ''
            });
            setDisplayPf(false);
            setDisplayPnf(true);
        }
    };

    const handleInputAutocompile = (newLocation) => {
        setLocation(newLocation?.toUpperCase());
        if (province && province?.toUpperCase() === 'EE') {
            setLocation(newLocation?.toUpperCase() || '');
            updateFormData({
                ...formData,
                location: newLocation.toUpperCase(),
                province: province?.toUpperCase() || ''
            });
            return;
        }
        actions.getLocation(newLocation?.toUpperCase()).then(
            (loc) => {
                if (loc) {
                    setProvince(loc.province);
                    updateFormData({
                        ...formData,
                        location: newLocation.toUpperCase(),
                        province: loc.province || "EE"
                    });
                } else {
                    updateFormData({
                        ...formData,
                        location: newLocation.toUpperCase()
                    });
                }
            },
            (errors) => {
                console.log(errors);
            })
    };

    const handleInputBirthLocationAutocompile = (newLocation) => {
        setBirthLocation(newLocation?.toUpperCase());
        if (birthProv && birthProv?.toUpperCase() === 'EE') {
            setBirthLocation(newLocation?.toUpperCase() || '');
            updateFormData({
                ...formData,
                location: newLocation.toUpperCase(),
                province: birthProv?.toUpperCase() || ''
            });
            return;
        }
        actions.getLocation(newLocation.toUpperCase()).then(
            (loc) => {
                if (isNotEmpty(loc)) {
                    setBirthProv(loc.province.toUpperCase());
                    updateFormData({
                        ...formData,
                        location: newLocation.toUpperCase(),
                        province: loc.province.toUpperCase()
                    });
                } else {
                    updateFormData({
                        ...formData,
                        location: newLocation.toUpperCase()
                    });
                }
            },
            (errors) => {
                console.log(errors);
            })
    };


    const resetTypeDependentFields = () => {
        setErrorCab({ isValid: true, msg: "" });
        setShowCab(false);
        setCab(null);
        setErrorDescription({ isValid: true, msg: "" });
        setShowDescription(false);
        setErrorCab({ isValid: true, msg: "" })
        setDescription(null);
        setEndDateDisabled(false);
    }

    const handlerChangeRelationshipType = (e) => {
        const relationshipTypeId = parseInt(e.target.value);
        const relationshipType = relationshipTypes.find(el => el.id === relationshipTypeId);
        const cod = relationshipType?.code;
        setRelType(relationshipType);
        resetTypeDependentFields();

        if (Constants.codRelTypeCab.indexOf(cod) !== -1) {
            setShowCab(true);
        } else if (cod === "98") {
            setEndDate(null);
            setEndDateDisabled(true);
        } else if (cod === "99") {
            setShowDescription(true);
        }

        const updatedRelationship = {
            ...formData,
            relationshipType: relationshipType,
            endDate: endDate
        };
        updateFormData(updatedRelationship);
        setRelationship(updatedRelationship);
    }

    const handlerChange = (e) => {
        if (e.target.name === "includedInNextCommunication_" + relationship.id) {
            setIncluded(e.target.checked);
            updateFormData({
                ...formData,
                includedInNextCommunication: e.target.checked
            });
        } else if (e.target.name === "communicationType") {
            setCommType(e.target.value.trim());
            setIncluded(true);
            updateFormData({
                ...formData,
                coraCommunicationType: { id: parseInt(e.target.value) },
                includedInNextCommunication: true
            });
            formData.includedInNextCommunication = true;
        }
    };

    const handlerChangeStartDate = (date) => {
        setStartDate(date);
        setErrorStartDate({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            startDate: date
        });
    };

    const handlerChangeEndDate = (date) => {
        setErrorEndDate({ isValid: true, msg: "" });
        setEndDate(date);
        handleEndDateLogic(date);
    };

    const handlerChangeCab = (e) => {
        setCab(e.target.value);
        if (e.target.value) {
            setErrorCab({ isValid: true, msg: "" });
            updateFormData({
                ...formData,
                cab: e.target.value
            });
        }
    };

    const handlerChangeDescription = (e) => {
        setDescription(e.target.value.toUpperCase());
        if (isNotEmpty(e.target.value) && e.target.value.length > 0) {
            updateFormData({
                ...formData,
                description: e.target.value.toUpperCase()
            });
        }
    };

    const handlerChangeIdentifier = (e) => {
        setIdentifier(e.target.value?.toUpperCase().trim());
        setErrorIdentifier({ isValid: true, msg: "" });
        if (isNotEmpty(e.target.value) && e.target.value.length > 0) {
            updateFormData({
                ...formData,
                identifier: e.target.value?.toUpperCase().trim()
            });
        }
    };

    const handlerChangeIdResult = (e) => {
        const value = e.target.value?.toUpperCase().trim();
        setIdResult(value);
        updateFormData({
            ...formData,
            idResult: value
        });
    }

    const handlerChangeFiscalCode = (e) => {
        setFiscalCode(e.target.value.toUpperCase().trim());
        updateFormData({
            ...formData,
            fiscalCode: e.target.value.toUpperCase().trim()
        });
    };

    const handlerChangeCompanyName = (e) => {
        setCompanyName(e.target.value.toUpperCase());
        updateFormData({
            ...formData,
            companyName: e.target.value.toUpperCase()
        });
    };

    const handlerChangeLastName = (e) => {
        setLastName(e.target.value.toUpperCase());
        updateFormData({
            ...formData,
            lastName: e.target.value.toUpperCase()
        });
    };

    const handlerChangeFirstName = (e) => {
        setFirstName(e.target.value.toUpperCase());
        updateFormData({
            ...formData,
            firstName: e.target.value.toUpperCase()
        });
    };

    const handlerChangeGender = (e) => {
        setGender(e.target.value);
        updateFormData({
            ...formData,
            gender: e.target.value
        });
    };

    const handlerChangeBirthDate = (date) => {
        setBirthDate(date);
        updateFormData({
            ...formData,
            birthDate: date
        });
    };

    const handleProvinceChange = (e) => {
        setProvince(e.target.value.toUpperCase());
        updateFormData({
            ...formData,
            province: e.target.value.toUpperCase()
        });
    };

    const handleBirthProvChange = (e) => {
        setBirthProv(e.target.value.toUpperCase());
        updateFormData({
            ...formData,
            province: e.target.value.toUpperCase()
        });
    };

    const handleNoteChange = (e) => {
        const value = e.target.value;
        setComment(value);
        updateFormData({
            ...formData,
            comment: value
        });
    };

    const handleValidation = () => {
        resetError();
        let isValid = true;
        if (!relType) {
            setErrorRelType({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }
        if (!commType) {
            setErrorCommType({ isValid: false, msg: "Campo obbligatorio" })
            isValid = false;
        }
        if (personType === undefined || personType.length === 0) {
            setErrorPersonType({ isValid: false, msg: "Devi selezionare il tipo di titolare" });
            isValid = false;
        } else {
            if (personType !== undefined && personType === Constants.PNF) {

                if (companyName === undefined || companyName === "") {
                    setErrorCompanyName({ isValid: false, msg: "Campo obbligatorio" });
                    isValid = false;
                } else {
                    formData.companyName = companyName?.trim();
                }
                if (location === undefined || location === "") {
                    setErrorLocation({ isValid: false, msg: "Campo obbligatorio" });
                    isValid = false;
                } else {
                    formData.location = location?.trim();
                }
                if (province === undefined || province === "") {
                    setErrorProvince({ isValid: false, msg: "Campo obbligatorio" });
                    isValid = false;
                } else {
                    formData.province = province?.trim();
                }
            } else if (personType !== undefined && personType === Constants.PF) {

                if (lastName === undefined || lastName === "") {
                    setErrorLastName({ isValid: false, msg: "Campo obbligatorio" });
                    isValid = false;
                } else {
                    formData.lastName = lastName?.trim();
                }
                if (firstName === undefined || firstName === "") {
                    setErrorFirstName({ isValid: false, msg: "Campo obbligatorio" });
                    isValid = false;
                } else {
                    formData.firstName = firstName?.trim();
                }
                if (birthDate === undefined || birthDate === null) {
                    setErrorBirthDate({ isValid: false, msg: "Campo obbligatorio" });
                    isValid = false;
                }
                if (birthLocation === undefined || birthLocation === "") {
                    setErrorBirthLocation({ isValid: false, msg: "Campo obbligatorio" });
                    isValid = false;
                } else {
                    formData.location = birthLocation.trim()
                }
                if (birthProv === undefined || birthProv === "") {
                    setErrorBirthProv({ isValid: false, msg: "Campo obbligatorio" });
                    isValid = false;
                } else {
                    formData.province = birthProv?.trim();
                }
            }
        }
        if (relationship?.id && !identifier) {
            setErrorIdentifier({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }
        if (isEmpty(startDate)) {
            setErrorStartDate({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        } else {

            if (endDate && isBefore(endDate, startDate)) {
                setErrorEndDate({ isValid: false, msg: "La data fine non può essere minore della data inizio" });
                isValid = false;
            } else if (endDate && commType == 1) {
                const endDateMonthYear = getMonth(endDate)+'-'+getYear(endDate);
                const startDateMonthYear = getMonth(startDate)+'-'+getYear(startDate);
                if ((endDateMonthYear !== startDateMonthYear)) {
                    setErrorEndDate({ isValid: false, msg: "Data di fine non valida per comunicazione nuovo rapporto" });
                    isValid = false;

                }

            }
        }
        if (showCab && !cab) {
            setErrorCab({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        } else {
            formData.cab = cab?.trim();
        }
        if (showDescription && !description) {
            setErrorDescription({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        } else {
            formData.description = description?.trim();
        }

        if (endDateDisabled && commType == 2) {//impediamo la chiusura di un rapporto che non può essere chiuso
            setErrorCommType({ isValid: false, msg: "Chiusura non ammessa per rapporti di tipo 98" });
            isValid = false;
        }


        return isValid;

    };


    const handleEndDateLogic = (endDate) => {
        let today = isNotEmpty(delayedCommunicationDate) ? new Date(delayedCommunicationDate) : new Date();
        //datemin = inizio mese precedente; datemax:fine mese precedente
        let endDateEndOfDay = endOfDay(endDate); //utilizzo endOfDay di date-fns per prevenire casistiche in cui l'ora di endDate sia successiva all'ra di dateMax
        let dateMin = endOfDay(new Date(today.getFullYear(), (today.getMonth() - 1), 1));
        let dateMax = endOfDay(new Date(today.getFullYear(), (today.getMonth()), 0));
        // Se la data selezionata è tra datemin e datemax-> tipo comunicazione 5-Chiusura nei termini
        if (endDateEndOfDay >= dateMin && endDateEndOfDay <= dateMax) {
            setCommType("2");
            setIncluded(true);
            updateFormData({
                ...formData,
                coraCommunicationType: { id: 2 },
                includedInNextCommunication: true,
                endDate: endDate
            });
        } else if (endDateEndOfDay < dateMin) {
            //se la data selezionata è precedente al mese precedente -> tipo comunicazione 2=chiusura tardiva
            setCommType("3");
            setIncluded(true);
            updateFormData({
                ...formData,
                coraCommunicationType: { id: 3 },
                includedInNextCommunication: true,
                endDate: endDate
            });
        } else {
            updateFormData({
                ...formData,
                endDate: endDate
            });
        }
    }

    const resetError = () => {
        setErrorRelType({ isValid: true, msg: "" });
        setErrorStartDate({ isValid: true, msg: "" });
        setErrorEndDate({ isValid: true, msg: "" });
        setErrorIncluded({ isValid: true, msg: "" });
        setErrorCommType({ isValid: true, msg: "" });
        setErrorPersonType({ isValid: true, msg: "" });
        setErrorFiscalCode({ isValid: true, msg: "" });
        setErrorCompanyName({ isValid: true, msg: "" });
        setErrorLocation({ isValid: true, msg: "" });
        setErrorProvince({ isValid: true, msg: "" });
        setErrorLastName({ isValid: true, msg: "" });
        setErrorFirstName({ isValid: true, msg: "" });
        setErrorIdentifier({ isValid: true, msg: "" });
        setErrorBirthDate({ isValid: true, msg: "" });
        setErrorBirthLocation({ isValid: true, msg: "" });
        setErrorBirthProv({ isValid: true, msg: "" });

    };

    const handleFiscalCode = val => {
        actions.getBaseRegistryPersonByFiscalCode(val).then(
            (baseRegistry) => {
                if (isNotEmpty(baseRegistry)) {
                    if (baseRegistry.personType === Constants.PNF) {
                        setCompanyName(baseRegistry?.companyName || companyName);
                        setLocation(baseRegistry?.location?.location || location);
                        setProvince(baseRegistry?.location?.province || province);
                        updateFormData({
                            ...formData,
                            companyName: baseRegistry?.companyName || companyName,
                            location: baseRegistry?.location?.location || location,
                            province: baseRegistry?.location?.province || province,
                            includedInNextCommunication: included
                        });
                        setErrorFiscalCode({ isValid: true, msg: '' });
                    } else if (baseRegistry?.personType === Constants.PF) {
                        setLastName(baseRegistry?.lastName || lastName);
                        setFirstName(baseRegistry?.firstName || firstName);
                        setBirthDate(new Date(baseRegistry?.birthDate) || birthDate);
                        setGender(baseRegistry?.gender || gender);
                        setBirthLocation(baseRegistry?.location?.location || birthLocation);
                        setBirthProv(baseRegistry?.location?.province || birthProv);
                        updateFormData({
                            ...formData,
                            lastName: baseRegistry?.lastName || lastName,
                            firstName: baseRegistry?.firstName || firstName,
                            location: baseRegistry?.location?.location || birthLocation,
                            province: baseRegistry?.location?.province || birthProv,
                            birthDate: new Date(baseRegistry?.birthDate) || birthDate,
                            gender: baseRegistry?.gender || gender,
                            includedInNextCommunication: included
                        });
                        setErrorFiscalCode({ isValid: true, msg: '' });
                    }
                }
            },
            (errors) => {

                if (displayPf) {
                    try {
                        const cf = new CodiceFiscale(val).toJSON();
                        if (cf) {
                            setBirthDate(new Date(cf?.birthday) || null);
                            setGender(cf?.gender === "M" ? "MALE" : gender);
                            setBirthLocation(cf?.birthplace.toUpperCase() || birthLocation);
                            setBirthProv(cf?.birthplaceProvincia || birthProv);
                            updateFormData({
                                ...formData,
                                location: cf?.birthplace.toUpperCase() || birthLocation,
                                province: cf?.birthplaceProvincia || birthProv,
                                birthDate: new Date(new Date(cf?.birthday) || birthDate),
                                gender: cf?.gender === "M" ? "MALE" : gender,
                                includedInNextCommunication: included
                            });
                            setErrorFiscalCode({ isValid: true, msg: '' });
                        }
                    } catch (e) {
                        console.log("codice fiscale errato");
                        setErrorFiscalCode({ isValid: false, msg: 'Codice fiscale non valido' })
                    }
                }

            }
        ).then(() => {
            testForSimilarCoraRelationship(props.handleCloseModal);
        })
    };

    const handleFormSubmit = event => {
        event && event.preventDefault();

        if (handleValidation()) {
            if (!relationship?.id) {
                saveAndSetRelationship(formData, props.handleCloseModal);
            } else {
                updateRelationship(formData, handlerShowDetail);
            }
        }
    };


    const testForSimilarCoraRelationship = (onConfirm = () => { }, onCancel = () => { }) => {
        return actions.testForSimilarCoraRelationship(formData, props.subjectId)
            .then((res) => {
                if (res) {
                    PopupConfirm({
                        titleColor: titleColors.ALERT,
                        title: "RILEVATO RAPPORTO POTENZIALMENTE DUPLICATO",
                        component: () => {
                            return (
                                <>
                                    <Row>
                                        <Col className='text-center'>
                                            <strong> Stessa data inizio, stesso soggetto, stesso tipo rapporto</strong>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-center' >
                                            Ricordiamo che per chiudere (o aggiornare) rapporti già comunicati basta selezionare il rapporto dall'elenco e indicare SOLO la data di chiusura (non deve essere creato un nuovo rapporto) e impostare poi il tipo comunicazione 5=chiusura nei termini o 2=chiusura tardiva o aggiornamento.
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-center'>
                                            <strong> Se il rapporto NON è duplicato, premere CONFERMA, altrimenti CHIUDI SENZA SALVARE e il rapporto NON verrà registrato.</strong>
                                        </Col>
                                    </Row>
                                </>
                            )
                        },
                        confirm: "CHIUDI SENZA SALVARE",
                        cancel: "CONFERMA",
                        handleClickConfirm: onConfirm,
                        onClickCancel: onCancel
                    })

                }
            });
    }

    const onTabChange = () => {
        if (handleValidation()) {
            if (!relationship?.id) {

                saveAndSetRelationship(formData);
                return true


            }
        } else {
            return false;
        }
    };


    const saveAndSetRelationship = (formData, onSuccess = () => { }) => {
        actions.createCoraRelationship(formData).then(
            (res) => {
                if (res?.constructor === Object) {
                    setRelationship(res)
                    setIdentifier(res.identifier);
                    updateFormData({ ...formData, id: res.id, identifier: res.identifier })
                }
                props.getRelationships(props.subjectId);
                onSuccess();
            },
            (errors) => {
                if (errors.errorKey && errors.title) {
                    PopupError({ text: errors.title });
                } else {
                    PopupError({ text: Constants.APPLICATION_GENERIC_ERROR });
                }
                return false;
            });
    }



    const updateRelationship = (formData, onSuccess) => {
        formData.lastCommunicationError = null;
        actions.updateCoraRelationship(formData).then(
            () => {
                onSuccess();
            },
            (errors) => {
                if (errors.errorKey && errors.title) {
                    PopupError({ text: errors.title });
                } else {
                    PopupError({ text: Constants.APPLICATION_GENERIC_ERROR });
                }
            })
    }


    const handlerShowDetail = () => {
        props.getRelationships(props.subjectId);
        if (props.handlerShowDetail) {
            props.handlerShowDetail()
        }
        if (modal) {
            props.handleCloseModal();
        }
    };

    const prepareClonedRelationship = () => {
        let cloned = JSON.parse(JSON.stringify(formData));
        let cleanDelegates = [];
        for (let delegate of delegates) {
            cleanDelegates.push({
                ...delegate,
                id: null
            });
        }

        cloned.id = null;
        actions.createCoraRelationship(cloned).then((res) => {
            if (cleanDelegates.length > 0) {
                let promises = [];
                for (let index = 0; index < cleanDelegates.length; index++) {
                    const delegate = cleanDelegates[index];
                    delegate.coraRelationship = { id: res.id };
                    promises.push(actions.createCoraDelegate(delegate));
                }
                Promise.all(promises).then(() => {
                    props.getRelationships(props.subjectId);
                    PopupSuccess({ title: "Rapporto clonato con successo , il rapporto clonato è aperto nel dettaglio" });
                    props.handlerShowDetail();
                    props.onClone(res.id);
                })
            } else {
                props.getRelationships(props.subjectId);
                PopupSuccess({ title: "Rapporto clonato con successo, il rapporto clonato è aperto nel dettaglio" });
                props.handlerShowDetail();
                props.onClone(res.id);
            }
        }, (err) => { PopupError({ text: Constants.APPLICATION_GENERIC_ERROR }) })
    }

    return (
        <div className="bg-blue">
            <input defaultValue={relationship?.identifier || ''}
                className="form-control"
                name="identifier"
                hidden={true}
            />

            <div className="form-row">
                <div className="panel mt-3 col col-lg-12 col-xl-6 " >
                    <div className="primary">
                        <div className="inputSelect">
                            <RankedDropdownList
                                list={relationshipTypes}
                                rankedElements={rankedrelationshipTypes}
                                label={'Tipo di Rapporto'}
                                onChange={handlerChangeRelationshipType}
                                error={errorRelType}
                                name='relationshipType'
                                selectedValue={relType?.id}
                            ></RankedDropdownList>

                        </div>
                    </div>
                    <div className="secondary">
                        <div className="inputDate">
                            <DateField label={"Data Inizio"} date={startDate}
                                onChange={date => handlerChangeStartDate(date)}
                                error={errorStartDate} />
                        </div>
                        {!endDateDisabled && <div className="inputDate">
                            <DateField label={"Data Fine"} date={endDate} onChange={date => handlerChangeEndDate(date)}
                                error={errorEndDate} isDisabled={endDateDisabled} />
                        </div>}
                        {
                            showCab &&
                            <div className="inputText small">
                                <BwmInput name='cab'
                                    label='CAB'
                                    className='form-control'
                                    value={cab}
                                    onChange={e => handlerChangeCab(e)}
                                    error={errorCab}
                                    maxLength="5"
                                />
                            </div>
                        }
                        {
                            showDescription &&
                            <div className="inputText">
                                <BwmInput name='description'
                                    label='Descrizione'
                                    className='form-control'
                                    value={description}
                                    onChange={e => handlerChangeDescription(e)}
                                    error={errorDescription}
                                    maxLength="24"
                                />
                            </div>
                        }
                    </div>
                    <div className="secondary">
                        {
                            relationship?.identifier &&
                            <div
                                className="inputText w-100">
                                <BwmInput name='identifier'
                                    label='Codice Univoco'
                                    className='form-control'
                                    Icon={
                                        () => {
                                            if (disableIdentifierInput) {
                                                return (
                                                    <IUnlock fill={"#128186"} className={"padding-bottom-4px svg"} width="25" />
                                                )
                                            } else {
                                                return null
                                            }
                                        }}
                                    iconTip={disableIdentifierInput ? "Clicca per abilitare le modifiche" : ""}
                                    onIconClick={() => { disableIdentifierInput && setDisableIdentifierInput(false) }}
                                    value={identifier}
                                    disabled={disableIdentifierInput}
                                    onChange={e => handlerChangeIdentifier(e)}
                                    error={errorIdentifier}
                                    maxLength="50"
                                />

                            </div>
                        }

                    </div>
                </div>
                <div className={`panel mt-3 col col-lg-6 col-xl-4 col-sm-12 d-flex flex-column justify-content-center `}>
                    <div className="primary">
                        <div className="inputSelect">
                            <BwmSelect
                                options={commTypeList}
                                name='communicationType'
                                label='Tipo Comunicazione'
                                className='form-control'
                                onChange={handlerChange}
                                error={errorCommType}
                                value={commType}
                                showCode={true}
                            />
                        </div>
                    </div>
                    <div className="secondary">
                        <div className="inputCheck">
                            <BwmCheckbox
                                name={`includedInNextCommunication_${relationship?.id}`}
                                label='Incluso nella prossima comunicazione'
                                className='form-control'
                                value={included}
                                error={errorIncluded}
                                onClick={(e) => handlerChange(e)}
                            />
                        </div>
                    </div>
                    {
                        canceledAndCommunicated &&
                        <div className="secondary justify-content-center pt-2">
                            <Col className='text-danger' style={{ fontSize: '1.1rem' }}><strong> ULTIMA COMUNICAZIONE CANCELLAZIONE</strong></Col>
                        </div>

                    }
                </div>

                {
                    relationship?.id &&
                    <div className="panel-info col col-lg-6 col-xl-2 col-sm-12 pt-2">
                        <h6>ID: {relationship?.id}</h6>
                        {relationship?.codError && <h6 className={"text-red"}>COD. ERRORE: {relationship?.codError}</h6>}
                        <h5>ID ESITO:</h5>
                        <h6>
                            <input className={"input-esit"} value={idResult} type={"text"} onChange={e => handlerChangeIdResult(e)} />
                        </h6>
                        <h5>Data di creazione:</h5>
                        <h6>{formatDateForDisplay(relationship?.createDate)}</h6>
                        <h5>Data ultima modifica:</h5>
                        <h6>{relationship.modifyDate ? formatDateForDisplay(relationship.modifyDate) : '--/--/--'}</h6>

                    </div>
                }

            </div>
            <div className="form-row">
                <div className="tab-content relationship col-md-12 col-lg-12">
                    <Tabs>
                        <div id="tab-rel" label="Rapporto">
                            <div className="form-row">
                                <div className="col-md-2 col-lg-2">
                                    <PersonTypeField key={index} id={index} personType={personType} onClick={handlePersonTypeClick}
                                        error={errorPersonType} />
                                </div>
                                <div id="infoPnf" className={"col-md-7 col-lg-7" + (!displayPnf ? " d-none" : "")}>
                                    <div className="row">
                                        <div className="col-md-3 col-lg-3 me-5 offset-1">
                                            <BwmInput name='fiscalCode'
                                                label='Codice Fiscale'
                                                className='form-control'
                                                value={fiscalCode}
                                                onChange={e => handlerChangeFiscalCode(e)}
                                                error={errorFiscalCode}
                                                maxLength="16"
                                                onBlur={e => handleFiscalCode(e.target.value)} />
                                        </div>
                                        <div className="col-md-5 col-lg-5">
                                            <BwmInput name='companyName'
                                                label='Denominazione'
                                                className='form-control'
                                                value={companyName}
                                                onChange={e => handlerChangeCompanyName(e)}
                                                error={errorCompanyName}
                                                maxLength="60"
                                            />
                                        </div>
                                        <div className="col-md-5 col-lg-5 me-5 offset-1">
                                            <AutoCompileCustom
                                                label='Sede Legale (Comune) '
                                                id="location"
                                                handleInputAutocompile={handleInputAutocompile}
                                                filtro={relationshipLocations}
                                                value={location}
                                                error={errorLocation}
                                            />
                                        </div>
                                        <div className="col-md-3 col-lg-3">
                                            <ProvinceField province={province}
                                                onChange={e => handleProvinceChange(e)}
                                                error={errorProvince} />
                                        </div>
                                    </div>
                                </div>
                                <div id="infoPf" className={"col-md-7 col-lg-7" + (!displayPf ? " d-none" : "")}>
                                    <div className="form-row col-md-12 col-lg-12">
                                        <div className="col-md-3 col-lg-3">
                                            <BwmInput name='fiscalCode'
                                                label='Codice Fiscale'
                                                className='form-control'
                                                value={fiscalCode}
                                                onChange={e => handlerChangeFiscalCode(e)}
                                                error={errorFiscalCode}
                                                maxLength="16"
                                                onBlur={e => handleFiscalCode(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-md-3 col-lg-3 offset-1">
                                            <BwmInput name='lastName'
                                                label='Cognome'
                                                className='form-control'
                                                value={lastName}
                                                onChange={e => handlerChangeLastName(e)}
                                                error={errorLastName}
                                                maxLength="26"
                                            />
                                        </div>
                                        <div className="col-md-3 col-lg-3 offset-1">
                                            <BwmInput name='firstName'
                                                label='Nome'
                                                className='form-control'
                                                value={firstName}
                                                onChange={e => handlerChangeFirstName(e)}
                                                error={errorFirstName}
                                                maxLength="25"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row col-md-12 col-lg-12">
                                        <div className="col-md-1 col-lg-1">
                                            <BwmSelect
                                                options={Constants.genderList}
                                                name='gender'
                                                label='Sesso'
                                                className='form-control'
                                                onChange={handlerChangeGender}
                                                value={gender}
                                            />
                                        </div>
                                        <div className="col-md-3 col-lg-3 offset-1">
                                            <DateField label={"Data di Nascita"} date={birthDate}
                                                onChange={date => handlerChangeBirthDate(date)}
                                                error={errorBirthDate} name={"birthDate"} />
                                        </div>
                                        <div className={"col-md-3 col-lg-3 offset-1"}>
                                            <AutoCompileCustom
                                                label='Comune di Nascita'
                                                id="birthLocation"
                                                handleInputAutocompile={handleInputBirthLocationAutocompile}
                                                filtro={props.relationshipLocations}
                                                value={birthLocation}
                                                error={errorBirthLocation}
                                            />
                                        </div>
                                        <div className="col-md-2 col-lg-2 offset-1">
                                            <ProvinceField province={birthProv}
                                                onChange={e => handleBirthProvChange(e)}
                                                error={errorBirthProv} />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3 panel-note ">
                                    <label>Note</label>
                                    <textarea className={'form-control'}
                                        rows={3}
                                        cols={12}
                                        onChange={e => handleNoteChange(e)}
                                        value={comment}
                                        name="comment"
                                        maxLength={255}>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div handleCreateRelationship={!relationship.id ? onTabChange : undefined} id="tab-tit" className="d-none" label="Titolare Eff. e Delegato"  >
                            <DelegateForm
                                key={index}
                                relationship={relationship}
                                startDate={startDate}
                                endDate={endDate}
                                relationshipProvinces={relationshipProvinces}
                                relationshipLocations={relationshipLocations}
                                roles={roles}
                                subjectId={subjectid}
                                getRelationships={props.getRelationships}
                                updateDelegates={(delegatesArr) => {
                                    setDelegates(delegatesArr)
                                    updateFormData({ ...formData, canceledAndCommunicated: false })
                                }} />
                        </div>
                        <div handleCreateRelationship={!relationship.id ? onTabChange : undefined} id="tab-saldi" className="d-none" label="Saldi Annuali">
                            <BalanceForm
                                key={index}
                                relationship={relationship}
                                currencies={currencies}
                                subjectId={subjectid}
                                onSave={() => {
                                    updateFormData({ ...formData, canceledAndCommunicated: false })
                                }}
                            />
                        </div>
                    </Tabs>

                </div>

            </div>
            <div className="form-row mt-2 form-relationship-button">
                <button
                    className="btn btn-empty px-4 btn-sm"
                    onClick={handlerShowDetail}><IUndo className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />&nbsp; ANNULLA
                </button>
                <button className={'btn btn-empty'} hidden={modal}
                    onClick={() => { prepareClonedRelationship() }}>
                    <ICopy className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />
                    &nbsp; CLONA RAPPORTO</button>
                <button onClick={handleFormSubmit}
                    className="btn btn-new-rel px-5"><ISave className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; CONFERMA
                </button>
            </div>
        </div>

    )
}


