import React from 'react';
import { BtnDelete } from "../../../shared/BtnDelete";
import { BtnFill } from "../../../shared/BtnFill";
import { PopupDelete } from "../../../shared/PopupDelete";
import * as actions from "../../../../actions";
import { PopupSuccess } from '../../../shared/PopupSuccess';
import { PopupError } from '../../../shared/PopupError';
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants';

class ControllingPersonList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idDisabled: true,
            controllingPersonList: [],
            controllingPerson: {},
            errorControllingPerson: "",
            checked: new Map(),
            allChecked: false
        }
    }

    componentWillMount() {
        this.getAllCrsControllingPeople();
    }

    getAllCrsControllingPeople = () => {
        let relId = this.props.relationship?.id || this.props.relationshipId;
        if (relId) {
            actions.getAllCrsControllingPeople(this.props.subject.id, relId).then(resp => {
                this.setState({ controllingPersonList: resp })
                console.log("controllingPersonList: " + JSON.stringify(this.state.controllingPersonList));
                if (resp.length > 0) {
                    this.setState({ errorControllingPerson: "" });
                } else {
                    this.setErrorControllingPerson();
                }
                return resp.length;
            })
        }
    }

    countControllingPerson = () => {
        if (this.state.controllingPersonList)
            return this.state.controllingPersonList.length;
        else
            return 0;
    }

    setErrorControllingPerson() {
        this.setState({ errorControllingPerson: "E' obbligatorio inserire almeno una persona controllante per il tipo titolare selezionato " })
    }

    openPopupDelete = () => {
        let finalArray = []
        this.state.checked.forEach(e => finalArray.push(e.id))
        let cfs = [];
        this.state.checked.forEach(p => cfs.push(p.fiscalIdentifier))
        PopupDelete({
            operazione: `${finalArray.length} persone controllanti selezionate?`,
            handleClickConfirm: () => this.handlerDeleteControllingPerson(finalArray)
        });
    };


    onCheck = (e, person) => {
        let checked = new Map(this.state.checked);
        if (e.target.checked) {
            checked.set(person.id, person);
        } else {
            checked.delete(person.id);
        }
        this.setState({ checked: checked })
    }

    onAllCheck = (e) => {
        let checked = new Map();
        if (e.target.checked) {
            this.state.controllingPersonList.forEach(p => {
                checked.set(p.id, p);
            })
        }
        this.setState({ checked: checked })
    }

    handlerDeleteControllingPerson = (ids = []) => {
        let relId = this.props.relationship?.id || this.props.relationshipId;
        actions.deleteControllingPeople(this.props.subject.id, relId, ids)
            .then((res) => {
                this.getAllCrsControllingPeople();
                PopupSuccess();
            }
                , (err) => PopupError({ text: APPLICATION_GENERIC_ERROR }))
    }


    renderRows() {
        return this.state.controllingPersonList.map((cp, index) => {
            const idCheckbox = `cpDetail${cp.id}`;
            const isChecked = this.state.checked.has(cp.id)
            return (
                <div className="text-start row-table" id={index} >
                    <div className='col w-5 text-center div-td'>
                        <input type="checkbox"
                            name='rowCheckBox'
                            id={idCheckbox}
                            checked={isChecked}
                            onChange={(e) => this.onCheck(e, cp)} />
                    </div>
                    <div className="col w-20 div-td" onClick={() => this.props.handlerShowPersonForm(cp)}>{cp.fiscalIdentifier}</div>
                    <div className="col w-35 div-td" onClick={() => this.props.handlerShowPersonForm(cp)}>{cp.lastName + " " + cp.firstName}</div>
                    <div className="col w-40 div-td" onClick={() => this.props.handlerShowPersonForm(cp)}>{cp.controllingPersonType?.code + ":" + cp.controllingPersonType?.description}</div>
                    <div className="col w-20 div-td" onClick={() => this.props.handlerShowPersonForm(cp)}>{cp.countryCode + " - " + cp.country}</div>
                </div>
            )
        })
    }

    render() {
        let relId = this.props.relationship?.id || this.props.relationshipId;
        const allChecked = this.state.checked.size === this.state.controllingPersonList.length
        return (
            <div className="row">
                <div className="col-md-12 col-lg-12"><h5>Elenco persone fisiche <strong>controllanti</strong> il rapporto</h5></div>
                <div className="col-md-12 col-lg-12 form-row header-btn small align-items-center">
                    <BtnDelete handlerOnClick={() => this.openPopupDelete()}
                        isDisabled={this.state.checked.size === 0} />
                    <BtnFill text="+ AGGIUNGI" classCustom="ms-auto text-end me-2" handlerOnClick={() => this.props.handlerShowPersonForm()} />
                </div>
                <div className="col-md-12 col-lg-12 table-delegate">
                    <div className="col-md-12 col-lg-12 header-table p-0">
                        <div className="col w-5 text-center div-td">
                            <input name='tableCheckBox'
                                type="checkbox"
                                onChange={(e) => this.onAllCheck(e)}
                                checked={allChecked} />
                        </div>
                        <div className="col w-20 div-td">
                            TIN
                        </div>
                        <div className="col w-35 div-td">
                            Anagrafica
                        </div>
                        <div className="col w-40 div-td">
                            Tipo persona
                        </div>
                        <div className="col w-20 div-td">
                            Paese residenza fiscale
                        </div>
                    </div>

                    {relId ? this.renderRows() : ''}

                </div>
                <div className='form-group col-12'>
                    <div className="text-danger">{this.state.errorControllingPerson}</div>
                </div>
            </div>

        );
    }
}

export default ControllingPersonList;
