import {ARCO_GET_REGISTRIES_SUCCESS,ADD_HOLDERS,ADD_SIGNATORIES,GET_ARCO_OPERATIONS_SUCCESS,CLEAR_ARCO_OPERATIONS,ARCO_CACHE_SEARCH, ARCO_REGISTRY_CACHE_SEARCH,
    GET_ARCO_CONTRACTS_SUCCESS,GET_ARCO_CASUAL_OPERATIONS_SUCCESS,GET_ARCO_REGISTRY_DOCUMENT_SUCCESS, REMOVE_HOLDER, REMOVE_SIGNATORY, ARCO_CLEAR_SEARCH} from "../../actions/types";

const INITIAL_STATE = {
    arcoRegistries: {
        data: [],
        errors: []
    },
    operationSearchData: {
        pageNumber:0,
        last:false,
        searchTerm: '',
        searchField: '',
        pageSize: 20,
        totalPages: 0
    },
    registrySearchData: {
        pageNumber:0,
        last:false,
        searchTerm: '',
        searchField: '',
        pageSize: 20,
        totalPages: 0
    },
    arcoRegistriesOperations: {
        data: [],
        errors: []
    },
    arcoContracts: {
        data: [],
        errors: []
    },
    arcoCausalOperations: {
        data: [],
        errors: []
    },
    arcoRegistryDocument: {
        data: [],
        errors: []
    }
};

export const arcoRegistriesReducer = (state = INITIAL_STATE.arcoRegistries, action) => {
    let newObject = null;
    let index = -1;
    switch (action.type) {
        case ARCO_GET_REGISTRIES_SUCCESS:

            let payload = action.arcoRegistries.content.map(registry => {
                if(!registry.arcoRegistrySignatories){
                    registry.arcoRegistrySignatories=[];
                }
                if(!registry.arcoRegistryHolders){
                    registry.arcoRegistryHolders=[];
                }
                return registry;
            });
            return {...state, data: payload};
        case ADD_SIGNATORIES:
            newObject = {...state,
                ...state.data,               
            }
            for(let i=0;i<newObject.data.length; i++){
                let item = newObject.data[i];
                if(item.id === action.value.originalObject.id){
                    index = i;
                }
            }

            if(index === -1 && !action.value.originalObject.id){
                newObject.data = newObject.data.concat(action.value.originalObject);
                index = newObject.data.length - 1;
            }else {
                newObject.data[index] = action.value.originalObject;
            }

            if(!newObject.data[index].arcoRegistrySignatories){
                newObject.data[index].arcoRegistrySignatories= [];
            }
            newObject.data[index].arcoRegistrySignatories = newObject.data[index].arcoRegistrySignatories.concat(action.value.selectedItem);
            return newObject;
        case ADD_HOLDERS:
            newObject = {...state,
                ...state.data,               
            }
            for(let i=0;i<newObject.data.length; i++){
                let item = newObject.data[i];
                if(item.id === action.value.originalObject.id){
                    index = i;
                }
            }
            
            if(index === -1 && !action.value.originalObject.id){
                newObject.data = newObject.data.concat(action.value.originalObject);
                index = newObject.data.length - 1;
            } else {
                newObject.data[index] = action.value.originalObject;
            }

            if(!newObject.data[index].arcoRegistryHolders){
                newObject.data[index].arcoRegistryHolders= [];
            }
            newObject.data[index].arcoRegistryHolders = newObject.data[index].arcoRegistryHolders.concat(action.value.selectedItem);
            return newObject;
        case REMOVE_HOLDER:
            newObject = {...state,
                ...state.data,               
            }
            for(let i=0;i<newObject.data.length; i++){
                let item = newObject.data[i];
                if(item.id === action.value.originalObject.id){
                    index = i;
                }
            }

            if(index === -1 && !action.value.originalObject.id){
                newObject.data = newObject.data.concat(action.value.originalObject);
                index = newObject.data.length - 1;
            }
            if(!newObject.data[index].arcoRegistryHolders){
                newObject.data[index].arcoRegistryHolders= [];
            }

            newObject.data[index].arcoRegistryHolders = newObject.data[index].arcoRegistryHolders.filter(e => e.id !== action.value.selectedItem.id);
            return newObject;
        case REMOVE_SIGNATORY:
            newObject = {...state,
                ...state.data,               
            }
            for(let i=0;i<newObject.data.length; i++){
                let item = newObject.data[i];
                if(item.id === action.value.originalObject.id){
                    index = i;
                }
            }

            if(index === -1 && !action.value.originalObject.id){
                newObject.data = newObject.data.concat(action.value.originalObject);
                index = newObject.data.length - 1;
            }

            if(!newObject.data[index].arcoRegistrySignatories){
                newObject.data[index].arcoRegistrySignatories= [];
            }
            newObject.data[index].arcoRegistrySignatories = newObject.data[index].arcoRegistrySignatories.filter(e => e.id !== action.value.selectedItem.id);
            return newObject;
        default:
            return state;
    }
};



export const arcoRegistriesOperationsReducer = (state = INITIAL_STATE.arcoRegistriesOperations, action) => {
    switch (action.type) {
        case GET_ARCO_OPERATIONS_SUCCESS:
            let payload = action.arcoRegistriesOperations;
            return {...state, data: payload.content};
        case CLEAR_ARCO_OPERATIONS:
            return {...state, data: []}
        default:
            return state;
    }
};

export const arcoOperationSearchReducer = (state = INITIAL_STATE.operationSearchData, action) => {
    switch (action.type) {
        case ARCO_CACHE_SEARCH:
            return action.searchObject
        case ARCO_CLEAR_SEARCH:
            return INITIAL_STATE.operationSearchData;
        default:
            return state;
    }
}


export const arcoRegistrySearchReducer = (state = INITIAL_STATE.registrySearchData, action) => {
    switch (action.type) {
        case ARCO_REGISTRY_CACHE_SEARCH:
            return action.searchObject;
        default:
            return state;
    }
}

export const arcoRegistriesContractsReducer = (state = INITIAL_STATE.arcoContracts, action) => {
    switch (action.type) {
        case GET_ARCO_CONTRACTS_SUCCESS:
            return {...state, data: action.arcoContracts?.content};
        default:
            return state;
    }
};


export const arcoRegistriesCausalOperationsReducer = (state = INITIAL_STATE.arcoCausalOperations, action) => {
    switch (action.type) {
        case GET_ARCO_CASUAL_OPERATIONS_SUCCESS:
            return {...state, data: action.arcoCausalOperations};
        default:
            return state;
    }
};

export const arcoRegistryDocumentReducer = (state = INITIAL_STATE.arcoRegistryDocument, action) => {
    switch (action.type) {
        case GET_ARCO_REGISTRY_DOCUMENT_SUCCESS:
            return {...state, data: action.arcoRegistryDocument};
        default:
            return state;
    }
};
