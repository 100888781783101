export const PF = "FISICAL";
export const PNF = "LEGAL";

export const SI = "S";
export const NO = "N";

export const genderList = [
  { id: "FEMALE", description: "F" },
  { id: "MALE", description: "M" }
];
export const tabRelationship = ['Rapporto', 'Titalore Eff. e Delegato', 'Saldi Annuali', 'Esiti', 'Note'];
export const codRelTypeCab = ['01', '02', '03', '12', '13', '96', '97'];
export const exludedCommTypes = ["3", "6", "7", "8", "9", "C"];
//export const codRelTypeSaldiAnnuali = ['08','11','13','16','17','18','18','19','20','21','22'];
export const FORMAT_DATE = 'DD/MM/YYYY';
export const FORMAT_DATE_AND_TIME = 'DD/MM/YYYY HH:mm';
export const BASE_ECOMMERCE_URL = "https://www.starsoluzioni.it"
export const CORA_BUY_LINK = `${BASE_ECOMMERCE_URL}/product/cora-comunicazione-anagrafe-rapporti/` 
export const MY_APPS_LINK = `${BASE_ECOMMERCE_URL}/my-account/tuoi-software/` 
export const CRS_BUY_LINK = `${BASE_ECOMMERCE_URL}/product/crs-comunicazione-common-reporting-standard-ocse/`;
export const COMUREI_BUY_LINK = `${BASE_ECOMMERCE_URL}/product/comunicazione-comurei/`;
export const LICENSE_RENEW_LINK = `${BASE_ECOMMERCE_URL}/my-account/tuoi-software/` 
export const ARCO_BUY_LINK = `${BASE_ECOMMERCE_URL}/product/arco-antiriciclaggio/`;
export const BLUE_BUY_LINK = `${BASE_ECOMMERCE_URL}/product/blue-gestione-black-list-antiterrorismo/`;
export const CART_LINK =(baseEcommerceProductId=0,subjectsProductId=0,quantity=1)=>{
  let url = `${BASE_ECOMMERCE_URL}/cart/?add-to-cart=`;
  if(baseEcommerceProductId !== 0 && subjectsProductId !== 0 ){
    url +=`${baseEcommerceProductId},${subjectsProductId}:${quantity}`;
  } else if (baseEcommerceProductId == 0 && subjectsProductId !== 0){
    url +=`${subjectsProductId}&quantity=${quantity}`;
  } else if (baseEcommerceProductId !== 0  && subjectsProductId == 0){
    url +=`${baseEcommerceProductId}`;
  } else {
    url +=`${baseEcommerceProductId}`;
  }

  return url;
}
export const DAC7_BUY_LINK = "#";
export const APPLICATION_COMUREI = 'COMUREI';
export const APPLICATION_CORA = 'CORA';
export const APPLICATION_CRS = 'CRS';
export const APPLICATION_CORPRO = 'CORPRO';
export const APPLICATION_ARCO = 'ARCO';
export const APPLICATION_BLUE = 'BLUE';
export const APPLICATION_DAC7 = 'DAC7';

/**ottiene id prodotto ecommerce da nome applicazione */
export const getEcommerceProductId=(applicationName)=>{
  const app = APPLICATIONS.find(a=>a.description===applicationName);
  return app?.baseEcommerceProductId;
}

/**ottiene id prodotto ecommerce da nome applicazione */
export const getEcommerceSubjectProductId=(applicationName)=>{
  const app = APPLICATIONS.find(a=>a.description===applicationName);
  return app?.subjectsProductId;
}

export const APPLICATIONS = [
  {
    id: 1
    , baseEcommerceProductId: 0
    , subjectsProductId: 9740
    , buyLink: COMUREI_BUY_LINK
    , description: APPLICATION_COMUREI
  },
  {
    id: 2
    , baseEcommerceProductId: 388
    , subjectsProductId: 810
    , buyLink: CORA_BUY_LINK
    , description: APPLICATION_CORA
  },
  {
    id: 3
    , baseEcommerceProductId: 397
    , subjectsProductId: 809
    , buyLink: CRS_BUY_LINK
    , description: APPLICATION_CRS
  },
  {
    id: 4
    , buyLink: "https://www.starsoluzioni.it/product/corpro/"
    , description: APPLICATION_CORPRO
  },
  {
    id: 5
    , baseEcommerceProductId: 2517
    , subjectsProductId: 2516
    , buyLink: ARCO_BUY_LINK
    , description: APPLICATION_ARCO
  },
  {
    id: 6
    , baseEcommerceProductId: 2523
    , subjectsProductId: 2522
    , buyLink: BLUE_BUY_LINK
    , description: APPLICATION_BLUE
  },
  {
    id: 7
    , buyLink: DAC7_BUY_LINK
    , description: APPLICATION_DAC7
  },
]


export const LICENSE_TYPES = [
  { id: "DEMO", description: "DEMO" },
  { id: "BOUGHT", description: "ACQUISTATA" }
]

export const APPLICATION_GENERIC_ERROR = "Si è verificato un errore durante il salvataggio dei dati";

export const BLUE_DATABASES = [
  { name: 'EU', full: `European Commission's Sanctions List`, url: 'https://webgate.ec.europa.eu/europeaid/fsd/fsf/public/files/xmlFullSanctionsList_1_1/content?token=n0027nrq', website: 'https://webgate.ec.europa.eu/cas' },
  { name: 'TALEB', full: 'United Nations Security Council Sanctions List', url: 'https://scsanctions.un.org/resources/xml/en/consolidated.xml', website: 'https://www.un.org/securitycouncil/' },
  { name: 'USA', full: 'U.S. Department of the Treasury Sanctions List', url: 'https://www.treasury.gov/ofac/downloads/sdn.xml', website: 'https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/default.aspx' },
  { name: 'UK', full: 'HM Treasury Sanctions List', url: 'https://ofsistorage.blob.core.windows.net/publishlive/2022format/ConList.csv', website: 'https://www.gov.uk/government/publications/financial-sanctions-consolidated-list-of-targets/consolidated-list-of-targets' },
];

export const ATPEC_REGEXP = /^ATPEC\S+(?:\.p7m\.enc|\.run|\.p7m)$/;

export const CURRENCYFORMAT = new Intl.NumberFormat('de-DE');

export const SUBJECT_NOT_FOUND = "SUBJECT_NOT_FOUND";
export const MAX_SUBJECTS_EXCEEDED = "MAX_SUBJECTS_EXCEEDED";
export const DUPLICATE_ENTRY = "DUPLICATE_ENTRY";

export const HOLDER = 'HOLDER';
export const SIGNATORY = 'SIGNATORY';

export const FAKE_USER="fake_user";