import React, { useEffect, useRef } from "react";
import { generateUniqueKey } from "../../Utility";
import "./star-checkbox.css"
export function StarCheckbox({
    checked = false
    , indeterminate = false
    , label = ""
    , ariaLabel = label
    , onChange }) {

    const id = generateUniqueKey();
    const checkBoxRef= useRef();

    useEffect(()=>{
        checkBoxRef.current.indeterminate = indeterminate;
        checkBoxRef.current.checked = checked;
    },[indeterminate,checked])

    return (
        <div className="custom-control custom-checkbox d-flex justify-content-center">
            <input type="checkbox" className="custom-control-input"
                aria-label={ariaLabel} id={id} defaultChecked={checked}
                onChange={onChange}
                ref={checkBoxRef}
                 />

            <label className="custom-control-label
            star-checkbox" htmlFor={id}
            >{label}</label>

        </div>
    )
}