import React from 'react';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import CrsForm  from "./CrsForm";
import imgShowBlu from "../../../../styles/images/show_blu.png";
import { ReactComponent as ISort } from "../../../../styles/images/svgs/regular/sort.svg";
import { Link } from "react-router-dom";

class CrsSubjectList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            indexShow: 0,
            subjects: undefined,
            sortCompanyName: 1,
            sortFiscalCode: 1,
            sortOicr: 1,
            sortCode: 1,
            sortYear: 1
        }
    }

    onCheckClick = (event) => {
        if (event.target.checked)
            this.checkAll();
        else
            this.uncheckAll();
    };

    checkAll = () => {
        document.getElementById('checkboxAllId').checked = true;
        this.props.subjects.map(subject => {
            const checkboxDetailsId = `checkboxDetails${subject.id}`;
            const idCheckbox = `checkboxDetails${subject.id}`;
            if(document.getElementById(checkboxDetailsId)){
                document.getElementById(checkboxDetailsId).checked = true;
            }
            if(document.getElementById(idCheckbox)){
                document.getElementById(idCheckbox).checked = true;
            }
        });
        let rowIds = document.querySelectorAll('[id^="row-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.add("bg-cobalt", "bold");
            }
        }
        this.props.checkAllSubjects();

    };

    uncheckAll = () => {
        document.getElementById('checkboxAllId').checked = false;
        this.props.subjects.map(subject => {
            const checkboxDetailsId = `checkboxDetails${subject.id}`;
            const idCheckbox = `checkboxDetails${subject.id}`;
            if(document.getElementById(checkboxDetailsId)){
                document.getElementById(checkboxDetailsId).checked = false;
            }
            if(document.getElementById(idCheckbox)){
                document.getElementById(idCheckbox).checked = false;
            }
        });
        this.props.uncheckAllSubjects();
        let rowIds = document.querySelectorAll('[id^="row-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
    };

    onCheckBoxClick = (event, subject) => {
        if (event.target.checked) {
            this.props.checkSubject(subject);
            let element = document.getElementById("row-" + subject.id);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        } else {
            this.props.uncheckSubject(subject);
            document.getElementById('checkboxAllId').checked = false;
            let element = document.getElementById("row-" + subject.id);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }

    };

    showDetail = (index) => {
        if (index !== this.state.indexShow){
            this.setState({
                show: true,
                indexShow: index
            })
        } else {
            this.setState({
                show: !this.state.show,
                indexShow: index
            })
        }
    };



    renderRows(subjects) {
        return subjects.map((subject, index) => {
            const idCheckbox = `checkboxDetails${subject.id}`;
            const path = this.props.licenseExpired ? "#" : `/app/crs/relationship/${subject.id}`
            let expirationDate = subject.coraSubjectSid?.expirationDate ? new Date(subject.coraSubjectSid?.expirationDate).toLocaleDateString("en-GB") : "";
            const onRowClick =()=>{
                if(this.props.onRowClick){
                    this.props.onRowClick()
                }
            }
            return (
                <React.Fragment key={index}>
                    <div
                        className={'text-start row-table' + (this.state.show && this.state.indexShow === index ? ' bg-blue bold' : '')}
                        id={`row-${subject.id}`}>
                        <div className='col w-3 text-center div-td'>
                            <input type="checkbox"
                                id={idCheckbox}
                                onClick={e => this.onCheckBoxClick(e, subject)} />
                        </div>
                        <Link onClick={onRowClick} className={`col w-5 div-td text-truncate text-decoration-none text-body`}
                            to={path}>{subject.subjectCode}</Link>
                        <Link onClick={onRowClick} className={`col w-20 div-td text-truncate text-decoration-none text-body`}
                            to={path}>{subject.fiscalCode}</Link>
                        <Link onClick={onRowClick} className={`col w-40 div-td text-truncate text-decoration-none text-body`}
                            to={path}>{subject.companyName}</Link>
                        <Link onClick={onRowClick} className={`col w-10 div-td text-truncate text-decoration-none text-body`}
                            to={path}>
                            {subject.idFiscalOicrTrust}
                        </Link>
                        <Link onClick={onRowClick} className={`col w-10 div-td text-truncate text-decoration-none text-body`}
                            to={path}>
                            {subject.referenceYear}
                        </Link>
                        <Link onClick={onRowClick} to={path} className={`col w-8 div-td text-truncate text-decoration-none text-body text-center`}>{expirationDate}</Link>
                        <div className={'col w-5 div-td  text-center'} onClick={() => this.showDetail(index)}>
                            <img src={imgShowBlu}  />
                        </div>
                    </div>
                    <TransitionGroup component={null}>
                        {this.state.show && this.state.indexShow === index ?
                            <CSSTransition
                                in={this.state.show}
                                timeout={300}
                                classNames="visible"
                                unmountOnExit>
                                <div className="row-detail bg-blue">
                                    <div className="col-md-12 col-lg-12 text-start p-0">
                                        {<CrsForm
                                            {...this.props}
                                            showDetail={this.showDetail}
                                            subjectItem={subject}
                                            index={index}
                                            countries={this.props.countries}
                                            user={this.props.user}
                                            handleCloseModal={this.showDetail}
                                            disableCreateCommunication={this.props.licenseExpired}
                                            {...this.props}
                                        />}
                                    </div>
                                </div>
                            </CSSTransition>

                            : ''}
                    </TransitionGroup>
                </React.Fragment>
            )
        });
    };

    sortBy = (val) => {
        if (val) {
            let subjects = (this.state.subjects && this.state.subjects.length > 0) ? this.state.subjects : this.props.subjects;
            let subjectsOrdered = subjects;
            switch (val) {
                case "code":
                    // Sort by code
                    subjectsOrdered = subjects.sort((a, b) => (a.subjectCode > b.subjectCode) ? this.state.sortCode : ((b.subjectCode > a.subjectCode) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({ subjects: subjectsOrdered, sortCode: -this.state.sortCode });
                    break;
                case "companyName":
                    // Sort by companyName
                    subjectsOrdered = subjects.sort((a, b) => (a.companyName > b.companyName) ? this.state.sortCompanyName : ((b.companyName > a.companyName) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({ subjects: subjectsOrdered, sortCompanyName: -this.state.sortCompanyName });
                    break;
                case "fiscalCode":
                    // Sort by fiscalCode
                    subjectsOrdered = subjects.sort((a, b) => (a.fiscalCode > b.fiscalCode) ? this.state.sortFiscalCode : ((b.fiscalCode > a.fiscalCode) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({ subjects: subjectsOrdered, sortFiscalCode: -this.state.sortFiscalCode });
                    break;
                case "oicr":
                    // Sort by oicr
                    subjectsOrdered = subjects.sort((a, b) => (a.idFiscalOicrTrust > b.idFiscalOicrTrust) ? this.state.sortOicr : ((b.idFiscalOicrTrust > a.idFiscalOicrTrust) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({ subjects: subjectsOrdered, sortOicr: -this.state.sortOicr });
                    break;
                case "year":
                    // Sort by year
                    subjectsOrdered = subjects.sort((a, b) => (a.referenceYear > b.referenceYear) ? this.state.sortYear : ((b.referenceYear > a.referenceYear) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({ subjects: subjectsOrdered, sortYear: -this.state.sortYear });
                    break;
                default:
                    return;
            }
        }
    }

    render() {
        let subjectsData = (this.state.subjects) ? this.state.subjects : this.props.subjects;
        return (
            <React.Fragment>
                <div className="text-start header-table">
                    <div className='col w-3 text-center div-td'>
                        <input type="checkbox"
                            id='checkboxAllId'
                            onClick={this.onCheckClick} />
                    </div>
                    <div className='col w-5 div-td'><strong>Codice</strong>  <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("code")} /></div>
                    <div className='col w-20 div-td'><strong>Codice Fiscale</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("fiscalCode")} /></div>
                    <div className='col w-40 div-td'><strong>Soggetto Obbligato</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("companyName")} /></div>
                    <div className='col w-10 div-td'><strong>OICR</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("oicr")} /></div>
                    <div className='col w-10 div-td'><strong>Anno Riferimento</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("year")} /></div>
                    <div className='col w-8 div-td text-center' ><strong>Scad. Certificati</strong></div>
                    <div className='col w-5 div-td' style={{ textAlign: 'center' }}><strong>Dettaglio</strong></div>
                </div>
                {this.renderRows(subjectsData)}
            </React.Fragment>
        )
    }
}

export default CrsSubjectList
