import React, { useEffect, useState } from "react";
import "./ModalPasswordBytes.css";
import { Form } from "react-bootstrap";

export default function ModalPasswordBits({certificateDto,fiscalCode}) {
  const subjectFiscalCode = fiscalCode;
  const [dateClass, setDateClass] = useState("");
  const [bitsText, setBitsText] = useState("");
  const [bitsClass, setBitsClass] = useState("");
  const [cnText, setCnText] = useState("");
  const [cnClass, setCnClass] = useState("");


  useEffect(() => {
    setBitsText(updateTextBytes());
    setBitsClass(computeBitsColor());
    setCnClass(computeCnColor());
    setCnText(updateTextCn());
    setDateClass(computeDateColor());
  }, [])

  /**
   * Funzione che rielabora la data passata dalla props in un formato piu leggibile
   * @returns data in formato stringa
   */
  function elaborateDate() {
    let text = ""
    let format = new Date(certificateDto.endDate);
    text =format.toLocaleDateString();
    const isNotValid = format < new Date();
    if (isNotValid) {
      text = text + " - Certificati scaduti."
    }
    return (<Form.Text className={dateClass+ " d-block"}>
      <strong>{text}</strong>
      {isNotValid && 
      <>
      <br></br>
      <strong>
        {"Istruzioni per il rinnovo in "}<a target="_blank" href="https://www.starsoluzioni.it/verifica-certificati-firma-digitale/">{"questa pagina"}</a>
      </strong>
      </>
      }
      </Form.Text>);
  }
  /**
   * Questa funzione controlla i bits passati come input se corrispondono a 4096, 
   * se no restituisce un messaggio di errore
   * @returns funzione che ritorna un messaggio a seconda se i bits sono aggiornati o no
   */
  function updateTextBytes() {
    let bits = certificateDto.econdingBits.trim();
    if (bits.includes("4096")) {
      return bits/*  + " (nessun aggiornamento richiesto)" */;
    }
    return bits + " (aggiornamento richiesto entro 31/01/2023)";
  }

  /**
 * Questa funzione controlla se il soggetto nel certificato corrisponde al codice fiscale inserito dall'utente.
 * @returns il messaggio a schermo
 */
  function updateTextCn() {
    if (!computeCn()) return "Codice fiscale inserito non corretto."

    return "Codice fiscale corretto";
  }


  /**
   * Questa funzione ritorna un nome di una proprieta css,
   * a seconda se il messaggio e accettabile o meno.
   * In questo modo si potra cambiare lore in modo piu automatico nelle scritte
   * @returns a seconda del messaggio una proprieta css differente 
   */
  function computeBitsColor() {
    let bits = certificateDto.econdingBits.trim();
    if (bits.includes("4096")) {
      return "correct";
    }
    return "error";
  }

  /**
  * Questa funzione ritorna un nome di una proprieta css,
  * a seconda se il messaggio e accettabile o meno.
  * In questo modo si potra cambiare lore in modo piu automatico nelle scritte
  * @returns a seconda del messaggio una proprieta css differente 
  */
  function computeCnColor() {
    if (computeCn()) return "correct";
    return "error";

  }

  /**
   * Questa funzione controlla se il soggetto nel certificato corrisponde al codice fiscale inserito dall'utente.
   * @returns vero se c'è corrispondenza, altrimenti falso
   */
  function computeCn() {
    if (!certificateDto.cn) return false;

    return certificateDto.cn.includes(subjectFiscalCode)
  }

  function computeDateColor() {

    if (new Date(certificateDto.endDate) < new Date()) {
      return 'error';
    }
    return 'correct';
  }


  return (

        <Form>
          <Form.Group className="mb-3">
          <h4>{certificateDto.certificateType}</h4>
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Label>DATA EMISSIONE:</Form.Label>
            <Form.Text className={"normal d-block"}>{new Date(certificateDto.startDate).toLocaleDateString()}</Form.Text>
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Label>DATA SCADENZA:</Form.Label>
            {elaborateDate()}
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Label>PASSWORD:</Form.Label>
            <Form.Text className={"correct d-block"}>{"CORRETTA"}</Form.Text>
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Label>CHIAVE PUBBLICA:</Form.Label>
            <Form.Text className={bitsClass + " d-block"}>{bitsText}</Form.Text>
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Label>Soggetto:</Form.Label>
            <Form.Text className={cnClass +" d-block"}>{certificateDto.cn.replace("-FOL", '')}{cnText && <div>{cnText}</div>}</Form.Text>
          </Form.Group>
        </Form>
  );
}
