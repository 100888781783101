import React from "react";
import { Row } from "react-bootstrap";

export function SelectableElement({  value = "", onChange = (value) => { } , defaultChecked = false, label = ""}) {

    return (

        <Row className="ms-4 mt-1 mb-1 row align-items-center">
            <input
                className="col"
                type="checkbox"
                value={value}
                defaultChecked={defaultChecked}
                onClick={() => {
                    onChange(value)
                }}
            />
            <label className=" col mb-0" htmlFor={label}>
                {label}
            </label>
        </Row>
    )
}