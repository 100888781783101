import React from 'react';
import { Form } from 'react-bootstrap';

/**
 * Permette di costruire un dropdown con determinati elementi in cima alla lista
 * @param {*} props
 * @param props.list lista di oggetti {id, code, description}
 * @param props.rankedElements come la lista di oggetti, verranno inseriti in cima 
 * @param props.onChange callback su selezione, con parametro evento
 * @param props.error oggetto errore {isValid, msg}
 * @param selectedValue item selezionato, per id
 * @returns 
 */
export function RankedDropdownList(props) {

    const list = props.list || [];
    const rankedElements = props.rankedElements || [];
    const label = props.label || "";
    const error = props.error;
    const selectedValue = props.selectedValue || null;
    const disabled = props.disabled;

    const renderOptions = () => {

        const completeList = [...new Set([...rankedElements, ...list])]
        let selectedIndex = completeList.findIndex(el => el.id === selectedValue);
        selectedIndex = selectedIndex != -1 ? selectedIndex : 0; 
        return completeList.map((el, index) => {

            if (rankedElements.length && index === rankedElements.length - 1) {
                return <>
                    <option selected={index === selectedIndex} value={el.id}>{`${el.code && `${el.code} - `}${el.description}`}</option>
                    <option value={null} disabled>________________________________________________________________________</option>
                </>
            } else {
                return <option key={`ranked-option-${index}`} selected={index === selectedIndex} value={el.id}>{`${el.code} - ${el.description}`}</option>
            }
        });


    }

    return (
        <div className={`form-group`}>
            <label>{label}</label>
            <div className='input-group'>


                <Form.Control disabled={disabled} className='form-control custom-select-arrow' onChange={(e) => props.onChange(e)} as="select">
                    <option value={null}></option>
                    {
                        renderOptions()
                    }
                </Form.Control>
            </div>
            {(error && !error.isValid) &&
                <div className="text-danger">{error.msg}</div>
            }
        </div>
    )
}