import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { BtnEmpty } from "../../shared/BtnEmpty";
import { BtnFill } from "../../shared/BtnFill";

import { deleteContactHistory, deleteContactInfo, deleteContactNumber, getContactInfo, saveContactHistories, saveContactHistory, saveContactInfo, saveContactNumber, saveContactNumbers } from "../../../actions";
import { BwmInput } from "../../shared/form/BwmInput";
import { PRIORITIES } from "./PRIORITY";
import { formatDateForDisplay, formatTimeFromDate, joinDateAndTime } from "../../shared/Utility";
import { DateField } from "../../shared/form/DateField";
import { ICopy, IDelete, IEdit, IPhoneAlt, IPlus, ISave } from "../../../styles/icons";
import { PopupError } from "../../shared/PopupError";
import { APPLICATION_GENERIC_ERROR } from "../../../config/Constants";
import { SimpleSpinner } from "../../shared/spinner/Spinner";
import { PopupConfirm, titleColors } from "../../shared/PopupConfirm";
import { ContactHistoryForm } from "./ContactHistoryForm";

import "./contactInfoModal.css"
export const baseContact = { id: null, denomination: "", phone: "" };
export function ContactInfoModal({
    show = false
    , onClose = () => { }
    , contactInfo = undefined
    , onSave = () => { }
    , selectedContactNumber
    , defaultCaller = "Valentina"
}) {

    const [remoteContactInfo, setRemoteContactInfo] = useState(null);


    const baseHistoryObject = { id: null, date: new Date(), contact: "", phone: "", caller: defaultCaller, notes: "" };
    const [showNewHistoryForm, setShowNewHistoryForm] = useState(false);
    const [newHistory, setNewHistory] = useState(baseHistoryObject);



    const [contactHistories, setContactHistories] = useState([])


    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(selectedContactNumber){
            setShowNewHistoryForm(true);
            const newHistory = { ...baseHistoryObject, contact: selectedContactNumber?.denomination, phone: selectedContactNumber?.phone };
            setNewHistory(newHistory);
        }

        
        return () => { }

    }, [])

    useEffect(() => {
        if (contactInfo?.id) {
            fetchContactInfo(contactInfo)
        } else {
            setRemoteContactInfo({...contactInfo, contactNumbers:[baseContact]});
        }

        return () => {
            setRemoteContactInfo(null);
        }
    }, [contactInfo]);


    const fetchContactInfo = (contactInfo) => {
        setIsLoading(true);
        getContactInfo(contactInfo?.id)
            .then((res) => {

                let contacts = res.contactNumbers;
                if(!res.contactNumbers?.length){
                    contacts.unshift(baseContact);
                }
                setRemoteContactInfo({...res,contactNumbers:contacts});
                setContactHistories([...res.contactHistories]);
            })
            .then(() => setIsLoading(false));;
    }

    const handleDelete = () => {
        if (remoteContactInfo?.id) {
            PopupConfirm({
                titleColor: titleColors.ALERT,
                title: "Eliminazione contatto",
                text: "Si vuole eliminare il contatto? L'operazione è irreversibile",
                handleClickConfirm: () => {
                    deleteContactInfo(remoteContactInfo.id).then((res) => onSave(), (err) => PopupError({ text: APPLICATION_GENERIC_ERROR }));

                }
            })
        }
    }

    const onChangeValue = (field, value) => {
        let contactInfo = { ...remoteContactInfo };
        contactInfo[field] = value;
        setRemoteContactInfo(contactInfo)

    }

    const handleChangeContactHistory = (index, field, value) => {
        let list = contactHistories;
        list[index] = { ...list[index], [field]: value };

        setContactHistories([...list]);
    }

    const handleChangeContactNumber = (index, field, value) => {
        let list = remoteContactInfo.contactNumbers;
        list[index] = { ...list[index], [field]: value };

        setRemoteContactInfo({...remoteContactInfo, contactNumbers: list})
    }


    const handleSave = (contactInfo, afterSave = (result) => { }) => {


        saveContactInfo(contactInfo).then((res) => {
            //salvo tutte le modifiche allo storico e contatti
            const numbersToSave = remoteContactInfo.contactNumbers.map(c => {
                c.contactInfo = res;
                return c;
            });
            let historiesToSave = contactHistories.map(c => {
                c.contactInfo = res;
                return c;
            });
            if(newHistory.notes){
                historiesToSave.push({ ...newHistory, contactInfo: res });
            }
            saveContactNumbers(numbersToSave).then((res) => { console.log("numeri salvati") }, (err) => { console.log("numeri NON salvati") })
                .then(() => {
                    saveContactHistories(historiesToSave).then((res) => { console.log("storici salvati") }, (err) => { console.log("storici NON salvati") })
                        .then(() => {
                            afterSave(res);
                        })

                })


        }, (err) => {
            PopupError({ text: APPLICATION_GENERIC_ERROR })
        });
    }


    const handleAddContactNumber = (contact) => {
        let contacts = remoteContactInfo.contactNumbers;
        contacts.unshift(contact);
        setRemoteContactInfo({...remoteContactInfo, contactNumbers: contacts})
    }



    const handleDeleteContactNumber = (index, contact) => {
        if (contact?.id) {

            PopupConfirm({
                titleColor: titleColors.ALERT,
                text: "Si desidera eliminare il contatto selezionato?",
                handleClickConfirm: () => {
                    setIsLoading(true);
                    deleteContactNumber(contact.id).then(res => {
                        let contacts = remoteContactInfo.contactNumbers.filter(c => c.id !== contact.id);//elimino i contatti in memoria invece di ricaricarli per preservare eventuali contatti non salvati
                        setRemoteContactInfo({...remoteContactInfo, contactNumbers: contacts})
                        setIsLoading(false);
                    });

                }
            })

        } else {
            let contacts = remoteContactInfo.contactNumbers;
            contacts.splice(index, 1);
            setRemoteContactInfo({...remoteContactInfo, contactNumbers: contacts})
        }

    }

    const handleDeleteContactHistory = (contactHistory) => {
        PopupConfirm({
            titleColor: titleColors.ALERT,
            text: "Si desidera eliminare la telefonata?",
            handleClickConfirm: () => {
                setIsLoading(true);
                deleteContactHistory(contactHistory.id).then(res => {
                    let histories = remoteContactInfo.contactHistories.filter(c => c.id !== contactHistory.id);
                    setRemoteContactInfo({...remoteContactInfo, contactHistories: histories})
                    setContactHistories(histories)
                    setIsLoading(false);
                });

            }
        })
    }


    const renderPriorityOptions = () => {
        return PRIORITIES.map((p, i) => {
            return <option key={`priority-${i}`} value={p.id}>
                {p.description}
            </option>
        })
    }


    return (
        <Modal
            show={show}
            onHide={onClose}
            size={"lg"}
            aria-labelledby="contained-modal-title-vcenter"
            className="big-modal mt-0 mb-1"
            centered
        >
            <div className="bar"></div>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h2>{remoteContactInfo?.denomination || remoteContactInfo?.login || "Nuovo contatto"}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-blue px-2">
                <Row>
                    <Col>
                        <BwmInput
                            name='denomination'
                            label='Nominativo'
                            className='form-control'
                            value={remoteContactInfo?.denomination}
                            onChange={e => { onChangeValue(e.target.name, e.target.value) }}
                        />
                    </Col>
                    <Col>
                        <BwmInput
                            name='login'
                            label='Login'
                            className='form-control'
                            value={remoteContactInfo?.login}
                            onChange={e => { onChangeValue(e.target.name, e.target.value) }}
                        />
                    </Col>
                    <Col>
                        <BwmInput
                            name='email'
                            label='Email'
                            className='form-control'
                            value={remoteContactInfo?.email}
                            onChange={e => { onChangeValue(e.target.name, e.target.value) }}
                            Icon={()=><ICopy fill={"#128186"} className={"padding-bottom-4px svg"} width="15"/>}
                            onIconClick={() => navigator.clipboard.writeText(remoteContactInfo?.email)}
                        />
                    </Col>
                    <Col >

                        <BwmInput
                            name='pwd'
                            label='Password'
                            type={"password"}
                            className='form-control'
                            value={remoteContactInfo?.pwd}
                            onChange={e => { onChangeValue(e.target.name, e.target.value) }}
                            Icon={()=><ICopy fill={"#128186"} className={"padding-bottom-4px svg"} width="15"/>}
                            onIconClick={() => navigator.clipboard.writeText(remoteContactInfo?.pwd)}
                        />
                    </Col>
                    <Col className="form-group">
                        <label>Priorità: </label>
                        <Form.Control className='form-control'
                            name="callPriority"
                            onChange={(e) => { onChangeValue(e.target.name, e.target.value) }} as="select"
                            value={remoteContactInfo?.callPriority}
                        >
                            {
                                renderPriorityOptions()
                            }
                        </Form.Control>
                    </Col>
                </Row>
                <Row>
                    <Col className="panel-note ">
                        <label>Note</label>
                        <textarea className={'form-control'}
                            name="notes"
                            rows={2}
                            cols={12}
                            maxLength={255}
                            onChange={e => { onChangeValue(e.target.name, e.target.value) }}
                            value={remoteContactInfo?.notes || ""}>
                        </textarea>

                    </Col>
                    <Col className="form-group">

                        <ContactList
                            contactInfo={remoteContactInfo}
                            onChangeRow={(index, field, value) => handleChangeContactNumber(index, field, value)}
                            onAdd={(newContact) => { handleAddContactNumber(newContact) }}
                            onDelete={(index, contact) => { handleDeleteContactNumber(index, contact) }}
                            onSelect={(contact) => { setShowNewHistoryForm(true); setNewHistory({ ...baseHistoryObject, contact: contact.denomination, phone: contact.phone }) }}
                            isLoading={isLoading}

                        />
                    </Col>
                    <Col className="form-group">
                        <label>Licenze: </label>
                        <div style={{ overflowX: "hidden", overflowY: "scroll", height: 150, border: "solid black 1px" }}>
                            <Row>
                                <Col>
                                    <label>
                                        App e soggetti
                                    </label>
                                </Col>
                                <Col>
                                    <label>
                                        Tipo Licenza
                                    </label>
                                </Col>
                                <Col>
                                    <label>
                                        Scadenza
                                    </label>
                                </Col>
                            </Row>
                            {
                                remoteContactInfo?.contactApplications && remoteContactInfo?.contactApplications.map(ca => {
                                    return <Row key={"applications-" + ca.id}>
                                        <Col>
                                            {`${ca.application?.name} x${ca.maxSubjects}`}
                                        </Col>
                                        <Col>
                                            {ca.licenseType}
                                        </Col>
                                        <Col>
                                            {formatDateForDisplay(ca.expiration)}
                                        </Col>
                                    </Row>
                                })
                            }

                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col className="form-group">
                        <div style={{ overflowY: "scroll", overflowX: "hidden", height: 400 }}>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <label>Storico Telefonate </label>

                                    {isLoading && <SimpleSpinner size={16} />}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={1} className="d-flex align-items-center justify-content-between me-4">
                                    <label>Data</label>
                                    <label>Ora</label>
                                </Col>

                                <Col lg={2}>
                                    <label>Contatto</label>
                                </Col>
                                <Col lg={1}>
                                    <label>Numero</label>
                                </Col>
                                <Col lg={1}>
                                    <label>Chiamato Da</label>
                                </Col>
                                <Col>
                                    <label>Note</label>
                                </Col>
                            </Row>
                            {showNewHistoryForm &&
                                <ContactHistoryForm key={"history-form-new"} historyObject={newHistory}
                                    onChange={(changed) => setNewHistory(changed)} />
                            }
                            {HistoryList({
                                elements: contactHistories,
                                onChangeRow: (index, field, value) => handleChangeContactHistory(index, field, value),
                                onDelete: (contactHistory) => { handleDeleteContactHistory(contactHistory) },
                                isLoading: isLoading

                            })}
                        </div>

                    </Col>

                </Row>

            </Modal.Body>
            <Modal.Footer>
                <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={onClose} />
                {
                    remoteContactInfo?.id &&
                    <button className="btn btn-primary btn-new-rel px-5 btn-sm ms-3"
                        onClick={() => handleDelete()} >
                        <IDelete width="15" />&nbsp;
                        ELIMINA CLIENTE</button>
                }


                <BtnFill text="SALVA E CHIUDI" classCustom="float-end text-end"
                    handlerOnClick={() => handleSave(remoteContactInfo, (result) => {
                        onSave(result)
                    })} />
            </Modal.Footer>
        </Modal>
    )
}



function HistoryList({
    elements
    , onDelete
    , isLoading = false
    , onChangeRow
}) {

    const [localElements, setLocalElements] = useState([])
    useEffect(() => {
        if (elements) {
            setLocalElements(elements);
        }

    }, [elements])


    const onChange = (index, field, value) => {
        onChangeRow(index, field, value)
    }

    return (
        <div >
            {

                localElements.map((ch, index) => {
                    return <Row key={"history" + ch.id} className="align-items-center">

                        <Col lg={1} className="d-flex align-items-center justify-content-between me-4">
                            <DateField
                                date={ch.date}
                                onChange={(date) => onChange(index, "date", date)}
                            />
                            <BwmInput
                                type={"time"}
                                className='form-control'
                                value={formatTimeFromDate(ch.date)}
                                onChange={(e) => onChange(index, "date", joinDateAndTime(ch.date, e.target.value))}
                            />
                        </Col>

                        <Col lg={2}>
                            <BwmInput
                                className='form-control'
                                value={ch.contact}
                                onChange={(e) => onChange(index, "contact", e.target.value)}
                            />
                        </Col>
                        <Col lg={1}>
                            {<BwmInput
                                className='form-control'
                                value={ch.phone}
                                onChange={(e) => onChange(index, "phone", e.target.value)}
                                Icon={() => <CallIcon phone={ch.phone} />}
                            />}
                        </Col>
                        <Col lg={1}>
                            <BwmInput
                                className='form-control'
                                value={ch.caller}
                                onChange={(e) => onChange(index, "caller", e.target.value)}
                            />
                        </Col>
                        <Col >
                            <BwmInput
                                className='form-control'
                                value={ch.notes}
                                onChange={(e) => onChange(index, "notes", e.target.value)}
                                Icon={() => <button onClick={() => onDelete(ch)} disabled={isLoading} ><IDelete width="17" fill={"#128186"} /></button>}
                            />
                        </Col>

                    </Row>
                })
            }
        </div>
    )


}


export function CallIcon({
    phone
}) {
    return <a target="_blank" rel="noopener noreferrer" href={`https://starinfostudio.my3cx.it/webclient/#/people?phone=${phone}`}>
        <IPhoneAlt fill={"#128186"} className={"padding-bottom-4px svg"} width="15" /></a>
}

function ContactList({
    contactInfo
    , onChangeRow
    , onDelete
    , onAdd
    , onSelect
    , isLoading = false
}) {
    const newContact = baseContact;
    const onChange = (index, field, value) => {
        onChangeRow(index, field, value)
    }



    return (
        <>
            <label>Contatti: </label>

                <button className="ms-2 mb-1 btn btn-new-rel btn-sm " onClick={() => { onAdd(newContact) }}>
                    AGGIUNGI</button>
            

            {isLoading && <SimpleSpinner size={15} />}

            <div style={{ overflowX: "hidden", overflowY: "scroll", height: 150, border: "solid black 1px" }} className="px-1">
                <Row className="d-flex align-items-center">
                    <Col>

                    </Col>
                </Row>
                <Row className="d-flex align-items-center">
                    <Col lg={7}>
                        <label>Nominativo</label>

                    </Col>
                    <Col lg={5}>
                        <label>Numero</label>
                    </Col>
                </Row>
                { contactInfo?.contactNumbers.map((item, index) => {

                    return <Row key={index} className="d-flex align-items-center">
                        <Col lg={7}>
                            <BwmInput
                                className='form-control'
                                value={item?.denomination}
                                placeholder={"Inserisci nominativo"}
                                onChange={(e) => onChange(index, "denomination", e.target.value)}
                            />

                        </Col>
                        <Col lg={5}>
                            <BwmInput
                                className='form-control'
                                value={item?.phone}
                                onChange={(e) => onChange(index, "phone", e.target.value)}
                                placeholder={"Inserisci numero"}
                                Icon={() => <span> <button className="py-0" onClick={() => onSelect(item)}><CallIcon phone={item.phone} /></button>
                                    <button className="py-0" onClick={() => onDelete(index, item)} disabled={isLoading}><IDelete width="14" /></button></span>}
                            />

                        </Col>

                    </Row>
                }




                )}

            </div>
        </>
    )
}