import React from 'react';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import ComureiForm from "./ComureiForm";
import CommunicationsHistoryModal from "../communications/CommunicationsHistoryModal";
import {ReactComponent as ISort} from "../../../../styles/images/svgs/regular/sort.svg";
import * as Constants from "../../../../config/Constants";

class ComureiSubjectList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            indexShow: undefined,
            subjects: undefined,
            sortComureiSubjectType: 1,
            sortCompanyName : 1,
            sortFiscalCode : 1,
            sortComureiCommunicationType : 1,
            sortComureiSubjectResponsible : 1
        }
    }


    showDetail = (index) => {
        if (index !== this.state.indexShow){
            this.setState({
                show: true,
                indexShow: index
            })
        } else {
            this.setState({
                show: !this.state.show,
                indexShow: index
            })
        }
    };
    closeDetail = () => {
        this.setState({
            show: !this.state.show
        })
    };

    renderRows(subjects) {
        return subjects.map((subject, index) => {

            let section = "";
            if (subject.registryReason) {
                section = section + "Indagini Bancarie/";
            }

            if (subject.monitoringReason) {
                section = section + "Monitoraggio Fiscale/";
            }

            if (subject.factaReason) {
                section = section + "FATCA/CRS";
            }

            if (section.endsWith("/")) {
                section = section.substring(0, section.length - 1);
            }
            return (
                <React.Fragment key={"comurei-subject"+subject.id}>
                    <div className={'text-start row-table' + (this.state.show && this.state.indexShow === index ? ' bg-blue bold' : '')} id={`row-${subject.id}`}>
                        {/* <div className='col w-5 text-center div-td'>
                            <input type="checkbox"
                                   id={idCheckbox}
                                   onClick={e => this.onCheckBoxClick(e, subject)}/>
                        </div> */}
                        <div className='col w-40 div-td text-truncate' onClick={() => this.showDetail(index)}>{subject.comureiSubjectType.code} - {subject.comureiSubjectType.description}</div>
                        <div className='col w-20 div-td text-truncate' onClick={() => this.showDetail(index)}>{subject.comureiCommunicationType.code} - {subject.comureiCommunicationType.description}</div>
                        <div className='col w-15 div-td text-truncate' onClick={() => this.showDetail(index)}><span className="notranslate">{subject.personType ? (subject.personType === Constants.PF ? subject.lastName + " " + subject.firstName : subject.companyName) :subject.companyName}</span></div>
                        <div className='col w-15 div-td text-truncate' onClick={() => this.showDetail(index)}>{subject.fiscalCode}</div>
                        <div className='col w-15 div-td text-truncate' onClick={() => this.showDetail(index)}><span className="notranslate">{subject?.comureiSubjectResponsible?.lastName} {subject?.comureiSubjectResponsible?.firstName}</span></div>
                        <div className='col w-15 div-td text-truncate' onClick={() => this.showDetail(index)}><span className="notranslate">{section}</span></div>
                        <div className='col w-5 div-td text-truncate d-flex justify-content-around pe-4 ps-4'>
                            <CommunicationsHistoryModal subjectId={subject.id} subjectName={subject.personType === Constants.PNF ? subject.companyName : subject.lastName + " " + subject.firstName}/>
                        </div>
                    </div>
                    <TransitionGroup component={null}>
                        {this.state.show && this.state.indexShow === index ?
                            <CSSTransition
                                in={this.state.show}
                                timeout={300}
                                classNames="visible"
                                unmountOnExit>
                                <div className="row-detail bg-blue">
                                    <div className="col-md-12 col-lg-12 text-start p-0">
                                        <ComureiForm
                                            {...this.props}
                                            closeModal = {this.closeDetail}
                                            showDetail={this.showDetail}
                                            subjectItem={subject}
                                            index={index}
                                            user={this.props.user}
                                            openCreateCommunicationModal={this.props.openCreateCommunicationModal}
                                            />
                                    </div>
                                </div>
                            </CSSTransition >

                            : ''}
                    </TransitionGroup>
                </React.Fragment>
            )
        });
    };

    sortBy = (val) => {
        if(val){
            let subjects = (this.state.subjects && this.state.subjects.length > 0) ? this.state.subjects : this.props.subjects;
            let subjectsOrdered = subjects;
            switch(val) {
                case "comureiSubjectType":
                    // Sort by comureiSubjectType
                    subjectsOrdered =  subjects.sort((a,b) => (a.comureiSubjectType?.code > b.comureiSubjectType?.code) ? this.state.sortComureiSubjectType : ((b.comureiSubjectType?.code > a.comureiSubjectType?.code) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortComureiSubjectType : -this.state.sortComureiSubjectType});
                    break;
                case "companyName":
                    // Sort by companyName
                    subjectsOrdered =  subjects.sort((a,b) => (a.companyName > b.companyName) ? this.state.sortCompanyName : ((b.companyName > a.companyName) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortCompanyName : -this.state.sortCompanyName});
                    break;
                case "fiscalCode":
                    // Sort by fiscalCode
                    subjectsOrdered =  subjects.sort((a,b) => (a.fiscalCode > b.fiscalCode) ? this.state.sortFiscalCode : ((b.fiscalCode > a.fiscalCode) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortFiscalCode : -this.state.sortFiscalCode});
                    break;
                case "comureiCommunicationType":
                    // Sort by comureiCommunicationType
                    subjectsOrdered =  subjects.sort((a,b) => (a.comureiCommunicationType?.code > b.comureiCommunicationType?.code) ? this.state.sortComureiCommunicationType : ((b.comureiCommunicationType?.code > a.comureiCommunicationType?.code) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortComureiCommunicationType : -this.state.sortComureiCommunicationType});
                    break;
                case "comureiSubjectResponsible":
                    // Sort by comureiSubjectResponsible
                    subjectsOrdered =  subjects.sort((a,b) => (a.comureiSubjectResponsible?.lastName > b.comureiSubjectResponsible?.lastName) ? this.state.sortComureiSubjectResponsible : ((b.comureiSubjectResponsible?.lastName > a.comureiSubjectResponsible?.lastName) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortComureiSubjectResponsible : -this.state.sortComureiSubjectResponsible});
                    break;
                default:
                    return;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="text-start header-table">
                    {/* <div className='col w-5 text-center div-td'>
                        <input type="checkbox"
                               id='checkboxAllId'
                               onClick={this.onCheckClick}/>
                    </div> */}
                    <div className='col w-40 div-td' ><strong>Tipo Soggetto</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("comureiSubjectType")}/></div>
                    <div className='col w-20 div-td' ><strong>Tipo Comunicazione</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("comureiCommunicationType")}/></div>
                    <div className='col w-15 div-td' ><strong>Soggetto Obbligato</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("companyName")}/></div>
                    <div className='col w-15 div-td' ><strong>Codice Fiscale</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("fiscalCode")}/></div>
                    <div className='col w-15 div-td' ><strong>Responsabile</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("comureiSubjectResponsible")}/></div>
                    <div className='col w-15 div-td' ><strong>Sezione</strong></div>
                    <div className='col w-5 div-td' style={{textAlign:'center'}}><strong>Storico</strong></div>
                </div>
                {this.renderRows(this.props.subjects)}
            </React.Fragment>
        )
    }
}

export default ComureiSubjectList
