import React from 'react';
import Modal from "react-bootstrap/Modal";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import axiosService from "../../../../services/axios-service";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { PopupError } from "../../../shared/PopupError";
import { downloadFile } from '../../../shared/Utility';
import { IExport } from '../../../../styles/icons';

export class YearlyBalanceExportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            loading: false,
            responseModal: false,
            responseKo: "",
            responseOk: ""
        }
    }

    closeModal = () => {
        this.setState({ showModal: false });
    };

    closeResponseModal = () => {
        this.setState({ responseModal: false });
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    exportCoraYearlyBalances = () => {
        let filename = "SALDI_ANNO_" + this.props.selectedYear + "_" + (this.props.subject?.companyName ? this.props.subject.companyName : this.props.subject?.lastName) + ".csv"
        let url = `/api/cora/custom/cora-balances/export-yearly-balances/${this.props.subjectId}/${this.props.selectedYear}`;
        this.callDownloadService(url, filename);
    };

    callDownloadService = (url, file) => {
        const axiosInstance = axiosService.getInstance();
        this.setState({ loading: true })
        axiosInstance({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            if (response) {
                this.closeModal();
                if (response?.data?.size > 0) {
                    downloadFile(response.data, file);
                    PopupSuccess({ text: "File scaricato" });
                } else {
                    this.closeModal();
                    PopupError({ ...this.props, text: "Impossibile generare il file" });
                }
            }
        }).catch(error => {
            this.closeModal();
            let reader = new FileReader();
            reader.onload = function () {
                PopupError({ ...this.props, text: reader.result });
            }
            reader.readAsText(error.request.response);
        })
            .then(() => this.setState({ loading: false }));
    };

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <PageSpinner /> }
                <button type="button" disabled={this.props.disabled} className={`btn btn-outline-primary  btn-empty px-4 btn-sm margin-left-5px ${this.props.disabled ? 'disabled' : ''}`} data-tip={"Esporta i saldi per l'anno selezionato"} onClick={this.openModal}>
                    <IExport className={"padding-bottom-4px"} width="16" fill={` ${this.props.disabled ? '#FFFFFF' : '#128186'}`} />&nbsp; Esporta Saldi
                </button>
                <Modal
                    size='sm'
                    backdrop="static"
                    show={this.state.showModal}
                    onHide={this.closeModal}
                    dialogClassName="modal-40w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>ESPORTA SALDI ANNUALI</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex justify-content-between">
                            <p>Attraverso questa funzione è possibile scaricare tutti i saldi riferiti all'anno selezionato (<strong>{this.props.selectedYear}</strong>).</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-between align-content-end mt-5">
                            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={this.closeModal} />
                            <button
                                onClick={this.exportCoraYearlyBalances}
                                type="button"
                                className="btn btn-new-rel px-5"
                            >
                                ESPORTA
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}
