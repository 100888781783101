import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from 'react-redux';
import { BwmInput } from "../../shared/form/BwmInput";
import { invioMailManuale } from "../../../actions";
import { PopupSuccess } from "../../shared/PopupSuccess";
import { PopupError } from "../../shared/PopupError";
import { BtnFill } from "../../shared/BtnFill";
import { isEmpty } from "../../shared/Utility";
import { Col } from "react-bootstrap";

export function FacciamoNoiAdmin() {

    const auth = useSelector(state => state.auth);
    const isAdmin = auth?.isAdmin;
    const [email, setEmail] = useState("");
    const [denominazione, setDenominazione] = useState("");
    const [partitaIva, setPartitaIva] = useState("");
    const location = useLocation()

    useEffect(()=>{
        setEmail(location?.state?.loginParam || "")
    },[])

    const onSubmit = () => {

        const form = {
            nomeCliente: denominazione,
            codiceFiscaleCliente: partitaIva,
            emailCliente: email
        }
        invioMailManuale(form).then((rest) => {
            PopupSuccess()
        }, (err) => {
            PopupError()
        })

    }

    return (<React.Fragment>

        {
            isAdmin &&
            <>
                <div className="row mt-4">
                    <Col lg={4}>
                        <BwmInput
                            label={"Email cliente"}
                            className={"form-control"}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Col>
                </div>
                <div className="row">
                    <Col lg={4}>

                        <BwmInput
                            label={"Partita IVA"}
                            className={"form-control"}
                            value={partitaIva}
                            onChange={(e) => setPartitaIva(e.target.value)}
                        />
                    </Col>
                </div>
                <div className="row">
                    <Col lg={4}>

                        <BwmInput
                            label={"Denominazione"}
                            className={"form-control"}
                            value={denominazione}
                            onChange={(e) => setDenominazione(e.target.value)}
                        />
                    </Col>
                </div>
                <div className="row">
                    <Col lg={4}>

                        <BtnFill
                            disbled={isEmpty(email)}
                            handlerOnClick={onSubmit} text={"INVIA"} />
                    </Col>
                </div>
            </>
        }
    </React.Fragment>)
}