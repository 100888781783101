import React from "react";
import { saveSid } from "../../SidCertsHandler";
import { Modal } from "react-bootstrap";
import { ParametriPecForm } from "./ParametriPecForm";
import { PopupSuccess } from "../../PopupSuccess";
import { PopupError } from "../../PopupError";

export function ParametriPecModal({coraSubjectSid,show=false, onClose=()=>{},afterSave=(savedSid)=>{}}){

    const save=(subjectSid)=>{
        saveSid(
            [],
            subjectSid,
            subjectSid.fiscalCode,
            subjectSid.application,
            (savedSid)=>{
                if(afterSave){
                    afterSave(savedSid)
                } else {
                    PopupSuccess()
                }
            },
            (errors) => {
                PopupError({ text: errors.detail ? errors.detail : "Si è verificato un errore durante il salvataggio dei dati" })
            }
        )

    }

    return(
         <Modal
            backdrop="static"
            show={show}
            onHide={onClose}
            dialogClassName="width-60 p-2"
            centered>
            <div className="bar"></div>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                <h2>Imposta parametri di connessione per INVIO AUTOMATICO PEC</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-blue pt-0">
                <ParametriPecForm coraSubjectSid={coraSubjectSid} onSave={(subjectSid) => save(subjectSid)} onCancel={onClose} />
            </Modal.Body>

        </Modal>
    )
}