import axios from 'axios';
import authService from './auth-service';
import { formatDateTimeForDisplay } from '../components/shared/Utility';
import { LicenseExpiredPopup } from '../components/application/application-cora/LicenseExpiredPopup';
import { FAKE_USER } from '../config/Constants';

 
class AxiosService {

    axiosInstance = {};



    constructor() {
        this.initInstance();
    }

    initInstance(applicationPaths) {
        this.axiosInstance = axios.create({
            baseURL: '',
            timeout: 500000
        });

        
        const currentPath = window.location.pathname;


        this.axiosInstance.logs = { errors: [], requests: [], responses: [] }
        this.axiosInstance.interceptors.request.use(
            (request) => {
                const token = authService.getToken();

                if (token) {
                    request.headers.Authorization = `Bearer ${token}`;
                    request.headers[FAKE_USER] =authService.getHeader(FAKE_USER);
                } 
                enqueueLogs(this.axiosInstance.logs.requests, prepareRequestlog(request));
                return request;
            });

        const loginPath = '/login';
        this.axiosInstance.interceptors.response.use(response => {
            if (currentPath === '/error') {
                window.location = loginPath;
            }
            enqueueLogs(this.axiosInstance.logs.responses, prepareResponselog(response));

            return response;
        }, error => {
            enqueueLogs(this.axiosInstance.logs.errors, prepareErrorlog(error));

            if ([401,403].includes(error.response?.status) ) {
                authService.invalidateUser();
                //se l'utente si trova in una pagina protetta, lo reindirizzo alla pagina di login
                const path = applicationPaths.protected.find(p => p.path === currentPath);
                if (path) {
                    window.location = loginPath;
                }
            }
            if (error.response?.status === 510) {//codice HTTP custom che indica licenza scaduta
                LicenseExpiredPopup();
            }
            return Promise.reject(error);
        });

        return this.axiosInstance;
    }

    getInstance(applicationPaths) {
        return this.axiosInstance || this.initInstance(applicationPaths);
    }
}


function enqueueLogs(array = [], log) {
    if (array.length < 5) {
        array.push(log);
    } else {
        array.shift();
        array.push(log);
    }
    return array;
}


function prepareErrorlog(error){
    let log = {};
    log.timestamp = formatDateTimeForDisplay(new Date());
    log.url = error.config.url;
    log.method = error.config.method;
    log.data = error.response.data;

    return log;
}

function prepareRequestlog(request){
    let log = {};
    log.timestamp = formatDateTimeForDisplay(new Date());
    log.url = request.url;
    log.method = request.method;
    return log;
}

function prepareResponselog(response){
    let log = {};
    log.timestamp = formatDateTimeForDisplay(new Date());
    log.url = response.config.url;
    log.method = response.config.method;
    log.status = `${response.status} ${response.statusText}`;


    return log;
}

export default new AxiosService();
