import {GET_ROLE_SUCCESS} from '../../actions/types';

const INITIAL_STATE = {
    roles: {
        data: [],
        errors: []
    }
}

export const roleReducer = (state = INITIAL_STATE.roles, action) => {
    switch(action.type) {
        case GET_ROLE_SUCCESS:
            return {...state, data: action.roles};
        default:
            return state;
    }
}


