import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Col, Form, Row } from "react-bootstrap";
import * as actions from "../../../../actions";
import { BtnFill } from "../../BtnFill";
import { PopupError } from "../../PopupError";
import { PopupSuccess } from "../../PopupSuccess";
import { PageSpinner } from "../../spinner/PageSpinner";
import { BwmCheckbox } from "../BwmCheckbox";
import { BwmInput } from "../BwmInput";
import imgShowBlu from "../../../../styles/images/show_blu.png";
import { ReactComponent as IUndo } from "../../../../styles/images/svgs/regular/times-circle.svg";
import { ReactComponent as ISave } from "../../../../styles/images/svgs/regular/save.svg";
import { ReactComponent as IToggleOn } from "../../../../styles/images/svgs/regular/toggle-on.svg";
import { ReactComponent as IToggleOff } from "../../../../styles/images/svgs/regular/toggle-off.svg";
import { InfoBox } from "../../InfoBox";
import { PopupAlert } from "../../PopupAlert";
import { isEmpty, testEmail } from "../../Utility";
import { BwmSelect } from "../BwmSelect";


const onlyNumbers = new RegExp('^\d+$');

export function ParametriPecForm(props) {
    const coraSubjectSid = props.coraSubjectSid;
    const subjectFiscalCode = props.subjectFiscalCode;
    const user = useSelector(state => state.auth.customer.user);
    const [emailAddress, setEmailAddress] = useState("");
    const [errorEmailAddress, setErrorEmailAddress] = useState();
    const [password, setPecPassword] = useState("");
    const [inFolder, setInFolder] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [errorContactEmail, setErrorContactEmail] = useState();
    const [recipientEmail, setRecipientEmail] = useState("");
    const [errorRecipientEmail, setErrorRecipientEmail] = useState();
    const [outgoingMailHost, setOutgoingMailHost] = useState("");
    const [outgoingMailPort, setOutgoingMailPort] = useState("");
    const [incomingMailHost, setIncomingMailHost] = useState("");
    const [incomingMailPort, setIncomingMailPort] = useState("");
    const [automaticElaborationEnabled, setAutomaticElaborationEnabled] = useState(false);
    const [mailPresets, setMailPresets] = useState([]);
    const [comureiSubjectPec, setComureiSubjectPec] = useState(null);
    const [needsToAccept, setNeedsToAccept] = useState(true);
    const [needsTest, setNeedsTest] = useState(true);
    const [disableSave, setDisableSave] = useState(true);
    const [disableVerify, setDisableVerify] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    const [passwordFieldType, setPasswordFieldType] = useState("password");

    const handleChangeTextField = (value, setFunc) => {
        const val = value.trim();
        setFunc(val);
    }

    const handleChangeNumericField = (value, setFunc) => {
        const val = value.replace(onlyNumbers);
        setFunc(val);
    }




    useEffect(() => {
        setEmailAddress(coraSubjectSid?.pecEmail || "");
        setPecPassword(coraSubjectSid?.mailPassword || "")
        setInFolder(coraSubjectSid?.incomingMailFolder || "INBOX")
        setContactEmail(coraSubjectSid?.contactEmail || user.email || "")
        setRecipientEmail(coraSubjectSid?.recipientEmail || "SID1@PCERT.AGENZIAENTRATE.IT");
        setOutgoingMailHost(coraSubjectSid?.outgoingMailHost || "")
        setOutgoingMailPort(coraSubjectSid?.outgoingMailPort || "")
        setIncomingMailHost(coraSubjectSid?.incomingMailHost || "")
        setIncomingMailPort(coraSubjectSid?.incomingMailPort || "")
        setAutomaticElaborationEnabled(coraSubjectSid?.automaticElaborationEnabled === false ? false : true)

    }, [coraSubjectSid]);

    useEffect(() => {
        actions.getMailPresets().then((res) => setMailPresets(res));
        if (subjectFiscalCode) {
            actions.getPecByFiscalCode(subjectFiscalCode).then(res => setComureiSubjectPec(res));
        }
    }, [])


    useEffect(() => {
        setNeedsTest(true);
        const hasMissingFields = !emailAddress
            || !password
            || !incomingMailHost
            || !incomingMailPort
            || !inFolder
            || !outgoingMailHost
            || !outgoingMailPort;

        setDisableVerify(hasMissingFields)
    }, [emailAddress,
        password,
        incomingMailHost,
        incomingMailPort,
        inFolder,
        outgoingMailHost,
        outgoingMailPort]);

    useEffect(() => {
        if (needsTest === false && needsToAccept === false) {
            setDisableSave(false);
        } else {
            setDisableSave(true);
        }
    }, [needsTest, needsToAccept])



    useEffect(() => {
        if (mailPresets.length > 0 && comureiSubjectPec && comureiSubjectPec.pecManager && isEmpty(coraSubjectSid?.pecEmail)) {
            handleChangeTextField(comureiSubjectPec.pecEmail, setEmailAddress);
        }
    }, [mailPresets, comureiSubjectPec])

    const onChangeMailPreset = (selected) => {
        const preset = mailPresets.find(e => e.id == selected);
        if (preset) {
            handleChangeTextField(preset.outgoingMailHost, setOutgoingMailHost);
            handleChangeTextField(preset.outgoingMailPort, setOutgoingMailPort);
            handleChangeTextField(preset.incomingMailHost, setIncomingMailHost);
            handleChangeTextField(preset.incomingMailPort, setIncomingMailPort);
        }

    }


    const onClickAutoElaborationCheckBox = (e) => {
        setAutomaticElaborationEnabled(e.target.checked);
    }

    const onClickPwdEye = () => {
        if (passwordFieldType === "password") {
            setPasswordFieldType("text");
        } else if (passwordFieldType === "text") {
            setPasswordFieldType("password");
        }
    }

    const testEmailAddress = (email, setErrorFunction) => {
        if (!testEmail(email)) {
            setErrorFunction({ isValid: false, msg: "Indirizzo di posta non valido" });
        } else {
            setErrorFunction(undefined);
        }
    }

    const onClean = () => {
        PopupAlert({
            text: "Si desidera eliminare la configurazione PEC?", handleClickConfirm: () => {
                let toSave = coraSubjectSid;

                toSave = {
                    ...toSave,
                    pecEmail: undefined,
                    outgoingMailHost: undefined,
                    outgoingMailPort: undefined,
                    incomingMailHost: undefined,
                    incomingMailPort: undefined,
                    mailPassword: undefined,
                    contactEmail: undefined,
                    recipientEmail: undefined,
                    incomingMailFolder: undefined,
                    automaticElaborationEnabled: false
                }

                props.onSave(toSave);
            }
        })
    }

    const onClickSave = () => {
        let toSave = coraSubjectSid;

        toSave = {
            ...toSave,
            pecEmail: emailAddress,
            outgoingMailHost: outgoingMailHost,
            outgoingMailPort: outgoingMailPort,
            incomingMailHost: incomingMailHost,
            incomingMailPort: incomingMailPort,
            mailPassword: password,
            contactEmail: contactEmail,
            recipientEmail: recipientEmail,
            incomingMailFolder: inFolder,
            automaticElaborationEnabled: automaticElaborationEnabled
        }

        props.onSave(toSave);
    }

    const testMailParameters = () => {
        setIsLoading(true);
        actions.testMailParameters(
            emailAddress,
            password,
            incomingMailHost,
            incomingMailPort,
            inFolder,
            outgoingMailHost,
            outgoingMailPort
        ).then((res) => {
            setNeedsTest(false);
            PopupSuccess({ title: "Parametri verificati con successo" });
        },
            (err) => {
                PopupError({ text: err })
            }).then(() => setIsLoading(false))
    }

    const renderMailParamsOptions = () => {
        return mailPresets.map(element => {
            return <option key={element.id} value={element.id}>
                {element.name}
            </option>
        })
    }

    const renderTipSection = () => {
        const tips = [
            {
                title: "Operatore",
                text: "Selezionare il gestore della vostra PEC"
            },
            {
                title: "Indirizzo PEC Mittente",
                text: "La pec indicata nella richiesta di accreditamento SID"
            },
            {
                title: "Indirizzo PEC Destinatario",
                text: "L'indirizzo dell'Agenzia delle Entrate (SID1, SID2-5)"
            },
            {
                title: "Server posta in entrata/uscita",
                text: "I parametri di configurazione del vostro gestore della PEC"
            },
            {
                title: "Casella di posta in entrata",
                text: "Il nome della casella configurata dal vostro gestore per la ricezione dei messaggi"
            },
            {
                title: "Indirizzo di posta ordinario",
                text: "La mail NON PEC a cui spedire le conferme, gli esiti e gli eventuali errori"
            },
            {
                title: "Elabora automaticamente le ricevute",
                text: "Lasciare il visto per attivare la gestione automatica delle ricevute"
            },
        ]


        return <InfoBox additionalClasses={"flex-grow-1"} simplePointsArr={tips} />

    }

    const renderMailSection = () => {

        return <div className="configurazioneSID mt-1 pt-1 pb-1 d-flex flex-column justify-content-center" key={"mailsection-" + coraSubjectSid?.id}>
            <Row>
                <Col lg={10}>
                    <BwmInput
                        className={"form-control"}
                        label={"Indirizzo Pec MITTENTE"}
                        error={errorEmailAddress}
                        value={emailAddress}
                        onChange={e => { handleChangeTextField(e.target.value.toUpperCase(), setEmailAddress) }}
                        onBlur={() => testEmailAddress(emailAddress, setErrorEmailAddress)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={10}>
                    <div className="d-flex">
                        <div style={{ flexGrow: 1 }}>
                            <BwmInput
                                className={"form-control"}
                                label={"Password"}
                                type={passwordFieldType}
                                name={"passwordPec"}
                                value={password}
                                onChange={e => handleChangeTextField(e.target.value, setPecPassword)}
                                autoComplete={"new-password"}
                            />
                        </div>
                        <div className="align-self-center mt-2">
                            <img
                                alt={"mostra password"}
                                className="img-info ms-2"
                                src={imgShowBlu}
                                onClick={() => onClickPwdEye()}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={10}>
                    <BwmInput
                        className={"form-control"}
                        label={"Indirizzo Pec DESTINATARIO"}
                        error={errorRecipientEmail}
                        value={recipientEmail}
                        onChange={e => { handleChangeTextField(e.target.value.toUpperCase(), setRecipientEmail) }}
                        onBlur={() => testEmailAddress(recipientEmail, setErrorRecipientEmail)}
                    />
                </Col>
            </Row>

        </div>

    }

    const renderMailParamsSection = () => {
        return <div key={"mailParamssection-" + coraSubjectSid?.id}>
            <Row className="ms-3 me-3 justify-content-center">
                <Col lg={6} className="configurazioneSID mt-1 pt-2 pb-1"> {/*posta in entrata*/}
                    <div>
                        <BwmInput
                            className={"form-control"}
                            label={"Server posta in entrata"}
                            value={incomingMailHost}
                            onChange={e => handleChangeTextField(e.target.value, setIncomingMailHost)}
                        />
                    </div>
                    <Row>
                        <Col lg={6}>
                            <BwmInput
                                className={"form-control"}
                                label={"Porta"}
                                value={incomingMailPort}
                                onChange={e => handleChangeNumericField(e.target.value, setIncomingMailPort)}
                            />
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex">
                                <div style={{ flexGrow: 1 }}>
                                    <BwmInput
                                        className={"form-control"}
                                        label={"Casella di posta in entrata"}
                                        value={inFolder}
                                        onChange={e => handleChangeTextField(e.target.value, setInFolder)}
                                    />

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6} className="configurazioneSID mt-1 pt-2 pb-2"> {/*posta in uscita*/}
                    <div>
                        <BwmInput
                            className={"form-control"}
                            label={"Server posta in uscita"}
                            value={outgoingMailHost}
                            onChange={e => handleChangeTextField(e.target.value, setOutgoingMailHost)}
                        />
                    </div>
                    <Row>
                        <Col lg={6}>
                            <BwmInput
                                className={"form-control"}
                                label={"Porta"}
                                value={outgoingMailPort}
                                onChange={e => handleChangeNumericField(e.target.value, setOutgoingMailPort)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    }

    const renderServiceSection = () => {
        return <Row className="ms-3 me-3">
            <div className="configurazioneSID mt-1 pt-1 pb-1 " key={"servicesection-" + coraSubjectSid?.id} >
                <Row >
                    <Col lg={8}>
                        <div className="d-flex">
                            <div style={{ flexGrow: 1 }}>
                                <BwmInput
                                    className={"form-control"}
                                    label={"Indirizzo di posta ordinario per contatti"}
                                    error={errorContactEmail}
                                    value={contactEmail}
                                    onChange={e => handleChangeTextField(e.target.value, setContactEmail)}
                                    onBlur={() => testEmailAddress(contactEmail, setErrorContactEmail)}

                                />
                            </div>
                        </div>
                    </Col>
                    <Col className="d-flex">
                        <div className={"align-self-center"}>
                            <BwmCheckbox
                                name={"automaticElaborationEnabled"}
                                label={"Elabora automaticamente le ricevute"}
                                value={automaticElaborationEnabled}
                                onClick={(e) => onClickAutoElaborationCheckBox(e)}
                            />

                        </div>
                    </Col>
                </Row>
            </div>
        </Row>
    }
    const renderEULASection = () => {
        return <Row className="ms-3 me-3">
            <div className="configurazioneSID mt-1 pt-1 pb-1 " key={"servicesection-" + coraSubjectSid?.id} >
                <Row>
                    <Col lg={10} className="text-small">
                        <small>
                            Inserendo i dati nel presente form di configurazione il Cliente è consapevole di trasmettere a STAR INFOSTUDIO SRL i dati personali necessari all'erogazione del servizio richiesto. STAR INFOSTUDIO SRL tratterrà detti dati con diligenza e nel rispetto della normativa sul trattamento dei dati personali.
                            <br />
                            Il Cliente è consapevole che la gestione della sicurezza e la segretezza di tali dati non può essere limitata alla sola azienda, il Cliente pertanto manleva STAR INFOSTUDIO SRL da ogni responsabilità derivante da un'errata gestione da parte del Cliente o di terzi.
                        </small>
                    </Col>
                    <Col lg={2} className="d-flex align-items-center justify-content-center">
                        <button
                            onClick={() => setNeedsToAccept(!needsToAccept)}
                            className={needsToAccept ? 'btn btn-outline-primary btn-empty px-4 btn-sm' : 'btn btn-outline-primary  btn-new-rel px-4 btn-sm'}>
                            Accetta&nbsp;
                            {needsToAccept
                                ? <IToggleOff className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />
                                : <IToggleOn className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} />}
                        </button>
                    </Col>
                </Row>

            </div>

        </Row>
    }

    return <React.Fragment key={"pec-" + coraSubjectSid?.id}>
        {isLoading && <PageSpinner />}
        <div>

            <Row className="ms-3 me-3">
                <Col lg={6} className={"mt-1 pb-2 d-flex flex-column"}>
                    <Row>
                        <Col>
                            <BwmSelect
                                options={mailPresets ? mailPresets.map(p=> {return {id:p.id, description: p.name}}) : [] }
                                name='select'
                                label='eleziona un operatore'
                                className='form-control'
                                onChange={(e) => onChangeMailPreset(e.target.value)}
                                value={undefined}
                            />

                        </Col>
                    </Row>
                    <Row className={"flex-grow-1"}>
                        {renderMailSection()}

                    </Row>
                </Col>
                <Col lg={6} className={"mt-1 pt-2 ps-0 pe-0 d-flex flex-column"}>
                    {renderTipSection()}
                </Col>
            </Row>

            {renderMailParamsSection()}
            {renderServiceSection()}
            {renderEULASection()}

        </div>
        <div className="row ms-2 mt-3  me-2 justify-content-between align-items-center">
            <Col lg={2}>
                <button className="btn btn-outline-primary btn-empty px-4 btn-sm" onClick={() => props.onCancel()}>
                    <IUndo className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />&nbsp; ANNULLA
                </button>
            </Col>
            <Col lg={4}>
                <button className="btn btn-primary ms-2  btn-empty btn-sm  btn-new-rel" onClick={() => onClean()}>
                    ELIMINA CONFIGURAZIONE
                </button>
            </Col>
            <Col lg={3} className="d-flex justify-content-center">
                <BtnFill text="Verifica connessione" isDisabled={disableVerify} handlerOnClick={() => testMailParameters()} ></BtnFill>
            </Col>
            <Col lg={3} className="d-flex justify-content-end">
                <button disabled={disableSave} className="btn btn-primary rounded-pill btn-new-rel px-5" onClick={() => onClickSave()}>
                    <ISave className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; CONFERMA
                </button>
            </Col>
        </div>
    </React.Fragment>;
}