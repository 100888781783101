import moment from "moment";
import React from 'react';
import { connect } from "react-redux";
import * as actions from "../../../../actions";
import * as Constants from "../../../../config/Constants";
import { ReactComponent as IDelete } from "../../../../styles/images/svg/trash-solid.svg";
import { PopupDelete } from "../../../shared/PopupDelete";
import { PopupError } from "../../../shared/PopupError";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import CreateCommunicationModal from "../communications/CreateCommunicationModal";
import CorproSubjectList from "./CorproSubjectList";
import { CorproSubjectModal } from "./CorproSubjectModal";

class CorproSubject extends React.Component {
    constructor() {
        super();
        this.state = {
            checkedSubjects: [],
            communication: {
                show: false,
                user: null,
                subject: null,
                communication: null
            }
        }
    }

    checkExpire(){
        let expired = moment(this.props?.auth?.user?.customer?.customerApplications.filter(ca => ca?.application?.name === Constants.APPLICATION_CORPRO)[0].expiration).add(1, 'days').isSameOrAfter(moment()) ? false : true;
        if(expired){
            PopupError({text: "Abbonamento scaduto <br> Acquista o contatta l'assistenza"})
        }
    }

    componentWillMount() {
        this.checkExpire();
        this.getSubjects();
        this.getLocations();
        this.getSubjectTypes();
        this.getCommunicationTypes();
        this.getTransmissionTypes();
        this.getSupplierTypes();
    }

    getSubjects = () => {
        this.props.dispatch(actions.getAllCorproSubjects());
    };

    getLocations = () => {
        this.props.dispatch(actions.getAllLocations());
    };

    getSubjectTypes = () => {
        this.props.dispatch(actions.getAllCorproSubjectTypes());
    };

    getSupplierTypes = () => {
        this.props.dispatch(actions.getAllCorproSupplierTypes());
    };

    getCommunicationTypes = () => {
        this.props.dispatch(actions.getAllCorproCommunicationTypes());
    };

    getTransmissionTypes = () => {
        this.props.dispatch(actions.getAllCorproTransmissionTypes());
    };

    checkSubject = (subject) => {
        let newList = this.state.checkedSubjects;
        newList.push(subject);
        this.setState({checkedSubjects: newList})
    };

    uncheckSubject = (subject) => {
        let newList = [];
        this.state.checkedSubjects.map(current => {
            if (current.id !== subject.id)
                newList.push(current);
        });
        this.setState({checkedSubjects: newList})
    };

    checkAllSubjects = () => {
        let allSubjects = this.props.subjects;
        this.setState({
            checkedSubjects: allSubjects
        })
    };

    uncheckAllSubjects = () =>{
        this.setState({
            checkedSubjects:[]
        })
    };

    openPopupDelete = () => {
        PopupDelete({operazione: "Soggetti Selezionati",
            handleClickConfirm:this.deleteCorproSubject});
    };

    deleteCorproSubject = () =>{
        this.state.checkedSubjects.map (subject=>{
            actions.deleteCorproSubject(subject.id).then(
                () => {
                    this.setState({checkedSubjects:[]})
                    this.getSubjects();
                },
                (errors) => {
                    PopupError({...this.props, text:errors})
                })
            document.getElementById(`checkboxDetails${subject.id}`).checked = false;
        });
        let rowIds = document.querySelectorAll('[id^="row-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt","bold");
            }
        }
    };

    closeCreateCommunicationModal = () => {
        this.setState({
            communication: {
                show: false,
                user: null,
                subject: null,
                communication: null
            }
        })
        this.getSubjects();
    };

    openCreateCommunicationModal = (subject, communications) => {
        this.setState({
            communication: {
                show: true,
                user: this.props.auth.user,
                subject: subject,
                communication: communications
            },
            showModal: true
        })
    }

    isCommunicationOK = (communication) => {
        return !this.isEmpty(communication.communicationTxt);

    }

    isEmpty(val){
        return val === "" || val == null;
    }

    getBlobFromBase64 = (base64, mimeType) => {
        let byteString = atob(base64);
    
        // Convert that text into a byte array.
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], {
          type: mimeType
        });
      }

    downloadTxt = (communication) => {
        let isOK = this.isCommunicationOK(communication);
        let dataToDownload = isOK ? communication.communicationTxt : communication.csv;
        const url = window.URL.createObjectURL(this.getBlobFromBase64(dataToDownload, isOK ? communication.communicationTxtContentType : communication.csvContentType));
        const link = document.createElement('a');
        let fileName = `_${communication.referenceYear}_${communication.corproSubject.subjectName.replaceAll(" ", "")}`;
        if (isOK) {
            fileName = 'CP' + fileName + '.zip';
        } else {
            fileName = 'CP' + fileName + '_ERRORI.txt';
        }
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        PopupSuccess({ text: "File scaricato" });
    }

    dowloadLocalDocument = (fileName) => {
        const link = document.createElement('a');
        link.href = process.env.PUBLIC_URL + "/docs/" + fileName;
        link.setAttribute("download",fileName);
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        let licenseExpired = moment(this.props?.auth?.user?.customer?.customerApplications.filter(ca => ca?.application?.name === Constants.APPLICATION_CORPRO)[0].expiration).add(1, 'days').isSameOrAfter(moment()) ? false : true;
        return (
            <React.Fragment>
                <div className="table-custom mt-1">
                    <div className="tab-content tab-rel">
                        <div className="row header-btn-relationship">
                            <div className="col-8">
                                <div className="btn-group" role="group" aria-label="action buttons">
                                    <button type="button" disabled={(Object.keys(this.state.checkedSubjects).length === 0)} className={`btn btn-outline-primary rounded-pill btn-empty px-4 btn-sm margin-left-5px ${(Object.keys(this.state.checkedSubjects).length === 0) ? 'disabled' : ''}`} onClick={() => this.openPopupDelete()} ><IDelete className={"padding-bottom-4px"} width="12" fill={` ${(Object.keys(this.state.checkedSubjects).length === 0) ? '#FFFFFF' : '#128186'}`}/>&nbsp; Elimina</button>
                                    <button type="button" className={`btn  rounded-pill btn-new-rel me-2 ms-2 px-5 btn-sm`}  onClick={()=>this.dowloadLocalDocument("CORPRO_ISCRITTI.csv")}> Scarica CSV base iscritti</button>
                                    <button type="button" className={`btn  rounded-pill  btn-new-rel px-5 btn-sm`} onClick={() => this.dowloadLocalDocument("CORPRO-Istruzioni.pdf")} > Scarica Istruzioni compilazione</button>
                                </div>
                            </div>
                            
                            <div className="col-4 text-end">
                                <CorproSubjectModal
                                    locations={this.props.locations}
                                    subjectTypes={this.props.subjectTypes}
                                    corproTransmissionTypes={this.props.corproTransmissionTypes}
                                    communicationTypes={this.props.communicationTypes}
                                    user={this.props.auth.user}
                                    getSubjects={this.getSubjects}
                                    corproSupplierTypes={this.props.corproSupplierTypes}
                                    openCreateCommunicationModal={this.openCreateCommunicationModal}
                                    licenseExpired={licenseExpired}
                                />
                            </div>
                        </div>
                        <CorproSubjectList
                            subjects={this.props.subjects}
                            subjectTypes={this.props.subjectTypes}
                            locations={this.props.locations}
                            getSubjects={this.getSubjects}
                            checkSubject={this.checkSubject}
                            uncheckSubject={this.uncheckSubject}
                            checkAllSubjects={this.checkAllSubjects}
                            uncheckAllSubjects={this.uncheckAllSubjects}
                            communicationTypes={this.props.communicationTypes}
                            corproTransmissionTypes={this.props.corproTransmissionTypes}
                            user={this.props.auth.user}
                            corproSupplierTypes={this.props.corproSupplierTypes}
                            openCreateCommunicationModal={this.openCreateCommunicationModal}
                            licenseExpired={licenseExpired}
                            downloadTxt={this.downloadTxt}
                        />
                    </div>
                </div>
                {this.state.communication.show &&
                    <CreateCommunicationModal {...this.props} downloadTxt={this.downloadTxt} isCommunicationOK ={this.isCommunicationOK} user={this.state.communication.user} subject={this.state.communication.subject} communications={this.state.communication.communication} closeModalComm={this.closeCreateCommunicationModal}/>
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    let customLocations = state.locations?.data.map((location, index) => {
        return {name: location.location, key: index}
    });
    return {
        auth: state.auth,
        locations: customLocations,
        subjects: state.corproSubjects.data,
        subjectTypes: state.corproSubjectTypes.data,
        communicationTypes: state.communicationTypes.data,
        corproTransmissionTypes: state.corproTransmissionTypes.data,
        corproSupplierTypes: state.corproSupplierTypes.data
    }
}

export default connect(mapStateToProps)(CorproSubject);
