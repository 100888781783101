import React from 'react';
import '../../../../styles/comurei-form.css';
import '../../../../styles/arcoTablesModal.css';
import * as actions from "../../../../actions";
import { PopupError } from "../../../shared/PopupError";
import { BwmInput } from "../../../shared/form/BwmInput";
import { BwmCheckbox } from "../../../shared/form/BwmCheckbox";
import { DateField } from "../../../shared/form/DateField";
import { PopupDelete } from "../../../shared/PopupDelete";
import { ArcoProfessionists } from "../subject-arco/ArcoProfessionists";
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants';
import { Col } from 'react-bootstrap';
import { CreateNewButton } from './table-components/CreateNewButton';
import { CloseButton } from './table-components/CloseButton';
import { DeleteButton } from './table-components/DeleteButton';
import { ConfirmButton } from './table-components/ConfirmButton';

class ArcoTableProfessionistsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            update: false,
            show: false,
            checkedProfessionists: [],
            professionist: {
                name: "",
                code: "",
                disabledDate: null,
                disabled: false,
                createDate: new Date(),
                updateDate: new Date(),
                arcoSubject: this.props.subject,
            },
            query: "",
            professionists: [],
            errorrCode: { isValid: true, msg: "" },
            errorDisabledDate: { isValid: true, msg: "" },
            errorEnabled: { isValid: true, msg: "" }
        }
    }

    componentDidMount() {
        this.setState({
            professionists: this.getPropsProfessionists()
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const query = this.state.query.toLowerCase();
        if (prevState.query !== this.state.query) {
            if (query) {
                const filtered = this.getPropsProfessionists().filter(e => e.name.toLowerCase().includes(query))
                this.setState({
                    professionists: filtered
                })
            } else {
                this.setState({
                    professionists: this.getPropsProfessionists()
                })
            }
        }
        if (prevProps.professionists !== this.props.professionists) {
            this.setState({
                professionists: this.getPropsProfessionists()
            })
        }
    }

    getPropsProfessionists = () => {
        let professionists = [];
        if (!this.props.showDisabled && this.props.professionists) {
            professionists = this.props.professionists.filter(p => !p.disabled);
        } else {
            professionists = this.props.professionists;
        }
        return professionists;
    }


    onCheckClick = (event) => {
        if (event.target.checked)
            this.checkAll();
        else
            this.uncheckAll();
    };

    checkAll = () => {
        document.getElementById('checkboxAllProfessionistId').checked = true;
        this.props.professionists.map(professionist => {
            const checkboxDetailsId = `checkboxProfessionistDetails${professionist.id}`;
            const idCheckbox = `professionist-${professionist.id}`;
            if (document.getElementById(checkboxDetailsId)) {
                document.getElementById(checkboxDetailsId).checked = true;
            }
            if (document.getElementById(idCheckbox)) {
                document.getElementById(idCheckbox).checked = true;
            }
        });
        let rowIds = document.querySelectorAll('[id^="professionist-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.add("bg-cobalt", "bold");
            }
        }
        this.checkAllProfessionists();

    };

    uncheckAll = () => {
        document.getElementById('checkboxAllProfessionistId').checked = false;
        this.props.professionists.map(professionist => {
            const checkboxDetailsId = `checkboxProfessionistDetails${professionist.id}`;
            const idCheckbox = `professionist-${professionist.id}`;
            if (document.getElementById(checkboxDetailsId)) {
                document.getElementById(checkboxDetailsId).checked = false;
            }
            if (document.getElementById(idCheckbox)) {
                document.getElementById(idCheckbox).checked = false;
            }
        });
        this.uncheckAllProfessionists();
        let rowIds = document.querySelectorAll('[id^="professionist-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
    };

    onCheckBoxClick = (event, professionist) => {
        if (event.target.checked) {
            this.checkProfessionist(professionist);
            let element = document.getElementById("professionist-" + professionist.id);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        } else {
            this.uncheckProfessionist(professionist);
            document.getElementById('checkboxAllProfessionistId').checked = false;
            let element = document.getElementById("professionist-" + professionist.id);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }

    };

    updateAll = () => {
        if (!this.handleValidation()) return;

        this.addArcoProfessionist(this.state.professionist);
        this.openUpdate();
    };
    handleValidation = () => {
        let isValid = true;
        if (!this.state.professionist.name) {
            this.setState({
                errorName: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.professionist.code) {
            this.setState({
                errorrCode: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        /*    if (!this.state.professionist.disabledDate && this.state.professionist.disabled) {
               this.setState({
                   errorDisabledDate: { isValid: false, msg: "Campo obbligatorio" }
               });
               isValid = false;
           }
   
           if (this.state.professionist.disabledDate && !this.state.professionist.disabled) {
               this.setState({
                   errorEnabled: { isValid: false, msg: "Campo obbligatorio" }
               });
               isValid = false;
           } */

        return isValid;
    };

    changeName = (e) => {
        this.setState({
            professionist: {
                ...this.state.professionist,
                name: e.target.value.toUpperCase()
            },
            errorName: { isValid: true, msg: "" },
        });
    };

    changeCode = (e) => {
        this.setState({
            professionist: {
                ...this.state.professionist,
                code: e.target.value.toUpperCase()
            },
            errorCode: { isValid: true, msg: "" },
        });
    };
    changedisabledDate = (date) => {
        this.setState({
            professionist: {
                ...this.state.professionist,
                disabledDate: date !== null ? new Date(date) : null
            },
            errorData: { isValid: true, msg: "" },
        });
    };

    changeDisable = (e) => {
        this.setState({
            professionist: {
                ...this.state.professionist,
                disabled: e.target.checked
            },
            errorEnabled: { isValid: true, msg: "" },
        });
    };


    addArcoProfessionist = (professionist) => {
        actions.saveArcoProfessionist(professionist).then(
            (response) => {
                this.props.getAllProfessionistBySubjectId();
            },
            (error) => {

                PopupError(
                    {
                        text: APPLICATION_GENERIC_ERROR
                    }
                );
            }
        );

    };

    openUpdate = () => {

        this.setState({
            update: !this.state.update,
            errorrCode: { isValid: true, msg: "" },
            errorDisabledDate: { isValid: true, msg: "" },
            errorEnabled: { isValid: true, msg: "" },
            professionist: {
                name: "",
                code: "",
                disabledDate: null,
                disabled: false,
                createDate: new Date(),
                updateDate: new Date(),
                arcoSubject: this.props.subject,

            }
        })
    };

    openPopupDelete = () => {
        PopupDelete({
            operazione: "Soggetti Selezionati",
            handleClickConfirm: this.deleteArcoProfessionistSubject
        });
    };

    deleteArcoProfessionistSubject = () => {
        this.state.checkedProfessionists.map(professionist => {
            actions.deleteArcoProfessionist(professionist.id).then(
                () => {
                    this.setState({ checkedProfessionists: [] })
                    this.props.getAllProfessionistBySubjectId();
                    //chiudo form se contiene l'elemento cancellato
                    if (this.state.professionist.id) {
                        this.openUpdate();
                    }
                },
                (errors) => {
                    PopupError({ ...this.props, text: errors })
                })
            document.getElementById(`checkboxProfessionistDetails${professionist.id}`).checked = false;
        });
        let rowIds = document.querySelectorAll('[id^="professionist-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
    };

    checkProfessionist = (professionist) => {
        let newList = this.state.checkedProfessionists;
        newList.push(professionist);
        this.setState({ checkedProfessionists: newList })
        if (newList.length === this.props.professionists.length) {
            this.checkAll();
        }
    };

    uncheckProfessionist = (professionist) => {
        let newList = [];
        this.state.checkedProfessionists.map(current => {
            if (current.id !== professionist.id)
                newList.push(current);
        });
        this.setState({ checkedProfessionists: newList })
    };

    checkAllProfessionists = () => {
        let allProfessionists = this.props.professionists;
        this.setState({
            checkedProfessionists: allProfessionists
        });
    };

    uncheckAllProfessionists = () => {
        this.setState({
            checkedProfessionists: []
        });
    };

    onClickCompileUpdate = (e, professionistSelected) => {
        this.setState({
            errorrCode: { isValid: true, msg: "" },
            errorName: { isValid: true, msg: "" },
            errorDisabledDate: { isValid: true, msg: "" },
            errorEnabled: { isValid: true, msg: "" },
            update: true,
            professionist: {
                ...professionistSelected,
                disabledDate: professionistSelected.disabledDate ? new Date(professionistSelected.disabledDate) : null
            }
        });
    }


    render() {
        let professionistsData = this.state.professionists;
        const hasNoCheckedItems = this.state.checkedProfessionists.length === 0;
        return (
            <React.Fragment>
                <div className='form-row'>
                    <div className="col-lg-4">
                        <BwmInput
                            label={"Cerca per nome e cognome"}
                            className='form-control'
                            value={this.state.query}
                            onChange={e => this.setState({ query: e.target.value })}
                        />
                    </div>
                </div>
                <div className="form-row mt-2 mb-2 align-item-center">
                    <Col lg={2}>
                        <DeleteButton
                            disabled={hasNoCheckedItems}
                            onClick={this.deleteArcoProfessionistSubject}
                        />
                    </Col>
                    <Col lg={8} className={"text-center"}>
                        {this.props.confirmButton && this.props.confirmButton(this.state.checkedProfessionists)}
                    </Col>

                </div>
                <div className='overflow-auto ms-3 me-3' style={{ height: '388px' }}>
                    <ArcoProfessionists
                        {...this.props}
                        onCheckBoxClick={this.onCheckBoxClick}
                        onClickCompileUpdate={this.onClickCompileUpdate}
                        onCheckClick={this.onCheckClick}
                        professionistsData={professionistsData}
                    />

                </div>

                <div className={` row me-2 ms-2 mt-4 mb-4 ${!this.state.update && 'invisible'}`} >
                    <div className='col-2'>
                        <BwmInput name='code'
                            label='Codice'
                            className='form-control'
                            onChange={e => this.changeCode(e)}
                            error={this.state.errorrCode}
                            value={this.state.professionist.code}
                            maxLength="5"
                        />
                    </div>
                    <div className='col-4'>
                        <BwmInput name='description'
                            label='Nome e Cognome'
                            onChange={e => this.changeName(e)}
                            value={this.state.professionist.name}
                            className='form-control'
                            error={this.state.errorName}
                        />
                    </div>

                    <div className="form-check col-1">
                        <label>Disabilitato</label>
                        <div className="input-group mt-2 d-flex justify-content-between">
                            <BwmCheckbox
                                name="ability"
                                value={this.state.professionist.disabled}
                                onClick={e => this.changeDisable(e)}
                                className='form-control'
                                error={this.state.errorEnabled}

                            />
                        </div>
                    </div>

                    <div className="col-2">
                        <DateField
                            label="Data Disabilitazione"
                            onChange={date => this.changedisabledDate(date)}
                            error={this.state.errorDisabledDate}
                            date={this.state.professionist.disabledDate}
                            value={this.state.professionist.disabledDate}
                            name={"disabilityDate"} />
                    </div>

                    <div className='col-3 d-flex justify-content-end align-items-center'>
                        <ConfirmButton
                            onClick={this.updateAll}
                        />
                    </div>
                </div>
                <div className="form-row mt-2 align-item-center">
                    <Col lg={2}>
                        <CloseButton
                            onClick={this.props.onClose}
                        />
                    </Col>
                    <Col lg={8} className={"text-center"}>
                        <CreateNewButton
                            disabled={this.props.disableInsert}
                            onClick={this.openUpdate}
                            active={this.state.update} />

                    </Col>
                </div>


            </React.Fragment>
        )
    }
}

export default ArcoTableProfessionistsList
