import React from 'react';
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as IPlus } from "../../../../styles/images/svgs/regular/plus.svg";
import { ArcoSubjectForm } from "./ArcoSubjectForm";

export class ArcoSubjectModal extends React.Component {

    constructor() {
        super();
        this.state = {
            showModal: false
        }
    }

    closeModal = () => {
        this.props.getSubjects();
        this.setState({showModal: false});
    }

    openModal = () => {
        this.setState({showModal: true});
    }


    render() {
        return (
            <React.Fragment>
                <button type="button" className="btn btn-primary btn-new-rel px-5 btn-sm"
                        onClick={this.openModal} disabled={this.props.disabled}>
                    <IPlus className={"padding-bottom-4px"} width="14" fill={` ${this.props.disabled ? '#128186' : '#FFFFFF'}`}/>&nbsp; NUOVO
                    SOGGETTO
                </button>
                <Modal
                    backdrop="static"
                    show={this.state.showModal}
                    onHide={this.closeModal}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>Nuovo Soggetto</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ArcoSubjectForm {...this.props} showModal={this.state.showModal} handleCloseModal={this.closeModal}/>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}
