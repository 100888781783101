import { jwtDecode } from "jwt-decode";
import * as moment from 'moment';

class AuthService {

  tokenKey = 'jwt-token';

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  decode(token) {
    return jwtDecode(token);
  }

  saveToken(token) {
    localStorage.setItem(this.tokenKey, token);
  }

  invalidateUser() {
    localStorage.removeItem(this.tokenKey);
  }

  getExpiration(token) {
    const exp = this.decode(token).exp;

    return moment.unix(exp);
  }

  getUsername() { //il nostro token ha solo il ruolo
    return this.decode(this.getToken()).auth;
  }

  isValid(token) {
    return moment().isBefore(this.getExpiration(token));
  }

  isAuthenticated() {
    const token = this.getToken();
    return (token && this.isValid(token)) ? true : false;
  }

  setHeader(header,value){
    localStorage.setItem(header, value);
  }

  getHeader(header){
    return localStorage.getItem(header);
  }
}


export default new AuthService();
