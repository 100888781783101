import React from "react";
import { Row } from "react-bootstrap";
import { CommunicationStatus } from "../../../../config/CommunicationStatus";
import { ReactComponent as IZipFile } from "../../../../styles/images/svg/file-archive.svg";
import { ReactComponent as IListFile } from "../../../../styles/images/svg/list.svg";
import { ReactComponent as ITextFile } from "../../../../styles/images/svgs/regular/file-alt.svg";
import { ReactComponent as IPdfFile } from '../../../../styles/images/svg/file-pdf.svg';
import { ReactComponent as ITriangle } from "../../../../styles/images/svgs/solid/exclamation-triangle.svg";
import { formatDateForDisplay, isNotEmpty } from "../../../shared/Utility";

export function computeIsError(communicationStatus) {
    return (communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED
        || communicationStatus === CommunicationStatus.ERROR_SID
        || communicationStatus === CommunicationStatus.CREATED_WITH_ERROR);
}

export function computeStatusText(communication) {
    let status = "";
    if (communication.communicationStatus === CommunicationStatus.CREATED) {
        if(isNotEmpty(communication?.scheduledPecDate)){
            status = `Creata - INVIO DIFFERITO IL ${formatDateForDisplay(communication?.scheduledPecDate)} (file ATPECZC.${communication.pecatFilename}.p7m)`;
        } else if(isNotEmpty(communication?.pecSentDate)){
            status = `Creata - Pec inviata in data ${formatDateForDisplay(communication?.pecSentDate)} (file ATPECZC.${communication.pecatFilename}.p7m)`;
        } else {
            status = `Creata - In attesa di ricevuta (file ATPECZC.${communication.pecatFilename}.p7m)`;
        }
    } else if (communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) {
        status = "Errori presenti nella comunicazione: cliccare sul pulsante giallo per scaricare gli errori in chiaro";
    } else if (communication?.communicationStatus === CommunicationStatus.ERROR_SID) {
        status = "Attenzione!! " + communication?.communicationError + ".";
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
        status = `Ricevuta Elaborata: comunicazione ACCOLTA (file ${communication.pecatFilename})` ;
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR) {
        status = `Ricevuta Elaborata: comunicazione ACCOLTA CON ERRORI (file ${communication.pecatFilename})`;
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
        status = `Ricevuta Elaborata: comunicazione SCARTATA`;
    }
    return status;
}

export function isReceipt(communication) {
    return (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED
        || communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR
        || communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED)
}
export function isCreatedWithError(communication){
    return  communication?.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR;
}

export function CommunicationModalRow(props) {
    const communication = props.communication;
    const status = props.status;
    const isError = props.isError;
    const createdWithError = isCreatedWithError(communication);


    return (
        <Row className="text-start row-table subrow" key={"communication-modal-row-" + communication.id} >
            <div className='col w-10 div-td'>{communication.referencePeriod}</div>
            <div className='col w-30 div-td'>{communication.communicationType?.code} - {communication.communicationType?.description}</div>
            <div className='col w-20 div-td'>{communication.dispatchType === "ORDINARY" ? "Ordinario" : "Straordinario"}</div>
            <div className={isError ? 'col w-30 div-td  color-red' : 'col w-30 div-td'}>{status}</div>
            <div className='col w-15 div-td files text-center containerPopOver'>
                {createdWithError &&
                    <span data-tip={"Scarica dettaglio errori"} onClick={() => props.downloadErrorReport(communication)}>
                        <ITriangle width="25" fill="#e3c400" />
                    </span>
                }
                <div data-tip={"Scarica COMUNICAZIONE TXT"}><ITextFile width="20" fill="#128186" onClick={() => props.downloadTxt(communication)} /></div>
                {communication.communicationStatus === CommunicationStatus.CREATED &&
                    <>
                        <div data-tip={"Scarica STAMPA COMUNICAZIONE PDF"}><IPdfFile width="20" fill="#128186" onClick={() => props.downloadPdf(communication)} /></div>
                        <div data-tip={"Scarica COPPIA FILE PECAT"}><IZipFile width="20" fill="#128186" onClick={() => props.downloadPecTz(communication)} /></div>
                    </>

                }
                {(communication?.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) &&
                    <>
                        <div data-tip={"Scarica STAMPA COMUNICAZIONE PDF"}><IPdfFile width="20" fill="#128186" onClick={() => props.downloadPdf(communication)} /></div>
                        <div data-tip={"Scarica DIAGNOSTICO"}>
                            <IListFile width="20" fill="#128186" onClick={() => props.downloadDgn(communication)} />
                        </div>
                    </>

                }

            </div>

        </Row>
    )
}