import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tab extends Component {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    };

    onClick = () => {
        const { label, onClick, idTab } = this.props;
        onClick(label, idTab);
    }

    render() {
        const {
            onClick,
            props: {
                activeTab,
                label,
                icon,
                disabled,
                idTab
            },
        } = this;

        let className = 'tab-list-item nav-link';

        if (activeTab === label) {
            className += ' tab-list-active active';
        }
        if(disabled) {
            className += ' disabled'
        }

        return (
            <li
                className={className}
                onClick={onClick}
                id={idTab}
            >
                <div className="col-md-12 col-lg-12 text-center ">
                    {icon && <img src={icon}/>}&nbsp;&nbsp;{label}
                </div>
            </li>
        );
    }
}

export default Tab;
