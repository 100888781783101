import React, { memo } from 'react';
import * as actions from "../../../../actions";
import { connect } from "react-redux";
import moment from "moment";
import * as Constants from "../../../../config/Constants";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import NumberFormat from "react-number-format";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { ReactComponent as ISave } from "../../../../styles/images/svgs/regular/save.svg";
import { ReactComponent as IQuestion } from "../../../../styles/images/svgs/regular/question-circle.svg";
import { YearlyBalanceExportModal } from "./YearlyBalanceExportModal";
import { YearlyBalanceImportModal } from "./YearlyBalanceImportModal";
import { ReactComponent as ISort } from '../../../../styles/images/svgs/regular/sort.svg';
import { ReactComponent as IExclude } from "../../../../styles/images/svg/do-not-enter.svg";
import { formatDateForDisplay, isEmpty, isNotEmpty } from '../../../shared/Utility';
import { BwmInput } from '../../../shared/form/BwmInput';
import { Row } from 'react-bootstrap';
import { PopupError } from '../../../shared/PopupError';
import RowSpinner from '../../../shared/spinner/Spinner';
import { VirtualList } from '../../../shared/form/lists/VirtualList';
import { getBalanceRulesByRelationshipType, getDisabledFields } from '../BalanceRules';
import { StarTooltip } from '../../../shared/tooltips/Tooltip';
import { CreateCommunicationModal } from '../communications/CreateCommunicationModal';

class YearlyBalanceList extends React.Component {

    constructor() {
        super();

        this.state = {
            allCheck: false,
            checkedItems: new Map(),
            isDisabled: true,
            dropdownYears: [],
            selectedYear: new Date().getMonth() > 4 ? new Date().getFullYear() : new Date().getFullYear() - 1,//i saldi possono essere  comunicati per l'anno precendente fino ad aprile
            relationships: [],
            sortCompleteName: 1,
            sortRelType: 1,
            sortStartDate: 1,
            sortEndDate: 1,
            excludedItems: [],
            loading: true,
            showAll: false
        }
    }

    componentWillMount() {
        const { subjectId } = this.props;
        const { selectedYear } = this.state;
        this.setDropdownYears();
        this.getYearlyBalance(subjectId, selectedYear);
        this.getAllCurrency();
    }

    componentDidMount() {
        this.setState({ relationships: this.props.yearlyBalanceRelationships })
    }

    /**
     * 
     * @param {*} subjectId 
     * @param {*} year 
     * @param {*} page 
     * @param {*} size 
     */
    getYearlyBalance = (subjectId, year, page, size) => {
        this.setState({ loading: true });
        this.props.dispatch(actions.getCommunicationsBalanceBySubjectIdAndYear(subjectId, year, page, size));
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.yearlyBalanceRelationships != this.props.yearlyBalanceRelationships) {
            this.setState({ relationships: this.props.yearlyBalanceRelationships })
            this.setState({ loading: false });
        }
    }

    showAll = () => {
        const { subjectId } = this.props;
        const { selectedYear } = this.state;
        if (this.state.showAll) {
            this.setState({ showAll: false })
            this.getYearlyBalance(subjectId, selectedYear);
        } else {
            this.setState({ showAll: true })
            this.getYearlyBalance(subjectId, selectedYear, 0, 0);
        }
    }

    getAllCurrency() {
        if (!this.props.currencies?.length) {
            this.props.dispatch(actions.getAllCurrency());
        }
        if (!this.props.currencyTypes?.length) {
            this.props.dispatch(actions.getAllCurrencyTypes());
        }
    }

    openGuideBalances() {
        window.open('https://www.starsoluzioni.it/media/TABELLA-RAPPORTI.pdf')
        PopupSuccess({ text: "File scaricato" });
    }

    saveBalances = () => {
        let yearlyBalances = {
            subjectId: parseInt(this.props.subjectId),
            year: "" + this.state.selectedYear,
            relationships: this.state.relationships
        };

        return actions.updateYearlyBalances(yearlyBalances).then(
            (message) => {
                if (this.state.showAll) {
                    this.getYearlyBalance(this.props.subjectId, this.state.selectedYear, 0, 0);
                } else {
                    this.getYearlyBalance(this.props.subjectId, this.state.selectedYear);
                }
            },
            (errors) => {
                PopupError({ text: Constants.APPLICATION_GENERIC_ERROR });
            })
    };

    setDropdownYears() {
        const initialYear = 2011; // anno di inizio comunicazione saldi come da normativa
        let currentYear = new Date().getFullYear();
        //vengono mostrati tutti gli anni dal 2011 fino a quello corrente
        let years = [];
        for (let i = currentYear; i >= initialYear; i--) {
            years.push({ id: i, description: i });
        }
        this.setState({
            dropdownYears: years
        });
    }




    renderRows(relationships) {
        if (this.state.loading) return <RowSpinner />

        const header = (
            <div className="text-start header-table virtual">
                {this.renderRowHeader(relationships)}
            </div>)


        let rows = relationships.map(relationship => this.renderBalanceRows(relationship));

        rows = [header, ...rows];

        if (rows.length === 1) {
            return <div className={"row"}><div className={"col-12 text-center mt-27 no-result"}>Nessun Saldo trovato</div></div>;
        }
        return (
            <VirtualList rows={rows} />
        )
    }

    handlerYearChange = (e) => {
        const { subjectId } = this.props;
        this.setState({
            selectedYear: parseInt(e.target.value)
        });
        this.getYearlyBalance(subjectId, parseInt(e.target.value));
        this.setState({ showAll: false })
    };



    getRelationshipToUpdate(relationships = [], relationship) {
        let index = relationships.findIndex(rel => rel.id === relationship?.id);
        if (index === -1) {
            relationships.push(relationship);
            index = relationships.findIndex(rel => rel.id === relationship?.id);
        }

        if (relationships[index].coraRelationBalances === undefined) {
            relationships[index].coraRelationBalances = [];
        }

        if (relationships[index].coraRelationBalances[0] === undefined) {
            relationships[index].coraRelationBalances[0] = {};
        }
        return index;
    }

    //prende un valore dal NumberFormat. contiene valore in formato float, valore formattato in stringa e valore raw
    handlerAmount = (relationship, field, value) => {
        let relationships = this.state.relationships;
        const index = this.getRelationshipToUpdate(relationships, relationship)

        relationships[index].coraRelationBalances[0][field] = value.floatValue;

        this.setState({
            relationships: relationships
        })
    };

    handlerCurrency = (relationship, e) => {
        let relationships = this.state.relationships;
        const index = this.getRelationshipToUpdate(relationships, relationship)

        relationships[index].coraRelationBalances[0].currency = {
            id: e.target.value.trim(),
            isoCode: e.target.options[e.target.value].getAttribute("data-cod"),
            description: e.target.options[e.target.value].text
        };
        this.setState({
            relationships: relationships
        })

    };

    handlerCurrencyType = (relationship, e) => {
        let relationships = this.state.relationships;
        const index = this.getRelationshipToUpdate(relationships, relationship)
        if (isNotEmpty(e.target.value)) {
            relationships[index].coraRelationBalances[0].currencyType = {
                id: e.target.value.trim()
            };
        } else {
            relationships[index].coraRelationBalances[0].currencyType = null;
        }
        this.setState({
            relationships: relationships
        })
    }

    handlerIdResult = (relationship, e) => {
        let relationships = this.state.relationships;
        const index = this.getRelationshipToUpdate(relationships, relationship)
        relationships[index].coraRelationBalances[0].idResult = e.target.value.toUpperCase().trim();

        this.setState({
            relationships: relationships
        })
    }

    handleAllCheckChange = (event) => {
        this.setState({ allCheck: event.target.checked });
        if (event.target.checked) {
            if (this.state.checkedItems.size > 0) {
                this.state.checkedItems.clear();
            }
            let items = new Map();
            let relationships = this.state.relationships;
            relationships.map((relatioship, index) => (
                items.set(relatioship.id, true)
            ));
            this.setState({ checkedItems: items })
            let rowIds = document.querySelectorAll('[id^="row-"]');
            for (let i = 0; i < rowIds.length; i++) {
                if (rowIds[i].localName === "div") {
                    rowIds[i].classList.add("bg-cobalt", "bold");
                }
            }

            this.setState({ isDisabled: false });
        } else {
            this.setState({ isDisabled: true, checkedItems: new Map() });
            let rowIds = document.querySelectorAll('[id^="row-"]');
            for (let i = 0; i < rowIds.length; i++) {
                if (rowIds[i].localName === "div") {
                    rowIds[i].classList.remove("bg-cobalt", "bold");
                }
            }
        }
    };

    handleCheckChange(event) {
        if (event !== undefined) {
            this.state.checkedItems.set(parseInt(event.target.value), event.target.checked);
            let count = 0;
            if (!event.target.checked) {
                let element = document.getElementById(event.target.id);
                if (element)
                    element.classList.remove("bg-cobalt", "bold");
            } else {
                let element = document.getElementById(event.target.id);
                if (element)
                    element.classList.add("bg-cobalt", "bold");
            }

            for (const checkedItem of this.state.checkedItems) {
                if (checkedItem[1]) {
                    this.setState({ isDisabled: false });
                    return;
                }
                if (!checkedItem[1]) {
                    count++;
                }
            }
            if (count === this.state.checkedItems.size) {
                this.setState({ isDisabled: true });
            }

        }
    }

    handlerDontSendCommunication = () => {
        this.setState({ loading: true });
        let selectedToExclude = this.state.excludedItems;
        let relationships = this.state.relationships;
        for (const checkedItem of this.state.checkedItems) {
            if (checkedItem[1]) {
                let idToRemove = parseInt(checkedItem[0]);
                selectedToExclude.push(idToRemove);
                let index = relationships.map(function (item) { return item.id }).indexOf(idToRemove);
                relationships.splice(index, 1);
            }
        }
        this.setState({
            excludedItems: selectedToExclude,
            relationships: relationships,
            loading: false
        });
        this.resetCheckBox();
    };

    resetCheckBox = () => {
        let rowIds = document.querySelectorAll('[id^="row-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }

        this.setState({
            checkedItems: new Map(),
            allCheck: false,
            isDisabled: true
        });
        document.getElementsByName('relationshipCheck').forEach(el => {
            if (el.checked) {
                el.checked = false
            }
        })
    }

    sortBy = (val) => {
        if (val) {
            let relationships = this.state.relationships;
            let relationshipsOrdered = relationships;
            switch (val) {
                case "completeName":
                    // Sort by completeName
                    relationshipsOrdered = relationships.sort((a, b) => (a.completeName > b.completeName) ? this.state.sortCompleteName : ((b.completeName > a.completeName) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortCompleteName: -this.state.sortCompleteName });
                    break;
                case "relType":
                    // Sort by relationship type
                    relationshipsOrdered = relationships.sort((a, b) => (a.relationshipType?.code > b.relationshipType?.code) ? this.state.sortRelType : ((b.relationshipType?.code > a.relationshipType?.code) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortRelType: -this.state.sortRelType });
                    break;
                case "startDate":
                    // Sort by startDate
                    relationshipsOrdered = relationships.sort((a, b) => (moment(a.startDate) > moment(b.startDate)) ? this.state.sortStartDate : ((moment(b.startDate) > moment(a.startDate)) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortStartDate: -this.state.sortStartDate });
                    break;
                case "endDate":
                    // Sort by endDate
                    relationshipsOrdered = relationships.sort((a, b) => (moment(a.endDate || new Date()) > moment(b.endDate || new Date())) ? this.state.sortEndDate : ((moment(b.endDate || new Date()) > moment(a.endDate || new Date())) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortEndDate: -this.state.sortEndDate });
                    break;
                default:
                    return;
            }
        }
    }

    renderRowHeader() {
        let smallcolclass = 'col w-7 div-td';
        return (
            <React.Fragment>
                <div className='col w-3 text-center div-td'>
                    <input type="checkbox" checked={this.state.allCheck} onChange={(e) => this.handleAllCheckChange(e)} />
                </div>
                <div className='col w-20 div-td' >Anagrafica <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("completeName")} /></div>
                <div className='col w-10 div-td'>Tipo Rapporto <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("relType")} /></div>
                <div className={smallcolclass}>Data Inizio <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("startDate")} /></div>
                <div className={smallcolclass}>Data Fine <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("endDate")} /></div>
                <div className={smallcolclass}>Importo 1</div>
                <div className={smallcolclass}>Importo 2</div>
                <div className={smallcolclass}>Importo 3</div>
                <div className={smallcolclass}>Importo 4</div>
                <div className={smallcolclass}>Altre Info</div>
                <div className={smallcolclass}>Giac. Media</div>
                <div className={smallcolclass}>Valuta</div>
                <div className={smallcolclass}>Natura</div>
                <div className={smallcolclass}>Esito</div>
            </React.Fragment>
        )
    }

    getDisplayedAmount(balance, amount) {
        if (isEmpty(balance?.id) && isEmpty(amount)) {
            return '';
        } else if ((isNotEmpty(balance?.id) && isEmpty(amount))) {
            return '0';
        } else {
            return amount;
        }
    }

    renderBalanceRows(relationship) {
        let balance = relationship?.coraRelationBalances[0];

        let amount1Balance = this.getDisplayedAmount(balance, balance?.amount1);
        let amount2Balance = this.getDisplayedAmount(balance, balance?.amount2);
        let amount3Balance = this.getDisplayedAmount(balance, balance?.amount3);
        let amount4Balance = this.getDisplayedAmount(balance, balance?.amount4);
        let amount5Balance = this.getDisplayedAmount(balance, balance?.amount5);
        let stockBalance = this.getDisplayedAmount(balance, balance?.stock);
        const rules = getBalanceRulesByRelationshipType(relationship?.relationshipType?.code);
        const disabledFields = getDisabledFields(rules);


        let checked = this.state.checkedItems.get(relationship.id);
        let smallcolclass = 'col w-7 div-td text-truncate';
        let smallcolClassNumber = `${smallcolclass} text-end`
        return (
            <React.Fragment>
                <div className="text-start row-table row-table-without-hover" id={`row-${relationship.id}`}>
                    <div className='col w-3 text-center div-td'>
                        <input type="checkbox" aria-label="Checkbox for following text input" id={`row-${relationship.id}`}
                            name="relationshipCheck"
                            value={relationship.id} checked={checked}
                            onChange={(e) => this.handleCheckChange(e)}
                        />
                    </div>
                    <div className='col w-20 div-td text-truncate'>{relationship.completeName}</div>
                    <div className='col w-10 div-td text-truncate'>{relationship.relationshipType?.code + " - " + relationship.relationshipType?.description}</div>
                    <div className={smallcolclass}>{formatDateForDisplay(relationship.startDate)}</div>
                    <div className={smallcolclass}>{relationship.endDate && formatDateForDisplay(relationship.endDate)}</div>
                    {this.renderNumberCell(amount1Balance, 'amount1', relationship, smallcolClassNumber, disabledFields.amount1Disabled, rules, this.handlerAmount)}
                    {this.renderNumberCell(amount2Balance, 'amount2', relationship, smallcolClassNumber, disabledFields.amount2Disabled, rules, this.handlerAmount)}
                    {this.renderNumberCell(amount3Balance, 'amount3', relationship, smallcolClassNumber, disabledFields.amount3Disabled, rules, this.handlerAmount)}
                    {this.renderNumberCell(amount4Balance, 'amount4', relationship, smallcolClassNumber, disabledFields.amount4Disabled, rules, this.handlerAmount)}
                    {this.renderNumberCell(amount5Balance, 'amount5', relationship, smallcolClassNumber, disabledFields.amount5Disabled, rules, this.handlerAmount)}
                    {this.renderNumberCell(stockBalance, 'stock', relationship, smallcolClassNumber, disabledFields.stockDisabled, rules, this.handlerAmount)}
                    <div className={smallcolclass}>
                        <BwmSelect
                            options={this.props.currencies}
                            showCode={true}
                            name={`currency_${relationship.id}`}
                            className='form-control'
                            nameKey={"currencies"}
                            onChange={e => this.handlerCurrency(relationship, e)}
                            value={balance?.currency?.id || 58}
                        />
                    </div>
                    <div className={smallcolclass}>
                        <BwmSelect
                            options={this.props.currencyTypes}
                            showCode={true}
                            name={`currencyType_${relationship.id}`}
                            className='form-control'
                            onChange={e => this.handlerCurrencyType(relationship, e)}
                            value={balance?.currencyType?.id || null}
                        />
                    </div>
                    <div className={smallcolclass}>
                        <BwmInput
                            name={"idResult"}
                            className={"form-control"}
                            value={balance?.idResult || ""}
                            onChange={e => this.handlerIdResult(relationship, e)}
                        />
                    </div>
                </div>
            </React.Fragment>
        )


    }

    /**
     * 
     * @param {*} value 
     * @param {*} valueName 
     * @param {*} relationship 
     * @param {*} className 
     * @param {*} disabled 
     * @param {*} rules 
     * @param {*} handler 
     * @returns 
     */
    renderNumberCell(value, valueName, relationship, className, disabled, rules, handler) {
        return <NumberCell
            value={value}
            valueName={valueName}
            relationship={relationship}
            className={className}
            disabled={disabled}
            rules={rules}
            handler={handler} />
    }

    render() {
        const { dropdownYears, selectedYear, relationships } = this.state;
        return (
            <React.Fragment>

                <div className="row header-btn-relationship">
                    <div className="col-1">
                        <BwmSelect
                            options={dropdownYears}
                            name='year'
                            label='Anno'
                            className='form-control fw-bold balance-year-dropdown '
                            onChange={this.handlerYearChange}
                            value={selectedYear}
                            showCode={false}
                        />
                    </div>
                    <div className={"col-11"}>
                        <Row className='align-items-center'>

                            <div className='col-7' role="group" aria-label="action buttons">
                                <button className="btn  btn-empty px-4 btn-sm margin-left-5px " onClick={() => this.openGuideBalances()}>
                                    <IQuestion className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />&nbsp;ISTRUZIONI SALDI
                                </button>
                                <button className="btn  btn-empty px-4 btn-sm margin-left-5px " onClick={() => this.showAll()}>
                                    {this.state.showAll ? 'Mostra primi ' + actions.BALANCE_DEFAULT_PAGE_SIZE : 'Mostra tutti'}
                                </button>
                                <YearlyBalanceExportModal disabled={false} selectedYear={selectedYear} {...this.props} subjectId={this.props.subjectId} />
                                <YearlyBalanceImportModal {...this.props} subjectId={this.props.subjectId} selectedYear={selectedYear} getYearlyBalance={()=>this.getYearlyBalance(this.props.subjectId, selectedYear, 0, 0)} />
                                <button type="button" className={`btn   btn-empty px-4 btn-sm margin-left-5px ${this.state.isDisabled && 'disabled'}`} disabled={this.state.checkedItems.size === 0} onClick={(e) => this.handlerDontSendCommunication()}>
                                    <IExclude className={"padding-bottom-4px"} fill={` ${this.state.isDisabled ? '#FFFFFF' : '#128186'}`} width="15" />&nbsp;Escludi dalla comunicazione
                                </button>

                            </div>
                            <div className="col-5 d-flex justify-content-end" role="group" aria-label="action buttons">
                                <button className="btn  btn-empty px-5 btn-sm "
                                    onClick={() => this.saveBalances()}>
                                    <ISave className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />&nbsp;SALVA</button>
                                <span className={'ms-3'}>
                                    <CreateCommunicationModal
                                        type="YEARLY"
                                        onClickCreateCommunicationBefore={this.saveBalances}
                                        subjectId={this.props.subjectId}
                                        subject={this.props.subject}
                                        selectedYear={this.state.selectedYear}
                                        excludedRelationships={this.state.excludedItems}
                                        isDisabled={relationships.length === 0}
                                    />

                                </span>

                            </div>
                        </Row>
                    </div>


                </div>

                {this.renderRows(relationships)}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        subject: state.subject.data,
        yearlyBalanceRelationships: state.yearlyBalanceRelationships.data,
        currencies: state.currencies.data,
        currencyTypes: state.currencyTypes.data
    }
}

export default connect(mapStateToProps)(YearlyBalanceList);


//memoizzo la cella per impedire rerender non necessari. Potrebbe valere la pena memoizzare l'intera riga
const NumberCell = memo(function NumberCell({ value, valueName, relationship, className, disabled, rules, handler }) {
    return (
        <StarTooltip text={rules?.[valueName]} >
            <div className={className} >
                <NumberFormat
                    key={`${valueName}_${relationship.id}`}
                    name={`${valueName}_${relationship.id}`}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    className="form-control"
                    inputMode="numeric"
                    value={disabled ? "-" : value}
                    readOnly={disabled}
                    onValueChange={e => handler(relationship, valueName, e)} 
                    />
            </div>
        </StarTooltip>

    );
})
