import React from "react";
import { Col, Row } from "react-bootstrap";
import { FloatLabelTextBox } from "../../shared/form/input-boxes/FloatLabelTextBox";

export function AdministratorSection({
    sectionTitleStyle,
    amministratoreNomeCognome, amministratoreCodiceFiscale,
    amministratorePassword, amministratorePin, amministratoreProgressivoSede,
    setAmministratoreNomeCognome, setAmministratoreCodiceFiscale,
    setAmministratorePassword, setAmministratorePin,
    setAmministratoreProgressivoSede
}) {

    return (
        <>
            <div className={sectionTitleStyle}>CREDENZIALI Accesso Area Privata dell'AMMINISTRATORE/INCARICATO del soggetto obbligato (Entratel o Fiscoline)**</div>
            <Row>
                <Col lg={6}>
                    <FloatLabelTextBox
                        value={amministratoreNomeCognome}
                        label={"Nome e Cognome"}
                        onChange={(e) => setAmministratoreNomeCognome(e.target.value)}
                    />

                </Col>
                <Col lg={3}>
                    <FloatLabelTextBox
                        value={amministratoreCodiceFiscale}
                        label={"Codice Fiscale"}
                        onChange={(e) => setAmministratoreCodiceFiscale(e.target.value)}
                    />

                </Col>
            </Row>
            <Row>
                <Col lg={5}>
                    <FloatLabelTextBox
                        value={amministratorePin}
                        label={"PIN Fisconline/Entratel"}
                        onChange={(e) => setAmministratorePin(e.target.value)}
                    />
                </Col>

                <Col lg={5}>
                    <FloatLabelTextBox
                        value={amministratorePassword}
                        label={"Password"}
                        onChange={(e) => setAmministratorePassword(e.target.value)}
                    />
                </Col>

            </Row>
            <Row>
                <Col lg={3}>
                    <FloatLabelTextBox
                        value={amministratoreProgressivoSede}
                        label={"Progressivo sede"}
                        onChange={(e) => setAmministratoreProgressivoSede(e.target.value)}
                    />
                </Col>
            </Row>
        </>
    )
}