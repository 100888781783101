import React from 'react'
import Autosuggest from 'react-autosuggest';
import "./AutoCompileCustom.css";
import { isEmpty } from '../Utility';


const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestions = (value, filtro, filterValues) => {
    
    if(!filterValues){
        return filtro;
    }

    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
        return [];
    }
    const regex = new RegExp('^.*' + escapedValue + '.*$', 'i');
    const suggestions = filtro.filter(datas => regex.test(datas.name));

    return suggestions;
};

class AutoCompileCustom extends React.Component {
    constructor(props) {
        super();

        this.state = {
            filterValues: isEmpty( props.filterValues) ,
            value: '',
            suggestions: [],
            input: '',
            suggestionIndex: -1,
            error: { isValid: true, msg: '' },

        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.error != undefined) {
            this.setState({ error: nextProps.error });
        }
    }

    callHandle(suggestionValue, suggestionIndex) {

        this.props.handleInputAutocompile(suggestionValue, this.props.id, suggestionIndex);

    }

    onChange = (event, { newValue, method }) => {

        this.setState({
            value: newValue,
            input: method,
            suggestionIndex: event.target.dataset.suggestionIndex
        });
        this.props.handleInputAutocompile(newValue, this.props.id, event.target.dataset.suggestionIndex);
    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        if (method === 'click') {
            this.setState({
                value: suggestionValue,
                input: method,
                suggestionIndex: event.target.dataset.suggestionIndex
            });
            this.callHandle(suggestionValue, event.target.dataset.suggestionIndex);
        } else {
            event.preventDefault()
        }
    }
    componentDidMount() {
        if (document.getElementsByClassName('react-autosuggest__input').length === 1) {

            document.getElementsByClassName('react-autosuggest__input')[0].classList.add("form-control");
        }

    }

    getSuggestionValue = suggestion => {

        return suggestion.name;
    };

    renderSuggestion = suggestion => {
        return suggestion.name;
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value, this.props.filtro, this.state.filterValues)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    reset = () => {
        this.setState({
            value: '',
            suggestions: [],
            input: '',
            suggestionIndex: -1
        });
    }

    render() {
        const { suggestions } = this.state;
        const inputProps = {
            placeholder: this.props.placeholder,
            value: this.props.value,
            onChange: this.onChange
        };
        return (
            <div className='form-group'>
                <label>{this.props.label}</label>
                <Autosuggest
                    shouldRenderSuggestions={() => true}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    onSuggestionSelected={this.onSuggestionSelected}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    disabled={true}
                />
                {!this.state.error.isValid
                    ? <div className="text-danger">{this.state.error.msg}</div>
                    : ''}
            </div>


        );
    }
}

export default AutoCompileCustom
