import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React from 'react';

//v. documentazione: https://react-bootstrap-v4.netlify.app/components/overlays/#tooltips
/**
 * Componente wrapper per visualizzare tooltip
 * se props.text è vuoto, il toltip viene nascosto
 * @param {*} props 
 * @returns 
 */
export function StarTooltip({text,className,placement,children}) {
    return (
        <React.Fragment>
        
        {
            text ?
                <OverlayTrigger
                    placement={placement || 'top'}
                    overlay={
                        <Tooltip aria-label={text} className={className}>
                            {text}
                        </Tooltip>
                    }>{children}</OverlayTrigger>
                :
                <>
                    {children}
                </>
        }
        </React.Fragment>

    )
}