import React, { useEffect, useState } from "react";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";

import { DraggableList } from "../form/lists/DraggableList";
import { TableUtils } from "./TableUtils";
import { SelectableElement } from "../form/lists/SelectableElement";
import { NumberOfResultsSelector } from "../searchComponents/NumberOfResultsSelector";

export function HeaderFilterDropdown({
    headers = []
    , hiddenColumnsProps = []
    , show = false
    , onHide = () => { }
    , resultsPerPage = 20 }) {

    const [hiddenColumns, setHiddenColumns] = useState(hiddenColumnsProps);
    const [headersState, setHeadersState] = useState([]);
    const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(resultsPerPage);

    useEffect(() => {
        setHeadersState(headers)
    }, [headers]);

    useEffect(() => {
        setSelectedResultsPerPage(resultsPerPage)
    }, [resultsPerPage]);

    const toggleColumn = (column) => {
        if (TableUtils.isHiddenColumn(hiddenColumns, column)) {
            setHiddenColumns(hiddenColumns.filter(v => v.fieldName !== column.fieldName));
        } else {
            setHiddenColumns(hiddenColumns.concat(column));
        }
    };

    const updateHeadersOrder = (reordered = []) => {
        setHeadersState(TableUtils.updateHeadersOrder(headersState, reordered));
    }

    return (
        <Modal
            show={show}

            dialogClassName="h-75"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={() => onHide(hiddenColumns, headersState, selectedResultsPerPage)}
            centered
            scrollable
        >
            <Modal.Header>
                <h3>
                    Configura Tabella
                </h3>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center ">
                    <DraggableList
                        elements={headersState}
                        listItemComponent={(element) => {
                            return <SelectableHeader
                                key={`header-filter-${element}`}
                                header={element}
                                onChange={toggleColumn}
                                hiddenColumns={hiddenColumns}
                            />
                        }}
                        onReorder={(reordered) => {
                            updateHeadersOrder(reordered)
                        }}
                    />

                </div>
                <div className="d-flex ms-3  justify-content-start">
                    <NumberOfResultsSelector
                        selected={selectedResultsPerPage}
                        onChange={val => setSelectedResultsPerPage(Number(val))}
                    />

                </div>

            </Modal.Body>
            <Modal.Footer>
                <Row >
                    <Col className="text-end">
                        <button
                            className="btn btn-outline-primary btn-empty"
                            onClick={() => onHide(hiddenColumns, headersState, selectedResultsPerPage)}
                        >
                            OK
                        </button>
                    </Col>

                </Row>
            </Modal.Footer>
        </Modal>

    )
}

function SelectableHeader({
    header,
    hiddenColumns,
    onChange
}) {

    return <SelectableElement
        value={header}
        onChange={() => onChange(header)}
        defaultChecked={!TableUtils.isHiddenColumn(hiddenColumns, header)}
        label={header.displayedName}
    />

}