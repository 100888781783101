import React from 'react';
import * as actions from "../../../../actions";
import { ReactComponent as ITextFile } from "../../../../styles/images/svgs/regular/file-alt.svg";
import { ReactComponent as ISave } from "../../../../styles/images/svgs/regular/save.svg";
import { ReactComponent as IUndo } from "../../../../styles/images/svgs/regular/times-circle.svg";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustom";
import { BwmInput } from "../../../shared/form/BwmInput";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import { PopupError } from "../../../shared/PopupError";
import { ProvinceField } from "../../application-cora/relationships/ProvinceField";
import { ReactComponent as IUpload } from "../../../../styles/images/svg/file-upload.svg";
import { ImportModal } from '../../../shared/popups/ImportModal';
import { PopupSuccess } from '../../../shared/PopupSuccess';
import { PageSpinner } from '../../../shared/spinner/PageSpinner';


export class ArcoSubjectForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            subject: {
                id: this.props.subjectItem?.id || null,
                fiscalCode: this.props.subjectItem?.fiscalCode || "",
                companyName: this.props.subjectItem?.companyName || "",
                address: this.props.subjectItem?.address || "",
                location: this.props.subjectItem?.location || "",
                province: this.props.subjectItem?.province || "",
                postalCode: this.props.subjectItem?.postalCode || "",
                vatNumber: this.props.subjectItem?.vatNumber || "",
                operationDescription: this.props.subjectItem?.operationDescription || "",
                operationNature: this.props.subjectItem?.operationNature || "",
                operationPurpose: this.props.subjectItem?.operationPurpose || "",
                arcoSubjectType: this.props.subjectItem?.arcoSubjectType || (this.props?.subjectTypes ? this.props?.subjectTypes[0] : {})
            },
            showImportDocumentsModal: false,
            errorSubjectCode: { isValid: true, msg: "" },
            errorFiscalCode: { isValid: true, msg: "" },
            errorCompanyName: { isValid: true, msg: "" },
            errorAddress: { isValid: true, msg: "" },
            errorLocation: { isValid: true, msg: "" },
            errorProvince: { isValid: true, msg: "" },
            errorPostalCode: { isValid: true, msg: "" },
            errorVatNumber: { isValid: true, msg: "" },
            errorSubjectType: { isValid: true, msg: "" },
            errorOperationDescription: { isValid: true, msg: "" },
            errorOperationNature: { isValid: true, msg: "" },
            errorOperationPurpose: { isValid: true, msg: "" },
            isLoading: false
        }
    }

    handlerChangeFiscalCode = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                fiscalCode: e.target.value.toUpperCase()
            },
            errorFiscalCode: { isValid: true, msg: "" },
        });
    };

    handlerChangeOperationDescription = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                operationDescription: e.target.value.toUpperCase()
            },
            errorOperationDescription: { isValid: true, msg: "" },
        });
    };

    handlerChangeOperationNature = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                operationNature: e.target.value.toUpperCase()
            },
            errorOperationNature: { isValid: true, msg: "" },
        });
    };

    handlerChangeOperationPurpose = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                operationPurpose: e.target.value.toUpperCase()
            },
            errorOperationPurpose: { isValid: true, msg: "" },
        });
    };


    setSubjectType = (subjectType) => {
        this.setState({
            subject: {
                ...this.state.subject,
                arcoSubjectType: subjectType
            }
        });
    }
    handlerChangeSubjectType = (e) => {

        const cod = e.target[e.target.selectedIndex].dataset.cod;
        this.setSubjectType({ id: parseInt(e.target.value), code: cod });
    }

    handleFiscalCode = val => {
        actions.getBaseRegistryByFiscalCode(val).then(
            (baseRegistry) => {
                if (baseRegistry) {
                    this.setState({
                        subject: {
                            ...this.state.subject,
                            companyName: baseRegistry?.companyName || '',
                            location: baseRegistry?.location?.location || '',
                            province: baseRegistry?.location?.province || '',
                            postalCode: baseRegistry?.location?.postalCode || ''
                        },
                        errorCompanyName: { isValid: true, msg: "" },
                        errorLocation: { isValid: true, msg: "" },
                        errorProvince: { isValid: true, msg: "" },
                        errorPostalCode: { isValid: true, msg: "" }
                    });
                }
            },
            (errors) => {
                console.log(errors);
            }
        )
    };

    handlerChangeCompanyName = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                companyName: e.target.value.toUpperCase()
            },
            errorCompanyName: { isValid: true, msg: "" },
        });
    };

    handlerChangeVatNumber = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                vatNumber: e.target.value.toUpperCase()
            },
            errorVatNumber: { isValid: true, msg: "" },
        });
    };

    handlerChangeAddress = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                address: e.target.value.toUpperCase()
            },
            errorAddress: { isValid: true, msg: "" },
        });
    };

    handleProvinceChange = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                province: e.target.value.toUpperCase()
            },
            errorProvince: { isValid: true, msg: "" }
        });
    };

    handlePostalCodeChange = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                postalCode: e.target.value
            },
            errorPostalCode: { isValid: true, msg: "" }
        });
    };

    handleValidation = () => {
        let isValid = true;
        if (!this.state.subject.companyName) {
            this.setState({
                errorCompanyName: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.fiscalCode) {
            this.setState({
                errorFiscalCode: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.address) {
            this.setState({
                errorAddress: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.location) {
            this.setState({
                errorLocation: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.province) {
            this.setState({
                errorProvince: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.postalCode) {
            this.setState({
                errorPostalCode: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.vatNumber) {
            this.setState({
                errorVatNumber: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        return isValid;
    };

    addArcoSubject = (subject, showGestioneTabelle) => {
        actions.saveArcoSubject(subject).then(
            (response) => {
                console.log("subject updated:" + JSON.stringify(response))
                this.setState({
                    subject: response
                }, () => {
                    this.props.handleCloseModal();
                    this.props.getSubjects();
                    if (showGestioneTabelle) {
                        this.props.showPopupGestioneTabelle(this.state.subject);
                    }
                });
            },
            (errors) => {
                this.props.handleCloseModal();
                PopupError(
                    {
                        ...this.props,
                        text: errors.status === 511 ?
                            'Hai raggiunto il numero massimo di soggetti gestibili con questa licenza.'
                            :
                            "Errore nell'aggiornamento del soggetto " + errors
                    }
                );
            }
        );

    };

    updateAll = (showGestioneTabelle) => {
        if (!this.handleValidation()) return;
        this.addArcoSubject(this.state.subject, showGestioneTabelle);
    };


    handleInputAutocompile = (newLocation, id, suggestionIndex) => {
        this.setState({
            subject: {
                ...this.state.subject,
                location: newLocation.toUpperCase()
            },
            errorLocation: { isValid: true, msg: "" }
        });
        actions.getLocation(newLocation.toUpperCase()).then(
            (loc) => {
                if (loc) {
                    this.setState({

                        subject: {
                            ...this.state.subject,
                            location: loc.location,
                            province: loc.province,
                            postalCode: loc.postalCode
                        },
                        errorLocation: { isValid: true, msg: "" },
                        errorProvince: { isValid: true, msg: "" }
                    });
                } else {
                    this.setState({

                        subject: {
                            ...this.state.subject,
                            location: newLocation.toUpperCase()
                        },
                        errorLocation: { isValid: true, msg: "" }
                    });
                }
            })
    };

    gestioneTabelle = () => {
        if (!this.handleValidation()) return;
        actions.saveArcoSubject(this.state.subject).then(
            (response) => {
                console.log("subject updated:" + JSON.stringify(response))
                this.props.showPopupGestioneTabelle(response);
            });
    }

    uploadZipFile= (dataFile)=>{
        let formData = new FormData();
        if (dataFile?.length > 0) {
            this.setState({isLoading:true}, () =>{
                for (let file of dataFile) {
                    formData.append("file", file);
                }
                actions.importArcoDocumentsZip(formData, this.state.subject.id).then(
                    (res)=>{
                        PopupSuccess({title: `Sono stati importati ${res} documenti`});
                    },
                    (err)=>{
                        PopupError({ text: "Errore durante l'import dei documenti" });
                    }).then(()=> this.setState({isLoading:false, showImportDocumentsModal:false}));

            })
        }
    }

    render() {
        return (
            <>  
                {this.state.isLoading && <PageSpinner></PageSpinner>}
                <div className="row-detail bg-blue pt-4 pe-3 pb-4 ps-3 d-flex row-form big">
                    <div>
                        <div className="soggetto-crs">
                            <form id={`anagrafica-${this.props.subject?.id || 0}`}>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <BwmInput name='fiscalCode'
                                            label='Codice Fiscale'
                                            className='form-control'
                                            value={this.state.subject.fiscalCode}
                                            onChange={e => this.handlerChangeFiscalCode(e)}
                                            error={this.state.errorFiscalCode}
                                            maxLength="16"
                                            onBlur={e => this.handleFiscalCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <BwmSelect
                                            options={this.props.subjectTypes}
                                            name='subjectType'
                                            label='Tipo Soggetto'
                                            className='form-control'
                                            onChange={e => this.handlerChangeSubjectType(e)}
                                            value={this.state.subject.arcoSubjectType.id}
                                            error={this.state.errorSubjectType}
                                            showCode={true}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-7'>
                                        <BwmInput name='Ragione Sociale'
                                            label='Ragione Sociale'
                                            type="companyName"
                                            className='form-control'
                                            value={this.state.subject.companyName}
                                            onChange={e => this.handlerChangeCompanyName(e)}
                                            error={this.state.errorCompanyName}
                                            maxLength="60"
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        <BwmInput label='Partita IVA'
                                            className='form-control'
                                            value={this.state.subject.vatNumber}
                                            onChange={e => this.handlerChangeVatNumber(e)}
                                            error={this.state.errorVatNumber}
                                            maxLength="16" />
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className='col-md-4'>
                                        <BwmInput name='Indirizzo'
                                            label='Indirizzo'
                                            className='form-control'
                                            value={this.state.subject.address}
                                            onChange={e => this.handlerChangeAddress(e)}
                                            error={this.state.errorAddress}
                                            maxLength="100"
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <AutoCompileCustom
                                            label='Località'
                                            id="location"
                                            handleInputAutocompile={e => this.handleInputAutocompile(e)}
                                            filtro={this.props.locations}
                                            value={this.state.subject.location || ""}
                                            error={this.state.errorLocation}
                                        />
                                    </div>
                                    <div className='col-md-2'>
                                        <ProvinceField province={this.state.subject.province || ""}
                                            onChange={e => this.handleProvinceChange(e)}
                                            error={this.state.errorProvince} />
                                    </div>
                                    <div className='col-md-2'>
                                        <BwmInput name='CAP'
                                            label='CAP'
                                            type="postalCode"
                                            className='form-control'
                                            value={this.state.subject.postalCode}
                                            onChange={e => this.handlePostalCodeChange(e)}
                                            error={this.state.errorPostalCode}
                                            maxLength="5"
                                        />
                                    </div>
                                </div>
                            </form>
                            <div style={{
                                position: 'absolute',
                                bottom: '15px', right: '100px', fontSize: '15px'
                            }}
                                className="text-danger">
                                {this.state.errorInConfigSid}
                            </div>
                        </div>
                        <div className="soggetto-crs">
                            <form>
                                <div>
                                    <h6><strong>Valori preimpostati</strong></h6>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <BwmInput name='operationDescription'
                                                label='Descrizione Operazione'
                                                className='form-control'
                                                value={this.state.subject.operationDescription}
                                                onChange={e => this.handlerChangeOperationDescription(e)}
                                                error={this.state.errorOperationDescription}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <BwmInput name='operationNature'
                                                label='Natura Operazione'
                                                className='form-control'
                                                value={this.state.subject.operationNature}
                                                onChange={e => this.handlerChangeOperationNature(e)}
                                                error={this.state.errorOperationNature}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <BwmInput name='operationPurpose'
                                                label='Scopo'
                                                className='form-control'
                                                value={this.state.subject.operationPurpose}
                                                onChange={e => this.handlerChangeOperationPurpose(e)}
                                                error={this.state.errorOperationPurpose}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div style={{
                                position: 'absolute',
                                bottom: '15px', right: '100px', fontSize: '15px'
                            }}
                                className="text-danger">
                                {this.state.errorInConfigSid}
                            </div>
                        </div>
                        <div
                            className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                            <button
                                className="btn btn-outline-primary btn-empty px-4 btn-sm"
                                onClick={this.props.handleCloseModal}><IUndo className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />&nbsp; ANNULLA
                            </button>

                            <button onClick={e => this.props?.showModal ? this.updateAll(this.props?.showModal) : this.gestioneTabelle()}
                                className="btn btn-primary btn-new-rel px-5"><ITextFile className={"padding-bottom-4px svg"} width="13" />&nbsp; GESTIONE TABELLE
                            </button>
                            {this.state.subject.id && <button onClick={() => { this.setState({ showImportDocumentsModal: true }) }}
                                className="btn btn-primary btn-new-rel px-5"
                                disabled={!this.props.isLicenseValid}><IUpload className={"padding-bottom-4px svg"} width="13" />&nbsp; IMPORTA DOCUMENTI
                            </button>}
                            <button onClick={e => this.updateAll(false)}
                                className="btn btn-primary btn-new-rel px-5"><ISave className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; CONFERMA
                            </button>
                        </div>
                    </div>

                </div>
                {this.state.showImportDocumentsModal &&
                    <ImportModal
                        key={"import-zip-" + this.props.subject?.id}
                        show={this.state.showImportDocumentsModal}
                        allowedFileExtensions={['zip', '7z', 'rar']}
                        onHide={() => { this.setState({ showImportDocumentsModal: !this.state.showImportDocumentsModal }) }}
                        onSend={(dataFile) => this.uploadZipFile(dataFile)}
                        secondaryTitle={() => <div>Importa un file zip/7z/rar con i documenti di ARCO desktop</div>}
                    />}
            </>

        )
    }

}