import React, { useState, useEffect } from "react";
import { ReactComponent as IPdfFile } from "../../../styles/images/svg/file-pdf.svg";
import Modal from "react-bootstrap/Modal";
import BlueDBListModal from "./BlueDBListModal";
import * as Constants from "../../../config/Constants";
import BlueResultDetailsPopup from "./BlueResultDetailsPopup";
import getText from "./labels";
import { useSelector } from "react-redux";
import { PopupError } from "../../shared/PopupError";
import { blueDownloadAntiTerrorism } from "../../../actions";
import { base64ToArrayBuffer, downloadFile } from "../../shared/Utility";
import { PopupSuccess } from "../../shared/PopupSuccess";
import moment from "moment";

export default function BlueResultForm(props) {
  const { subject, results } = props;
  const [showDBListModal, setShowDBListModal] = useState(false);
  const [hasResults, setHasResults] = useState(false);
  const [showDetails, setShowdetails] = useState(false);
  const [itemDetails, setItemDetails] = useState(null);
  const lang = useSelector((state) => state.blueLanguage.language);
  const labels = getText(lang);

  const closeModal = () => {
    setShowDBListModal(!showDBListModal);
  };

  useEffect(() => {
    setHasResults(results?.result?.length > 0);
  }, [results]);

  const showDetailsModal = (item) => {
    setItemDetails(item);
    setShowdetails(true);
  };

  const closeDetailsModal = () => {
    setShowdetails(false);
  };

  const renderRows = () => {
    if (hasResults) {
      return props?.results?.result[0]?.entity?.map((o, index) => {
        if (index < 100) {
          let name = o?.nameJoin;
          let birthDate = o?.birthList && o?.birthList[0]?.date;
          let citizenship = o?.citizenList && o?.citizenList[0]?.country;
          let notes = o?.note;

          return (
            <React.Fragment key={o?.id}>
              <div className={"text-start row-table "} >
                <div
                  className="col-lg-3 div-td text-start text-truncate"
                  onClick={() => showDetailsModal(o)}
                >
                  {name}
                </div>
                <div
                  className="col-lg-2 div-td text-start text-truncate"
                  onClick={() => showDetailsModal(o)}
                >
                  {birthDate}
                </div>
                <div
                  className="col-lg-2 div-td text-start text-truncate"
                  onClick={() => showDetailsModal(o)}
                >
                  {citizenship}
                </div>
                <div
                  className="col-lg-5 div-td text-start text-truncate"
                  onClick={() => showDetailsModal(o)}
                >
                  {notes}
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
    }
  };


  const downloadDocument = (subject, results, entityId) => {
    blueDownloadAntiTerrorism(subject?.id, results?.savedEventId, entityId, lang).then((response) => {
      if (response) {
        let ext = "pdf";
        downloadFile(base64ToArrayBuffer(response), `${subject.denomination}-${moment(results.searchDate).format('YYYY-MM-DD')}.${ext}`)
        PopupSuccess({
          title: labels.SUCCESSTITLE,
          text: labels.DOWLOADOK,
          ok: labels.SUCCESSOK
        });
      } else {
        PopupError({
          text: labels.FILEERROR,
          title: labels.WARNING,
          request: labels.CONTACTSUPPORT,
          close: labels.CLOSE
        });
      }

    }, (err) => {
      PopupError({
        text: labels.FILEERROR,
        title: labels.WARNING,
        request: labels.CONTACTSUPPORT,
        close: labels.CLOSE
      });
    });
  }



  return (
    <React.Fragment>
      <div
        className="container me-0 ms-0 ps-0 pe-0 overflow-auto border"
        style={{ height: "55vh" }}
      >
        <div className="row me-0 ms-0 ">
          <div className="col-lg-12 ps-0 pe-0">
            <div className="text-start header-table">
              <div className="col-lg-3 div-td ">
                <strong>{labels.NAME}</strong>{" "}
              </div>
              <div className="col-lg-2 div-td ">
                <strong>{labels.BIRTHDATE}</strong>{" "}
              </div>
              <div className="col-lg-2 div-td ">
                <strong>{labels.CITIZENCOUNTRY}</strong>{" "}
              </div>
              <div className="col-lg-5 div-td">
                <strong>{labels.NOTE}</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="row me-0 ms-0 ">
          <div className="col-lg-12 ps-0 pe-0 ">{renderRows()}</div>
        </div>
      </div>
      <div className="row me-0 ms-0 mb-3 pb-2 border">
        <div className="col-lg-12 ">
          <div className="row me-0 ms-0 text-start  justify-content-between align-items-center mt-2">
            <div className="col-lg-6">
              {hasResults && "Sono visualizzati solo i primi 100 risultati"}
            </div>
            <div className="col-lg-3">

              <button
                className="btn btn-primary w-100 btn-new-rel px-3"
                disabled={!hasResults}
                onClick={() => downloadDocument(subject, results)}
              >
                <IPdfFile
                  className={"padding-bottom-4px svg"}
                  width="16"
                  fill={"#FFFFFF"}
                />{" "}
                {labels.VIEWPDF}
              </button>

            </div>
          </div>
        </div>
      </div>
      <div className="row me-0 ms-0 justify-content-end">
        <div className="btngroup" aria-label="Liste">
          <button
            className="btn btn-outline-primary btn-empty px-3 me-4"
            onClick={() => setShowDBListModal(true)}
          >
            {labels.ORIGINLIST}
          </button>
          <a
            href={Constants.BLUE_DATABASES[0].url}
            target="_blank"
            rel="noopener noreferrer"
            className="px-3 mx-1 btn btn-outline-primary btn-empty "
            role="button"
          >
            EU
          </a>
          <a
            href={Constants.BLUE_DATABASES[1].url}
            target="_blank"
            rel="noopener noreferrer"
            className="px-3 mx-1 btn btn-outline-primary btn-empty "
            role="button"
          >
            TALEB
          </a>
          <a
            href={Constants.BLUE_DATABASES[2].url}
            target="_blank"
            rel="noopener noreferrer"
            className="px-3 mx-1 btn btn-outline-primary btn-empty "
            role="button"
          >
            USA
          </a>
          <a
            href={Constants.BLUE_DATABASES[3].url}
            target="_blank"
            rel="noopener noreferrer"
            className="px-3 mx-1 btn btn-outline-primary btn-empty "
            role="button"
          >
            UK
          </a>
        </div>
      </div>

      <Modal
        backdrop="static"
        show={showDBListModal}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="bar"></div>
        <Modal.Header closeButton className="ps-3">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.ORIGINLIST}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlueDBListModal key="DBListModal" />
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={showDetails}
        onHide={closeDetailsModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <div className="bar"></div>
        <Modal.Header closeButton className="ps-3">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.SEARCHRESULTTITLE}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlueResultDetailsPopup item={itemDetails} />
        </Modal.Body>
        <Modal.Footer>
          <div className="row mt-6 w-100 align-items-center justify-content-around">
            <div className="col-lg-4 ">
              <button
                className="btn btn-primary  btn-new-rel px-3"
                onClick={closeDetailsModal}
              >
                {labels.CLOSE}
              </button>

            </div>
            <div className="col-lg-4 ">
              <button
                className="btn btn-primary btn-new-rel px-3"
                onClick={() => downloadDocument(subject, results, itemDetails._id)}
              >
                <IPdfFile
                  className={"padding-bottom-3px svg"}
                  width="16"
                  fill={"#FFFFFF"}
                />{" "}
                {labels.VIEWPDF}
              </button>

            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
