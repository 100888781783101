import React, { useEffect, useState } from 'react';
import * as actions from "../../../../actions";
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm';
import { PopupSuccess } from '../../../shared/PopupSuccess';
import { useSelector, useDispatch } from 'react-redux';
import { CommunicationModal } from './CommunicationModal';
import { Modal, Row } from 'react-bootstrap';
import EmailiForm from './EmailiForm';
import { CommunicationHistoryList } from './CommunicationHistoryList';
import { ReactComponent as IDelete } from "../../../../styles/images/svg/trash-solid.svg";
import { PopupError } from '../../../shared/PopupError';
import * as Constants from "../../../../config/Constants";
import { PopupDelete } from '../../../shared/PopupDelete';
import { CommunicationErrorList } from './CommunicationErrorList';
import { PageSpinner } from '../../../shared/spinner/PageSpinner';
import { downloadDgn, downloadErrorReport, downloadPdf, downloadPecTz, downloadReceiptTxt, downloadTxt } from '../CommunicationDownloadUtils';
import { isEmpty } from '../../../shared/Utility';
import { BwmSelect } from '../../../shared/form/BwmSelect';
import { BwmInput } from '../../../shared/form/BwmInput';


export function CommunicationsTab(props) {
    const { subjectId } = props;
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const communicationHistory = useSelector(state => state.communicationsHistory.data);
    const [loadedInitialHistory, setLoadedInitialHistory] = useState(false);
    const [initialHistoryRows, setInitialHistoryRows] = useState([]);
    const communicationErrors = useSelector(state => state.lastErrorCommunication.data);
    const subject = useSelector(state => state.subject.data);
    const [showCommunicationModal, setShowCommunicationModal] = useState(false);
    const [reelaboratedCommunication, setReelaboratedCommunication] = useState(undefined);
    const resetCommunicationModal = () => {
        setShowCommunicationModal(false);
        setReelaboratedCommunication(undefined);
    }
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [sentCommunication, setSentCommunication] = useState(undefined);


    const resetEmailModal = () => {
        setShowEmailModal(false);
        setSentCommunication(undefined);
    }

    const getHistory = () => {
        dispatch(actions.getCommunicationsHistoryBySubjectId(subjectId));
    }

    const getErrors = () => {
        dispatch(actions.getLastCommunicationsErrorBySubjectId(subjectId));
    }

    const getSubject = () => {
        dispatch(actions.getCoraSubject(subjectId))
    }

    const reloadAll = () => {
        setIsLoading(true);
        getHistory();
        getErrors();
        getSubject();
        setIsLoading(false);
    }

    useEffect(() => {
        reloadAll();
    }, []);

    //Conservo il risultato del caricamento iniziale delle righe: servirà a determinare se mostrare le tabelle o no
    useEffect(()=>{
        if(!loadedInitialHistory && communicationHistory.length !== 0 ){
            setInitialHistoryRows(communicationHistory);
            setLoadedInitialHistory(true);
        }
    },[communicationHistory] )



    const reelaborateCommunicationTxt = (communication) => {
        PopupConfirm({
            title: "Richiesta conferma"
            , innerHtml: `Rielaborazione comunicazione txt: <p class = "text-truncate">${communication.txtFilename}</p>`
            , titleColor: titleColors.SUCCESS
            , handleClickConfirm: () => {
                setIsLoading(true);
                actions.reElaborateCommunicationTxt(subjectId, communication.id).then(
                    (res) => {
                        if (res?.length) {
                            setReelaboratedCommunication(res[0]);
                            setShowCommunicationModal(true);
                            reloadAll();
                        }
                    }
                    , (err) => {
                        PopupError({ text: err || Constants.APPLICATION_GENERIC_ERROR });
                    })
                    .then(() => setIsLoading(false));

            }
        });
    }

    const renderCommunicationModal = () => {
        return (reelaboratedCommunication && <CommunicationModal
            showModal={showCommunicationModal}
            closeModal={() => resetCommunicationModal()}
            communications={[reelaboratedCommunication]}
            coraSubject={reelaboratedCommunication.subject}
            downloadTxt={downloadTxt}
            downloadPecTz={downloadPecTz}
            downloadDgn={downloadDgn}
            downloadPdf={downloadPdf}
            downloadErrorReport={downloadErrorReport}
        />)
    }

    const renderEmailModal = () => {
        return (<Modal
            backdrop="static"
            show={showEmailModal}
            onHide={() => resetEmailModal()}
            dialogClassName="modal-60w"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <div className="bar"></div>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h2>INVIA COMUNICAZIONE SELEZIONATA</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EmailiForm
                    {...props}
                    closeModal={() => resetEmailModal()}
                    emailAddress={subject?.coraSubjectSid?.email}
                    flagPec={subject?.coraSubjectSid?.flagPec}
                    sentCommunications={[sentCommunication]}
                    subject={subject}
                    subjectId={subject?.id}
                />
            </Modal.Body>
        </Modal>)
    }

    const renderDeleteButton = (disabled = false, communications = []) => {
        return <button type="button" className={`btn btn-empty px-4 btn-sm margin-left-5px ${disabled && 'disabled'}`}
            onClick={() => openPopupDelete(communications)} disabled={disabled}>
            <IDelete className={"padding-bottom-4px"} width="12" fill={` ${(disabled) ? '#FFFFFF' : '#128186'}`} />
            &nbsp; Elimina
        </button>
    }


    const confirmSearchHistory = (selectedSearchOption, query) => {
        if (selectedSearchOption?.id === "BY_IDENTIFIER") {
            dispatch(actions.getCommunicationsHistoryBySubjectIdAndIdentifier(subjectId, query)) 
       } else if(selectedSearchOption?.id === "BY_NAME"){
            dispatch(actions.getCommunicationsHistoryBySubjectIdAndDenomination(subjectId,query))
        } else if(selectedSearchOption?.id === "BY_FILE"){
            dispatch(actions.getCommunicationsHistoryBySubjectIdAndFileName(subjectId,query))
        }
    }



    const openPopupDelete = (communications = []) => {
        let idsToDelete = [];
        communications.forEach(comm => idsToDelete.push(comm.id));
        PopupDelete({
            operazione: "Comunicazioni",
            text: `Sei proprio sicuro di voler eliminare ${idsToDelete.length} comunicazione/i dallo storico?`,
            handleClickConfirm: () => handlerDeleteCommunications(idsToDelete)
        });
    };

    const handlerDeleteCommunications = (idsToDelete = []) => {
        setIsLoading(true);
        let promises = [];
        for (const id of idsToDelete) {
            promises.push(actions.deleteCommunication(id));
        }
        Promise.all(promises).then(() => {
            PopupSuccess();
        }
            , (errors) => {
                PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
            })
            .then(() => {
                reloadAll(subjectId);
                setIsLoading(false);
            })
    };




    return (<>
        {renderCommunicationModal()}
        {renderEmailModal()}
        {isLoading && <PageSpinner />}
        <CommunicationErrorList
            communicationsInError={communicationErrors}
            renderDeleteButton={(disabled, elementsToDelete) => renderDeleteButton(disabled, elementsToDelete)}
            downloadDgn={(communication) => downloadDgn(communication)}
            downloadTxt={(communication) => downloadTxt(communication)}
            reelaborateCommunicationTxt={(communication) => reelaborateCommunicationTxt(communication)}

        />
        <CommunicationHistoryList
            hasRowsToShow={initialHistoryRows?.length >0}
            communications={communicationHistory}
            subjectId={subjectId}
            renderDeleteButton={(disabled, elementsToDelete) => renderDeleteButton(disabled, elementsToDelete)}
            openEmailModal={(communication) => { setShowEmailModal(true); setSentCommunication(communication) }}
            downloadReceiptTxt={(communication) => downloadReceiptTxt(communication)}
            downloadPecTz={(communication) => downloadPecTz(communication)}
            downloadPdf={(communication) => downloadPdf(communication)}
            downloadTxt={(communication) => downloadTxt(communication)}
            downloadDgn={(communication) => downloadDgn(communication)}
            downloadErrorReport={(communication) => downloadErrorReport(communication)}
            reelaborateCommunicationTxt={(communication) => reelaborateCommunicationTxt(communication)}
            SearchBar={()=><CoraCommunicationSearchBar  
                onClickSearch={(searchOption, query)=> confirmSearchHistory(searchOption,query)} 
                onAbortSearch={getHistory}
                />}
        />
    </>)
}


function CoraCommunicationSearchBar({onClickSearch, onAbortSearch }) {
    const searchOptions = [
        {
            id: "BY_IDENTIFIER",
            description: "Codice Univoco",
            placeholder: "Inserire codice univoco, anche parziale (min. 5 caratteri)"
        },
        {
            id: "BY_NAME",
            description: "Denominazione",
            placeholder: "Inserire denominazione titolare rapporto, anche parziale (min. 5 caratteri)"
        },
        {
            id: "BY_FILE",
            description: "Nome comunicazione",
            placeholder: "Inserire nome del file della comunicazione, anche parziale (min. 5 caratteri)"
        }
    ];
    const [selectedSearchOption, setSelectedSearchOption] = useState(searchOptions[0])
    const [searchQuery, setSearchQuery] = useState("");
    const [isQueryActive, setIsQueryActive] = useState(false);
    return (<>

        <Row className='align-items-center'>
            <div className="inputSelect col-3">
                <BwmSelect
                    options={searchOptions}
                    name='searchType'
                    label='Ricerca nel campo:'
                    className='form-control'
                    onChange={(e) => setSelectedSearchOption(searchOptions.find(o => o.id === e.target.value))}
                    value={selectedSearchOption?.id} />
            </div>
            <div className="col-7">
                <BwmInput name='searchTerms'
                    label={"Cerca"}
                    className='form-control'
                    maxLength={50}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder={selectedSearchOption?.placeholder}
                    value={searchQuery} />

            </div>
            <div className="col-1">
                <button type="button"
                    disabled={isEmpty(searchQuery) || searchQuery.length < 5}
                    className="btn btn-new-rel" onClick={() =>{setIsQueryActive(true); onClickSearch(selectedSearchOption, searchQuery)}}>
                    CERCA
                </button>

            </div>
            <div className="col-1">
                {
                    isQueryActive &&

                    <button type="button"
                        className="btn btn-new-rel" onClick={() => {setSearchQuery("");setIsQueryActive(false); onAbortSearch();}}>
                        PULISCI RICERCA
                    </button>
                }
            </div>

        </Row>

    </>)
}