import React, { memo } from 'react';
import { connect } from 'react-redux';
import * as actions from "../../actions";
import * as Constants from "../../config/Constants";
import { Col, Row } from 'react-bootstrap';
import { PopupError } from '../shared/PopupError';
import { PopupSuccess } from '../shared/PopupSuccess';

import { TableUtils, baseSearchObject, fieldTypes } from '../shared/tables/TableUtils';
import { StarTable } from '../shared/tables/StarTable';

import { BtnFill } from '../shared/BtnFill';

import { Link } from "react-router-dom";
import { AdminNewApplicationModal } from './AdminNewApplicationModal';
import authService from '../../services/auth-service';
import { IPhoneAlt } from '../../styles/icons';
import { BwmInput } from '../shared/form/BwmInput';


const tableName = "admin-customer-applications";
const startelPath = "/admin/star-tel";
class AdminCustomers extends React.Component {

    constructor() {
        super();
        this.state = {
            showNewCustomerModal: false,
            licenseTypes: Constants.LICENSE_TYPES,
            customerApplications: [],
            loading: true,
            editedIds: [],
            sidNumber: "",//valore provvisorio
            subjectDenomination: "",//valore provvisorio
            searchObject: {
                ...baseSearchObject,
                denomination: "",
                email: "",
                applicationId: [],
                maxSubjects: "",
                licenseType: [],
                expiration: "",
                lastModified: "",
                pageSize: 100,
                sortField: "lastModified",
                sidNumber: "",
                subjectDenomination: "",
                version: "3"

            },
            rows: [],
        }
    }

    componentDidMount() {
        this.props.dispatch(actions.getAccount())
    }

    headers = [
        TableUtils.composeHeader({
            fieldName: "denomination",
            displayedName: "Ragione Sociale"
        }),
        TableUtils.composeHeader({
            fieldName: "email",
            displayedName: "Email"
        }),
        TableUtils.composeHeader({
            fieldName: "applicationId",
            displayedName: "Nome App",
            type: fieldTypes.SELECTION,
            options: Constants.APPLICATIONS,
            minWidth: "9rem",
            maxWidth: "9rem"
        }),
        TableUtils.composeHeader({
            fieldName: "maxSubjects",
            displayedName: "Num. Soggetti",
            type: fieldTypes.NUMBER,
            minWidth: "8rem",
            maxWidth: "8rem"
        }),
        TableUtils.composeHeader({
            fieldName: "licenseType",
            displayedName: "Tipo Licenza",
            type: fieldTypes.SELECTION,
            options: Constants.LICENSE_TYPES,
            minWidth: "10rem",
            maxWidth: "10rem"

        }),
        TableUtils.composeHeader({
            fieldName: "expiration",
            displayedName: "Scadenza",
            type: fieldTypes.DATE,
            minWidth: "10rem",
            maxWidth: "10rem"

        }),
        TableUtils.composeHeader({
            fieldName: "lastModified",
            displayedName: "Ultimo Ordine",
            type: fieldTypes.DATE,
            minWidth: "9rem",
            maxWidth: "9rem"
        }),
        TableUtils.composeHeader({
            fieldName: "save",
            displayedName: "Modifica",
            sortable: false,
            searchable: false,
            minWidth: "9rem",
            maxWidth: "9rem"
        }),

    ]

    prepareRows(customerApplications = []) {
        return customerApplications.map(app => {
            const application = Constants.APPLICATIONS.find(a => a.id === app.applicationId);
            return TableUtils.composeRow({
                id: app.id,
                cellData: {
                    denomination: TableUtils.composeCell({
                        fieldName: "denomination",
                        fieldValue: app.denomination
                    }),
                    email: TableUtils.composeCell({
                        fieldName: "email",
                        fieldValue: app.email,
                        component: () => <div className=' text-truncate d-flex align-items-center'>
                            <AdminNewApplicationModal onSave={(customerApplication) => this.onSave(customerApplication)}
                                key={'admin-new-application' + app.id} customer={app.customer} />
                            &nbsp;
                            <Link onClick={() => { authService.setHeader(Constants.FAKE_USER, app.email); }} to={"/dashboard"}>
                                <button type="button" className="ms-2 btn btn-primary btn-new-rel btn-sm ">accedi</button>
                            </Link>
                            &nbsp;
                            <span className="text-truncate" style={{ maxWidth: "75%" }}>{app.email}</span>
                            &nbsp;
                            <Link to={{
                                pathname: "/app/cora/admin",
                                state: { loginParam: app.email }
                            }}
                                rel="noopener noreferrer" className="ms-auto"
                            >
                                <button  type="button" className="ms-2 btn btn-primary btn-new-rel btn-sm ">C</button>
                            </Link>
                            &nbsp;
                            <Link to={{
                                pathname: "/app/facciamo-noi/admin",
                                state: { loginParam: app.email }
                            }}
                                rel="noopener noreferrer"
                            >
                                <button  type="button" className="ms-2 btn btn-primary btn-new-rel btn-sm ">FNT</button>
                            </Link>
                            &nbsp;
                            <Link to={`${startelPath}?login=${app.email}`} target="_blank" rel="noopener noreferrer" >
                                <IPhoneAlt fill={"#128186"} className={"padding-bottom-4px svg"} width="15" />
                            </Link>
                        </div>
                    }),
                    applicationId: TableUtils.composeCell({
                        fieldName: "applicationId",
                        fieldValue: application ? application.description : ""
                    }),
                    maxSubjects: TableUtils.composeCell({
                        fieldName: "maxSubjects",
                        fieldValue: app.maxSubjects,
                        editable: true,
                        onEdit: (rowId, fieldName, value) => this.onEdit(TableUtils.updateList({ list: customerApplications, rowId, fieldName, value }), app.id)
                    }),
                    licenseType: TableUtils.composeCell({
                        fieldName: "licenseType",
                        fieldValue: app.licenseType,
                        editable: true,
                        onEdit: (rowId, fieldName, value) => this.onEdit(TableUtils.updateList({ list: customerApplications, rowId, fieldName, value }), app.id)
                    }),
                    expiration: TableUtils.composeCell({
                        fieldName: "expiration",
                        fieldValue: app.expiration,
                        editable: true,
                        onEdit: (rowId, fieldName, value) => this.onEdit(TableUtils.updateList({ list: customerApplications, rowId, fieldName, value }), app.id)
                    }),
                    lastModified: TableUtils.composeCell({
                        fieldName: "lastModified",
                        fieldValue: app.lastModified
                    }),
                    save: TableUtils.composeCell({
                        fieldName: "save",
                        component: () => {
                            return <div className='w-100 d-flex justify-content-center'>
                                <button disabled={!this.state.editedIds.includes(app.id)} className="btn btn-new-rel btn-sm " onClick={() => { this.onUpdate(app) }} >Salva</button>
                            </div>
                        }
                    }),
                },
            })
        })
    }

    onEdit(newList = [], id) {
        this.setState({ customerApplications: newList, rows: this.prepareRows(newList), editedIds: [...this.state.editedIds, id] })
    }

    getCustomerApplications(searchObject) {
        actions.searchAdminCustomerApplications(searchObject).then(res => {
            this.setState({ customerApplications: res.content, rows: this.prepareRows(res.content) })
        });
    }


    renderTable() {

        return (
            <StarTable
                headerColums={this.headers}
                rows={this.state.rows}
                tableConfigurationKey={tableName}
                searchObjectPrototype={this.state.searchObject}
                setSearchObject={(newSearchObject) => this.setState({ searchObject: newSearchObject })}
                searchCallBack={actions.searchAdminCustomerApplications}
                onExecutedSearch={content => this.setState({ customerApplications: content, rows: this.prepareRows(content) })}
            />

        )
    }

    showNewCustomerModal = () => {
        this.setState({ showNewCustomerModal: !this.state.showNewCustomerModal });
    }



    onUpdate = (customerApplication) => {

        actions.updateCustomerApplication(customerApplication.id, customerApplication)
            .then((res) => {
                this.getCustomerApplications(this.state.searchObject);
                const ids = this.state.editedIds.filter(id => id !== customerApplication.id);
                this.setState({ editedIds: ids });
            }, (err) => {
                PopupError({ text: err?.title || Constants.APPLICATION_GENERIC_ERROR });
            });

    }

    onSave = (customerApplication) => {
        this.setState({ loading: true });
        actions.addCustomerApplication(customerApplication)
            .then((res) => {
                PopupSuccess({ text: "Licenza inserita" })
                this.getCustomerApplications(this.state.searchObject)
            }, (err) => {
                PopupError({
                    text: err?.title || Constants.APPLICATION_GENERIC_ERROR
                })
            })
            .then(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <React.Fragment>

                <Row className='mt-4 mb-2'>
                    <Col lg={4} className={" d-flex align-items-center"}>
                        <span className='me-3'>
                            <BwmInput
                                label="Cerca tramite nome soggetto"
                                value={this.state.subjectDenomination}
                                onChange={(e) => { this.setState({ subjectDenomination: e.target.value }) }}
                            />

                        </span>
                        <BwmInput
                            label="Cerca tramite numero SID"
                            value={this.state.sidNumber}
                            onChange={(e) => { this.setState({ sidNumber: e.target.value }) }}
                        />
                        <BtnFill
                            classCustom={"mt-2 ms-1"}
                            text={"CERCA"}
                            handlerOnClick={() => {
                                this.setState(
                                    {
                                        searchObject: {
                                            ...this.state.searchObject,
                                            sidNumber: this.state.sidNumber,
                                            subjectDenomination: this.state.subjectDenomination
                                        }
                                    },
                                    () => {
                                        this.getCustomerApplications(this.state.searchObject)
                                    })
                            }} />
                    </Col>
                    <Col lg={5} className={"ms-auto d-flex align-items-center justify-content-end"}>
                        <Link to={"/app/facciamo-noi/admin"}>
                            <BtnFill text={"FACCIAMO NOI"} />
                        </Link>
                        <Link className="ms-2 me-2" to={"/app/cora/admin"}>
                            <BtnFill text={"CORA"} />
                        </Link>
                        <Link to={startelPath}>
                            <BtnFill text={"STAR-TEL"} />
                        </Link>

                    </Col>
                </Row>
                {this.renderTable()}

            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {

    return {
        auth: state.auth,
        customerApplications: state.customerApplications.data
    }
}



export default connect(mapStateToProps)(AdminCustomers)