import React from "react";
import * as actions from "../../../../actions";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as IUpload } from "../../../../styles/images/svg/file-upload.svg";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { PopupError } from "../../../shared/PopupError";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import Row from "react-bootstrap/Row";
import { ReactComponent as ITextFile } from "../../../../styles/images/svg/file-alt.svg";
import ShowManyMofalTogheter from "./showManyMofalTogheter";
import GenerateNameFileWhenIsend from './GenerateNameFileWhenIsend';
import {handleUploadReceiptCrsCora} from '../../../shared/UploadReceipt';
export class UploadReceiptModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      receiptFile: [],
      errorReceipt: { isValid: true, msg: "" },
      loading: false,
      communication: [],
    };
  }

  closeModal = () => {
    this.setState({ showModal: false });
    this.setState({ receiptFile: [] });
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  setCertificateFile = () => {};

  /**
   * Funzione che cambia lo state e che mi da la possibilita di cambiare lo stato anche da altre funzioni
   * @param {*} val valore dell'oggetto che dovra' essere cambiato
   */
  setStateFunction = (val)=>{
    this.setState(val);
  }

  /**
   * controlla estensione e nome file
   * @param {*} files
   * @returns
   */
  checkFileName = (files) => {
    for (let x = 0; x < files.length; x++) {
      let file = files[x];
      let name = file.name;
      let checkIfNameIsCorrect =
        name.slice(0, 7) === "ATPECRQ" && (name.split(".").pop().toLowerCase() === "p7m" || name.split(".").pop().toLowerCase() === "run");
      if (!checkIfNameIsCorrect) {
        return false;
      }
      return true;
    }
  };

  handleSendReceipt = () => {
    this.setState({ showModal: false });
    this.setState({ loading: true });
    let length_file = this.state.receiptFile.length;
    const formData = new FormData();
    for (var x = 0; x < length_file; x++) {
      let file = this.state.receiptFile[x];
      formData.append("receiptFiles", file);
    }
    if (this.state.communication.length !== 0){ 
      //NOTE: questo id serve a controllare che la lista delle ricevute sia vuota in partenza quando faccio la chiamta, cosi si se si rifa la richiesta e ci sono ricevute nuove si riparte da zero
      this.setState({
        communication: [],
      });
    }
    actions.createCrsReceipt(formData).then(
      (communication) => {
        this.setState({
          loading: false,
          communication: communication,
          receiptFile: [],
        });
      },
      (errors) => {
        this.setState({
          loading: false,
          receiptFile: [],
        });
        PopupError({ ...this.props, text: "Errore durante la verifica della ricevuta" });
      }
    );
  };

  writeClearFile = (clearTxt) => {
    let lines = clearTxt.split("\r\n");
    return lines.map((line, index) => {
      return <Row className="alert-header">{line}</Row>;
    });
  };


  render() {
    let disabled = this.props.disabled;
    return (
      <React.Fragment>
        {this.state.loading && <PageSpinner />}
        <button
          type="button"
          className="btn btn-primary  btn-new-rel btn-sm"
          data-tip={
            "Caricare file RICEVUTA DI TRASMISSIONE (ATPECRQ) o ESITI (ATPECXX)"
          }
          onClick={this.openModal}
          disabled={disabled}
        >
          <ITextFile
            className={"padding-bottom-4px svg"}
            width="13"
            fill={` #FFFFFF`}
          />
          &nbsp; ELABORA RICEVUTE
        </button>
        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.closeModal}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>ELABORA RICEVUTE</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-between">
              <div className="image-upload text-start">
                <label htmlFor={`receiptFile`}>
                  <div className="certificateFiles">
                    CARICA FILE RICEVUTO
                    <IUpload width="25" fill="#000" />
                  </div>
                </label>
                <input
                  id={`receiptFile`}
                  type="file"
                  name={`receiptFile`}
                  multiple
                  ref={(ref) => this.setCertificateFile(ref)}
                  onChange={(e) => handleUploadReceiptCrsCora(e,this.setStateFunction,this.checkFileName)}
                />
                <div className="certificatiLoaded text-start">
                  {!this.state.errorReceipt.isValid ? (
                    <div className="certificatiLoaded-error">
                      {this.state.errorReceipt.msg}
                    </div>
                  ) : (
                    <GenerateNameFileWhenIsend files = {this.state.receiptFile}/>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-content-end mt-5">
              <BtnEmpty
                text="ANNULLA"
                classCustom="float-start"
                handlerOnClick={this.closeModal}
              />
              <button
                disabled={
                  !this.state.errorReceipt.isValid || !this.state.receiptFile
                }
                onClick={this.handleSendReceipt}
                type="button"
                className="btn btn-primary  btn-new-rel px-5"
              >
                CONFERMA
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <ShowManyMofalTogheter
          communication={this.state.communication}
          props={this.props}
        />
      </React.Fragment>
    );
  }
}
