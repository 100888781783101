import React from 'react';

export class BtnEmpty extends  React.Component {
    constructor(props) {
        super(props);
    }

    render(){
       const {text, handlerOnClick, classCustom} = this.props;
       return (
           <div className={(classCustom != undefined ? classCustom : '') }>
               <button type="button" className="btn btn-outline-primary btn-empty px-4 btn-sm"
                       onClick={handlerOnClick}>{text}</button>
           </div>
       )
   }
}



