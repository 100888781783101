import React from 'react';
import Modal from "react-bootstrap/Modal";
import ComureiForm from "./ComureiForm";
import { IPlus } from '../../../../styles/icons';

export class ComureiSubjectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    closeModal = () =>{
        this.setState({showModal: false});
    };

    openModal = () =>{
        this.setState({showModal: true});
    };

    render() {
        return (
            <React.Fragment>
                <button type="button" className="btn btn-primary btn-new-rel px-5 btn-sm" 
                        onClick={this.openModal}><IPlus className={"padding-bottom-4px"} width="14" fill={ '#FFFFFF'}/>&nbsp; NUOVO SOGGETTO
                </button>
                <Modal
                    dialogClassName="comurei-registry-modal"
                    backdrop="static"
                    show={this.state.showModal}
                    onHide={this.closeModal}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>Nuovo Soggetto</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ComureiForm {...this.props} closeModal={this.closeModal}/>
                    </Modal.Body>
                </Modal>


            </React.Fragment>
        )
    }
}
