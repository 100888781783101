import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from "redux-form";
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../styles/autocomplete.css";
import { ArcoRelationshipNewOperation } from "./ArcoRelationshipNewOperation"
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ReactComponent as ILeft } from "../../../../styles/images/svgs/regular/arrow-left.svg";
import { ReactComponent as IRight } from "../../../../styles/images/svgs/regular/arrow-right.svg";

import * as Constants from "../../../../config/Constants"
import { NumberOfResultsSelector } from '../../../shared/searchComponents/NumberOfResultsSelector';
import { formatDateForDisplay } from '../../../shared/Utility';

registerLocale("it", it);

const ArcoRegistrationsList = props => {

    const searchObject = useSelector(state => state.operationSearchData);
    const operations = useSelector(state => state.arcoRegistriesOperations.data);
    const pageNumber = searchObject?.pageNumber || 0;
    const totalPages = searchObject?.totalPages || 1;
    const [resultsPerPage, setResultsPerPage] = useState(searchObject?.pageSize || 20);
    const [prevResultsPerPage, setPrevResultsPerPage] = useState(20);

    useEffect(()=>{
        if(operations.length > 0 && prevResultsPerPage != resultsPerPage){
            props.getArcoOperation(null, { ...searchObject, pageNumber: 0, pageSize: resultsPerPage});
            setPrevResultsPerPage(resultsPerPage);
        }
    },[resultsPerPage]);

    const getSurnameOrDenomination = (item) => {
        return item?.completeName && item.completeName.toUpperCase();
    }

    const printClientsOrCounterParts = (array) => {
        let newArray = [];
        array.forEach(item => {
            newArray.push(getSurnameOrDenomination(item));
        });
        return newArray.join(', ');
    }

    const printProfessionals = (array) => {
        let newArray = [];
        array.forEach(item => {
            newArray.push(item.name.toUpperCase());
        });
        return newArray.join(', ');
    }

    const renderRows = () => {
        return operations.map((arcoRegistriesOperation, index) => {
            const idCheckbox = `checkboxRegistrationDetails${arcoRegistriesOperation.id}`;
            let latestContract = null;
            if (arcoRegistriesOperation.arcoContracts) {
                latestContract = arcoRegistriesOperation.arcoContracts.filter((c) =>  c.status < 1).sort((a, b) => new Date(b.registrationDate)?.getTime() - new Date(a.registrationDate)?.getTime())[0];
            }
            return (

                <React.Fragment key={`registration-${arcoRegistriesOperation.id}`} >
                    <div
                        className={'text-start row-table' + (props.showOperationModal && props?.currentOperation?.id === arcoRegistriesOperation?.id ? ' bg-blue bold' : '')}
                        id={`registration-${arcoRegistriesOperation.id}`}
                    >
                        <div className='col-lg-1 text-center div-td'>
                            <input type="checkbox"
                                id={idCheckbox}
                                onClick={e => props.onCheckBoxClick(e, arcoRegistriesOperation)}
                            />
                        </div>
                        <div className='col-lg-1 div-td' onClick={() => props.showDetail(arcoRegistriesOperation, true)} >&nbsp;&nbsp;{arcoRegistriesOperation.number} </div>
                        <div className='col-lg-1 div-td' onClick={() => props.showDetail(arcoRegistriesOperation, true)} >&nbsp;&nbsp;{formatDateForDisplay(arcoRegistriesOperation.profilingDate)} </div>
                        <div className='col-lg-2 div-td text-truncate' onClick={() => props.showDetail(arcoRegistriesOperation, true)} >&nbsp;&nbsp;{arcoRegistriesOperation.description.toUpperCase()} </div>
                        <div className='col-lg-3 div-td text-truncate' onClick={() => props.showDetail(arcoRegistriesOperation, true)} >&nbsp;&nbsp;{printClientsOrCounterParts(arcoRegistriesOperation.arcoRegistriesAsCustomers || [])}</div>
                        <div className='col-lg-3 div-td text-truncate' onClick={() => props.showDetail(arcoRegistriesOperation, true)} >&nbsp;&nbsp;{isProfessionals() ? printProfessionals(arcoRegistriesOperation.arcoProfessionals || []) : printClientsOrCounterParts(arcoRegistriesOperation.arcoRegistriesAsProfessionals || [])}</div>
                        <div className='col-lg-1 div-td' onClick={() => props.showDetail(arcoRegistriesOperation, true)} >&nbsp;&nbsp;{latestContract?.registrationDate ? formatDateForDisplay(latestContract.registrationDate) : ''}</div>

                    </div>
                    <TransitionGroup component={null}>
                        {props.show && (props?.currentOperation?.id === arcoRegistriesOperation?.id) ?
                            <CSSTransition
                                in={props.show}
                                timeout={300}
                                classNames="visible"
                                unmountOnExit>
                                <div className="row-detail bg-blue">
                                    <div className="col-md-12 col-lg-12 text-start p-0">
                                        {<ArcoRelationshipNewOperation
                                            {...props}
                                            onHide={props.showDetail}
                                            currentOperation={arcoRegistriesOperation}
                                            getArcoOperation={props.getArcoOperation}
                                            disableInsert={props.disableInsert}
                                        />}
                                    </div>
                                </div>
                            </CSSTransition>

                            : ''}
                    </TransitionGroup>
                </React.Fragment>
            )
        });
    };

    const isProfessionals = () => {
        return props?.arcoSubject?.arcoSubjectType?.code === 'PB';
    }


    return (
        <div className="col-lg-12" key={new Date().getTime().toString()}>
            <div className="row me-0 ms-0">
                <div className="col-lg-12 ps-0 pe-0">

                    <div className="text-center header-table">
                        <div className='col-sm-1 text-center div-td'>
                            <input type="checkbox"
                                id='checkboxAllRelationshipId'
                                onClick={props.onCheckClick} />
                        </div>
                        <div className='col-lg-1 div-td'><strong>&nbsp;&nbsp;Numero</strong> </div>
                        <div className='col-lg-1 div-td'><strong>&nbsp;&nbsp;Data</strong> </div>
                        <div className='col-lg-2 div-td'><strong>&nbsp;&nbsp;Descrizione</strong> </div>
                        <div className='col-lg-3 div-td'><strong>&nbsp;&nbsp;Clienti</strong></div>
                        <div className='col-lg-3 div-td'><strong>&nbsp;&nbsp;{isProfessionals() ? 'Professionisti' : 'Controparti'}</strong></div>
                        <div className='col-lg-1 div-td'><strong>&nbsp;&nbsp;AUI</strong></div>
                    </div>
                </div>
            </div>
            {operations &&
                <div className="col-lg-12 ps-0 pe-0">
                    {renderRows()}
                </div>}
            <div className="row me-0 ms-0 mt-3 justify-content-around">
                <div className='col-8 ms-auto d-flex justify-content-around align-items-center'>
                    <button disabled={pageNumber <= 0} type="button" className={`btn btn-primary btn-new-rel px-4  w-10 ${pageNumber <= 0 && 'disabled'}`} onClick={() => props.getArcoOperation(null, { ...searchObject, pageNumber: pageNumber - 1 , pageSize: resultsPerPage})}>
                        <ILeft className={"padding-bottom-4px"} width="14" fill={'#FFFFFF'}></ILeft>
                    </button>
                    <div ><strong>Pagina {pageNumber + 1} di {totalPages}</strong></div>
                    <button disabled={totalPages === pageNumber + 1} type="button" className={`btn btn-primary btn-new-rel px-4  w-10 ${(totalPages === pageNumber + 1) && 'disabled'}`} onClick={() => props.getArcoOperation(null, { ...searchObject, pageNumber: pageNumber + 1, pageSize: resultsPerPage })}>
                        <IRight className={"padding-bottom-4px"} width="14" fill={'#FFFFFF'}></IRight>
                    </button>
                </div>
                <div className="col-2 text-center justify-content-center">
                    <NumberOfResultsSelector
                        selected={resultsPerPage || 20}
                        onChange={val => setResultsPerPage(val)}
                    />
                </div>
            </div>
        </div>
    )
}


export default reduxForm({
    form: "arcoRelationshipList"
})(ArcoRegistrationsList)
