import React, { useState, useEffect } from 'react';
import BlueSearchForm from './BlueSearchForm';
import BlueResultForm from './BlueResultForm';
import elenco from "../../../styles/images/elenco.png";
import arrow_l from "../../../styles/images/arrow_l.png";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "../../../actions";
import { PageSpinner } from "../../shared/spinner/PageSpinner";
import { PopupError } from '../../shared/PopupError';
import Modal from "react-bootstrap/Modal";
import BlueSearchResultPopup from './BlueSearchResultsPopup';
import getText from './labels';
import BlueLanguageSelector from './BlueLanguageSelector';
import { CheckLicenseAndRedirect } from '../../shared/auth/license/LicenseChecker';
import { APPLICATION_BLUE } from '../../../config/Constants';

export default function BlueSearchPage(props) {
    const dispatch = useDispatch();
    const [subjectid, setSubjectId] = useState(props.match.params.id);
    const subject = useSelector(state => state.blueSubject.data);
    const auth = useSelector(state=> state.auth)
    const [isLoading, setLoading] = useState(false);
    const [searchResult, setSearchResults] = useState('');
    const [showResultsModal, setShowResultsModal] = useState(false);
    const lang = useSelector(state => state.blueLanguage.language)
    const labels = getText(lang);

    useEffect(() => {
        if (!subject) {
            dispatch(actions.getBlueSubject(subjectid));
        }
        
    }, []);

    useEffect(()=>{
        if(auth){
            CheckLicenseAndRedirect(
                props.history,
                "/app/blue/dashboard",
                auth?.user?.customer?.customerApplications,
                APPLICATION_BLUE
            )
        }
    },[auth])

    const executeSearch = (data) => {
        setLoading(true)
        actions.checkBlueAntiTerrorism(subjectid, data).then((res) => {
            setSearchResults(res);
            if (res?.result) {
                setShowResultsModal(true);
            }
        }, (err) => {
            PopupError({
                text: labels.SEARCHERROR,
                title: labels.WARNING,
                request: labels.CONTACTSUPPORT,
                close: labels.CLOSE
            })
        })
            .then(() => setLoading(false));
    }

    const closeResultsModal = () => {
        setShowResultsModal(false);
    }

    const isLoadingFunction = (value)=>{
        setLoading(value);
    }
    return (
        <React.Fragment>
            {isLoading && <PageSpinner text={labels.SPINNERTITLE} /> }
            <div className="table-custom mt-1">
                <div className="row title-page">
                    <Link to="/app/blue/dashboard" >
                        <img src={arrow_l} className="px-4" alt="Torna a elenco soggetti" />
                        {subject?.denomination}
                    </Link>
                </div>
                <div className="tabs single">
                    <ul className="tab-list nav nav-tabs d-flex justify-content-between">
                        <li className="tab-list-item nav-link tab-list-active active">
                            <div className="col-md-12 col-lg-12 text-center "><img src={elenco} alt={labels.SEARCH}/>&nbsp;&nbsp;{labels.SEARCH}</div>
                        </li>
                        <li>
                            <BlueLanguageSelector/>
                        </li>
                    </ul>

                    <div className="row">
                        <div className="col-lg-5 text-center mt-3">
                            <BlueSearchForm subject={subject} executeSearch={executeSearch}></BlueSearchForm>
                        </div>
                        <div className="col-lg-7 text-center mt-3">
                            <BlueResultForm isLoading={isLoadingFunction} subject={subject}  results={searchResult} className="d-flex flax-direction-column" ></BlueResultForm>
                        </div>
                    </div>

                </div>

            </div>

            <Modal
                backdrop="static"
                show={showResultsModal}
                onHide={closeResultsModal}
                dialogClassName="blue-results-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="bar"></div>
                <Modal.Header closeButton className="ps-3">
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2>{labels.SEARCHRESULTTITLE}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BlueSearchResultPopup
                        key="resultsModal"
                        data={searchResult}
                        subject={subject}
                        onClose={closeResultsModal}
                    />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}