import React from 'react';
import { NumberOfResultsSelector } from './NumberOfResultsSelector';
import { ReactComponent as ILeft } from "../../../styles/images/svgs/regular/arrow-left.svg";
import { ReactComponent as IRight } from "../../../styles/images/svgs/regular/arrow-right.svg";

export function PageSelector(props) {
    const pageNumber = props.pageNumber || 0;
    const resultsPerPage = props.resultsPerPage || 20;
    const totalPages = props.totalPages || 0;
    const onClickBack = props.onClickBack;
    const onClickForward = props.onClickForward;
    const onUpdateNumberOfResults = props.onUpdateNumberOfResults;

    return (
        
            <div className='d-flex justify-content-center align-items-center'>
                <button disabled={pageNumber <= 0} type="button" 
                className={`btn btn-primary btn-new-rel px-4  ${pageNumber <= 0 && 'disabled'} `} 
                    onClick={() => onClickBack()}>
                    <ILeft className={"padding-bottom-4px "} width={15} fill={'#FFFFFF'}></ILeft>
                </button>
                <strong className='ms-2 me-2'>Pag. {pageNumber + 1} di {totalPages}</strong>
                <button disabled={totalPages === pageNumber + 1} type="button" 
                className={`btn btn-primary btn-new-rel px-4   ${(totalPages === pageNumber + 1) && 'disabled'}`} 
                onClick={() => onClickForward()}>
                    <IRight className={"padding-bottom-4px "} width={15} fill={'#FFFFFF'}></IRight>
                </button>
                {/* <NumberOfResultsSelector
                    selected={resultsPerPage || 20}
                    onChange={val => onUpdateNumberOfResults(Number(val))}
                /> */}
            </div>

        )
}