import React, { useEffect, useState } from 'react';
import { reduxForm } from "redux-form";
import Tabs from "../../application-cora/relationships/Tabs";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import { BwmInput } from "../../../shared/form/BwmInput";
import * as actions from "../../../../actions";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustom";
import radioSel from "../../../../styles/images/radiobutton-selected.png";
import radioUnsel from "../../../../styles/images/radiobutton-unselected.png";
import { DateField } from "../../../shared/form/DateField";
import * as Constants from "../../../../config/Constants";
import { BwmCheckbox } from "../../../shared/form/BwmCheckbox";
import { ReactComponent as IInfo } from '../../../../styles/images/svg/info-circle.svg';
import '../../../../styles/comurei-form.css';
import CodiceFiscale from "codice-fiscale-js";
import { PopupError } from "../../../shared/PopupError";
import { ReactComponent as IUndo } from "../../../../styles/images/svgs/regular/times-circle.svg";
import { ReactComponent as ISave } from "../../../../styles/images/svgs/regular/save.svg";
import { ReactComponent as ITextFile } from "../../../../styles/images/svg/file-alt.svg";
import * as Utility from "../../../shared/Utility"
import { Row } from 'react-bootstrap';
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm';
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense';
import { CheckRemainingSubjects } from '../../../shared/auth/license/LicenseChecker';
import { ProvinceField } from '../../../shared/form/ProvinceField';

const ComureiForm = props => {
    const [sub, setSub] = useState(props.subjectItem || null);
    const [user, setUser] = useState(props.user || null);
    const [subjectTypes, setSubjectTypes] = useState(props.subjectTypes || []);
    const [formData, updateFormData] = useState();
    const [subjectId, setSubjectId] = useState(sub?.id || null);
    const [subjectType, setSubjectType] = useState(sub?.comureiSubjectType?.id || '');
    const [subjectTypeCode, setSubjectTypeCode] = useState(sub?.comureiSubjectType?.code || '');
    const [fiscalCode, setFiscalCode] = useState(sub?.fiscalCode || '');
    const [companyName, setCompanyName] = useState(sub?.companyName || '');
    const [location, setLocation] = useState(sub?.location || '');
    const [province, setProvince] = useState(sub?.province || '');
    const [progr, setProgr] = useState(sub?.id || '');
    const [commType, setCommType] = useState(sub?.comureiCommunicationType.id.toString() || '1');
    const [centralizedStructure, setCentralizedStructure] = useState(sub?.centralizedStructure || false);
    const [registryReason, setRegistryReason] = useState((sub && sub.registryReason !== null) ? sub.registryReason : true); //default a true per nuovi soggetti
    const [monitoringReason, setMonitoringReason] = useState(sub?.monitoringReason || false);
    const [factaReason, setFactaReason] = useState(sub?.factaReason || false);
    const [birthDate, setBirthDate] = useState(sub?.birthDate ? new Date(sub?.birthDate) : null);
    const [firstName, setFirstName] = useState(sub?.firstName || '');
    const [lastName, setLastName] = useState(sub?.lastName || '');
    const [personType, setPersonType] = useState(sub?.personType || Constants.PNF);
    const [gender, setGender] = useState(sub?.gender || (personType === Constants.PF ? Constants.genderList[0].id : null))

    const [responsibleId, setResponsibleId] = useState(sub?.comureiSubjectResponsible?.id || null);
    const [responsibleFiscalCode, setResponsibleFiscalCode] = useState(sub?.comureiSubjectResponsible?.fiscalCode || '');
    const [responsibleLastName, setResponsibleLastName] = useState(sub?.comureiSubjectResponsible?.lastName || '');
    const [responsibleFirstName, setResponsibleFirstName] = useState(sub?.comureiSubjectResponsible?.firstName || '');
    const [responsibleGender, setResponsibleGender] = useState(sub?.comureiSubjectResponsible?.gender || 'FEMALE');
    const [responsibleBirthtDate, setResponsibileBirthtDate] = useState(sub?.comureiSubjectResponsible?.birthDate ? new Date(sub?.comureiSubjectResponsible?.birthDate) : null);
    const [responsibleBirthtLocation, setResponsibleBirthtLocation] = useState(sub?.comureiSubjectResponsible?.location || '');
    const [responsibleBirthProvince, setResponsibleBirthtProvince] = useState(sub?.comureiSubjectResponsible?.province || '');
    const [responsibleEmailAddress, setResponsibleEmailAddress] = useState(sub?.comureiSubjectResponsible?.email || (user?.login || ''));
    const [responsiblePhoneNumber, setResponsiblePhoneNumber] = useState(sub?.comureiSubjectResponsible?.phone || '');

    const [subjectPecId, setSubjectPecId] = useState(sub?.comureiSubjectPec?.id || null);
    const [pecEmailAddress, setPecEmailAddress] = useState(sub?.comureiSubjectPec?.pecEmail || '');
    const [reiEmail, setReiEmailAddress] = useState(sub?.comureiSubjectPec?.reiEmail || '');
    const [effectiveDate, setEffectiveDate] = useState(sub?.comureiSubjectPec?.effectiveDate ? new Date(sub.comureiSubjectPec.effectiveDate) : '');
    const [endDate, setEndDate] = useState(sub?.comureiSubjectPec?.endDate ? new Date(sub.comureiSubjectPec.endDate) : '');
    const [pecManager, setPecManager] = useState(sub?.comureiSubjectPec?.pecManager || '');

    const [cancellationId, setCancellationId] = useState(sub?.comureiSubjectCancellation?.id || null);
    const [pecDeleteDate, setPecDeleteDate] = useState(sub?.comureiSubjectCancellation?.cancellationDate ? new Date(sub.comureiSubjectCancellation.cancellationDate) : null);
    const [cancellationReason, setCancellationReason] = useState(sub?.comureiSubjectCancellation?.comureiSubjectCancellationType?.id.toString() || '1');
    const [cancellationAddress, setCancellationAddress] = useState(sub?.comureiSubjectCancellation?.address || '');
    const [cancellationLocation, setCancellationLocation] = useState(sub?.comureiSubjectCancellation?.location || '');
    const [cancellationProvince, setCancellationProvince] = useState(sub?.comureiSubjectCancellation?.province || '');
    const [incorporatingFiscalCodeOne, setIncorporatingFiscalCode1] = useState(sub?.comureiSubjectCancellation?.incorporatingFiscalCodeOne || '');
    const [incorporatingFiscalCodeTwo, setIncorporatingFiscalCode2] = useState(sub?.comureiSubjectCancellation?.incorporatingFiscalCodeTwo || '');
    const [incorporatingFiscalCodeThree, setIncorporatingFiscalCode3] = useState(sub?.comureiSubjectCancellation?.incorporatingFiscalCodeThree || '');

    const [transmissionId, setTransmissionId] = useState(sub?.comureiTransmission?.id || null);
    const [transmissionFiscalCode, setTransmissionFiscalCode] = useState(sub?.comureiTransmission?.fiscalCode || '');
    const [transmissionType, setTransmissionType] = useState(sub?.comureiTransmission?.comureiTransmissionType.id.toString() || '1');
    const [errorSubjectType, setErrorSubjectType] = useState({ isValid: true, msg: "" });
    const [errorFiscalCode, setErrorFiscalCode] = useState({ isValid: true, msg: "" });
    const [errorCompanyName, setErrorCompanyName] = useState({ isValid: true, msg: "" });
    const [errorLocation, setErrorLocation] = useState({ isValid: true, msg: "" });
    const [errorProvince, setErrorProvince] = useState({ isValid: true, msg: "" });
    const [errorMonitoringReason, setErrorMonitoringReason] = useState({ isValid: true, msg: "" });
    const [errorFactaReason, setErrorFactaReason] = useState({ isValid: true, msg: "" });
    const [errorCentralizedStructure, setErrorCentralizedStructure] = useState({ isValid: true, msg: "" });
    const [errorResponsibleFiscalCode, setErrorResponsibleFiscalCode] = useState({ isValid: true, msg: "" });
    const [errorResponsibleLastName, setErrorResponsibleLastName] = useState({ isValid: true, msg: "" });
    const [errorResponsibleFirstName, setErrorResponsibleFirstName] = useState({ isValid: true, msg: "" });
    const [errorResponsibleGender, setErrorResponsibleGender] = useState({ isValid: true, msg: "" });
    const [errorResponsibleBirthDate, setErrorResponsibleBirthDate] = useState({ isValid: true, msg: "" });
    const [errorResponsibleBirthLocation, setErrorResponsibleBirthLocation] = useState({ isValid: true, msg: "" });
    const [errorResponsibleBirthProvince, setErrorResponsibleBirthProvince] = useState({ isValid: true, msg: "" });
    const [errorResponsibleEmailAddress, setErrorResponsibleEmailAddress] = useState({ isValid: true, msg: "" });
    const [errorResponsiblePhoneNumber, setErrorResponsiblePhoneNumber] = useState({ isValid: true, msg: "" });
    const [errorEmailAddress, setErrorEmailAddress] = useState({ isValid: true, msg: "" });
    const [errorReiEmail, setErrorReiEmail] = useState({ isValid: true, msg: "" });
    const [errorEffectiveDate, setErrorEffectiveDate] = useState({ isValid: true, msg: "" });
    const [errorEndDate, setErrorEndDate] = useState({ isValid: true, msg: "" });
    const [errorPecManager, setErrorPecManager] = useState({ isValid: true, msg: "" });
    const [errorPecDeleteDate, setErrorPecDeleteDate] = useState({ isValid: true, msg: "" });
    const [errorCancellationAddress, setErrorCancellationAddress] = useState({ isValid: true, msg: "" });
    const [errorCancellationLocation, setErrorCancellationLocation] = useState({ isValid: true, msg: "" });
    const [errorCancellationProvince, setErrorCancellationProvince] = useState({ isValid: true, msg: "" });
    const [errorCancellationFiscalCode, setErrorCancellationFiscalCode] = useState({ isValid: true, msg: "" });
    const [errorIncorporatingFiscalCodeOne, setErrorIncorporatingFiscalCode1] = useState({ isValid: true, msg: "" });
    const [errorIncorporatingFiscalCodeTwo, setErrorIncorporatingFiscalCode2] = useState({ isValid: true, msg: "" });
    const [errorIncorporatingFiscalCodeThree, setErrorIncorporatingFiscalCode3] = useState({ isValid: true, msg: "" });
    const [errorTransmissionFiscalCode, setErrorTransmissionFiscalCode] = useState({ isValid: true, msg: "" });
    const [errorFirstName, setErrorFirstName] = useState({ isValid: true, msg: "" });
    const [errorLastName, setErrorLastName] = useState({ isValid: true, msg: "" });
    const [errorBirthDate, setErrorBirthDate] = useState({ isValid: true, msg: "" });
    const [errorGender, setErrorGender] = useState({ isValid: true, msg: "" });

    const [subjectSummary, setSubjectSummary] = useState({});

    const [tabError, setTabError] = useState({ subject: true, responsible: true, pec: true, canc: true });

    useEffect(() => {
        if (!tabError.subject) {
            if (!document.getElementById('tab-sub').classList.contains('active')) {
                document.getElementById('tab-sub').click()
            }
        } else if (!tabError.responsible) {
            if (!document.getElementById('tab-resp').classList.contains('active')) {
                document.getElementById('tab-resp').click()
            }
        } else if (!tabError.pec) {
            if (!document.getElementById('tab-pec').classList.contains('active')) {
                document.getElementById('tab-pec').click()
            }
        } else if (!tabError.canc) {
            if (!document.getElementById('tab-delete').classList.contains('active')) {
                document.getElementById('tab-delete').click()
            }
        }

        updateFormData({
            ...formData,
            id: subjectId,
            fiscalCode: fiscalCode,
            companyName: companyName,
            location: location,
            province: province,
            registryReason: registryReason,
            monitoringReason: monitoringReason,
            comureiCommunicationType: { id: commType },
            factaReason: factaReason,
            birthDate: birthDate,
            firstName: firstName,
            lastName: lastName,
            centralizedStructure: centralizedStructure,
            gender: gender,
            personType: personType,
            comureiSubjectType: {
                id: subjectType,
                code: subjectTypeCode
            },
            comureiSubjectResponsible: {
                id: responsibleId,
                fiscalCode: responsibleFiscalCode,
                firstName: responsibleFirstName,
                lastName: responsibleLastName,
                gender: responsibleGender,
                birthDate: responsibleBirthtDate,
                location: responsibleBirthtLocation,
                province: responsibleBirthProvince,
                email: responsibleEmailAddress,
                phone: responsiblePhoneNumber
            },
            comureiSubjectPec: {
                id: subjectPecId,
                pecEmail: pecEmailAddress,
                pecManager: pecManager,
                effectiveDate: effectiveDate,
                endDate: endDate,
                reiEmail: reiEmail
            },
            comureiSubjectCancellation: {
                id: cancellationId,
                cancellationDate: pecDeleteDate,
                address: cancellationAddress,
                incorporatingFiscalCodeOne: incorporatingFiscalCodeOne,
                incorporatingFiscalCodeTwo: incorporatingFiscalCodeTwo,
                incorporatingFiscalCodeThree: incorporatingFiscalCodeThree,
                location: cancellationLocation,
                province: cancellationProvince,
                comureiSubjectCancellationType: {
                    id: cancellationReason,
                    code: cancellationReason
                }
            },
            comureiTransmission: {
                id: transmissionId,
                fiscalCode: transmissionFiscalCode,
                comureiTransmissionType: { id: transmissionType }
            }
        })
    }, [tabError]);

    useEffect(() => {
        checkRemainingSubjects();
    }, []);

    const checkRemainingSubjects = () => {
        CheckRemainingSubjects(Constants.APPLICATION_COMUREI, false
            , (subjectSummary) => {
                setSubjectSummary(subjectSummary);
            });
    }


    const addComureiSubject = (newSubject, sendComm) => {
        actions.addComureiSubject(newSubject).then(
            (response) => {
                if (sendComm) {
                    createCommunication(response);
                    props.getSubjects();
                } else {

                    PopupConfirm({
                        text: "DATI SALVATI, SI DESIDERA CREARE LA COMUNICAZIONE?",
                        confirm: "CREA COMUNICAZIONE",
                        cancel: "CHIUDI",
                        titleColor: titleColors.SUCCESS,
                        title: "Soggetto salvato correttamente",
                        handleClickConfirm: () => createCommunication(response)
                    })

                }

                props.getSubjects();
            },
            (errors) => {
                if (errors?.status === 511) {
                    PopupSubjectLicense({ total: subjectSummary.total, used: subjectSummary.used,available:subjectSummary?.available, link: Constants.COMUREI_BUY_LINK });
                } else {
                    PopupError(
                        {
                            text: Constants.APPLICATION_GENERIC_ERROR
                        }
                    );
                }
            }
        );

        if (subjectId === null) {
            props.closeModal();
        }

    };


    const createCommunication = (response) => {

        actions.createComureiCommunication(response).then(
            (communications) => {
                props.openCreateCommunicationModal(response, communications);
            },
            (errors) => {
                if (errors?.status === 511) {
                    PopupSubjectLicense({ total: subjectSummary.total, used: subjectSummary.used,available:subjectSummary?.available, link: Constants.COMUREI_BUY_LINK });
                } else if (errors?.data?.title) {
                    PopupError(
                        {
                            text: errors?.data?.title
                        }
                    );
                } else {
                    PopupError(
                        {
                            text: 'Errore nella generazione della comunicazione'
                        }
                    );
                }

            }
        );
    }

    const handlerChangeSubjectType = (e) => {
        setSubjectType(e.target.value);
        const cod = e.target[e.target.selectedIndex].dataset.cod;
        setSubjectTypeCode(cod);
        updateFormData({
            ...formData,
            comureiSubjectType: {
                id: parseInt(e.target.value),
                code: cod
            }
        });
    };



    const handleChangeTextField = (value, field, setFunc, trim) => {
        let val = value.toUpperCase();
        if (trim) {
            val = val.trim();
        }
        let form = formData;
        Utility.updateObjProp(form, val, field);
        setFunc(val);

        updateFormData(form);
    }

    const handleFiscalCode = val => {
        actions.getBaseRegistryByFiscalCode(val).then(
            (baseRegistry) => {
                if (baseRegistry) {
                    if (personType === Constants.PNF) {
                        setCompanyName(baseRegistry?.companyName || companyName || '');
                        setLocation(baseRegistry?.location?.location || location || '');
                        setProvince(baseRegistry?.location?.province || province || '');
                        updateFormData({
                            ...formData,
                            companyName: baseRegistry?.companyName || companyName || '',
                            location: baseRegistry?.location?.location || location || '',
                            province: baseRegistry?.location?.province || province || '',
                        });
                    } else if (personType === Constants.PF) {
                        setFirstName(baseRegistry?.firstName || '');
                        setLastName(baseRegistry?.lastName || '');
                        setLocation(baseRegistry?.location?.location || '');
                        setProvince(baseRegistry?.location?.province || '');
                        setBirthDate(new Date(baseRegistry?.birthDate) || null);
                        setGender(baseRegistry?.gender || '');
                        updateFormData({
                            ...formData,
                            firstName: baseRegistry?.firstName || '',
                            lastName: baseRegistry?.lastName || '',
                            location: baseRegistry?.location?.location || '',
                            province: baseRegistry?.location?.province || '',
                            birthDate: new Date(baseRegistry?.birthDate) || null,
                            gender: baseRegistry?.gender || ''
                        })
                    }
                }
            },
            (errors) => {
                console.log(errors);
            }
        )
    };


    const handleInputAutocompile = (newLocation, id, suggestionIndex) => {
        setLocation(newLocation.toUpperCase());
        actions.getLocation(newLocation.toUpperCase()).then(
            (loc) => {
                if (loc) {
                    setLocation(loc.location);
                    setProvince(loc.province);
                    updateFormData({
                        ...formData,
                        location: newLocation.toUpperCase(),
                        province: loc.province
                    });
                } else {
                    updateFormData({
                        ...formData,
                        location: newLocation.toUpperCase()
                    });
                }
            },
            (errors) => {
                console.log(errors);
            })
    };

    const handlerChangeRegistryReason = (e) => {
        setRegistryReason(e.target.checked);
        updateFormData({
            ...formData,
            registryReason: e.target.checked
        });
    };

    const handlerChangeMonitoringReason = (e) => {
        setMonitoringReason(e.target.checked);
        updateFormData({
            ...formData,
            monitoringReason: e.target.checked
        });
    };

    const handleCommTypeClick = event => {
        setCommType(event.target.value);
        updateFormData({
            ...formData,
            comureiCommunicationType: { id: parseInt(event.target.value) }
        });
    };

    const handlerChangeFactaReason = (e) => {
        setFactaReason(e.target.checked);
        updateFormData({
            ...formData,
            factaReason: e.target.checked
        });
    };

    const handleCentralizedStructure = (e) => {
        setCentralizedStructure(e.target.checked);
        updateFormData({
            ...formData,
            centralizedStructure: e.target.checked
        });
    };


    const handlerChangeEffectiveDate = (date) => {
        setEffectiveDate(date);
        updateFormData({
            ...formData,
            comureiSubjectPec: {
                id: subjectPecId,
                effectiveDate: date,
                pecEmail: pecEmailAddress,
                pecManager: pecManager,
                endDate: endDate,
                reiEmail: reiEmail
            }
        });
    };

    const handlerChangeEndDate = (date) => {
        setEndDate(date);
        updateFormData({
            ...formData,
            comureiSubjectPec: {
                id: subjectPecId,
                endDate: date,
                pecEmail: pecEmailAddress,
                pecManager: pecManager,
                effectiveDate: effectiveDate,
                reiEmail: reiEmail
            }
        });
    };


    const handlerChangePecDeleteDate = (date) => {
        setPecDeleteDate(date);
        updateFormData({
            ...formData,
            comureiSubjectCancellation: {
                id: cancellationId,
                cancellationDate: date,
                incorporatingFiscalCodeOne: incorporatingFiscalCodeOne,
                incorporatingFiscalCodeTwo: incorporatingFiscalCodeTwo,
                incorporatingFiscalCodeThree: incorporatingFiscalCodeThree,
                address: cancellationAddress,
                location: cancellationLocation,
                province: cancellationProvince,
                comureiSubjectCancellationType: {
                    id: cancellationReason,
                    code: cancellationReason
                }
            }
        });
    };

    const handleCancellationReasonClick = event => {
        setCancellationReason(event.target.value);
        updateFormData({
            ...formData,
            comureiSubjectCancellation: {
                id: cancellationId,
                comureiSubjectCancellationType: { id: event.target.value, code: cancellationReason },
                cancellationDate: pecDeleteDate,
                address: cancellationAddress,
                incorporatingFiscalCodeOne: incorporatingFiscalCodeOne,
                incorporatingFiscalCodeTwo: incorporatingFiscalCodeTwo,
                incorporatingFiscalCodeThree: incorporatingFiscalCodeThree,
                location: cancellationLocation,
                province: cancellationProvince
            }
        });
    };


    const handleInputCancAutocompile = (newLocation, id, suggestionIndex) => {
        setCancellationLocation(newLocation.toUpperCase());
        actions.getLocation(newLocation.toUpperCase()).then(
            (loc) => {
                if (loc) {
                    setCancellationLocation(loc.location);
                    setCancellationProvince(loc.province);
                    updateFormData({
                        ...formData,
                        comureiSubjectCancellation: {
                            id: cancellationId,
                            location: newLocation.toUpperCase(),
                            cancellationDate: pecDeleteDate,
                            address: cancellationAddress,
                            incorporatingFiscalCodeOne: incorporatingFiscalCodeOne,
                            incorporatingFiscalCodeTwo: incorporatingFiscalCodeTwo,
                            incorporatingFiscalCodeThree: incorporatingFiscalCodeThree,
                            province: loc.province,
                            comureiSubjectCancellationType: {
                                id: cancellationReason,
                                code: cancellationReason
                            }
                        }
                    });
                } else {
                    updateFormData({
                        ...formData,
                        comureiSubjectCancellation: {
                            id: cancellationId,
                            location: newLocation.toUpperCase(),
                            cancellationDate: pecDeleteDate,
                            address: cancellationAddress,
                            incorporatingFiscalCodeOne: incorporatingFiscalCodeOne,
                            incorporatingFiscalCodeTwo: incorporatingFiscalCodeTwo,
                            incorporatingFiscalCodeThree: incorporatingFiscalCodeThree,
                            province: cancellationProvince,
                            comureiSubjectCancellationType: {
                                id: cancellationReason,
                                code: cancellationReason
                            }
                        }
                    });
                }
            },
            (errors) => {
                console.log(errors);
            })
    };

    const handlerResponsibleFiscalCode = (val) => {
        setResponsibleFiscalCode(val.toUpperCase());
        actions.getBaseRegistryPersonByFiscalCode(val).then(
            (baseRegistry) => {
                if (baseRegistry != undefined) {
                    if (baseRegistry?.personType === Constants.PF) {
                        setResponsibleLastName(baseRegistry?.lastName || '');
                        setResponsibleFirstName(baseRegistry?.firstName || '');
                        setResponsibileBirthtDate(new Date(baseRegistry?.birthDate) || null);
                        setResponsibleGender(baseRegistry?.gender || '');
                        setResponsibleBirthtLocation(baseRegistry?.location?.location || '');
                        setResponsibleBirthtProvince(baseRegistry?.location?.province || '');
                        updateFormData({
                            ...formData,
                            comureiSubjectResponsible: {
                                id: responsibleId,
                                fiscalCode: responsibleFiscalCode,
                                lastName: baseRegistry?.lastName || '',
                                firstName: baseRegistry?.firstName || '',
                                location: baseRegistry?.location?.location || '',
                                province: baseRegistry?.location?.province || '',
                                birthDate: new Date(baseRegistry?.birthDate) || null,
                                gender: baseRegistry?.gender,
                                email: responsibleEmailAddress
                            }

                        });
                    }
                } else {
                    try {
                        const cf = new CodiceFiscale(val).toJSON();
                        if (cf) {
                            setResponsibileBirthtDate(new Date(cf?.birthday) || null);
                            setResponsibleGender(cf?.gender === "M" ? 'MALE' : 'FEMALE');
                            if (cf?.birthplaceProvincia !== 'EE') {
                                setResponsibleBirthtLocation(cf?.birthplace.toUpperCase() || '');
                            }
                            setResponsibleBirthtProvince(cf?.birthplaceProvincia || '');
                            updateFormData({
                                ...formData,
                                comureiSubjectResponsible: {
                                    id: responsibleId,
                                    fiscalCode: responsibleFiscalCode,
                                    firstName: responsibleFirstName,
                                    lastName: responsibleLastName,
                                    location: cf?.birthplaceProvincia !== 'EE' ? cf?.birthplace : '',
                                    province: cf?.birthplaceProvincia || '',
                                    birthDate: new Date(new Date(cf?.birthday) || null),
                                    gender: cf?.gender === "M" ? 'MALE' : 'FEMALE',
                                    email: responsibleEmailAddress
                                }
                            });
                        }
                    } catch (e) {
                        setErrorResponsibleFiscalCode({ isValid: false, msg: 'Codice fiscale non valido' })
                        setResponsibleGender(null);
                        setResponsibileBirthtDate(null);
                        setResponsibleBirthtLocation("");
                        setResponsibleBirthtProvince(null);
                    }
                }
            },
            (errors) => {
                console.log(errors);
            }
        )
    };


    const handlerChangeResponsibleBirthDate = (date) => {
        setResponsibileBirthtDate(date);
        updateFormData({
            ...formData,
            comureiSubjectResponsible: {
                id: responsibleId,
                birthDate: date,
                fiscalCode: responsibleFiscalCode,
                firstName: responsibleFirstName,
                lastName: responsibleLastName,
                gender: responsibleGender,
                location: responsibleBirthtLocation,
                province: responsibleBirthProvince,
                email: responsibleEmailAddress,
                phone: responsiblePhoneNumber
            }
        });
    };

    const handlerChangeBirthDate = (date) => {
        setBirthDate(date);
        updateFormData({
            ...formData,
            birthDate: date
        })
    }

    const handleInputResponsibleAutocompile = (newLocation, id, suggestionIndex) => {
        setResponsibleBirthtLocation(newLocation.toUpperCase());
        actions.getLocation(newLocation.toUpperCase()).then(
            (loc) => {
                if (loc) {
                    setResponsibleBirthtLocation(loc.location);
                    setResponsibleBirthtProvince(loc.province);
                    updateFormData({
                        ...formData,
                        comureiSubjectResponsible: {
                            id: responsibleId,
                            location: newLocation.toUpperCase(),
                            fiscalCode: responsibleFiscalCode,
                            firstName: responsibleFirstName,
                            lastName: responsibleLastName,
                            gender: responsibleGender,
                            birthDate: responsibleBirthtDate,
                            province: loc.province,
                            email: responsibleEmailAddress,
                            phone: responsiblePhoneNumber
                        }
                    });
                } else {
                    updateFormData({
                        ...formData,
                        comureiSubjectResponsible: {
                            id: responsibleId,
                            location: newLocation.toUpperCase(),
                            fiscalCode: responsibleFiscalCode,
                            firstName: responsibleFirstName,
                            lastName: responsibleLastName,
                            gender: responsibleGender,
                            birthDate: responsibleBirthtDate,
                            province: responsibleBirthProvince,
                            email: responsibleEmailAddress,
                            phone: responsiblePhoneNumber
                        }
                    });
                }
            },
            (errors) => {
                console.log(errors);
            })
    };



    const handlerChangeResponsiblePhoneNumber = (e) => {
        if (!Number(e.target.value) && e.target.value != "") {
            return;
        }
        handleChangeTextField(e.target.value, "comureiSubjectResponsible.phone", setResponsiblePhoneNumber, true);

    };


    const handleTransmissionTypeClick = event => {
        setTransmissionType(event.target.value);
        updateFormData({
            ...formData,
            comureiTransmission: {
                id: transmissionId,
                comureiTransmissionType: { id: event.target.value },
                fiscalCode: transmissionFiscalCode,
            }
        });
    };

    const resetError = () => {
        setTabError({ subject: true, responsible: true, pec: true, canc: true });
        setErrorSubjectType({ isValid: true, msg: "" });
        setErrorFiscalCode({ isValid: true, msg: "" });
        setErrorCompanyName({ isValid: true, msg: "" });
        setErrorLocation({ isValid: true, msg: "" });
        setErrorProvince({ isValid: true, msg: "" });
        setErrorMonitoringReason({ isValid: true, msg: "" });
        setErrorResponsibleFiscalCode({ isValid: true, msg: "" });
        setErrorResponsibleLastName({ isValid: true, msg: "" });
        setErrorResponsibleFirstName({ isValid: true, msg: "" });
        setErrorResponsibleGender({ isValid: true, msg: "" });
        setErrorResponsibleBirthDate({ isValid: true, msg: "" });
        setErrorResponsibleBirthLocation({ isValid: true, msg: "" });
        setErrorResponsibleBirthProvince({ isValid: true, msg: "" });
        setErrorResponsibleEmailAddress({ isValid: true, msg: "" });
        setErrorResponsiblePhoneNumber({ isValid: true, msg: "" });
        setErrorEmailAddress({ isValid: true, msg: "" });
        setErrorReiEmail({ isValid: true, msg: "" });
        setErrorEffectiveDate({ isValid: true, msg: "" });
        setErrorEndDate({ isValid: true, msg: "" });
        setErrorPecManager({ isValid: true, msg: "" });
        setErrorPecDeleteDate({ isValid: true, msg: "" });
        setErrorCancellationAddress({ isValid: true, msg: "" });
        setErrorCancellationLocation({ isValid: true, msg: "" });
        setErrorCancellationProvince({ isValid: true, msg: "" });
        setErrorIncorporatingFiscalCode1({ isValid: true, msg: "" });
        setErrorIncorporatingFiscalCode2({ isValid: true, msg: "" });
        setErrorIncorporatingFiscalCode3({ isValid: true, msg: "" });
    };

    const handleValidation = () => {
        resetError();
        let isValidSubject = true;
        let isValidResp = true;
        let isValidPec = true;
        let isValidCanc = true;

        // VALIDATION SUBJECT
        if (personType === Constants.PNF) {
            if (!companyName) {
                setErrorCompanyName({ isValid: false, msg: "Campo obbligatorio" });
                isValidSubject = false;
            }
        } else if (personType === Constants.PF) {
            if (!firstName) {
                setErrorFirstName({ isValid: false, msg: "Campo obbligatorio" });
                isValidSubject = false;
            }
            if (!lastName) {
                setErrorLastName({ isValid: false, msg: "Campo obbligatorio" });
                isValidSubject = false;
            }
            if (!gender) {
                setErrorGender({ isValid: false, msg: "Campo obbligatorio" });
                isValidSubject = false;
            }
            if (!birthDate) {
                setErrorBirthDate({ isValid: false, msg: "Campo obbligatorio" });
                isValidSubject = false;
            }
        }
        if (!fiscalCode) {
            setErrorFiscalCode({ isValid: false, msg: "Campo obbligatorio" });
            isValidSubject = false;
        }

        if (!location) {
            setErrorLocation({ isValid: false, msg: "Campo obbligatorio" })
            isValidSubject = false;
        }
        if (!province) {
            setErrorProvince({ isValid: false, msg: "Campo obbligatorio" });
            isValidSubject = false;
        }
        if (!subjectType) {
            setErrorSubjectType({ isValid: false, msg: "Campo obbligatorio" });
            isValidSubject = false;
        }
        if (!monitoringReason && !registryReason && !factaReason) {
            setErrorMonitoringReason({ isValid: false, msg: "Selezionare almeno una tipologia" });
            isValidSubject = false;
        } else if (monitoringReason && subjectType === '5') {
            setErrorSubjectType({ isValid: false, msg: "Monitoraggio fiscale non può essere selezionato" });
            isValidSubject = false;
        } else if (registryReason && subjectType === '29') {
            setErrorSubjectType({ isValid: false, msg: "Il tipo soggetto 29 non può essere iscritto alla sezione INDAGINI BANCARIE. In caso di Holding da  iscrivere per la comunicazione Anagrafe Rapporti scegliere il tipo 05" });
            isValidSubject = false;
        }



        // VALIDATION RESPONSABLE
        if (!responsibleFiscalCode) {
            setErrorResponsibleFiscalCode({ isValid: false, msg: "Campo obbligatorio" });
            isValidResp = false;
        }
        if (!responsibleLastName) {
            setErrorResponsibleLastName({ isValid: false, msg: "Campo obbligatorio" });
            isValidResp = false;
        }
        if (!responsibleFirstName) {
            setErrorResponsibleFirstName({ isValid: false, msg: "Campo obbligatorio" });
            isValidResp = false;
        }
        if (!responsibleGender) {
            setErrorResponsibleGender({ isValid: false, msg: "Campo obbligatorio" });
            isValidResp = false;
        }
        if (!responsibleBirthtDate) {
            setErrorResponsibleBirthDate({ isValid: false, msg: "Campo obbligatorio" });
            isValidResp = false;
        }
        if (!responsibleBirthtLocation) {
            setErrorResponsibleBirthLocation({ isValid: false, msg: "Campo obbligatorio" });
            isValidResp = false;
        }
        if (!responsibleBirthProvince) {
            setErrorResponsibleBirthProvince({ isValid: false, msg: "Campo obbligatorio" });
            isValidResp = false;
        }
        if (!responsibleEmailAddress) {
            setErrorResponsibleEmailAddress({ isValid: false, msg: "Campo obbligatorio" });
            isValidResp = false;
        } else {
            if (!Utility.testEmail(responsibleEmailAddress)) {
                setErrorResponsibleEmailAddress({ isValid: false, msg: "Formato email non valido" });
                isValidResp = false;
            }
        }
        if (!responsiblePhoneNumber) {
            setErrorResponsiblePhoneNumber({ isValid: false, msg: "Campo obbligatorio" });
            isValidResp = false;
        }
        // VALIDATION EMAIL
        if (commType !== "2" && commType !== "3") {
            if (!pecEmailAddress) {
                setErrorEmailAddress({ isValid: false, msg: "Campo obbligatorio" });
                isValidPec = false;
            }
        } else {
            if (!reiEmail) {
                setErrorReiEmail({ isValid: false, msg: "Campo obbligatorio" });
                isValidPec = false;
            } else if (!Utility.testEmail(reiEmail)) {
                setErrorReiEmail({ isValid: false, msg: "Formato email non valido" });
                isValidPec = false;
            }
        }
        if (commType !== "2") {
            if (!pecManager) {
                setErrorPecManager({ isValid: false, msg: "Campo obbligatorio" });
                isValidPec = false;
            }
            if (!effectiveDate) {
                setErrorEffectiveDate({ isValid: false, msg: "Campo obbligatorio" });
                isValidPec = false;
            } else {
                if (endDate !== '' && endDate < effectiveDate) {
                    setErrorEndDate({ isValid: false, msg: "La data fine non può essere minore della data inizio" });
                    isValidPec = false;
                }
            }
        }
        // VALIDATION CANCELLATION
        if (commType === '2') {
            if (!pecDeleteDate) {
                setErrorPecDeleteDate({ isValid: false, msg: "Campo obbligatorio" });
                isValidCanc = false;
            }
            if (!cancellationAddress && (cancellationReason === '2' || cancellationReason === '3' || cancellationReason === '4')) {
                setErrorCancellationAddress({ isValid: false, msg: "Campo obbligatorio" });
                isValidCanc = false;
            }
            if (!cancellationLocation && (cancellationReason === '2' || cancellationReason === '3' || cancellationReason === '4')) {
                setErrorCancellationLocation({ isValid: false, msg: "Campo obbligatorio" });
                isValidCanc = false;
            }
            if (!cancellationProvince && (cancellationReason === '2' || cancellationReason === '3' || cancellationReason === '4')) {
                setErrorCancellationLocation({ isValid: false, msg: "Campo obbligatorio" });
                isValidCanc = false;
            }
            if (!incorporatingFiscalCodeOne && (cancellationReason === "1")) {
                setErrorIncorporatingFiscalCode1({ isValid: false, msg: "Campo obbligatorio" });
                isValidCanc = false;
            }
            if (cancellationReason === "5" && ((!incorporatingFiscalCodeOne && !incorporatingFiscalCodeTwo) || (!incorporatingFiscalCodeOne && !incorporatingFiscalCodeThree) || (!incorporatingFiscalCodeTwo && !incorporatingFiscalCodeThree))
            ) {
                if (!incorporatingFiscalCodeOne) {
                    setErrorIncorporatingFiscalCode1({ isValid: false, msg: "Campo obbligatorio" });
                }
                if (!incorporatingFiscalCodeTwo) {
                    setErrorIncorporatingFiscalCode2({ isValid: false, msg: "Campo obbligatorio" });
                }
                if (!incorporatingFiscalCodeThree) {
                    setErrorIncorporatingFiscalCode3({ isValid: false, msg: "Campo obbligatorio" });
                }
                isValidCanc = false;
            }
        }


        if (!isValidSubject) {
            setTabError({ subject: false, responsible: true, pec: true, canc: false })
        } else if (!isValidResp) {
            setTabError({ subject: true, responsible: false, pec: true, canc: false })
        } else if (!isValidPec) {
            setTabError({ subject: true, responsible: true, pec: false, canc: false })
        } else if (!isValidCanc) {
            setTabError({ subject: true, responsible: true, pec: true, canc: false })
        }

        return isValidSubject && isValidResp && isValidPec && isValidCanc;
    };

    const handleFormSubmit = (event, sendComm) => {
        event.preventDefault();
        if (handleValidation()) {
            addComureiSubject(formData, sendComm);
        }
    };

    const handlePersonTypeClick = event => {
        setPersonType(event.target.value);
        updateFormData({
            ...formData,
            personType: event.target.value
        });
    }

    return (
        <React.Fragment>
            <form onSubmit={e => handleFormSubmit(e, false)}>
                <div className="row-detail comurei bg-blue pt-4 pe-3 pb-4 ps-3 d-flex">
                    <div className="tab-content col-md-12 col-lg-12">
                        <Tabs>
                            <div id="tab-sub" label="Soggetto">
                                <div className="form-row">
                                    <div className="col-md-8 col-lg-8 subDetailComurei">
                                        <h6>Dettaglio Soggetto che Comunica</h6>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6 mb-2">
                                                <div className="input-group mt-2 d-flex justify-content-between">
                                                    <div className="inputRadio">
                                                        <input hidden={true} className="form-check-input" type="radio" name={`pType${progr}`}
                                                            id={`pType1${progr}`} value={Constants.PNF} onChange={handlePersonTypeClick} />
                                                        <label className="form-check-label" htmlFor={`pType1${progr}`}>
                                                            <img alt={""} width="20" className="me-1" src={personType === Constants.PNF ? radioSel : radioUnsel} />Persona giuridica
                                                        </label>
                                                    </div>
                                                    <div className="inputRadio">
                                                        <input hidden={true} className="form-check-input" type="radio" name={`pType${progr}`}
                                                            id={`pType2${progr}`} value={Constants.PF} onChange={handlePersonTypeClick} />
                                                        <label className="form-check-label" htmlFor={`pType2${progr}`} >
                                                            <img alt={""} width="20" className="me-1" src={personType === Constants.PF ? radioSel : radioUnsel} />Persona fisica
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-10 col-lg-10">
                                                <BwmSelect
                                                    options={subjectTypes}
                                                    name='subjectType'
                                                    label='Tipo Soggetto'
                                                    className='form-control'
                                                    onChange={handlerChangeSubjectType}
                                                    value={subjectType}
                                                    error={errorSubjectType}
                                                    showCode={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-lg-4">
                                                <BwmInput name='fiscalCode'
                                                    label='Codice Fiscale'
                                                    className='form-control'
                                                    value={fiscalCode}
                                                    onChange={e => handleChangeTextField(e.target.value, "fiscalCode", setFiscalCode, true)}
                                                    error={errorFiscalCode}
                                                    maxLength="16"
                                                    onBlur={e => handleFiscalCode(e.target.value)}
                                                />
                                            </div>
                                            {personType === Constants.PNF &&
                                                <div className="col-md-7 col-lg-7">
                                                    <BwmInput name='companyName'
                                                        label='Ragione Sociale'
                                                        className='form-control'
                                                        value={companyName}
                                                        onChange={e => handleChangeTextField(e.target.value, "companyName", setCompanyName)}
                                                        error={errorCompanyName}
                                                        maxLength="60"

                                                    />
                                                </div>
                                            }
                                            {personType === Constants.PF &&
                                                <div className="col-md-7 col-lg-7 d-flex justify-content-around">
                                                    <BwmInput name='firstName'
                                                        label='Nome'
                                                        className='form-control'
                                                        value={firstName}
                                                        onChange={e => handleChangeTextField(e.target.value, "firstName", setFirstName)}
                                                        error={errorFirstName}
                                                        maxLength="60"
                                                    />
                                                    <BwmInput name='lastName'
                                                        label='Cognome'
                                                        className='form-control'
                                                        value={lastName}
                                                        onChange={e => handleChangeTextField(e.target.value, "lastName", setLastName)}
                                                        error={errorLastName}
                                                        maxLength="60"
                                                    />
                                                </div>
                                            }


                                        </div>
                                        <div className="row ">
                                            {personType === Constants.PF &&
                                                <div className="col-md-6 col-lg-6 d-flex justify-content-around">
                                                    <BwmSelect
                                                        options={Constants.genderList}
                                                        name='gender'
                                                        label='Sesso'
                                                        className='form-control '
                                                        onChange={e => handleChangeTextField(e.target.value, "gender", setGender)}
                                                        value={gender}
                                                        error={errorGender}
                                                    />

                                                    <DateField label="Data di nascita"
                                                        date={birthDate}
                                                        onChange={date => handlerChangeBirthDate(date)}
                                                        error={errorBirthDate} />

                                                </div>
                                            }
                                            <div className="col-md-4 col-lg-4">
                                                <AutoCompileCustom
                                                    label={personType === Constants.PNF ? 'Sede Legale (Località)' : 'Comune di nascita'}
                                                    id="location"
                                                    handleInputAutocompile={handleInputAutocompile}
                                                    filtro={props.locations}
                                                    value={location}
                                                    error={errorLocation}
                                                />
                                            </div>
                                            <div className="col-md-2 col-lg-2">
                                                <ProvinceField province={province}
                                                    onChange={e => handleChangeTextField(e.target.value, "province", setProvince, true)}
                                                    error={errorProvince}
                                                    maxLength="2" />
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-lg-12" >
                                                <label style={{ color: '#128186', fontWeight: 'bold' }}>Struttura accentrata</label>
                                                <div className="input-group mt-2 d-flex justify-content-start align-items-center">
                                                    <BwmCheckbox
                                                        name={`centralizedStructure${progr}`}
                                                        label='Sì'
                                                        className='form-control'
                                                        value={centralizedStructure}
                                                        error={errorCentralizedStructure}
                                                        onClick={(e) => handleCentralizedStructure(e)}
                                                    />
                                                    {centralizedStructure && <span className="text-danger ms-1 me-1 fw-bold"> per le strutture accentrate non sarà possibile caricare l'impegno alla trasmissione di un intermediario</span>}
                                                    <div className="text-danger">
                                                        {!errorMonitoringReason.isValid ? errorMonitoringReason.msg : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-lg-4 commDetailComurei">
                                        <h6>Dettaglio Comunicazione</h6>
                                        <div className="form-check">
                                            <label>Tipo Comunicazione</label>
                                            <div className="input-group mt-2 d-inline-flex d-flex justify-content-between">
                                                <div className="col-12 p-0 inputRadio">
                                                    <input hidden={true} className="form-check-input"
                                                        type="radio"
                                                        name={`commType${progr}`}
                                                        checked={commType === '2'}
                                                        id={`ct2${progr}`} value={'2'}
                                                        onChange={handleCommTypeClick} />
                                                    <label className="form-check-label" htmlFor={`ct2${progr}`}>
                                                        <img width="20" className="me-2"
                                                            src={commType === '2' ? radioSel : radioUnsel} />Cancellazione dal/dai Registro/i
                                                    </label>
                                                </div>
                                                <div className="col-12 p-0  inputRadio">
                                                    <input hidden={true} className="form-check-input"
                                                        type="radio"
                                                        name={`commType${progr}`} checked={commType === '1'}
                                                        id={`ct1${progr}`} value={'1'}
                                                        onChange={handleCommTypeClick} />
                                                    <label className="form-check-label" htmlFor={`ct1${progr}`}>
                                                        <img width="20" className="me-2"
                                                            src={commType === '1' ? radioSel : radioUnsel} />
                                                        Prima iscrizione
                                                    </label>
                                                </div>
                                                <div className="col-12 p-0  inputRadio">
                                                    <input hidden={true} className="form-check-input"
                                                        type="radio"
                                                        name={`commType${progr}`}
                                                        checked={commType === '3'}
                                                        id={`ct3${progr}`} value={'3'}
                                                        onChange={handleCommTypeClick} />
                                                    <label className="form-check-label" htmlFor={`ct3${progr}`}>
                                                        <img width="20" className="me-2"
                                                            src={commType === '3' ? radioSel : radioUnsel} />Variazione di dati già comunicati
                                                    </label>
                                                </div>
                                                <div className="col-12 p-0  inputRadio">
                                                    <input hidden={true} className="form-check-input"
                                                        type="radio"
                                                        name={`commType${progr}`}
                                                        checked={commType === '4'}
                                                        id={`ct4${progr}`} value={'4'}
                                                        onChange={handleCommTypeClick} />
                                                    <label className="form-check-label" htmlFor={`ct4${progr}`}>
                                                        <img width="20" className="me-2"
                                                            src={commType === '4' ? radioSel : radioUnsel} />Reiscrizione al/ai Registri
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divide-form" />
                                        <div className="form-check">
                                            <label>Motivi della Comunicazione (sezioni REI)</label>
                                            <div className="input-group mt-2 d-flex justify-content-between">
                                                <BwmCheckbox
                                                    name={`registryReason_${progr}`}
                                                    label='Indagini Finanziarie (Anagrafe Rapporti)'
                                                    className='form-control'
                                                    value={registryReason}
                                                    onClick={(e) => handlerChangeRegistryReason(e)}
                                                />
                                                <BwmCheckbox
                                                    name={`monitoringReason_${progr}`}
                                                    label='Monitoraggio fiscale'
                                                    className='form-control'
                                                    value={monitoringReason}
                                                    onClick={(e) => handlerChangeMonitoringReason(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-check pt-2">
                                            <label>Sezione FATCA/CRS</label>
                                            <div className="input-group mt-2 d-flex justify-content-between">
                                                <BwmCheckbox
                                                    name={`factaReason_${progr}`}
                                                    label='FATCA/CRS'
                                                    className='form-control'
                                                    value={factaReason}
                                                    error={errorFactaReason}
                                                    onClick={(e) => handlerChangeFactaReason(e)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div id="tab-resp" className="d-none" label="Responsabile">
                                <div className="form-row">
                                    <div className="col-md-3 col-lg-3 me-4">
                                        <BwmInput name='responsibleFiscalCode'
                                            label='Codice Fiscale'
                                            className='form-control'
                                            value={responsibleFiscalCode}
                                            onChange={e => handleChangeTextField(e.target.value, "comureiSubjectResponsible.fiscalCode", setResponsibleFiscalCode, true)}
                                            onBlur={e => handlerResponsibleFiscalCode(e.target.value)}
                                            error={errorResponsibleFiscalCode}
                                            maxLength="60"
                                        />
                                    </div>
                                    <div className="col-md-3 col-lg-3 me-4">
                                        <BwmInput name='responsibleLastName'
                                            label='Cognome'
                                            className='form-control'
                                            value={responsibleLastName}
                                            onChange={e => handleChangeTextField(e.target.value, "comureiSubjectResponsible.lastName", setResponsibleLastName)}
                                            error={errorResponsibleLastName}
                                            maxLength="60"
                                        />
                                    </div>
                                    <div className="col-md-3 col-lg-3">
                                        <BwmInput name='responsibleFirstName'
                                            label='Nome'
                                            className='form-control'
                                            value={responsibleFirstName}
                                            onChange={e => handleChangeTextField(e.target.value, "comureiSubjectResponsible.firstName", setResponsibleFirstName)}
                                            error={errorResponsibleFirstName}
                                            maxLength="60"
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-1 col-lg-1 me-4">
                                        <BwmSelect
                                            options={Constants.genderList}
                                            name='responsibleGender'
                                            label='Sesso'
                                            className='form-control'
                                            onChange={e => handleChangeTextField(e.target.value, "comureiSubjectResponsible.gender", setResponsibleGender)}
                                            value={responsibleGender}
                                            error={errorResponsibleGender}
                                        />
                                    </div>
                                    <div className="col-md-2 col-lg-2 me-4">
                                        <DateField label="Data di nascita"
                                            date={responsibleBirthtDate}
                                            onChange={date => handlerChangeResponsibleBirthDate(date)}
                                            error={errorResponsibleBirthDate} />
                                    </div>
                                    <div className="col-md-3 col-lg-3 me-4">
                                        <AutoCompileCustom
                                            label='Comune di nascita'
                                            id="respLocation"
                                            handleInputAutocompile={handleInputResponsibleAutocompile}
                                            filtro={props.locations}
                                            value={responsibleBirthtLocation}
                                            error={errorResponsibleBirthLocation}
                                        />
                                    </div>
                                    <div className="col-md-1 col-lg-1">
                                        <ProvinceField
                                            province={responsibleBirthProvince}
                                            onChange={e => handleChangeTextField(e.target.value, "comureiSubjectResponsible.province", setResponsibleBirthtProvince, true)}
                                            error={errorResponsibleBirthProvince}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-5 col-lg-5 me-4">
                                        <BwmInput name='responsibleEmailAddress'
                                            label='Indirizzo Email (indirizzo personale del responsabile)'
                                            className='form-control'
                                            value={responsibleEmailAddress}
                                            onChange={e => handleChangeTextField(e.target.value, "comureiSubjectResponsible.email", setResponsibleEmailAddress, true)}
                                            error={errorResponsibleEmailAddress}
                                            maxLength="60"
                                        />
                                    </div>
                                    <div className="col-md-2 col-lg-2">
                                        <BwmInput name='responsiblePhoneNumber'
                                            label='Telefono'
                                            className='form-control'
                                            value={responsiblePhoneNumber}
                                            onChange={e => handlerChangeResponsiblePhoneNumber(e)}
                                            error={errorResponsiblePhoneNumber}
                                            maxLength="10"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id="tab-pec" className="d-none" label="Posta Elettronica">
                                <div className="form-row">
                                    <div className="col-md-7 col-lg-7">
                                        <div className="form-row mb-3">
                                            <div className={`col-md-12 col-lg-12 ${!(commType == '1' || commType == '4') ? 'hidden' : ''}`}>
                                                <BwmInput name='emailAddress'
                                                    label={`${commType === "4" ? 'INDIRIZZO PEC DA REISCRIVERE AL REI' : 'INDIRIZZO PEC DA SCRIVERE AL REI'} `}
                                                    className='form-control'
                                                    value={pecEmailAddress}
                                                    onChange={e => handleChangeTextField(e.target.value, "comureiSubjectPec.pecEmail", setPecEmailAddress, true)}
                                                    error={errorEmailAddress}
                                                    maxLength="100" />
                                            </div>
                                        </div>
                                        {commType != '2' &&
                                            <div className="form-row mb-3">
                                                <div className={`col-md-12 col-lg-12 ${(commType == '2') ? 'hidden' : ''}`}>
                                                    <BwmInput name='pecManager'
                                                        label='Gestore PEC'
                                                        className='form-control'
                                                        value={pecManager}
                                                        onChange={e => handleChangeTextField(e.target.value, "comureiSubjectPec.pecManager", setPecManager)}
                                                        error={errorPecManager}
                                                        maxLength="100" />
                                                </div>
                                            </div>
                                        }
                                        {commType != '2' &&
                                            <div className="form-row">
                                                <div className={`col-md-5 col-lg-5 ${(commType == '2') ? 'hidden' : ''}`}>
                                                    <DateField label={"Data DECORRENZA"}
                                                        date={effectiveDate}
                                                        onChange={date => handlerChangeEffectiveDate(date)}
                                                        error={errorEffectiveDate} />
                                                </div>
                                                <div className={`col-md-5 col-lg-5 offset-2 ${(commType == '2') ? 'hidden' : ''}`}>
                                                    <DateField label={"Data CESSAZIONE"}
                                                        date={endDate}
                                                        onChange={date => handlerChangeEndDate(date)}
                                                        error={errorEndDate} />
                                                </div>
                                            </div>
                                        }
                                        <div className={`form-row ${!(commType == '2' || commType == '3') ? 'hidden' : ''}`}>
                                            <div className={'col-6'}>
                                                <BwmInput name='emailAddress'
                                                    label={` ${commType == '2' ? 'Indirizzo di posta elettronica già comunicata al REI DA CANCELLARE' : 'Indirizzo PEC già comunicato DA VARIARE'}`}
                                                    className='form-control'
                                                    value={reiEmail}
                                                    onChange={e => handleChangeTextField(e.target.value, "comureiSubjectPec.reiEmail", setReiEmailAddress, true)}
                                                    error={errorReiEmail}
                                                    maxLength="255"
                                                />
                                            </div>
                                            {commType === '3' &&
                                                <div className={'col-6'}>
                                                    <BwmInput name='emailAddress'
                                                        label={`Indirizzo PEC ultima versione`}
                                                        className='form-control '
                                                        value={pecEmailAddress}
                                                        onChange={e => handleChangeTextField(e.target.value, "comureiSubjectPec.pecEmail", setPecEmailAddress, true)}
                                                        error={errorEmailAddress}
                                                        maxLength="255" />
                                                </div>}
                                        </div>

                                    </div>

                                    <div className="col-md-5 col-lg-5  infoPostaEle mb-3">
                                        <div className="infoPoint-title"><IInfo fill="#b8b8b8"
                                            width="20" /> Informazioni
                                        </div>
                                        <div className="infoPoint"><strong>Indirizzo EMAIL PEC:</strong> lo
                                            stesso da indicare nella RICHIESTA DI ACCREDITAMENTO al SID
                                        </div>
                                        <div className="infoPoint"><strong>Gestore PEC:</strong> Il gestore del
                                            servizio PEC (Infocamere, Aruba, PEC.it etc.)
                                        </div>
                                        <div className="infoPoint"><strong>Data DECORRENZA:</strong> è l'inizio
                                            del contratto con il gestore del servizio PEC
                                        </div>
                                        <div className="infoPoint"><strong>Data CESSAZIONE:</strong> non
                                            obbligatoria. Se indicata in caso di
                                            rinnovo <strong>NON</strong> deve essere fatta una nuova
                                            comunicazione
                                        </div>
                                        <div className="infoPoint"><strong>La Data VARIAZIONE</strong> non è più
                                            prevista quindi <strong>NON</strong> va indicata
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                commType == '2' ?

                                    <div id="tab-delete" className="d-none" label="Cancellazione">
                                        <Row className='align-items-center'>
                                            <div className="col-2 mb-2">
                                                <DateField label={"Data CANCELLAZIONE"}
                                                    date={pecDeleteDate}
                                                    onChange={date => handlerChangePecDeleteDate(date)}
                                                    error={errorPecDeleteDate} />
                                            </div>



                                        </Row>
                                        <div className="form-row mt-5">
                                            <div className="col-lg-6 p-0">
                                                <div className="form-check">
                                                    <label>Motivo della CANCELLAZIONE</label>
                                                    <div className="input-group mt-2">
                                                        <div className="col-12 p-0 inputRadio mb-2">
                                                            <input hidden={true} className="form-check-input"
                                                                type="radio"
                                                                name={`cancellationReason${progr}`}
                                                                checked={cancellationReason === '1'}
                                                                id={`cr1${progr}`} value={'1'}
                                                                onClick={handleCancellationReasonClick} />
                                                            <label className="form-check-label"
                                                                htmlFor={`cr1${progr}`}>
                                                                <img width="20" className="me-2"
                                                                    src={cancellationReason === '1' ? radioSel : radioUnsel} />
                                                                Cessazione  con confluenza dell'attività finanziaria in un solo operatore finanziario
                                                            </label>
                                                        </div>
                                                        <div className="col-12 p-0  inputRadio mb-2">
                                                            <input hidden={true} className="form-check-input"
                                                                type="radio"
                                                                name={`cancellationReason${progr}`}
                                                                checked={cancellationReason === '2'}
                                                                id={`cr2${progr}`} value={'2'}
                                                                onClick={handleCancellationReasonClick} />
                                                            <label className="form-check-label" htmlFor={`cr2${progr}`}>
                                                                <img width="20" className="me-2"
                                                                    src={cancellationReason === '2' ? radioSel : radioUnsel} />
                                                                Perdita dei requisiti soggettivi
                                                            </label>
                                                        </div>
                                                        <div className="col-12 p-0  inputRadio mb-2">
                                                            <input hidden={true} className="form-check-input"
                                                                type="radio"
                                                                name={`cancellationReason${progr}`}
                                                                checked={cancellationReason === '3'}
                                                                id={`cr3${progr}`} value={'3'}
                                                                onClick={handleCancellationReasonClick} />
                                                            <label className="form-check-label" htmlFor={`cr3${progr}`}>
                                                                <img width="20" className="me-2"
                                                                    src={cancellationReason === '3' ? radioSel : radioUnsel} />
                                                                Cessazione senza confluenza in altro soggetto
                                                            </label>
                                                        </div>
                                                        <div className="col-12 p-0  inputRadio mb-2">
                                                            <input hidden={true} className="form-check-input"
                                                                type="radio"
                                                                name={`cancellationReason${progr}`}
                                                                checked={cancellationReason === '4'}
                                                                id={`cr4${progr}`} value={'4'}
                                                                onClick={handleCancellationReasonClick} />
                                                            <label className="form-check-label" htmlFor={`cr4${progr}`}>
                                                                <img width="20" className="me-2"
                                                                    src={cancellationReason === '4' ? radioSel : radioUnsel} />
                                                                Cessazione con confluenza in altro soggetto non operatore finanziario
                                                            </label>
                                                        </div>
                                                        <div className="col-12 p-0  inputRadio mb-2">
                                                            <input hidden={true} className="form-check-input"
                                                                type="radio"
                                                                name={`cancellationReason${progr}`}
                                                                checked={cancellationReason === '5'}
                                                                id={`cr5${progr}`} value={'5'}
                                                                onClick={handleCancellationReasonClick} />
                                                            <label className="form-check-label" htmlFor={`cr5${progr}`}>
                                                                <img width="20" className="me-2"
                                                                    src={cancellationReason === '5' ? radioSel : radioUnsel} />
                                                                Cessazione  con confluenza dell'attività finanziaria in più operatori finanziari
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 p-0">
                                                <h6 className="mb-4"><strong>Disponibilità Contabilità
                                                    Ordinaria</strong></h6>
                                                {
                                                    (cancellationReason == '2' || cancellationReason === '3' || cancellationReason == '4') &&
                                                    <div className="row">
                                                        <div className="col-md-4 col-lg-4">
                                                            <BwmInput name='cancellationAddress'
                                                                label='Indirizzo'
                                                                className='form-control'
                                                                value={cancellationAddress}
                                                                onChange={e => handleChangeTextField(e.target.value, "comureiSubjectCancellation.address", setCancellationAddress)}
                                                                error={errorCancellationAddress}
                                                                maxLength="100" />
                                                        </div>
                                                        <div className="col-md-4 col-lg-4">
                                                            <AutoCompileCustom
                                                                label='Comune Sede Legale'
                                                                id="CancLocation"
                                                                handleInputAutocompile={handleInputCancAutocompile}
                                                                filtro={props.locations}
                                                                value={cancellationLocation}
                                                                error={errorCancellationLocation}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 col-lg-2 ">
                                                            <ProvinceField
                                                                province={cancellationProvince}
                                                                onChange={e => handleChangeTextField(e.target.value, "comureiSubjectCancellation.province", setCancellationProvince, true)}
                                                                error={errorCancellationProvince}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    cancellationReason === '1' &&
                                                    <div className="row">
                                                        <div className="col-md-4 col-lg-4">
                                                            <BwmInput name='incorporatingFiscalCodeOne'
                                                                label='Codice Fiscale operatore incorporante 1'
                                                                className='form-control'
                                                                value={incorporatingFiscalCodeOne}
                                                                onChange={e => handleChangeTextField(e.target.value, "comureiSubjectCancellation.incorporatingFiscalCodeOne", setIncorporatingFiscalCode1, true)}
                                                                error={errorIncorporatingFiscalCodeOne}
                                                                maxLength="100" />
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    cancellationReason === '5' &&
                                                    <div className="row">
                                                        <div className="col-md-4 col-lg-4">
                                                            <BwmInput name='incorporatingFiscalCodeOne'
                                                                label='Codice Fiscale operatore incorporante 1'
                                                                className='form-control'
                                                                value={incorporatingFiscalCodeOne}
                                                                onChange={e => handleChangeTextField(e.target.value, "comureiSubjectCancellation.incorporatingFiscalCodeOne", setIncorporatingFiscalCode1, true)}
                                                                error={errorIncorporatingFiscalCodeOne}
                                                                maxLength="100" />
                                                        </div>
                                                        <div className="col-md-4 col-lg-4">
                                                            <BwmInput name='incorporatingFiscalCodeTwo'
                                                                label='Codice Fiscale operatore incorporante 2'
                                                                className='form-control'
                                                                value={incorporatingFiscalCodeTwo}
                                                                onChange={e => handleChangeTextField(e.target.value, "comureiSubjectCancellation.incorporatingFiscalCodeTwo", setIncorporatingFiscalCode2, true)}
                                                                error={errorIncorporatingFiscalCodeTwo}
                                                                maxLength="100" />
                                                        </div>
                                                        <div className="col-md-4 col-lg-4">
                                                            <BwmInput name='incorporatingFiscalCodeThree'
                                                                label='Codice Fiscale operatore incorporante 3'
                                                                className='form-control'
                                                                value={incorporatingFiscalCodeThree}
                                                                onChange={e => handleChangeTextField(e.target.value, "comureiSubjectCancellation.incorporatingFiscalCodeThree", setIncorporatingFiscalCode3, true)}
                                                                error={errorIncorporatingFiscalCodeThree}
                                                                maxLength="100" />
                                                        </div>
                                                    </div>
                                                }


                                            </div>
                                        </div>
                                    </div> : <div id="tab-delete" className="d-none" label="Cancellazione" disabled></div>}
                            {centralizedStructure ? <div id="tab-transmission" className="d-none" label="Impegno alla trasmissione" disabled></div> :
                                <div id="tab-transmission" className="d-none" label="Impegno alla trasmissione">
                                    <div className="form-row mb-2">
                                        <div className="col-md-4 col-lg-4">
                                            <BwmInput name='transmissionFiscalCode'
                                                label='Codice Fiscale'
                                                className='form-control'
                                                value={transmissionFiscalCode}
                                                onChange={e => handleChangeTextField(e.target.value, "comureiTransmission.fiscalCode", setTransmissionFiscalCode, true)}
                                                error={errorTransmissionFiscalCode}
                                                maxLength="100" />
                                        </div>
                                        <div className="col-md-7 col-lg-7 commDetailComurei offset-1">
                                            <div className="form-check">
                                                <label>Impegno alla trasmisssione telematica</label>
                                                <div className="input-group mt-2">
                                                    <div className="inputRadio me-3">
                                                        <input hidden={true} className="form-check-input"
                                                            type="radio"
                                                            name={`transmissionType${progr}`}
                                                            checked={transmissionType === '1'}
                                                            id={`tt1${progr}`} value={'1'}
                                                            onClick={handleTransmissionTypeClick} />
                                                        <label className="form-check-label" htmlFor={`tt1${progr}`}>
                                                            <img width="20" className="me-2"
                                                                src={transmissionType === '1' ? radioSel : radioUnsel} />
                                                            Da CONTRIBUENTE
                                                        </label>
                                                    </div>
                                                    <div className="inputRadio">
                                                        <input hidden={true} className="form-check-input"
                                                            type="radio"
                                                            name={`transmissionType${progr}`}
                                                            checked={transmissionType === '2'}
                                                            id={`tt2${progr}`} value={'2'}
                                                            onClick={handleTransmissionTypeClick} />
                                                        <label className="form-check-label" htmlFor={`tt2${progr}`}>
                                                            <img width="20" className="me-2"
                                                                src={transmissionType === '2' ? radioSel : radioUnsel} />
                                                            Da chi effettua l'INVIO
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-9 col-lg-9 me-4 mt-3">
                                            <div className="infoPostaEle panelTrasm">
                                                <div className="infoPoint-title"><IInfo fill="#b8b8b8"
                                                    width="20" /> Informazioni
                                                </div>
                                                <div className="infoPoint">
                                                    Compilare solo nel caso di comunicazione spedita tramite
                                                    Entratel di un intermediario (commercialista) diverso dal
                                                    soggetto obbligato.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Tabs>
                    </div>
                </div>
                <div
                    className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                    <button
                        className="btn btn-outline-primary rounded-pill btn-empty px-4 btn-sm"
                        onClick={props.closeModal}><IUndo className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />&nbsp; ANNULLA
                    </button>
                    <button
                        className="btn btn-primary rounded-pill btn-new-rel px-5"
                        onClick={e => handleFormSubmit(e, true)}
                    ><ITextFile className={"padding-bottom-4px svg"} width="13" fill={'#FFFFFF'} />&nbsp; CREA COMUNICAZIONE
                    </button>
                    <button type="submit"
                        className="btn btn-primary rounded-pill btn-new-rel px-5"><ISave className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; CONFERMA
                    </button>
                </div>
            </form>

        </React.Fragment>
    )

};

export default reduxForm({
    form: "ComureiForm"
})(ComureiForm)

