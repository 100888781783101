import {COMUREI_GET_SUBJECT_TYPES_SUCCESS, COMUREI_GET_SUBJECTS_SUCCESS} from "../../actions/types";


const INITIAL_STATE = {
    comureiSubjectTypes: {
        data: [],
        errors: []
    },

    comureiSubjects: {
        data: [],
        errors: []
    }
};

export const comureiSubjectTypesReducer = (state = INITIAL_STATE.comureiSubjectTypes, action) => {
    switch (action.type) {
        case COMUREI_GET_SUBJECT_TYPES_SUCCESS:
            return {...state, data: action.comureiSubjectTypes};
        default:
            return state;
    }
};

export const comureiSubjectsReducer = (state = INITIAL_STATE.comureiSubjects, action) => {
    switch (action.type) {
        case COMUREI_GET_SUBJECTS_SUCCESS:
            return {...state, data: action.comureiSubjects};
        default:
            return state;
    }
};

