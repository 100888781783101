import { isApplicationExpired } from "../../actions";
import { APPLICATION_COMUREI, CART_LINK, getEcommerceProductId, getEcommerceSubjectProductId } from "../../config/Constants";
import { formatDateForDisplay } from "./Utility";

export function SubjectTotalAndCallToAction({
    total = 0,
    used = 0,
    remaining = 0,
    licenseType = "",
    customerApplication
}) {
    /**
     * Se la licenza è in demo, indirizzo l'utente verso la pagina di carrello con prodotto base + il numero di soggetti usati
     * se DEMO
        Abbonanmento in DEMO valido fino al xxxxx  ACQUISTA
        se licenza
        Abbonamento valido fino al xxxxxxxxxxxxx
        Abbonamento SCADUTO il xxxxxxx  RINNOVA
     */


    const renderAddSubjectsButton = (customerApplication, remaining) => {
        if (remaining >= 0) {

            return (
                <a href={CART_LINK(0
                    , getEcommerceSubjectProductId(customerApplication?.application.name)
                    , 1)}
                    target='_blank' rel="noopener noreferrer" className='text-light'>
                    <button type="button" className="btn btn-empty  btn-sm" >
                        AGGIUNGI
                    </button>
                </a>
            )

        } else {
            return (
                <a href={CART_LINK(0
                    , getEcommerceSubjectProductId(customerApplication?.application.name)
                    , remaining*-1)}
                    target='_blank' rel="noopener noreferrer" className='text-light'>
                    <button type="button" className="btn btn-danger  btn-sm" >
                        AGGIUNGI MANCANTI
                    </button>
                </a>
            )
        }
    }

    const renderRinnovoSection = (customerApplication,remaining,used,licenseType) => {
        if (customerApplication?.application.name !== APPLICATION_COMUREI) {
            return (
                <>
                    <span className='ms-2 me-2' style={{ fontWeight: "bold" }}>
                        Abbonamento
                        {
                            licenseType === "DEMO" &&
                            <> in <strong style={{ color: "red" }}>DEMO</strong> </>

                        }
                        {
                            isApplicationExpired(customerApplication)
                                ?
                                <> &nbsp;<strong style={{ color: "red" }}>SCADUTO</strong> il <strong>{formatDateForDisplay(customerApplication?.expiration)}</strong></>
                                :
                                <>&nbsp;valido fino al <strong>{formatDateForDisplay(customerApplication?.expiration)}</strong></>

                        }
                    </span>
                    {
                        (licenseType === "DEMO" || isApplicationExpired(customerApplication)) &&

                        <a href={CART_LINK(getEcommerceProductId(customerApplication?.application.name), getEcommerceSubjectProductId(customerApplication?.application.name), used)} target='_blank' rel="noopener noreferrer" className='text-light'>
                            <button type="button" className="btn btn-danger  btn-sm" >
                                {(licenseType === "DEMO" && "ACQUISTA") || (isApplicationExpired(customerApplication) && "RINNOVA")}
                            </button>
                        </a>
                    }
                    {
                        remaining < 0 &&
                        <strong style={{ color: "red" }}>
                            Acquista i soggetti mancanti ({remaining*-1})
                        </strong>
                    }
                </>
            )
        } else {
            return "";
        }
    }


    if (customerApplication) {
        return (
            <>
                <span className='me-2' style={{ fontWeight: "bold" }}>
                    Soggetti Acquistati/Disponibili: {total}/{remaining}
                </span>
                {
                    (licenseType !== "DEMO" && !isApplicationExpired(customerApplication)) &&

                    renderAddSubjectsButton(customerApplication, remaining)
                }

                {renderRinnovoSection(customerApplication,remaining,used,licenseType)}
            </>)
    } else {
        return ""
    }


}