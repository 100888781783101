import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BtnFill } from "./BtnFill";


export const titleColors = {
    ALERT: "popup-title-alert",
    SUCCESS: "popup-title-success",
    ERROR: "popup-title-delete"
}

export function PopupConfirm({ title, titleColor, component, innerHtml, text, onClickCancel, showConfirmButton = true, showCancelButton = true, handleClickConfirm, confirm, cancel }) {

    const Component = component || (() => <></>);

    return (
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <React.Fragment>
                        <div className="bar"></div>
                        <div className='popup popup-large'>
                            <button type="button" className="close" onClick={onClose}><span aria-hidden="true">×</span><span
                                className="visually-hidden">Close</span>
                            </button>
                            <Row className={`show-grid ${titleColor} text-center`}>
                                <Col md={12} lg={12}>
                                    <img alt={""} src="" />
                                </Col>
                                <Col md={12} lg={12} className="py-3">
                                    <h4>{title || 'Attenzione'}</h4>
                                </Col>
                            </Row>
                            <Row className={"show-grid text-center"}>
                                <Col md={12} lg={12} dangerouslySetInnerHTML={{ __html: innerHtml }}>
                                </Col>
                                <Col md={12} lg={12}>
                                    {text}
                                </Col>
                                <Col md={12} lg={12}>
                                    <Component />
                                </Col>
                            </Row>
                            <div className={`my-2 py-5 d-flex align-items-center ${showCancelButton && showConfirmButton ? "justify-content-between" : "justify-content-center"}`}>
                                {showCancelButton &&
                                    <button type="button" className="btn btn-outline-primary btn-empty px-4 btn-sm"
                                        onClick={() => {
                                            if (onClickCancel) onClickCancel();
                                            onClose()
                                        }}>{cancel || "ANNULLA"}</button>
                                }
                                {
                                    showConfirmButton &&
                                    <BtnFill text={confirm || "CONTINUA"} handlerOnClick={() => {
                                        if (handleClickConfirm) handleClickConfirm();
                                        onClose()
                                    }} />
                                }
                            </div>
                        </div>
                    </React.Fragment>
                )
            },
            closeOnClickOutside: false
        })
    );
}