import React from "react";

export function TableFooter({ PageSelectorComponent, CleanButtonComponent, renderConfigurationBox, tableRef }) {

    return (
        (
            <div className="row pt-1 pb-2 justify-content-between align-items-center"
                style={{ background: "#FFFFFF", bottom: "45px", }}>

                <div className="col-4">
                    {
                        renderConfigurationBox()
                    }
                </div>
                <div className='col-4'>
                    <PageSelectorComponent />

                </div>
                <div className='col-4 text-end'>
                    <CleanButtonComponent />

                </div>
            </div>

        )


    )
}