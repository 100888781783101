import React from 'react';
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import * as Constants from "../../../../config/Constants";
import radioSel from "../../../../styles/images/radiobutton-selected.png";
import radioUnsel from "../../../../styles/images/radiobutton-unselected.png";

registerLocale("it", it);

export class PersonTypeField extends  React.Component {
    constructor(props) {
        super(props);

        this.state = {
            personType : props.personType,
            error: {isValid:true, msg:''}
        }
    }
    componentWillReceiveProps(nextProps){
        this.setState({personType: nextProps.personType})
        if(nextProps.error !== undefined) {
            this.setState({error: nextProps.error});
        }
    }

    render(){
       const {label, onClick, id} = this.props;
       return (
           <React.Fragment>
               <div className="form-check">
                   <label>Tipo</label>
                   <div className="input-group mt-2 d-flex flex-column">
                       <div className="inputRadio mt-1 mb-4">
                           <input hidden={true} className="form-check-input" type="radio" name={`personType${id}`}
                                  id={`personType1${id}`} value={Constants.PNF} onClick={onClick} disabled={this.props.readOnly}/>
                           <label className="form-check-label" htmlFor={`personType1${id}`}>
                               <img alt={""} width="20" className="me-3" src={this.state.personType === Constants.PNF? radioSel : radioUnsel}/>Persona giuridica
                           </label>
                       </div>
                       <div className="inputRadio">
                           <input hidden={true} className="form-check-input" type="radio" name={`personType${id}`}
                                  id={`personType2${id}`}  value={Constants.PF} onClick={onClick} disabled={this.props.readOnly}/>
                           <label className="form-check-label" htmlFor={`personType2${id}`} >
                               <img alt={""}  width="20" className="me-3" src={this.state.personType === Constants.PF? radioSel : radioUnsel}/>Persona Fisica
                           </label>
                       </div>
                   </div>
                   {  !this.state.error.isValid &&
                            <div className="text-danger">{this.state.error.msg}</div>
                   }
               </div>
           </React.Fragment>
       )
   }
}



