import React from 'react';
import RowSpinner from "./Spinner";

import '../../../styles/spinner.css'

export class PageSpinner extends React.Component {
    render() {
        return (
            <div className='resultLoader'>
                <div className='loader'>
                    <RowSpinner/>
                    <div className='testo'>
                        {this.props?.text || "Stiamo elaborando la tua richiesta..."}
                    </div>
                </div>
            </div>
        )
    }
}
