import React from 'react';
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../styles/autocomplete.css";
import Modal from "react-bootstrap/Modal";
import { useSelector } from 'react-redux';
import { ArcoRegistrationContractForm } from "./ArcoRegistrationContractForm"

registerLocale("it", it);

export function ArcoRegistrationContractModal(props) {
    const arcoSubject = useSelector(state => state.arcoSubject.data);    

    const getSubjectName = () => {
        return arcoSubject?.companyName;
    };

return (
    <React.Fragment>
        <Modal
            backdrop="static"
            show={props.show}
            onHide={props.onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <div className="bar"></div>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                <h2>{'< ' + getSubjectName()}</h2>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <ArcoRegistrationContractForm
                        {...props}
                        arcoCausalOperations={props.arcoCausalOperations}
                        currencies={props.currencies}
                        addArcoContract={props.addArcoContract}
                        onHide={props.onHide}
                        selectedContract={props.selectedContract}
                    />
                </div>
            </Modal.Body>
        </Modal>
    </React.Fragment>
)
}