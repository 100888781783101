import React from "react";
import { ReactComponent as IAnagrafica } from "../../../../styles/images/svg/id-card.svg";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustom";
import { BwmInput } from "../../../shared/form/BwmInput";
import { DateField } from "../../../shared/form/DateField";
import radioSel from "../../../../styles/images/radiobutton-selected.png";
import radioUnsel from "../../../../styles/images/radiobutton-unselected.png";
import * as Constants from "../../../../config/Constants";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import SidContactFormContainer from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/SidContactFormContainer';
export const CoraSubjectForm = (self) => {

  const nameApplication = Constants.APPLICATION_CORA;
  const id = self.props.subject?.id || 0;
  const personType = self.state.personType;

  return (
    <div className="row-detail bg-blue pt-4 pe-3 pb-4 ps-3 d-flex">

      <div className={"col-6"}>
        <div className="soggetto-crs big">
          <form id={`anagrafica-${id}`}>
            <div className="row">
              <h5 className="ms-4 mb-2">
                <IAnagrafica fill="#000" width="25" />
                Anagrafica
              </h5>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 mb-2">
                <div className="input-group mt-2 d-flex justify-content-between">
                  <div className="inputRadio">
                    <input
                      hidden={true}
                      className="form-check-input"
                      type="radio"
                      name={`pType1-${id}`}
                      id={`pType1-${id}`}
                      value={Constants.PNF}
                      onChange={(e) => self.handlePersonTypeClick(e)}
                      checked={personType === Constants.PNF}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`pType1-${id}`}
                    >
                      <img
                        alt={"Persona giuridica"}
                        width="20"
                        className="me-1"
                        src={
                          personType === Constants.PNF ? radioSel : radioUnsel
                        }
                      />
                      Persona giuridica
                    </label>
                  </div>
                  <div className="inputRadio">
                    <input
                      hidden={true}
                      className="form-check-input"
                      type="radio"
                      name={`pType2-${id}`}
                      id={`pType2-${id}`}
                      value={Constants.PF}
                      onChange={(e) => self.handlePersonTypeClick(e)}
                      checked={personType === Constants.PF}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`pType2-${id}`}
                    >
                      <img
                        alt={"Persona fisica"}
                        width="20"
                        className="me-1"
                        src={
                          personType === Constants.PF ? radioSel : radioUnsel
                        }
                      />
                      Persona fisica
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {personType === Constants.PNF ? (
                <div className="col-md-11">
                  <BwmInput
                    name="fiscalCode"
                    label="Codice Fiscale"
                    type="text"
                    className="form-control"
                    value={self.state.fiscalCode}
                    onChange={(e) => self.handlerChangeFiscalCode(e)}
                    error={self.errorFiscalCode}
                    maxLength="16"
                    onBlur={(e) => self.handleFiscalCode(e.target.value)}
                  />
                </div>
              ) : (
                <>
                  <div className="col-4">
                    <BwmInput
                      name="fiscalCode"
                      label="Codice Fiscale"
                      type="text"
                      className="form-control"
                      value={self.state.fiscalCode}
                      onChange={(e) => self.handlerChangeFiscalCode(e)}
                      error={self.errorFiscalCode}
                      maxLength="16"
                      onBlur={(e) => self.handleFiscalCode(e.target.value)}
                    />
                  </div>
                  <div className="col-2">
                    <BwmSelect
                      options={Constants.genderList}
                      name="gender"
                      label="Sesso"
                      className="form-control "
                      onChange={(e) => self.handlerChangeGender(e)}
                      value={self.state.gender}
                    />
                  </div>
                  <div className="col-4">
                    <DateField
                      label="Data di nascita"
                      date={self.state.birthDate}
                      onChange={(date) => self.handlerChangeBirthDate(date)}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={"row"}>
              {personType === Constants.PNF ? (
                <div className="col-md-11">
                  <BwmInput
                    key={"Ragione-sociale"}
                    name="Ragione Sociale"
                    label="Ragione Sociale"
                    type="companyName"
                    className="form-control"
                    value={self.state.companyName}
                    onChange={self.handleChange}
                    error={self.state.errorCompanyName}
                    maxLength="60"
                  />
                </div>
              ) : (
                <>
                  <div className="col-6">
                    <BwmInput
                      key={"nome"}
                      name="Nome"
                      label="Nome"
                      type="text"
                      className="form-control"
                      value={self.state.firstName}
                      onChange={(e) => self.handlerChangeFirstName(e)}
                    />
                  </div>
                  <div className="col-6">
                    <BwmInput
                      key={"cognome"}
                      name="cognome"
                      label="Cognome"
                      type="text"
                      className="form-control"
                      value={self.state.lastName}
                      onChange={(e) => self.handlerChangeLastName(e)}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-md-8">
                <AutoCompileCustom
                  label={
                    personType === Constants.PNF
                      ? "Sede Legale (Comune)"
                      : "Luogo di nascita"
                  }
                  id="location"
                  handleInputAutocompile={self.handleSelectLocation}
                  filtro={self.props.customLocations}
                  value={self.state.location}
                  error={self.state.errorLocation}
                />
              </div>
              <div className="col-md-3">
                <BwmInput
                  label="Provincia"
                  type="provincia"
                  className="form-control"
                  value={self.state.province}
                  onChange={self.handleChange}
                  error={self.state.errorProvince}
                />
              </div>
            </div>
          </form>

          <div
            style={{
              position: "absolute",
              bottom: "15px",
              right: "100px",
              fontSize: "15px",
            }}
            className="text-danger"
          >
            {self.state.errorInConfigSid}
          </div>
        </div>
      </div>
      <div className={"col-6"}>
        <div className="configurazioneSID">
          <SidContactFormContainer  {...self} nameApplication={nameApplication} />
          {!self.modal && (
            <div className="panel-info align-items-end">
              <h5>Codice soggetto: {self.props.subject?.id}</h5>
              {self.state.coraSubjectSid ? (
                <h5>Codice SID: {self.state.coraSubjectSid?.id}</h5>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
