import React from 'react';
import show from "../../../../styles/images/show_blu.png";
import { TransitionGroup, CSSTransition  } from 'react-transition-group';
import {RelationshipRowDetail} from "./RelationshipRowDetail";
import * as Constants from "../../../../config/Constants";

export class RelationshipRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            indexShow: -1
        };
        this.handleRowChange = this.handleRowChange.bind(this);
    }

    handleRowChange(e) {
        this.props.handleCheckChange(e);
    }

    closeAllRowDetails() {
        let details = document.getElementsByClassName("bg-blue bold");
        if(details){
            for (let i = 0; i < details.length; i++) {
                let detail = "detail-" + details[i].id.replace('row-','');
                document.getElementById(detail).click();
            }
        }
    }

    showDetail = (e, index) => {
        this.closeAllRowDetails();
        if (!e) {
            this.setState({
                show: false
            })
        } else if (e?.target?.tagName !== 'INPUT') {

            this.setState({
                show: !this.state.show,
                indexShow: index
            })
        }else{
            this.setState({
                show: !this.state.show,
                indexShow: index
            })
        }
    };

    formatCurrency = ((amount, currencyCode) => {
        let body = {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        };
        return amount.toLocaleString('it-IT', body);
    });

    render() {
        const {subject, relationship, index} = this.props;
        let holderType = relationship.crsRelationshipHolder?.holderType ? relationship.crsRelationshipHolder?.holderType?.code + "=" + relationship.crsRelationshipHolder?.holderType?.description : "";
        let numberType = relationship.crsRelationshipNumberType.code + ": " + relationship.crsRelationshipNumberType.description;
        let rowIndex = "row-" +  index;
        return (
            <React.Fragment>
                <div className={'text-start row-table' + (this.state.show && this.state.indexShow === index ? ' bg-blue bold' : '')} id={rowIndex} >
                    <div className='col w-5 text-center div-td'>
                        <input type="checkbox" aria-label="Checkbox for following text input" id={`row-${index}`}
                               name="relationshipCheck"
                               value={relationship.id} checked={this.props.isChecked}
                               onChange={(e) => this.handleRowChange(e)}
                        />
                    </div>
                    <div className='col w-8 div-td text-truncate' onClick={(e) => {this.showDetail(e, index)}}>
                        {relationship.progressive}
                    </div>
                    <div className='col w-12 div-td text-truncate' onClick={(e) => {this.showDetail(e, index)}}>
                        {relationship.crsRelationshipHolder.fiscalIdentifier}
                    </div>
                    <div className='col w-20 div-td text-truncate' onClick={(e) => {this.showDetail(e, index)}}>
                        {relationship.crsRelationshipHolder.personType === Constants.PF ? (relationship.crsRelationshipHolder.firstName + " " + relationship.crsRelationshipHolder.lastName) : relationship.crsRelationshipHolder.companyName}
                    </div>
                    <div className='col w-20 div-td text-truncate' onClick={(e) => {this.showDetail(e, index)}}>
                        {relationship.crsRelationshipHolder.personType === Constants.PNF ? holderType: ""}
                    </div>
                    <div className='col w-8 div-td text-truncate' onClick={(e) => {this.showDetail(e, index)}}>
                        {relationship.relationshipNumber}
                    </div>
                    <div className='col w-30 div-td text-truncate' onClick={(e) => {this.showDetail(e, index)}}>
                        {numberType}
                    </div>
                    <div className='col w-8 div-td text-truncate' onClick={(e) => {this.showDetail(e, index)}}>
                        {this.formatCurrency(parseInt(relationship.balance == null ? '0.00' : relationship.balance), relationship?.currency?.isoCode || "EUR" )}
                    </div>
                    <div id={"detail-" + index} className={"hidden"} onClick={(e) => {this.showDetail(e, index)}}><img src={show} alt={"Mostra dettaglio"}/></div>
                </div>
                <TransitionGroup component={null}>
                {this.state.show && this.state.indexShow === index ?
                    <CSSTransition
                        in={this.state.show}
                        timeout={300}
                        classNames="visible"
                        unmountOnExit>
                        <RelationshipRowDetail
                            subject={subject}
                            relationship={relationship}
                            index={index}
                            handlerShowDetail={this.showDetail}
                            getRelationships={this.props.getRelationships}
                        />
                    </CSSTransition >
                    : ''}
                </TransitionGroup>
            </React.Fragment>
        )
    }
}
