import React from "react";
import * as actions from "../../../../actions";
import { connect } from "react-redux";
import moment from "moment";
import * as Constants from "../../../../config/Constants";
import axiosService from "../../../../services/axios-service";
import { ReactComponent as IPdfFile } from "../../../../styles/images/svg/file-pdf.svg";
import { ReactComponent as ITextFile } from "../../../../styles/images/svg/file-alt.svg";
import { ReactComponent as IListFile } from "../../../../styles/images/svg/list.svg";
import { ReactComponent as IZipFile } from "../../../../styles/images/svg/file-archive.svg";
import { Link } from "react-router-dom";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { PopupError } from "../../../shared/PopupError";
import { ReactComponent as IEnvelope } from "../../../../styles/images/svgs/solid/envelope.svg";
import EmailiForm from "./EmailiForm";
import Modal from "react-bootstrap/Modal";
import { CreateCommunicationModal } from "./CreateCommunicationModal";
import { CommunicationStatus } from "../../../../config/CommunicationStatus";
import { base64ToArrayBuffer, downloadFile } from "../../../shared/Utility";
import { ReactComponent as IDelete } from "../../../../styles/images/svg/trash-solid.svg";
import { PopupDelete } from "../../../shared/PopupDelete";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";


export const columnSizes = {
  row: {
    checkBox: "w-5",
    creationDate: "w-12",
    referenceYEar: "w-5",
    subject: "w-50",
    fiscalCode: "w-5",
    status: "w-50",
    download: "w-10"

  }
}

class CommunicationsHistoryList extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      emailFormOpen: false,
      communicationToSend: null,
      selectedCommunications: [],
      isAllSelected: false,
      isLoading: false
    };
  }

  componentDidMount() {
    const { subjectId } = this.props;
    this.getHistory(subjectId);
    this.getSubject(subjectId);
    this.getAllCurrency();
  }

  getHistory = (subjectId) => {
    this.props.dispatch(
      actions.getCrsCommunicationsHistoryBySubjectId(subjectId)
    );
  };

/*   getLastCommunicationError = (subjectId) => {
    this.props.dispatch(
      actions.getLastCommunicationsErrorBySubjectId(subjectId)//FIXME questo endpoint si riferisce a CORA
    );
  }; */

  getSubject(subjectId) {
    this.props.dispatch(actions.getCrsSubject(subjectId));
  }

  getAllCurrency() {
    if(!this.props.currencies?.length){
      this.props.dispatch(actions.getAllCurrency());
    }
  }

  downloadReceiptTxt = (communication) => {
    const { subjectId } = this.props;
    let url = `/api/crs/custom/crs-communications/downloadReceiptTxt/${subjectId}/${communication.id}`;
    this.callDownloadService(
      url,
      "ATPECRQ." + communication.sidFilename + ".run.txt"
    );
  };

  downloadDgn = (communication) => {
    const { subjectId } = this.props;
    let url = `/api/crs/custom/crs-communications/downloadDgn/${subjectId}/${communication.id}`;
    this.callDownloadService(
      url,
      communication.xmlFilename.replace(".xml", ".dgn")
    );
  };

  downloadXml = (communication) => {
    const { subjectId } = this.props;
    let url = `/api/crs/custom/crs-communications/downloadXml/${subjectId}/${communication.id}`;
    this.callDownloadService(url, communication.xmlFilename);
  };

  downloadPecTz = (communication) => {
    const { subjectId } = this.props;
    let url = `/api/crs/custom/crs-communications/downloadPecTz/${subjectId}/${communication.id}`;
    this.callDownloadService(
      url,
      "PECAT." + communication.sidFilename + ".zip"
    );
  };

  downloadPdf = (communication) => {

    downloadFile(new Blob([base64ToArrayBuffer(communication.pdf)], { type: "application/pdf" }),communication.xmlFilename.replace(".xml", ".pdf"))
    PopupSuccess({ text: "File scaricato" });

  };

  callDownloadService = (url, fileName) => {
    const axiosInstance = axiosService.getInstance();
    axiosInstance({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      if (response) {
        if (response?.data?.size > 0) {
          downloadFile(new Blob([response.data]), fileName)
          PopupSuccess({ text: "File scaricato" });
        } else {
          PopupError({ ...this.props, text: "File non trovato" });
        }
      }
    });
  };

  openPopupDelete() {
    PopupDelete({
      operazione: `${this.state.selectedCommunications.length} comunicazione/i?`,
      handleClickConfirm: this.onConfirmDeleteSelectedCommunications
    });
  };

  onConfirmDeleteSelectedCommunications = () => {
    this.setState({ isLoading: true });
    let idArray = this.state.selectedCommunications.map(el => el.id);
    actions.deleteCrsCommunications(this.props.subjectId, idArray)
      .then((res) => {
        PopupSuccess({
          text: "Operazione completata con successo"
        })
      }, (err) => {
        let error = Constants.APPLICATION_GENERIC_ERROR;
        if (err?.data?.title) {
          error = err.data.title;
        }
        PopupError({
          text: error
        })
      })
      .then(() => {
        this.getHistory(this.props.subjectId);
        this.setState({ isLoading: false, isAllSelected: false, selectedCommunications: [] })

      });

  }

  showDetail = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  closeEmailModal = () => {
    this.setState({ emailFormOpen: false, communicationToSend: null });
  };
  openEmailModal = (communication) => {
    this.setState({ emailFormOpen: true, communicationToSend: communication });
  };

  renderRowHeader() {
    const row = columnSizes.row;
    return (
      <React.Fragment>
        <div className={`col ${row.checkBox} div-td text-center`}>
          <input type="checkbox" onChange={() => this.onSelectAllCommunications()} />
        </div>
        <div className={`col ${row.creationDate} div-td`}>Data creazione</div>
        <div className={`col ${row.referenceYEar} div-td`}>Periodo</div>
        <div className={`col ${row.subject} div-td`}>Soggetto</div>
        <div className={`col ${row.fiscalCode} div-td`}>Tipo</div>
        <div className={`col ${row.status} div-td`}>Stato</div>
        <div className={`col ${row.download} div-td`}>Scarica</div>
      </React.Fragment>
    );
  }

  renderErrorRowHeader() {
    return (
      <React.Fragment>
        <div className="col w-10 div-td">Data creazione</div>
        <div className="col w-8 div-td">Periodo</div>
        <div className="col w-15 div-td">Soggetto</div>
        <div className="col w-10 div-td">Tipologia di invio</div>
        <div className="col w-8 div-td">Comunicazione</div>
        <div className="col w-15 div-td">Stato</div>
        <div className="col w-50 div-td">Errore</div>
        <div className="col w-10 div-td">Scarica</div>
      </React.Fragment>
    );
  }

  onSelectCommunication(communication) {
    let selected = this.state.selectedCommunications;

    if (selected.find(element => element.id === communication.id)) {
      this.setState({ selectedCommunications: selected.filter(el => el.id !== communication.id) });
    } else {
      selected.push(communication)
      this.setState({ selectedCommunications: selected });
    }

  }

  onSelectAllCommunications() {
    if (this.state.isAllSelected) {
      this.setState({ selectedCommunications: [], isAllSelected: false });
    } else {
      let selected =  this.props.communicationsHistory.filter(el => el.communicationStatus !== CommunicationStatus.RECEIPT_ACCEPTED)
      this.setState({ selectedCommunications: selected, isAllSelected: true })
    }

  }

  renderRows(communications) {
    const row = columnSizes.row;
    return communications.map((communication, index) => {
      /**
       * Se la comunicazione è creata e in attesa: puoi scaricare tutti i file. 
          Se è creata con errori, puoi scaricare l'xml,il pdf ed i log. i Pecat in questo caso non ci sono.
          Se carichi una ricevuta di una comunicazione su crs, puoi scaricare tutti i file 
          Se carichi una ricevuta di una comunicazione che non esiste, puoi scaricare solo l'xml e la ricevuta in chiaro. 
       */
      let status = "";
      let receipt = false; // indica se la ricevuta esiste o abbiamo solo la comunicazione
      let communication_exist = false;//indica se la comunicazione esiste, o se è stata elaborata solo la ricevuta
      let error = false;
      let created =
        communication.communicationStatus === CommunicationStatus.CREATED ||
        communication.communicationStatus ===
        CommunicationStatus.CREATED_WITH_ERROR;
      if (communication.communicationStatus === CommunicationStatus.CREATED) {
        status = "Creata - In attesa di ricevuta (file ATPECRQ." + communication.sidFilename + ".p7m)";
        communication_exist = true;
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.CREATED_WITH_ERROR
      ) {
        communication_exist = true;
        error = true;
        status = "Creata con errori";
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_ACCEPTED
      ) {
        communication_exist = true;
        status = "Comunicazione ACCOLTA TOTALMENTE";
        receipt = true;
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_NOT_ACCEPTED
      ) {
        communication_exist = true;
        status = "Comunicazione SCARTATA TOTALMENTE";
        receipt = true;
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR
      ) {
        status = "Comunicazione ACCOLTA PARZIALMENTE (al netto degli errori indicati)";
        communication_exist = true;
        receipt = true;
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR_AND_COMMUNICATION_NOT_FIND
      ) {
        status = "Ricevuta ACCOLTA CON ERRORI FORMALI, comunicazione non trovata su CRS WEB";
        receipt = true;
        communication_exist = false;
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_ACCEPTED_AND_COMMUNICATION_NOT_FIND
      ) {
        status = "Ricevuta ACCOLTA TOTALMENTE, comunicazione non trovata su CRS WEB";
        receipt = true;
        communication_exist = false;
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_NOT_ACCEPTED_AND_COMMUNICATION_NOT_FIND
      ) {
        status = "Ricevuta SCARTATA TOTALMENTE, comunicazione non trovata su CRS WEB";
        receipt = true;
        communication_exist = false;
      }
      return (
        <React.Fragment key={"communicationHistory-" + communication.id}>
          <div className={`text-start row-table`}>
            <div className={`col ${row.checkBox} text-center div-td`}>
              <input type="checkbox" checked={this.state.selectedCommunications.find(el => el.id === communication.id)}
                disabled ={communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED}
                onChange={() => this.onSelectCommunication(communication)} />
            </div>
            <div className={`col ${row.creationDate} div-td text-truncate`}>
              {moment(communication.creationDate).format(
                Constants.FORMAT_DATE_AND_TIME
              )}
            </div>
            <div className={`col ${row.referenceYEar} div-td text-truncate`}>
              {communication.referenceYear}
            </div>
            <div className={`col ${row.subject} div-td text-truncate`}>
              {communication.subject.companyName}
            </div>
            <div className={`col ${row.fiscalCode} div-td text-truncate`}>
              {communication.relationships ? "Con Rapporti": "Vuota"}
            </div>
            <div className={`col ${row.status} div-td text-truncate`} title={status}>
              {status}
            </div>
            <div className={`col ${row.download} div-td text-truncate d-flex justify-content-around pe-4 ps-4 containerPopOver`}>
              {(created || receipt) && (
                <span
                  data-tip={"Invia COMUNICAZIONE"}
                  onClick={() => this.openEmailModal(communication)}
                >
                  <IEnvelope width="20" fill="#128186" />
                </span>
              )}
              {receipt &&
                <div className="recepit">
                  <span onClick={() => this.downloadReceiptTxt(communication)}>
                    <ITextFile width="20" />
                  </span>
                </div>
              }
              {communication_exist && (
                <>
                  <div className="xml">
                    <span onClick={() => this.downloadXml(communication)}>
                      <ITextFile width="20" fill="#128186" />
                    </span>
                  </div>
                  {!error &&
                    <div className="zip">
                      <span onClick={() => this.downloadPecTz(communication)}>
                        <IZipFile width="20" fill="#128186" />
                      </span>
                    </div>
                  }

                  {this.downloadPdfComponent(communication)}

                  <div className="dgn">
                    <span onClick={() => this.downloadDgn(communication)}>
                      <IListFile width="20" fill="#128186" />
                    </span>
                  </div>
                </>
              )}


            </div>
          </div>
        </React.Fragment>
      );
    });
  }

  downloadPdfComponent(communication) {
    const iconPdf =
      communication?.pdf !== null &&
      communication?.pdf !== "" &&
      communication !== undefined;
    if (!iconPdf) {
      return null;
    }
    return (
      <div className="pdf">
        <span onClick={() => this.downloadPdf(communication)}>
          <IPdfFile width="20" fill="#128186" />
        </span>
      </div>
    );
  }

  renderErrorRows(communication) {
    let status = "";
    if (communication.communicationStatus === CommunicationStatus.CREATED) {
      status = "Creata - In attesa di ricevuta";
    } else if (communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) {
      status = "Creata con errori";
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
      status = "Comunicazione accolta";
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
      status = "Comunicazione NON accolta";
    }
    return (
      <React.Fragment>
        <div
          className="text-start row-table"
          onClick={(e) => {
            if (communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR)
              this.showDetail();
          }}
        >
          <div className="col w-10 div-td text-truncate">
            {moment(communication.creationDate).format(
              Constants.FORMAT_DATE_AND_TIME
            )}
          </div>
          <div className="col w-8 div-td text-truncate">
            {communication.subject.referenceYear}
          </div>
          <div className="col w-15 div-td text-truncate">
            {this.props.subject.companyName}
          </div>
          <div className="col w-10 div-td text-truncate">CRS</div>
          <div className="col w-8 div-td text-truncate">XML</div>
          <div className="col w-15 div-td text-truncate">{status}</div>
          <div className="col w-50 div-td text-truncate">
            {communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR
              ? communication.communicationError
                ? communication.communicationError
                : "Errori presenti nei rapporti inclusi nella comunicazione"
              : ""}
          </div>
          <div className="col w-10 div-td text-truncate d-flex justify-content-around pe-4 ps-4">
            <button alt="Scarica xml " onClick={() => this.downloadXml(communication)}>
              <ITextFile width="20" fill="#128186" />
            </button>
          </div>
        </div>
    {/*     {this.state.show ? (
          <div>
            <CommunicationsHistoryRow
              subject={this.props.subject}
              subjectId={this.props.subjectId}
              relationships={communication.relationships}
              currencies={this.props.currencies}
              getRelationships={this.getHistory}
              getLastError={this.getLastCommunicationError}
            />
          </div>
        ) : (
          ""
        )} */}
      </React.Fragment>
    );
  }

  renderLastCommunicationError() {
    const { lastErrorCommunication } = this.props;
    return (
      <React.Fragment>
        {lastErrorCommunication.creationDate ? (
          <React.Fragment>
            <div className="row header-btn align-items-left">
              ULTIMA COMUNICAZIONE IN ERRORE
            </div>
            <div className="text-start header-table error">
              {this.renderErrorRowHeader()}
            </div>
            {this.renderErrorRows(lastErrorCommunication)}
          </React.Fragment>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

 


  render() {
    const { communicationsHistory } = this.props;
    return (
      <React.Fragment>
        {this.renderLastCommunicationError()}
        {this.state.isLoading && <PageSpinner />}
        <div className="row header-btn-relationship">
          <div className="col-8 row header-btn align-items-left">
            STORICO COMUNICAZIONI
          </div>
          <div className="col-2  row align-items-right">
            <button type="button" className={`btn btn-outline-primary rounded-pill btn-empty px-4 btn-sm margin-left-5px `}
              onClick={() => this.openPopupDelete()}
              disabled={this.state.selectedCommunications.length === 0}><IDelete
                className={"padding-bottom-4px"} width="12" />&nbsp; Elimina selezionati</button>

          </div>
          <div className="col-2 row align-items-right">
            <CreateCommunicationModal
              {...this}
              upload={true}
              subjectId={this.props.subject?.id}
              subject={this.props.subject}
              emailAddress={this.props.subject?.coraSubjectSid?.email}
              getHistory={this.getHistory}
            />
          </div>
        </div>
        {communicationsHistory?.length > 0 ? (
          <React.Fragment>
            <div className="text-start header-table">
              {this.renderRowHeader()}
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
        {this.renderRows(communicationsHistory)}
        <Modal
          backdrop="static"
          show={this.state.emailFormOpen}
          onHide={this.closeEmailModal}
          dialogClassName="modal-60w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>INVIA COMUNICAZIONE SELEZIONATA</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EmailiForm
              {...this.props}
              closeModal={this.closeEmailModal}
              emailAddress={this.props.subject?.coraSubjectSid?.email}
              flagPec={this.props.subject?.coraSubjectSid?.flagPec}
              sentCommunications={[this.state.communicationToSend]}
            />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  let customLocations = state.locations?.data.map((location, index) => {
    return { name: location.location, key: index };
  });
  return {
    subject: state.crsSubject.data,
    communicationsHistory: state.communicationsHistory.data,
    lastErrorCommunication: state.lastErrorCommunication.data,
    relationshipTypes: state.relationshipTypes.data,
    communicationTypes: state.communicationTypes.data,
    currencies: state.currencies.data,
    roles: state.roles.data,
    relationshipLocations: customLocations,
  };
}

export default connect(mapStateToProps)(CommunicationsHistoryList);
