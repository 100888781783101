import React, { useEffect, useState } from 'react';
import { reduxForm } from "redux-form";
import * as actions from "../../../../actions";
import '../../../../styles/comurei-form.css';
import { BwmInput } from "../../../shared/form/BwmInput";
import { BwmCheckbox } from "../../../shared/form/BwmCheckbox";


const UploadReceiptEmailForm = ({
    subjectId
    , emailAddress
    , communication
    , flagPec
    , closeModal
    , fileName }) => {
    const [formData, updateFormData] = useState();
    const [emailAddressLocal, setEmailAddress] = useState(emailAddress);
    const [errorEmailAddress, setErrorEmailAddress] = useState({ isValid: true, msg: "" });
    const [flagPecLocal, setFlagPec] = useState(flagPec || false);

    useEffect(() => {

        updateFormData({
            ...formData,
            id: subjectId,
            emailAddress: emailAddressLocal,
            flagPec: flagPec
        })
    }, []);

    const confirmCreateReceiptEmail = (subjectId, communication, emailAddress, flagPec,type) => {
        actions.sendCoraReceiptMail(subjectId, communication.id, emailAddress, flagPec,type);
        closeModal();
    };

    const handlerChangeEmailAddress = (e) => {
        setEmailAddress(e.target.value.toUpperCase());
        updateFormData({
            ...formData,
            emailAddress: e.target.value.toUpperCase()
        });
        setFlagPec(false);
    };

    const handlerChangeFlagPec = (e) => {
        let checked = e.target.checked;
        setFlagPec(checked);
        updateFormData({
            ...formData,
            flagPec: checked
        });
    };

    const resetError = () => {
        setErrorEmailAddress({ isValid: true, msg: "" });
    };

    const handleValidation = () => {
        resetError();
        let isValid = true;
        if (!emailAddressLocal) {
            setErrorEmailAddress({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        } else if (emailAddress.toUpperCase().indexOf("AGENZIAENTRATE") != -1) {
            setErrorEmailAddress({ isValid: false, msg: "Inserire un destinatario diverso dall'agenzia delle entrate" });
            isValid = false;
        }

        return isValid;
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (handleValidation()) {
            const type = fileName.substring(0,7);//atpeczc e 03 possono trovarsi nella stessa posizione. si rende necessaria una disambiguazione
            confirmCreateReceiptEmail(subjectId, communication, emailAddressLocal, flagPecLocal,type);
        }
    };

    return (
        <form onSubmit={e => handleFormSubmit(e)}>
            <div className="row-detail comurei bg-blue pt-4 pe-3 pb-4 ps-3 mt-5 d-flex radius">
                <div className="tab-content col-md-12 col-lg-12">
                    <div className="form-row">
                        <div className="col-md-12 col-lg-12 subDetailComurei">
                            <div className="row">
                                <div className="col-md-11 col-lg-11">
                                    <BwmInput
                                        id="emailAddress"
                                        type="email"
                                        className="form-control"
                                        label="Indirizzo email a cui spedire la ricevuta in chiaro"
                                        value={emailAddressLocal}
                                        maxLength="100"
                                        onChange={handlerChangeEmailAddress}
                                    />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12 pt-1 pb-1">
                                    <BwmCheckbox
                                        name="flagPec"
                                        label='Selezionare in caso di email PEC'
                                        className='form-control'
                                        value={flagPecLocal}
                                        onClick={handlerChangeFlagPec}
                                    ></BwmCheckbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                <button
                    className="btn btn-empty px-4 btn-sm"
                    onClick={closeModal}>ANNULLA
                </button>
                <button type="submit"
                    className="btn btn-new-rel px-5">CONFERMA
                </button>
            </div>
        </form>
    )

};

export default reduxForm({
    form: "UploadReceiptEmailForm"
})(UploadReceiptEmailForm)
