import {CRS_GET_SUBJECT_SUCCESS, CRS_GET_SUBJECTS_SUCCESS} from "../../actions/types";

const INITIAL_STATE = {
    crsSubjects: {
        data: [],
        errors: []
    },

    crsSubject: {
        data: {},
        errors: []
    }
};

export const crsSubjectsReducer = (state = INITIAL_STATE.crsSubjects, action) => {
    switch (action.type) {
        case CRS_GET_SUBJECTS_SUCCESS:
            return {...state, data: action.crsSubjects};
        default:
            return state;
    }
};

export const crsSubjectReducer = (state = INITIAL_STATE.crsSubject, action) => {
    switch (action.type) {
        case CRS_GET_SUBJECT_SUCCESS:
            return {...state, data: action.crsSubject};
        default:
            return state;
    }
};

