import React from 'react';
import '../../../../styles/comurei-form.css';
import '../../../../styles/arcoTablesModal.css';
import * as actions from "../../../../actions";
import { PopupError } from "../../../shared/PopupError";
import { BwmInput } from "../../../shared/form/BwmInput";
import { BwmCheckbox } from "../../../shared/form/BwmCheckbox";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import { Col } from 'react-bootstrap';
import { CloseButton } from './table-components/CloseButton';
import { CreateNewButton } from './table-components/CreateNewButton';
import { DeleteButton } from './table-components/DeleteButton';
import { ConfirmButton } from './table-components/ConfirmButton';
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants';


class ArcoTableRiskValues extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            update: false,
            add: false,
            show: false,
            indexShow: 0,
            checkedRiskValues: [],

            riskValue: {
                description: "",
                value: "",
                code: "",
                type: "A1",
                def: false,
                arcoSubject: {
                    id: this.props.subject.id
                },
                customer: {
                    id: this.props.subject.customerId
                }
            },
            selectedRiskCategory: undefined,
            errorrCode: { isValid: true, msg: "" },
            errorValue: { isValid: true, msg: "" },
            errorDescription: { isValid: true, msg: "" },
            errorDef: { isValid: true, msg: "" }
        }
    }

    onCheckClick = (event) => {
        if (event.target.checked)
            this.checkAll();
        else
            this.uncheckAll();
    };

    componentDidUpdate(prevProps){
    if (prevProps.arcoTableDomains !==  this.props.arcoTableDomains
    && this.props.arcoTableDomains?.length >0){
        this.setState({selectedRiskCategory: this.props.arcoTableDomains[0]})
    }
}


    checkAll = () => {
        document.getElementById('checkboxAllRiskValueId').checked = true;
        this.props.riskValues.map(riskValue => {
            const checkboxDetailsId = `checkboxRiskValueDetails${riskValue.id}`;
            const idCheckbox = `riskValue-${riskValue.id}`;
            if (document.getElementById(checkboxDetailsId)) {
                document.getElementById(checkboxDetailsId).checked = true;
            }
            if (document.getElementById(idCheckbox)) {
                document.getElementById(idCheckbox).checked = true;
            }
        });
        let rowIds = document.querySelectorAll('[id^="riskValue-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.add("bg-cobalt", "bold");
            }
        }
        this.checkallRiskValues();

    };

    uncheckAll = () => {
        document.getElementById('checkboxAllRiskValueId').checked = false;
        this.props.riskValues.map(riskValue => {
            const checkboxDetailsId = `checkboxRiskValueDetails${riskValue.id}`;
            const idCheckbox = `riskValue-${riskValue.id}`;
            if (document.getElementById(checkboxDetailsId)) {
                document.getElementById(checkboxDetailsId).checked = false;
            }
            if (document.getElementById(idCheckbox)) {
                document.getElementById(idCheckbox).checked = false;
            }
        });
        this.uncheckAllRiskValues();
        let rowIds = document.querySelectorAll('[id^="riskValue-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
    };

    onCheckBoxClick = (event, riskValue) => {
        if (event.target.checked) {
            this.checkRiskValue(riskValue);
            let element = document.getElementById("riskValue-" + riskValue.id);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        } else {
            this.uncheckRiskValue(riskValue);
            document.getElementById('checkboxAllRiskValueId').checked = false;
            let element = document.getElementById("riskValue-" + riskValue.id);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }

    };
    showDetail = (index) => {
        this.setState({
            show: !this.state.show,
            indexShow: index
        })
    };

    updateAll = () => {
        if (!this.handleValidation()) return;

        this.addArcoRiskValue(this.state.riskValue);
        this.setState({
            update: false
        })
        this.resetAll();
    };

    addArcoRiskValue = (riskValue) => {
        actions.saveArcoRiskValue(riskValue).then(
            (response) => {
                console.log("RiskValue updated:" + JSON.stringify(response))
                this.props.getAllRiskValueBySubjectId();

            },
            (error) => {

                PopupError(
                    {
                        ...this.props,
                        text: error.status === 511 ?
                            'Hai raggiunto il numero massimo di soggetti gestibili con questa licenza.'
                            :
                            "Errore nell'aggiornamento del soggetto: " + error.title
                    }
                );
            }
        );

    };
    handleValidation = () => {
        let isValid = true;
        if (!this.state.riskValue.description) {
            this.setState({
                errorDescription: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.riskValue.value) {
            this.setState({
                errorValue: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.riskValue.code) {
            this.setState({
                errorrCode: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        return isValid;
    };

    changeName = (e) => {
        this.setState({
            riskValue: {
                ...this.state.riskValue,
                description: e.target.value
            },
            errorDescription: { isValid: true, msg: "" },
        });
    };
    changeValue = (e) => {
        this.setState({
            riskValue: {
                ...this.state.riskValue,
                value: parseInt(e.target.value)
            },
            errorValue: { isValid: true, msg: "" },
        });
    };

    changeCode = (e) => {
        this.setState({
            riskValue: {
                ...this.state.riskValue,
                code: e.target.value
            },
            errorCode: { isValid: true, msg: "" },
        });
    };


    changeDefault = (e) => {
        this.setState({
            riskValue: {
                ...this.state.riskValue,
                def: e.target.checked
            },
            errorDef: { isValid: true, msg: "" },
        });
    };

    openUpdate = () => {
        this.setState({
            update: !this.state.update,
            errorrCode: { isValid: true, msg: "" },
            errorValue: { isValid: true, msg: "" },
            errorDescription: { isValid: true, msg: "" },
            errorDef: { isValid: true, msg: "" },
            riskValue: {
                ...this.state.riskValue,
                id: null,
                description: "",
                value: "",
                code: "",
                def: false,
                arcoSubject: {
                    id: this.props.subject.id
                },
                customer: {
                    id: this.props.subject.customerId
                }
            }
        })
    };


    resetAll = () => {
        this.setState({
            riskValue: {
                errorrCode: { isValid: true, msg: "" },
                errorValue: { isValid: true, msg: "" },
                errorDescription: { isValid: true, msg: "" },
                errorDef: { isValid: true, msg: "" },
                ...this.state.riskValue,
                id: null,
                description: "",
                value: "",
                code: "",
                def: false,
                arcoSubject: {
                    id: this.props.subject.id
                },
                customer: {
                    id: this.props.subject.customerId
                }
            }
        });
    }

    deleteArcoRiskValueSubject = () => {
        this.state.checkedRiskValues.map(riskValue => {
            actions.deleteArcoRiskValue(this.props.subject.id, riskValue.id).then(
                () => {
                    this.setState({ checkedRiskValues: [] })
                    this.props.getAllRiskValueBySubjectId();
                    //chiudo form se contiene l'elemento cancellato
                    if (this.state.riskValue.id) {
                        this.openUpdate();
                    } else {
                        this.resetAll();
                    }
                },
                (errors) => {
                    PopupError({ text: errors.title || APPLICATION_GENERIC_ERROR })
                })
            document.getElementById(`checkboxRiskValueDetails${riskValue.id}`).checked = false;
        });
        let rowIds = document.querySelectorAll('[id^="riskValue-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }

    };

    checkRiskValue = (riskValue) => {
        let newList = this.state.checkedRiskValues;
        newList.push(riskValue);
        this.setState({ checkedRiskValues: newList });
        if (newList.length === this.props.riskValues.length) {
            this.checkAll();
        }
    };

    uncheckRiskValue = (riskValue) => {
        let newList = [];
        this.state.checkedRiskValues.map(current => {
            if (current.id !== riskValue.id)
                newList.push(current);
        });
        this.setState({ checkedRiskValues: newList })
    };

    checkallRiskValues = () => {
        let allRiskValues = this.props.riskValues;
        this.setState({
            checkedRiskValues: allRiskValues
        });
    };

    uncheckAllRiskValues = () => {
        this.setState({
            checkedRiskValues: []
        });
    };

    onClickCompileUpdate = (e, riskValueSelected) => {
        this.setState({
            errorrCode: { isValid: true, msg: "" },
            errorValue: { isValid: true, msg: "" },
            errorDescription: { isValid: true, msg: "" },
            errorDef: { isValid: true, msg: "" },
            update: true,
            riskValue: {
                ...riskValueSelected,
            }
        });
    };

    getSelectedElement(e) {
        const cod = e.target[e.target.selectedIndex].dataset.cod;

        const selected = this.props.arcoTableDomains.find(a => a.code === cod);

        this.setState({
            update: false,
            riskValue: {
                ...this.state.riskValue,
                type: cod
            },
            selectedRiskCategory:selected

        }
        );
    };
    renderRows(riskValues) {
        return riskValues.map((riskValue) => {
            const idCheckbox = `checkboxRiskValueDetails${riskValue.id}`;
            return (
                <React.Fragment >
                    <div
                        key={`riskValue-${riskValue.id}`}
                        className={'row text-start row-table'}
                        id={`riskValue-${riskValue.id}`}>
                        <div className='col-lg-1 text-center div-td'>
                            <input type="checkbox"
                                id={idCheckbox}
                                onClick={e => this.onCheckBoxClick(e, riskValue)} />
                        </div>
                        <div className='col-lg-1 text-center div-td text-truncate' onClick={e => this.onClickCompileUpdate(e, riskValue)}>
                            {riskValue.code}
                        </div>
                        <div className='col-lg-6 text-center div-td text-truncate' onClick={e => this.onClickCompileUpdate(e, riskValue)}>
                            {riskValue.description}
                        </div>
                        <div className='col-lg-1 text-center div-td text-truncate' onClick={e => this.onClickCompileUpdate(e, riskValue)}>
                            {riskValue.value}
                        </div>
                        <div className='col-lg-3 text-center div-td text-truncate text-center' onClick={e => this.onClickCompileUpdate(e, riskValue)}>
                            {riskValue.def ? "SI" : "NO"}
                        </div>
                    </div>
                </React.Fragment>
            )
        });
    };

    render() {
        let riskValuesData = (this.props.riskValues).filter(riskValue => riskValue.type === this.state.riskValue.type);
        const hasNoCheckedItems = (this.state.checkedRiskValues).length === 0;
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="col-md-6">
                        <BwmSelect
                            options={this.props.arcoTableDomains}
                            value={this.state.selectedRiskCategory?.id }
                            name='table'
                            label='Tabella'
                            className='form-control'
                            showCode={true}
                            onChange={e => this.getSelectedElement(e)}
                        />
                    </div>
                </div>

                <div className="form-row mt-2 mb-2 align-item-center">
                    <div className="col-lg-9">
                        <DeleteButton
                            disabled={hasNoCheckedItems}
                            onClick={this.deleteArcoRiskValueSubject}
                        />
                    </div>
                </div>
                <div className='overflow-auto ms-3 me-3' style={{ height: '408px' }}>
                    <div className={"px-2 mx-2"}>
                        <div className="row text-center header-table">
                            <div className='col-sm-1 text-center div-td'>
                                <input type="checkbox"
                                    id='checkboxAllRiskValueId'
                                    onClick={this.onCheckClick} />
                            </div>
                            <div className='col-lg-1 text-center div-td' ><strong>Codice</strong> </div>
                            <div className='col-lg-6 text-center div-td' ><strong>Descrizione</strong></div>
                            <div className='col-lg-1 text-center div-td' ><strong>Valore</strong> </div>
                            <div className='col-lg-3 text-center div-td' ><strong>Default</strong> </div>
                        </div>
                        {this.renderRows(riskValuesData)}
                    </div>
                </div>
                <div>
                    <div className={` row me-2 ms-2 mt-4 mb-4 ${!this.state.update && 'invisible'}`} >

                        <div className='col-2'>
                            <BwmInput name='code'
                                label='Codice'
                                className='form-control'
                                onChange={e => this.changeCode(e)}
                                error={this.state.errorrCode}
                                value={this.state.riskValue.code}
                                maxLength="5"
                            />
                        </div>
                        <div className='col-5'>
                            <BwmInput name='description'
                                label='Descrizione'
                                onChange={e => this.changeName(e)}
                                value={this.state.riskValue.description}
                                className='form-control'
                                error={this.state.errorDescription}
                            />
                        </div>
                        <div className="col-1">
                            <BwmInput name='value'
                                label="Valore"
                                className='form-control text-end'
                                onChange={e => this.changeValue(e)}
                                error={this.state.errorValue}
                                value={this.state.riskValue.value}
                                type={"number"}
                            />
                        </div>
                        <div className="form-check col-1">
                            <label>Default</label>
                            <div className="input-group mt-2 d-flex justify-content-between">
                                <BwmCheckbox
                                    name="ability"
                                    value={this.state.riskValue.def}
                                    onClick={e => this.changeDefault(e)}
                                    className='form-control'
                                    error={this.state.errorDef}

                                />
                            </div>
                        </div>
                        <div className='col-3 d-flex justify-content-end align-items-center'>
                            <ConfirmButton
                                onClick={this.updateAll}
                            />
                        </div>
                    </div>
                    <div className="form-row mt-2 align-item-center">
                        <Col lg={2}>
                            <CloseButton
                                onClick={this.props.onClose}
                            />
                        </Col>
                        <Col lg={8} className={"text-center"}>
                            <CreateNewButton
                                disabled={this.props.disableInsert}
                                onClick={this.openUpdate}
                                active={this.state.update} />

                        </Col>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}

export default ArcoTableRiskValues
